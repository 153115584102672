import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ValidationElement } from "../../api/types";
import { useState } from "react";
import {
  annexIIITag,
  annexIITag,
  annexITag,
  annexIVTag,
  annexVITag,
  annexVTag,
  errorIdAnnexIIIRef,
  errorIdAnnexIIRef,
  errorIdAnnexIRef,
  errorIdAnnexIVRef,
  errorIdAnnexVIRef,
  errorIdAnnexVRef,
  errorIdCELEXRef,
  errorIdESMARef,
  errorIdIx11Ref,
  errorIdXBRLRef,
  eSEFRTSTag,
  eSEFTag,
  xbrlSubStringValue,
  xBrlTag,
  ix11Tag,
  reportErrorColor,
  warningColor,
  errorColor,
  inconsistencyColor,
  infoColor,
  internalValidationErrorIds,
  calc11,
  errorIdcalc11,
} from "../../helpers/constants";
import { dataKey } from "../../helpers/downloadPageAsPdf";
import { t, Trans } from "@lingui/macro";
import ErrorElementIcons from "./errorElementIcons";
import { getUniqueErrorMessages } from "../../helpers/getUniqueErrorMessages";
import { TableCellSeverity } from "./validationSummaryComponents/tableCellSeverity";

export interface ErrorShowCaseProps {
  title: string;
  data: Array<ValidationElement>;
  shouldCombine: boolean;
  showCalcInconsistencyToggle?: boolean;
}

export const ErrorShowCase = (props: ErrorShowCaseProps) => {
  let FatalError = 0;
  let errors = 0;
  let warnings = 0;
  let inconsistencies = 0;
  var info = 0;
  const [hideErrors, setHideErrors] = useState(false);
  const [hideWarnings, setHideWarnings] = useState(false);
  const [hideRoundingErrors, setHideRoundingErrors] = useState(false);

  const [expanded, setExpanded] = useState(true);

  const combinedData: ValidationElement[] = props.shouldCombine
    ? getUniqueErrorMessages(props.data)
    : props.data;

  if (props !== null || props !== undefined || combinedData !== null) {
    try {
      combinedData.forEach((entry: ValidationElement) => {
        if (entry.severity === "FatalError") {
          FatalError++;
        }
        if (entry.severity === "Error") {
          errors++;
        }
        if (entry.severity === "Warning") {
          warnings++;
        }
        if (
          entry.severity === "Inconsistency" &&
          (!hideRoundingErrors ||
            (hideRoundingErrors && !entry.isRoundingError))
        ) {
          inconsistencies++;
        }
        if (entry.severity === "Info") {
          info++;
        }
      });
    } catch {
      console.log("Error in ErrorShowcase");
    }
  }

  const pdfGeneratorDataKey = dataKey(props.title);

  return props.data.length === 0 ? null : (
    <Accordion
      expanded={expanded}
      sx={{ overflowX: "hidden", overflowY: "auto", mb: 2 }}>
      <AccordionSummary
        sx={{ zIndex: 1 }}
        onClick={() => setExpanded(!expanded)}
        expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h6"
          sx={{ mr: 2 }}
          data-downloadaspdf={`${pdfGeneratorDataKey}header`}>
          {props.title}
        </Typography>
        {FatalError > 0 && (
          <Typography
            variant="h6"
            sx={{ color: errorColor, pr: 0.5, pl: 0.5 }}
            display="inline">
            {moreThanOneError(errors, "FatalError")}
          </Typography>
        )}
        {FatalError > 0 && errors > 0 && (
          <Typography display="inline" sx={{ pl: 0.5, pr: 0.5 }} variant="h6">
            &
          </Typography>
        )}
        {errors > 0 && (
          <Typography
            variant="h6"
            sx={{ color: errorColor, pr: 0.5, pl: 0.5 }}
            display="inline">
            {moreThanOneError(errors, "Error")}
          </Typography>
        )}
        {errors > 0 && warnings > 0 && (
          <Typography display="inline" sx={{ pl: 0.5, pr: 0.5 }} variant="h6">
            &
          </Typography>
        )}
        {warnings > 0 && (
          <Typography
            sx={{ color: warningColor, pr: 0.5, pl: 0.5 }}
            display="inline"
            variant="h6">
            {moreThanOneError(warnings, "Warning")}
          </Typography>
        )}
        {warnings > 0 && inconsistencies > 0 && (
          <Typography display="inline" sx={{ pl: 0.5, pr: 0.5 }} variant="h6">
            &
          </Typography>
        )}
        {inconsistencies > 0 && (
          <Typography
            sx={{ color: inconsistencyColor, pr: 0.5, pl: 0.5 }}
            display="inline"
            variant="h6">
            {moreThanOneError(inconsistencies, "Inconsistency")}
          </Typography>
        )}
        {inconsistencies > 0 && info > 0 && (
          <Typography display="inline" sx={{ pl: 0.5, pr: 0.5 }} variant="h6">
            &
          </Typography>
        )}
        {info > 0 && (
          <Typography
            sx={{ color: infoColor, pr: 0.5, pl: 0.5 }}
            display="inline"
            variant="h6">
            {moreThanOneError(info, "Info")}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}>
          {errors > 0 && warnings > 0 ? (
            <FormControlLabel
              onClick={(e) => {
                e.stopPropagation();
                setHideWarnings(!hideWarnings);
              }}
              sx={{ pl: 1, pr: 1 }}
              control={
                <Switch
                  checked={hideWarnings}
                  onChange={(e) => {
                    e.stopPropagation();
                    setHideWarnings(!hideWarnings);
                  }}
                  name="loading"
                  color="primary"
                />
              }
              label={t`Hide Errors`}
            />
          ) : null}
          {warnings > 0 && errors > 0 ? (
            <FormControlLabel
              onClick={(e) => {
                e.stopPropagation();
                setHideErrors(!hideErrors);
              }}
              control={
                <Switch
                  checked={hideErrors}
                  onChange={(e) => {
                    e.stopPropagation();
                    setHideErrors(!hideErrors);
                  }}
                  name="loading"
                  color="primary"
                />
              }
              label={t`Hide Warnings`}
            />
          ) : null}
          {props.showCalcInconsistencyToggle ? (
            <FormControlLabel
              control={
                <Switch
                  checked={hideRoundingErrors}
                  onChange={(e) => {
                    e.stopPropagation();
                    setHideRoundingErrors((prev) => !prev);
                  }}
                  name="loading"
                  color="primary"
                />
              }
              label={t`Hide Rounding Errors`}
            />
          ) : null}
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>
                  <Trans>Severity</Trans>
                </TableCell>
                <TableCell
                  data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>
                  <Trans>Error Id</Trans>
                </TableCell>
                <TableCell
                  data-downloadaspdf={`${pdfGeneratorDataKey}columnheader`}>
                  <Trans>Message</Trans>
                </TableCell>
                {props.data.some(
                  (ve) => ve.elements && ve.elements.length > 0
                ) && <TableCell sx={{ width: 60 }}></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedData
                .filter((entry: ValidationElement) => {
                  if (hideErrors) {
                    return entry.severity === "Error";
                  } else {
                    return true;
                  }
                })
                .filter((entry: ValidationElement) => {
                  if (hideWarnings) {
                    return entry.severity === "Warning";
                  } else {
                    return true;
                  }
                })
                .filter(
                  (entry: ValidationElement) =>
                    !hideRoundingErrors ||
                    (hideRoundingErrors && !entry.isRoundingError)
                )
                .sort((a: ValidationElement, b: ValidationElement) => {
                  if (a.severity === "Error" && b.severity === "Warning") {
                    return -1;
                  } else if (
                    a.severity === "Warning" &&
                    b.severity === "Error"
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((entry: ValidationElement, index: number) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        maxWidth: 50,
                        width: 40,
                        verticalAlign: "top",
                      }}>
                      <Typography
                        noWrap
                        data-downloadaspdf={pdfGeneratorDataKey + "level"}
                        data-cellcolor={reportErrorColor(entry.severity)}
                        sx={{
                          fontSize: 12,
                        }}
                        color={reportErrorColor(entry.severity)}>
                        {entry.severity}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 250,
                        width: 100,
                        verticalAlign: "top",
                      }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                        }}
                        title={entry.code}
                        noWrap
                        variant="subtitle2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={includesError(entry.code)}
                          data-downloadaspdf={`${pdfGeneratorDataKey}errorId`}>
                          {entry.code}
                        </a>
                      </Typography>
                    </TableCell>
                    {entry.explanation && (
                      <CustomWidthTooltip
                        title={
                          <Grid
                            paddingTop={0.5}
                            paddingBottom={0.5}
                            paddingLeft={1}
                            paddingRight={1}
                            display={"flex"}
                            justifyContent={"center"}
                            alignContent={"center"}>
                            {entry.explanation ? (
                              <Grid
                                display={"flex"}
                                justifyContent={"center"}
                                alignContent={"center"}
                                flexDirection={"row"}
                                alignItems={"center"}>
                                <Typography sx={{ fontSize: 14 }}>
                                  {entry.explanation}
                                </Typography>
                                {entry.link && (
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      ml: 1,
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      window.open(entry.link, "_blank");
                                    }}>
                                    {"More information"}
                                  </Typography>
                                )}
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                        }>
                        {TableCellSeverity(
                          entry.message,
                          entry.severity,
                          400,
                          `${pdfGeneratorDataKey}message`,
                          entry.leadingText
                        )}
                      </CustomWidthTooltip>
                    )}
                    {!entry.explanation && (
                      <>
                        {TableCellSeverity(
                          entry.message,
                          entry.severity,
                          400,
                          `${pdfGeneratorDataKey}message`,
                          entry.leadingText
                        )}
                      </>
                    )}
                    {entry.elements &&
                      entry.elements.length > 0 &&
                      !Object.values(internalValidationErrorIds).includes(
                        entry.id
                      ) && <ErrorElementIcons entry={entry} />}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

const includesError = (s: string) => {
  let link;
  if (s.includes(eSEFTag)) {
    if (s.includes(eSEFRTSTag)) {
      if (s.includes(annexITag)) {
        link = errorIdAnnexIRef;
      } else if (s.includes(annexIITag)) {
        link = errorIdAnnexIIRef;
      } else if (s.includes(annexIIITag)) {
        link = errorIdAnnexIIIRef;
      } else if (s.includes(annexIVTag)) {
        link = errorIdAnnexIVRef;
      } else if (s.includes(annexVTag)) {
        link = errorIdAnnexVRef;
      } else if (s.includes(annexVITag)) {
        link = errorIdAnnexVIRef;
      } else {
        link = errorIdCELEXRef;
      }
    } else {
      link = errorIdESMARef;
    }
  } else if (s.includes(xBrlTag)) {
    link =
      errorIdXBRLRef +
      s
        .split(":")
        .pop()
        ?.substring(0, xbrlSubStringValue); /* Add substring of 7? */
  } else if (s.includes(ix11Tag)) {
    link = errorIdIx11Ref;
  } else if (s.includes(calc11)) {
    link = errorIdcalc11;
  }

  return link;
};

type PickOnly = "FatalError" | "Error" | "Warning" | "Info" | "Inconsistency";

export const moreThanOneError = (errors: number, name: PickOnly) => {
  if (errors > 1) {
    if (name === "FatalError") {
      return errors + " " + t`Fatal Errors`;
    } else if (name === "Error") {
      return errors + " " + t`Errors`;
    } else if (name === "Warning") {
      return errors + " " + t`Warnings`;
    } else if (name === "Info") {
      return errors + " " + t`Info`;
    } else if (name === "Inconsistency") {
      return errors + " " + t`Inconsistencies`;
    }
  } else if (errors === 1) {
    if (name === "FatalError") {
      return errors + " " + t`Fatal Error`;
    } else if (name === "Error") {
      return errors + " " + t`Error`;
    } else if (name === "Warning") {
      return errors + " " + t`Warning`;
    } else if (name === "Info") {
      return errors + " " + t`Info`;
    } else if (name === "Inconsistency") {
      return errors + " " + t`Inconsistency`;
    }
  }
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});
