import {
  Alert,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getCachedText } from "../../helpers/getCachedPackage";
import { getSanitizeReportHtml } from "../../helpers/getSanitizeReportHtml";
import LoadinDialog from "../loadingDialog";
import {
  MarkedArea,
  getDebugReportMarkedAreasDistinct,
} from "../../helpers/getDebugReportMarkedAreas";
import { Trans } from "@lingui/macro";
import { delayAsync } from "../../helpers/delayAsync";

interface DebugReportContainerProps {
  onInitReportRendering: () => void;
}

const DebugReportContainer = ({
  onInitReportRendering,
}: DebugReportContainerProps) => {
  const importedReportUrl = useAppSelector(
    (state) => state.debug.downloadedReportArtifactUrl
  );
  const [showRenderingDialog, setShowRenderingDialog] = useState(false);
  const [sanitizedReportHtml, setSanitizedReportHtml] = useState("");
  const [markedAreas, setMarkedAreas] = useState<MarkedArea[]>([]);
  const [allIframeElements, setAllIframeElements] = useState<HTMLElement[]>([]);
  const [selectedArea, setSelectedArea] = useState<MarkedArea>();
  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (importedReportUrl !== "") {
      handleReportInitialRendering();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedReportUrl]);

  const handleReportInitialRendering = async () => {
    onInitReportRendering();
    setShowRenderingDialog(true);
    const reportHtml = await getCachedText(importedReportUrl);
    let sanitizedHtml = getSanitizeReportHtml(reportHtml);
    const markedAreas = getDebugReportMarkedAreasDistinct(sanitizedHtml);
    console.log(markedAreas);
    setMarkedAreas(markedAreas);
    setSanitizedReportHtml(sanitizedHtml);
    await delayAsync(5000);
    if (showRenderingDialog) {
      setShowRenderingDialog(false);
    }
  };

  const handleIframeLoaded = async (e: React.MouseEvent<HTMLIFrameElement>) => {
    setAllIframeElements(
      Array.from(
        document
          .querySelector("iframe")
          ?.contentDocument?.querySelectorAll("*") || []
      )
    );
    setShowRenderingDialog(false);
  };

  useEffect(() => {
    if (selectedArea) {
      const rawAreaId = `${selectedArea.label.replaceAll(" ", "_")}#-#${
        selectedArea.id
      }`;
      const iframeDoc = document.querySelector("iframe")?.contentDocument;
      if (iframeDoc) {
        const startAndEndElems = Array.from(
          iframeDoc.querySelectorAll(`[data-debug-area-id*="${rawAreaId}"]`)
        );
        if (startAndEndElems.length === 2) {
          const startIndex = allIframeElements.indexOf(
            startAndEndElems[0] as HTMLElement
          );
          const endIndex = allIframeElements.indexOf(
            startAndEndElems[1] as HTMLElement
          );
          let index = 0;
          for (const element of allIframeElements) {
            if (element.tagName.toLowerCase() === "span") {
              if (index >= startIndex && index <= endIndex) {
                element.style.background = "#00FF30";
              } else {
                element.style.background = "";
              }
            }

            index++;
          }
          startAndEndElems[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea]);

  return (
    <>
      {sanitizedReportHtml !== "" && (
        <Paper
          ref={(node) => {
            containerRef.current = node;
          }}
          variant="outlined"
          sx={{
            position: "relative",
          }}>
          <Drawer
            variant="permanent"
            PaperProps={{ style: { position: "absolute" } }}
            ModalProps={{
              container: containerRef.current,
              style: { position: "absolute" },
            }}
            anchor="left"
            open={true}
            sx={{
              width: 250,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: 250, boxSizing: "border-box" },
            }}>
            <Typography variant="h6" textAlign="center">
              <Trans>Marked Areads</Trans>
            </Typography>
            <Divider />
            <>
              {markedAreas.length === 0 && (
                <Alert variant="filled" severity="info">
                  <Trans>No marked areas found</Trans>
                </Alert>
              )}
              {markedAreas.length > 0 && (
                <>
                  <List>
                    {markedAreas.map((markedArea, i) => (
                      <ListItem disablePadding key={`${markedArea.id}_${i}`}>
                        <ListItemButton
                          disableRipple
                          onClick={() => setSelectedArea(markedArea)}
                          sx={{
                            background: (theme) =>
                              selectedArea?.id === markedArea.id
                                ? theme.palette.info.light
                                : "",
                            "&:hover": {
                              background: (theme) =>
                                selectedArea?.id === markedArea.id
                                  ? theme.palette.info.light
                                  : "",
                            },
                          }}>
                          <ListItemText primary={markedArea.label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </>
          </Drawer>
          <div
            style={{
              minHeight: "83vh",
              maxHeight: "83vh",
              marginLeft: 250,
              position: "relative",
            }}>
            <iframe
              onLoad={handleIframeLoaded}
              title="debug_report_viewer"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              srcDoc={sanitizedReportHtml}
              sandbox="allow-same-origin"
              loading="lazy"
            />
          </div>
        </Paper>
      )}

      {showRenderingDialog && <LoadinDialog title="Rendering report" />}
    </>
  );
};

export default DebugReportContainer;
