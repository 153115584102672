import { Trans } from "@lingui/macro";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { IApiOrganization } from "../../api/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface OrganizationDetailsAccordionProps {
  org: IApiOrganization;
}

const OrganizationDetailsAccordion = ({
  org,
}: OrganizationDetailsAccordionProps) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary
      sx={{ pl: 3 }}
      expandIcon={<ExpandMoreIcon />}
      id="user_details_accordion">
      <Typography>
        <Trans>Organization Information</Trans>
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ minHeight: "250px", maxHeight: "250px" }}>
      <Stack
        sx={{ pl: 1, pr: 1 }}
        spacing={1}
        divider={<Divider orientation="horizontal" flexItem />}>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Name</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={org.name ?? "N/A"}>
            {org.name ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Identifier</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={org.id ?? "N/A"}>
            {org.id ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Country</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={org.country ?? "N/A"}>
            {org.country ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Domain</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={org.domain ?? "N/A"}>
            {org.domain ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Registration Number</Trans>:
          </Typography>
          <Typography
            variant="body2"
            noWrap
            title={org.registrationNumber ?? "N/A"}>
            {org.registrationNumber ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>LEI Code</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={org.leiCode ?? "N/A"}>
            {org.leiCode ?? "N/A"}
          </Typography>
        </Box>
      </Stack>
    </AccordionDetails>
  </Accordion>
);

export default OrganizationDetailsAccordion;
