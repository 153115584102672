import { defaultConvertedPackageName } from "./constants";

export const getFileNameFromHeader = (headerValue: string): string => {
  let output = defaultConvertedPackageName;
  if (!headerValue) return output;
  const sections = headerValue.split(";");
  if (sections.length < 2) return output;
  const sectionArray = sections[1].split("=");
  if (sectionArray.length < 2) return output;
  output = sectionArray[1];
  if (output[0] === '"' && output[output.length - 1] === '"') {
    output = output.substring(1, output.length - 1);
  }
  return output;
};
