import { t, Trans } from "@lingui/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  CircularProgress,
  Alert,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { exportToExcel } from "../api/taxonomies";
import { downloadReportFile } from "../helpers/downloadReportFile";

export interface ExcelSheetConfig {
  sheetName: string;
  serializedCollection: string;
  dataType:
    | "Fact"
    | "EurofilingFact"
    | "ChangedFact"
    | "ChangedLinkbaseTree"
    | "SummaryValidationSummary"
    | "SummaryGeneralInformation"
    | "SummaryTaxonomyPackage"
    | "SummaryZipContent"
    | "SummaryErrorLog"
    | "SummaryValidationLog"
    | "SummaryImportErrors"
    | "MandatoryTags";
}

export interface ExcelExportDialogProps {
  open: boolean;
  actionUrl: string;
  actionBody: { sheets: (ExcelSheetConfig | undefined)[] } | {} | undefined;
  onClose: () => void;
  title: string;
}

const ExcelExportDialog = ({
  open,
  actionUrl,
  actionBody,
  onClose,
  title,
}: ExcelExportDialogProps) => {
  const [generating, setGenerating] = useState(false);
  const [generateError, setGenerateError] = useState("");

  const handleClose = () => {
    setGenerating(false);
    setGenerateError("");
    onClose();
  };

  const callAction = async (): Promise<void> => {
    try {
      setGenerateError("");
      setGenerating(true);
      const { blob, name } = await exportToExcel(actionUrl, actionBody);
      downloadReportFile(blob, name);
      handleClose();
    } catch (ex) {
      setGenerating(false);
      setGenerateError(t`Could not export data`);
    }
  };

  useEffect(() => {
    if (open && !generating && !generateError) {
      callAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, generateError, generating]);

  return (
    <Dialog open={open} onClose={(e: any) => e.preventDefault()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          {generating && <CircularProgress sx={{ margin: "0 auto" }} />}
          {!generating && generateError && (
            <Alert severity="error">{generateError}</Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExcelExportDialog;
