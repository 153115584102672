import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { refreshToken } from "../api/accounts";
import tokenHelper from "../helpers/tokenHelper";
import Loader from "./loader";

let isRefreshingToken = false;
const Protected = ({ children }: PropsWithChildren) => {
  const [needsRefresh, setNeedsRefresh] = useState<boolean>(
    tokenHelper.isAboutToExpire && tokenHelper.isRefreshable
  );

  useEffect(() => {
    if (needsRefresh && !isRefreshingToken) {
      isRefreshingToken = true;
      refreshToken().finally(() => {
        isRefreshingToken = false;
        setNeedsRefresh(false);
      });
    }
  }, [needsRefresh]);

  if (needsRefresh) {
    return <Loader />;
  } else if (!tokenHelper.isValid) {
    return (
      <>
        <Navigate to="/login" replace={true} />
      </>
    );
  }
  return <>{children}</>;
};

export default Protected;
