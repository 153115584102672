import {
  ChangedProperty,
  ContextDimension,
  FactPeriod,
  IApiFact,
  IApiFactComparisonResult,
  LabeledElement,
  ExtractedLinkbaseTree,
  ValidationElement,
  IApiEurofilingFact,
} from "../api/types";
import { IExtractionState } from "../reducers/extractionReducer";
import {
  getFactDisplayLabelFromLebeledElements,
  getFactDisplayLabelFromLinkbaseTree,
} from "./getFactDisplayLabel";
import { getInternalValidationEntriesForFact } from "./getInternalValidationEntriesForFact";
import { getValueString } from "./getValueString";

export type DataDisplayObject = { id: string | number };

export interface ChangedFactDisplayItem
  extends Omit<FactDisplayItem, "relatedErrors"> {
  changeType: IApiFactComparisonResult["state"];
  changedProps?: ChangedProperty[];
}

export interface FactDisplayItem extends DataDisplayObject {
  orderOfAppearance: number;
  id: string;
  label: string;
  name: string;
  language: string;
  unit: string;
  formattedValue: string;
  escape: string;
  isHidden: string;
  balanceType: string;
  periodType: string;
  itemType: string;
  decimals: string;
  scale: string;
  periodStart: string;
  periodEnd: string;
  dimensions: string;
  dimensionMembers: string;
  wideAnchor: string;
  narrowerAnchor: string;
  footnotes: string;
  contextRef: string;
  contextElementName: string;
  relatedErrors: ValidationElement[];
  isValueHtml: boolean;
}

export interface EuroFilingFactDisplayItem extends DataDisplayObject {
  orderOfAppearance: number;
  id: string;
  formattedValue: string;
  tableName: string;
  unit: string;
  rowCode: string;
  columnCode: string;
  dimensionValues: Record<string, string>;
}

export const factDisplayItemDateKeys = ["periodStart", "periodEnd"];

const getContextDimensionValues = (
  dimensions: ContextDimension[] | undefined
): { dimensionsValue: string; dimensionMemberValue: string } => {
  const output = {
    dimensionsValue: "",
    dimensionMemberValue: "",
  };
  if (dimensions && dimensions.length > 0) {
    output.dimensionsValue = dimensions
      .map((d) => d.dimension.elementId.replace("_", ":"))
      .join(";");
    output.dimensionMemberValue = dimensions
      .map((d) => d.dimensionMember.elementId.replace("_", ":"))
      .join(";");
  }
  return output;
};

const getPeriodValue = (
  period: FactPeriod | undefined,
  isStart: boolean
): string => {
  let output = "";
  if (!period) return output;
  if (period.type === "Instant" && period.value) {
    output = period.value;
  }
  if (period.type === "Duration") {
    if (isStart) output = period.startTime;
    else output = period.endTime;
  }
  if (!output) return output;
  return new Date(output).toLocaleDateString();
};

const mapStandardFactItemForDisplay = (
  rawItem: IApiFact,
  rawItems: IApiFact[],
  lang: string,
  presentationTree: ExtractedLinkbaseTree | undefined,
  labeledElements: LabeledElement[] | undefined,
  factRelatedValidationEntries?: ValidationElement[]
): FactDisplayItem => {
  const itemLang =
    rawItem?.factElement?.language ||
    rawItem?.contextRef?.language ||
    rawItem?.factElement?.label?.lang ||
    lang;
  let label = presentationTree?.roleTypes?.every(
    (rt) => rt.elementTrees && rt.elementTrees.length > 0
  )
    ? getFactDisplayLabelFromLinkbaseTree(
        rawItem.factElement,
        presentationTree,
        lang || "en"
      )
    : getFactDisplayLabelFromLebeledElements(
        rawItem.factElement,
        labeledElements,
        lang
      );
  const dimensionValues = getContextDimensionValues(
    rawItem.contextRef.context?.scenario?.dimensions
  );
  return {
    orderOfAppearance: rawItem.order,
    balanceType: rawItem.factElement.balanceType,
    decimals: rawItem.contextRef.decimals?.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    dimensionMembers: dimensionValues.dimensionMemberValue,
    dimensions: dimensionValues.dimensionsValue,
    footnotes: rawItem.footnotes?.map((fn) => fn.value).join(";") || "",
    itemType: rawItem.factElement?.type,
    language: itemLang ? itemLang : label?.language || "",
    label: label?.label || rawItem.factElement?.label?.value || "",
    narrowerAnchor: rawItem.factElement?.anchoredFrom?.join(";") || "",
    periodEnd: getPeriodValue(rawItem.contextRef?.context?.period, false),
    periodStart: getPeriodValue(rawItem.contextRef?.context?.period, true),
    periodType: rawItem.factElement?.periodType,
    wideAnchor: rawItem.factElement?.anchoredTo,
    name: `${rawItem.factElement?.namespace.abbreviation}:${rawItem.factElement?.name}`,
    formattedValue: getValueString(
      rawItem.formattedValue,
      rawItem.contextRef.unitRef || rawItem.contextRef.unit?.unitId,
      rawItem.contextRef.decimals,
      false
    ),
    escape: rawItem.contextRef.escape.toString(),
    isHidden: rawItem.contextRef.isHidden.toString(),
    id: rawItem.id,
    scale: rawItem.contextRef?.scale?.toString() || "",
    unit:
      rawItem.contextRef?.unit?.type === "Simple"
        ? rawItem.contextRef?.unit?.measure.split(":")[1] || ""
        : rawItem.contextRef?.unit?.divide?.numerators[0].split(":")[1] || "",
    contextElementName: rawItem.contextRef.name,
    contextRef: rawItem.contextRef.contextRef,
    isValueHtml:
      rawItem.contextRef?.escape &&
      rawItem.factElement?.type?.includes("textBlockItemType"),
    relatedErrors:
      factRelatedValidationEntries && factRelatedValidationEntries.length > 0
        ? [
            ...factRelatedValidationEntries.filter((entry) =>
              entry?.elements?.find(
                (elem) =>
                  elem.name === rawItem.contextRef?.name &&
                  elem.contextRef === rawItem.contextRef?.contextRef
              )
            ),
            ...getInternalValidationEntriesForFact(
              rawItem,
              rawItems.filter((ri) => ri.id !== rawItem.id),
              "standard"
            ),
          ]
        : [
            ...getInternalValidationEntriesForFact(
              rawItem,
              rawItems.filter((ri) => ri.id !== rawItem.id),
              "standard"
            ),
          ],
  };
};

const mapStandardFactItemsForDisplay = (
  rawItems: IApiFact[],
  lang: string,
  presentationTree: ExtractedLinkbaseTree | undefined,
  labeledElements: LabeledElement[] | undefined,
  factRelatedValidationEntries?: ValidationElement[]
): FactDisplayItem[] => {
  return rawItems.map((rawItem: IApiFact, _, rawItems: IApiFact[]) =>
    mapStandardFactItemForDisplay(
      rawItem,
      rawItems,
      lang,
      presentationTree,
      labeledElements,
      factRelatedValidationEntries
    )
  );
};

export const mapFactItemsForDisplay = (
  rawItems: Array<IApiFact | IApiEurofilingFact>,
  type: IExtractionState["factType"],
  lang: string,
  presentationTree: ExtractedLinkbaseTree | undefined,
  labeledElements: LabeledElement[] | undefined,
  factRelatedValidationEntries?: ValidationElement[]
): Array<FactDisplayItem | EuroFilingFactDisplayItem> => {
  if (type === "standard") {
    return mapStandardFactItemsForDisplay(
      rawItems as IApiFact[],
      lang,
      presentationTree,
      labeledElements,
      factRelatedValidationEntries
    );
  } else if (type === "eurofiling") {
    return (rawItems as IApiEurofilingFact[]).map(
      (euroFact: IApiEurofilingFact) => {
        // eslint-disable-next-line
        return <EuroFilingFactDisplayItem>{
          orderOfAppearance: euroFact.order,
          formattedValue: euroFact.value,
          ...euroFact,
        };
      }
    );
  }
  return [];
};

export const mapFactChangeItemsForDisplay = (
  changeItems: IApiFactComparisonResult[],
  allFacts: IApiFact[],
  lang: string,
  presentationTree?: ExtractedLinkbaseTree
): ChangedFactDisplayItem[] => {
  const output: ChangedFactDisplayItem[] = [];
  for (const changeItem of changeItems) {
    if (changeItem.state === "Modified") {
      const relatedFact = allFacts.find(
        (f) => f.id === changeItem.currentFactId
      );
      if (relatedFact) {
        const changedProps: ChangedProperty[] = [];
        if (
          changeItem.contextRefElementComparison &&
          changeItem.contextRefElementComparison.state === "Modified"
        ) {
          changedProps.push(
            ...(changeItem.contextRefElementComparison.changedProperties || [])
          );
          if (
            changeItem.contextRefElementComparison.unitComparison?.state ===
            "Modified"
          ) {
            changedProps.push(
              ...(changeItem.contextRefElementComparison.unitComparison
                .changedProperties || [])
            );
          }
        }
        if (
          changeItem.factElementComparison &&
          changeItem.factElementComparison.state === "Modified"
        ) {
          changedProps.push(
            ...(changeItem.factElementComparison.changedProperties || [])
          );
        }
        if (changeItem.formattedValueChangedProperty) {
          changedProps.push(changeItem.formattedValueChangedProperty);
        }
        output.push({
          ...mapStandardFactItemForDisplay(
            relatedFact,
            allFacts,
            lang,
            presentationTree,
            undefined
          ),
          changeType: "Modified",
          changedProps: changedProps,
        });
      }
    } else if (changeItem.state === "Added") {
      const relatedFact = allFacts.find(
        (f) => f.id === changeItem.currentFactId
      );
      if (relatedFact) {
        output.push({
          ...mapStandardFactItemForDisplay(
            relatedFact,
            allFacts,
            lang,
            presentationTree,
            undefined
          ),
          changeType: "Added",
        });
      }
    } else if (changeItem.state === "Deleted" && changeItem.expectedFact) {
      output.push({
        ...mapStandardFactItemForDisplay(
          changeItem.expectedFact,
          allFacts,
          lang,
          presentationTree,
          undefined
        ),
        changeType: "Deleted",
      });
    }
  }
  return output;
};
