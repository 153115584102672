export const findPropertyValueByPath = (obj: any, path: string): any => {
  const paths = path.replaceAll("_", ".").split(".");
  let current = obj;
  for (let i = 0; i < paths.length; i++) {
    if (current[paths[i]] === undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
};
