import { t } from "@lingui/macro";
import { SxProps, Theme, Tooltip } from "@mui/material";
import { ChangedProperty } from "../../api/types";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useMemo } from "react";
import { ChangedFactDisplayItem } from "../../helpers/mapFactItems";

interface ModifiedValueDisplayIconProps {
  displayPropName: string;
  changedProps?: ChangedProperty[];
  sxProps?: SxProps<Theme>;
  formatCurrency?: boolean;
}

const ModifiedValueDisplayIcon = ({
  displayPropName,
  changedProps,
  sxProps,
  formatCurrency,
}: ModifiedValueDisplayIconProps) => {
  const relevantProp = useMemo(
    () => getRelevantChangedProperty(displayPropName, changedProps),
    [changedProps, displayPropName]
  );

  if (!relevantProp) return null;

  return (
    <Tooltip
      title={t`This value has been modified. Original value: ${
        formatCurrency
          ? parseInt(relevantProp.expectedValue).toLocaleString()
          : relevantProp.expectedValue
      }`}>
      <ReportGmailerrorredIcon
        sx={{
          ...sxProps,
          color: (theme) => theme.palette.info.main,
        }}
      />
    </Tooltip>
  );
};

interface ChangedPropertyMapping {
  displayPropName: keyof ChangedFactDisplayItem;
  apiPropName: string;
}

export const changedPropertyMappings: ChangedPropertyMapping[] = [
  {
    displayPropName: "label",
    apiPropName: "label",
  },
  {
    displayPropName: "language",
    apiPropName: "language",
  },
  {
    displayPropName: "unit",
    apiPropName: "unit",
  },
  {
    displayPropName: "formattedValue",
    apiPropName: "formattedValue",
  },
  {
    displayPropName: "balanceType",
    apiPropName: "balanceType",
  },
  {
    displayPropName: "escape",
    apiPropName: "escape",
  },
  {
    displayPropName: "isHidden",
    apiPropName: "isHidden",
  },
  {
    displayPropName: "periodType",
    apiPropName: "periodType",
  },
  {
    displayPropName: "itemType",
    apiPropName: "type",
  },
  {
    displayPropName: "decimals",
    apiPropName: "decimals",
  },
  {
    displayPropName: "scale",
    apiPropName: "scale",
  },
  {
    displayPropName: "narrowerAnchor",
    apiPropName: "anchoredFrom",
  },
  {
    displayPropName: "wideAnchor",
    apiPropName: "anchoredTo",
  },
  {
    displayPropName: "footnotes",
    apiPropName: "footnotes",
  },
];

const getRelevantChangedProperty = (
  displayPropName: string,
  allProps?: ChangedProperty[]
): ChangedProperty | undefined => {
  if (!allProps || allProps.length === 0) return undefined;
  const relevantMapping = changedPropertyMappings.find(
    (m) => m.displayPropName.toLowerCase() === displayPropName.toLowerCase()
  );
  if (!relevantMapping) return undefined;
  return allProps.find(
    (prop) =>
      prop.propertyName.toLowerCase() ===
      relevantMapping.apiPropName.toLowerCase()
  );
};

export default ModifiedValueDisplayIcon;
