import { t, Trans } from "@lingui/macro";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getPasswordValidationRequirements } from "../api/accounts";
import { IApiPasswordOptions } from "../api/types";
import { validatePassword } from "../helpers/validatePassword";

export interface PasswordValidationFormProps {
  isLoading: boolean;
  onSave: (newPass: string, oldPass?: string) => void;
  onCancel?: () => void;
  validateExistingPassword?: boolean;
}

const PasswordValidationForm = ({
  isLoading,
  onSave,
  onCancel,
  validateExistingPassword,
}: PasswordValidationFormProps) => {
  const [passwordValidationOptions, setPasswordValidationOptions] =
    useState<IApiPasswordOptions>();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRepeatPass, setShowRepeatPass] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    getPasswordValidationRequirements().then((resp) =>
      setPasswordValidationOptions(resp.data)
    );
  }, []);

  const handleChange = (inputName: "old" | "new" | "repeat", val: string) => {
    let setter = undefined;
    if (inputName === "new") {
      setter = setNewPass;
      setValidationErrors(
        validatePassword(passwordValidationOptions, val, repeatPass, oldPass)
      );
    } else if (inputName === "repeat") {
      setter = setRepeatPass;
      setValidationErrors(
        validatePassword(passwordValidationOptions, newPass, val, oldPass)
      );
    } else {
      setter = setOldPass;
      setValidationErrors(
        validatePassword(passwordValidationOptions, newPass, repeatPass, val)
      );
    }
    setter(val);
  };

  const handleCancel = () => {
    if (onCancel) {
      setOldPass("");
      setNewPass("");
      setRepeatPass("");
      onCancel();
    }
  };

  if (!passwordValidationOptions) {
    return <CircularProgress />;
  }

  return (
    <Stack
      spacing={2}
      sx={{
        mt: 2,
        height:
          validationErrors.length > 0
            ? 300 + validationErrors.length * 30
            : 300,
      }}>
      {validateExistingPassword && (
        <FormControl variant="outlined">
          <InputLabel htmlFor="change_passowrd_dialog_old_password">
            <Trans>Existing Password</Trans>
          </InputLabel>
          <OutlinedInput
            id="change_passowrd_dialog_old_password"
            type={showOldPass ? "text" : "password"}
            value={oldPass}
            onChange={(e) => handleChange("old", e.currentTarget.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowOldPass((prev) => !prev)}
                  onMouseDown={() => setShowOldPass((prev) => !prev)}
                  edge="end">
                  {showOldPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={t`Existing Password`}
          />
        </FormControl>
      )}
      <FormControl variant="outlined">
        <InputLabel htmlFor="change_passowrd_dialog_new_password">
          <Trans>New Password</Trans>
        </InputLabel>
        <OutlinedInput
          id="change_passowrd_dialog_new_password"
          type={showNewPass ? "text" : "password"}
          value={newPass}
          onChange={(e) => handleChange("new", e.currentTarget.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPass((prev) => !prev)}
                onMouseDown={() => setShowNewPass((prev) => !prev)}
                edge="end">
                {showNewPass ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={t`New Password`}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="change_passowrd_dialog_repeat_password">
          <Trans>Repeat New Password</Trans>
        </InputLabel>
        <OutlinedInput
          id="change_passowrd_dialog_repeat_password"
          type={showRepeatPass ? "text" : "password"}
          value={repeatPass}
          onChange={(e) => handleChange("repeat", e.currentTarget.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowRepeatPass((prev) => !prev)}
                onMouseDown={() => setShowRepeatPass((prev) => !prev)}
                edge="end">
                {showRepeatPass ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={t`Repeat New Password`}
        />
      </FormControl>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        {onCancel && (
          <Button onClick={handleCancel} variant="outlined">
            <Trans>Cancel</Trans>
          </Button>
        )}
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          disabled={validationErrors.length > 0}
          onClick={() => onSave(newPass, oldPass)}>
          <Trans>Save</Trans>
        </LoadingButton>
      </Stack>
      {validationErrors.map((error) => (
        <Typography
          sx={{
            color: (theme) => theme.palette.error.main,
          }}>
          {error}
        </Typography>
      ))}
    </Stack>
  );
};

export default PasswordValidationForm;
