import { t } from "@lingui/macro";
import { IApiConversionResponse } from "../../api/types";
import { DataTableColumn } from "../facts/dataTable";
import moment from "moment";
import TimerDisplayText from "./timerDisplayText";
import { Chip } from "@mui/material";
import { getStatusColor } from "../../helpers/getConversionStatusColoer";

export const getConversionHistoryTableColumns = (
  isAdmin: boolean
): DataTableColumn<IApiConversionResponse>[] => {
  const output: DataTableColumn<IApiConversionResponse>[] = [
    {
      header: {
        filterable: false,
        id: "id",
        label: t`Conversion Id`,
        maxWidth: 280,
        minWidth: 280,
        showLabel: true,
        sortable: true,
      },
      key: "id",
    },
  ];
  if (isAdmin) {
    output.push({
      key: "userName",
      header: {
        filterable: false,
        id: "userName",
        label: t`User`,
        maxWidth: 230,
        minWidth: 230,
        showLabel: true,
        sortable: true,
      },
    });
  }
  const moreColumns: DataTableColumn<IApiConversionResponse>[] = [
    {
      key: "metadata_reporter_identifier",
      header: {
        filterable: false,
        id: "metadata_reporter_identifier",
        label: t`Reporter Identifier`,
        maxWidth: 150,
        minWidth: 150,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) => row.metaData.reporter.identifier,
    },
    {
      key: "metadata_reporter_name",
      header: {
        filterable: false,
        id: "metadata_reporter_name",
        label: t`Reporter Name`,
        maxWidth: 150,
        minWidth: 150,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) => row.metaData.reporter.name,
    },
    {
      key: "startedAt",
      header: {
        id: "startedAt",
        filterable: false,
        label: t`Start`,
        maxWidth: 165,
        minWidth: 165,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) =>
        moment(row.startedAt?.toString() || "").format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      key: "elapsedTime",
      header: {
        id: "elapsedTime",
        filterable: false,
        label: t`Time`,
        maxWidth: 120,
        minWidth: 120,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) =>
        row.status === "Running" ? (
          <TimerDisplayText startValue={row.startedAt?.toString()} />
        ) : (
          row.elapsedTime?.split(".")[0] || ""
        ),
    },
    {
      key: "metadata_taxonomy_name",
      header: {
        id: "metadata_taxonomy_name",
        label: t`Taxonomy Name`,
        filterable: false,
        maxWidth: 150,
        minWidth: 150,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) => row.metaData.taxonomy.name,
    },
    {
      key: "metaData_period_start",
      header: {
        id: "metaData_period_start",
        filterable: false,
        label: t`Period Start`,
        maxWidth: 125,
        minWidth: 125,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) =>
        moment(row.metaData.period?.start?.toString() || "").format(
          "DD/MM/YYYY"
        ),
    },
    {
      key: "metaData_period_end",
      header: {
        id: "metaData_period_end",
        filterable: false,
        label: t`Period End`,
        maxWidth: 125,
        minWidth: 125,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) =>
        moment(row.metaData.period?.end?.toString() || "").format("DD/MM/YYYY"),
    },
    {
      key: "status",
      header: {
        id: "status",
        filterable: false,
        label: t`Status`,
        maxWidth: 90,
        minWidth: 90,
        showLabel: true,
        sortable: true,
      },
      valueFormatter: (row) => (
        <Chip
          sx={{ width: 100 }}
          label={row.status.toUpperCase()}
          variant="filled"
          size="small"
          color={getStatusColor(row.status)}
        />
      ),
    },
  ];
  output.push(...moreColumns);
  return output;
};
