import { currencySymbols } from "./constants";

export const getValueString = (
  value: string | undefined,
  unit: string | undefined,
  decimals: string | undefined,
  returnWithCurrency: boolean = true
): string => {
  if (!value) return "";

  const [integerPart, fractionalPart] = value.split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  const formattedValue = fractionalPart
    ? `${formattedIntegerPart}.${fractionalPart}`
    : formattedIntegerPart;

  if (!returnWithCurrency) {
    if (
      !decimals ||
      decimals.toLowerCase() === "inf" ||
      isNaN(parseInt(decimals))
    )
      return value;
    return formattedValue;
  }

  const symbol = currencySymbols.find(
    (sym) => sym.name.toLowerCase() === unit?.toLowerCase()
  )?.symbol || currencySymbols.find(
    (sym) => sym.name.toLowerCase() === unit?.slice(-3).toLowerCase()
  )?.symbol;

  if (
    !decimals ||
    decimals.toLowerCase() === "inf" ||
    isNaN(parseInt(decimals))
  )
    return symbol ? `${symbol} ${value}` : value;

  return symbol ? `${symbol} ${formattedValue}` : formattedValue;
};
