import { t, Trans } from "@lingui/macro";
import {
  Dialog,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  CircularProgress,
  DialogTitle,
  Alert,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getExternalScript } from "../../api/external";
import { generateStandalone } from "../../api/validation";
import { ixbrlViewerCDNUrl } from "../../helpers/constants";
import {
  downloadReportFile,
  getReportPackage,
} from "../../helpers/downloadReportFile";
import moment from "moment";
import { IImporterState } from "../../reducers/importReducer";
import { getCachedPackage } from "../../helpers/getCachedPackage";

export interface GenerateStandaloneViewerDialogProps {
  open: boolean;
  onClose: () => void;
  packageUrl: string;
  packageName: string;
  packageType: IImporterState["downloadedPayloadType"];
  reportIdentifier: string;
  reportEndDate: string;
  importId?: string;
}

const GenerateStandaloneViewerDialog = ({
  open,
  onClose,
  packageUrl,
  packageName,
  packageType,
  reportIdentifier,
  reportEndDate,
  importId,
}: GenerateStandaloneViewerDialogProps) => {
  const [outputFileName, setOutputFileName] = useState(
    `${reportIdentifier}_${
      reportEndDate ? moment(reportEndDate).format("YYYYMMDD") : ""
    }_viewer`
  );
  const [isNameValid, setIsNameValid] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [generateError, setGenerateError] = useState("");
  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined);

  const generateStandaloneReport = async (): Promise<void> => {
    try {
      setGenerateError("");
      setGenerating(true);
      if (!packageUrl) {
        console.error("no package url");
        throw new Error("no package url");
      }
      const controller = new AbortController();
      setAbortController(controller);
      let outputFile = undefined;
      if (packageType === "package") {
        const { blob } = await getReportPackage(packageUrl, packageName);
        outputFile = blob;
      } else {
        const blob = await getCachedPackage(packageUrl);
        outputFile = new File([blob], packageName);
      }
      const formData = new FormData();
      formData.append(
        packageType === "package" ? "reportPackage" : "reportFile",
        outputFile
      );
      const { standaloneFileHtmlString, name: standaloneName } =
        await generateStandalone(
          packageType === "package" ? "reportpackage" : "reportfile",
          importId || crypto.randomUUID(),
          outputFileName,
          formData,
          controller.signal
        );
      const viewerScript = await getExternalScript(ixbrlViewerCDNUrl);
      standaloneFileHtmlString.replace(
        `<script type="text/javascript" src="${ixbrlViewerCDNUrl}"></script>`,
        `<script type="text/javascript">${viewerScript}</script>`
      );
      downloadReportFile(
        new Blob([standaloneFileHtmlString], {
          type:
            standaloneName.split(".").length > 1
              ? standaloneName.split(".")[1].toLowerCase().includes("x")
                ? "text/xhtml"
                : "text/html"
              : "text/html",
        }),
        standaloneName
      );
      handleClose(false);
    } catch (ex) {
      console.log(ex);
      setGenerating(false);
      setGenerateError(t`Could not generate stand alone report file`);
    }
  };

  const handleClose = (cancel: boolean) => {
    if (cancel) {
      abortController?.abort();
    }
    setGenerating(false);
    setGenerateError("");
    onClose();
  };

  useEffect(() => {
    const validFileNamePattern = /^[^\\/:*?"<>|]+$/;
    const isValid =
      validFileNamePattern.test(outputFileName) &&
      !outputFileName.includes(" ");
    setIsNameValid(isValid);
  }, [outputFileName]);

  return (
    <Dialog
      open={open}
      onClose={(e: any) => e.preventDefault()}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        {generating && <Trans>Generating...</Trans>}
        {!generateError && !generating && open && (
          <Trans>Enter name for viewer file</Trans>
        )}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          {generating && <CircularProgress sx={{ margin: "0 auto" }} />}
          {!generating && generateError && (
            <Alert severity="error">{generateError}</Alert>
          )}
          {!generating && (
            <TextField
              id="enter_output_file_name"
              value={outputFileName}
              onChange={(e) => setOutputFileName(e.currentTarget.value)}
              label={t`Viewer File Name`}
              error={!isNameValid}
              helperText={
                isNameValid
                  ? ""
                  : t`Suggested name cannot have spaces or illegal characters`
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">.html</InputAdornment>
                ),
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} variant="outlined">
          <Trans>Cancel</Trans>
        </Button>
        <Button
          onClick={generateStandaloneReport}
          variant="outlined"
          disabled={generating || !isNameValid}>
          <Trans>Generate</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateStandaloneViewerDialog;
