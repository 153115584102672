import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useEffect, useMemo, useState } from "react";
import { EntryPoint, IApiFact, ExtractedLinkbaseTree } from "../../api/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  mapLinkbaseTreeToTreeviewElement,
  MappedLinkebaseTree,
} from "../../helpers/mapLinkbaseTreeToTreeviewElement";
import TaxonomyExplorerTreeItem from "./taxonomyExplorerTreeItem";
import { taxonomyTreeSearchMinimumCharCount } from "../../helpers/constants";
import { getUniqueArray } from "../../helpers/getUniqueArrayByPoprties";
import { Menu, MenuItem, Typography } from "@mui/material";
import { getHighlightedText } from "../../helpers/getHighlightedText";
import { Trans } from "@lingui/macro";
import { navigateToTreeviewNode } from "../../helpers/navigateToFact";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { startUpdateLinkbaseTree } from "../../reducers/extractionReducer";
import { useAppSelector } from "../../hooks/useAppSelector";
import LoadingOverlay from "../loadingOverlay";
import {
  factSelectionCallerUpdated,
  selectedFactIdsUpdated,
} from "../../reducers/internalLifeCycleReducer";

export interface GeneralSidebarTaxonomyExplorerLazyTreeviewProps {
  currentSearchFilter: string;
  linkbaseTree: ExtractedLinkbaseTree | undefined;
  activeEntryPointHref: string;
  facts: IApiFact[];
  currentSelectedLanguage: string;
  entryPoints: EntryPoint[] | undefined;
  onViewItemDetails: () => void;
  currentWidth: number;
  isPackageTaxonomy: boolean;
}

const GeneralSidebarTaxonomyExplorerLazyTreeview = ({
  currentSearchFilter,
  linkbaseTree,
  activeEntryPointHref,
  facts,
  currentSelectedLanguage,
  entryPoints,
  onViewItemDetails,
  currentWidth,
  isPackageTaxonomy,
}: GeneralSidebarTaxonomyExplorerLazyTreeviewProps) => {
  const dispatch = useAppDispatch();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [contextMenuItemId, setContextMenuItemId] = useState("");
  const [expandedTreeItemMapping, setExpandedTreeItemMapping] = useState<
    { id: string; itemIds: string[] }[]
  >([]);

  const updatingTree = useAppSelector(
    (state) => state.extract.updatingLinkbaseTree
  );

  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );

  const handleToggleLoadedTreeItems = (
    id: string,
    itemIds: string[],
    concant: boolean
  ) => {
    const currentTree = expandedTreeItemMapping.find((m) => m.id === id);
    if (currentTree) {
      expandedTreeItemMapping.map((m) => {
        if (m.id === id) {
          if (concant) {
            m.itemIds = itemIds.concat(
              expandedTreeItemMapping.find((m) => m.id === linkbaseTree?.id)
                ?.itemIds || []
            );
          } else {
            m.itemIds = itemIds;
          }
        }
        return m;
      });
    } else {
      expandedTreeItemMapping.push({
        id: id,
        itemIds: itemIds,
      });
    }
    setExpandedTreeItemMapping([...expandedTreeItemMapping]);
  };

  const { tree: mappedTreeview, flatArray: nodeFlatArray } = useMemo(
    () =>
      mapLinkbaseTreeToTreeviewElement(
        linkbaseTree,
        facts,
        currentSelectedLanguage,
        entryPoints
      ),
    [linkbaseTree, facts, currentSelectedLanguage, entryPoints]
  );

  const handleSelectNode = (e: any, selectedId: string[]) => {
    const identifier = nodeFlatArray.find((nfa) => selectedId.includes(nfa.id));
    if (identifier && identifier.factIds && identifier.factIds.length > 0) {
      dispatch(selectedFactIdsUpdated(identifier.factIds));
      dispatch(factSelectionCallerUpdated("sidebar"));
    } else if (selectedFactIds.length > 0) {
      dispatch(selectedFactIdsUpdated([]));
    }
  };

  const handleToggleExpanded = async (
    e: React.SyntheticEvent,
    itemIds: string[]
  ): Promise<void> => {
    handleToggleLoadedTreeItems(linkbaseTree?.id || "", itemIds, false);
    const itemIdsToLoad = nodeFlatArray.filter(
      (node) =>
        itemIds.includes(node.id) &&
        node.hasChildren &&
        !node.childrenLoaded &&
        !node.errorLoadingChildren
    );
    if (itemIdsToLoad.length > 0) {
      await dispatch(
        startUpdateLinkbaseTree({
          treeId: linkbaseTree?.id || "",
          roleTypeId: itemIdsToLoad[0].parentRoleTypeId,
          elementId:
            itemIdsToLoad[0].internalId !== itemIdsToLoad[0].parentRoleTypeId
              ? itemIdsToLoad[0].internalId
              : undefined,
        })
      );
    }
  };

  const handleTreeItemContextMenu = (event: React.MouseEvent, id: string) => {
    setContextMenuItemId(id);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
    setContextMenuItemId("");
  };

  const handleViewItemDetails = () => {
    const identifier = nodeFlatArray.find(
      (nfa) => nfa.id === contextMenuItemId
    );
    if (identifier) {
      if (identifier.factIds && identifier.factIds.length > 0) {
        dispatch(selectedFactIdsUpdated(identifier.factIds));
      } else {
        dispatch(
          selectedFactIdsUpdated([
            identifier.linkbaseTreeElement?.elementId || "",
          ])
        );
      }
      onViewItemDetails();
    }
    handleCloseContextMenu();
  };

  const matchingNodesByLabel = useMemo(() => {
    if (currentSearchFilter.length >= taxonomyTreeSearchMinimumCharCount) {
      return nodeFlatArray.filter(
        (node) =>
          node &&
          node.label.toLowerCase().includes(currentSearchFilter.toLowerCase())
      );
    }
    return [];
  }, [nodeFlatArray, currentSearchFilter]);

  const matchingNodesByName = useMemo(() => {
    if (currentSearchFilter.length >= taxonomyTreeSearchMinimumCharCount) {
      return nodeFlatArray.filter(
        (node) =>
          node.linkbaseTreeElement?.elementId
            .toLowerCase()
            .includes(currentSearchFilter.toLowerCase()) ||
          node.linkbaseTreeElement?.elementId
            .toLowerCase()
            .replace("_", ":")
            .includes(currentSearchFilter.toLowerCase())
      );
    }
    return [];
  }, [nodeFlatArray, currentSearchFilter]);

  useEffect(() => {
    if (currentSearchFilter.length >= taxonomyTreeSearchMinimumCharCount) {
      const allMatching = matchingNodesByLabel.concat(matchingNodesByName);
      if (allMatching.length > 0) {
        const allIds = getUniqueArray(
          allMatching.map((n) => n.path.split(";")).flat()
        ) as string[];

        allIds.unshift((mappedTreeview as MappedLinkebaseTree).topBranch.id);
        const toOpen = getUniqueArray(allIds) as string[];
        handleToggleLoadedTreeItems(linkbaseTree?.id || "", toOpen, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matchingNodesByName,
    matchingNodesByLabel,
    currentSearchFilter,
    mappedTreeview,
    linkbaseTree?.id,
  ]);

  useEffect(() => {
    if (selectedFactIds.length > 0) {
      const matchingNodes = nodeFlatArray.filter((node) =>
        selectedFactIds.some((f) => node.factIds?.includes(f))
      );
      if (matchingNodes.length > 0) {
        const allIds = getUniqueArray(
          matchingNodes.map((n) => n.path.split(";")).flat()
        ) as string[];

        allIds.unshift((mappedTreeview as MappedLinkebaseTree).topBranch.id);
        const toOpen = getUniqueArray(allIds) as string[];
        handleToggleLoadedTreeItems(linkbaseTree?.id || "", toOpen, true);
        setTimeout(() => {
          navigateToTreeviewNode(matchingNodes[0].id);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeFlatArray, selectedFactIds, linkbaseTree?.id, mappedTreeview]);

  useEffect(() => {
    if (selectedFactIds.length > 0) {
      const matchingNodes = nodeFlatArray.filter((node) =>
        selectedFactIds.some((f) => node.factIds?.includes(f))
      );
      if (matchingNodes.length === 0) {
        const fact = facts.find((f) => f.id === selectedFactIds[0]);
        if (
          fact &&
          fact.entryPointHref === activeEntryPointHref &&
          linkbaseTree
        ) {
          dispatch(
            startUpdateLinkbaseTree({
              treeId: linkbaseTree.id,
              elementNamespaceUri: fact.factElement.namespace.uri,
              elementId: fact.factElement.elementId,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactIds, activeEntryPointHref, linkbaseTree?.id]);

  const selectedNodeIds = useMemo(() => {
    const outsideSelectedNodes = nodeFlatArray.filter((nfa) =>
      nfa.factIds?.some((fid) => selectedFactIds.includes(fid))
    );
    if (outsideSelectedNodes.length === 0) return [];
    return outsideSelectedNodes.map((o) => o.id);
  }, [nodeFlatArray, selectedFactIds]);
  if (!mappedTreeview) {
    return <></>;
  }

  return (
    <LoadingOverlay isVisible={updatingTree}>
      <SimpleTreeView
        sx={{
          mb: 2,
          mt: 2,
          mr: 2,
          width: currentWidth,
        }}
        slots={{
          collapseIcon: ExpandMoreIcon,
          expandIcon: ChevronRightIcon,
        }}
        multiSelect
        selectedItems={selectedNodeIds}
        expandedItems={
          expandedTreeItemMapping.find((m) => m.id === linkbaseTree?.id)
            ?.itemIds || []
        }
        onSelectedItemsChange={handleSelectNode}
        onExpandedItemsChange={handleToggleExpanded}>
        <TreeItem
          itemId={mappedTreeview.topBranch.id}
          label={
            <div style={{ fontSize: "0.8rem" }}>
              {currentSearchFilter.length >= taxonomyTreeSearchMinimumCharCount
                ? getHighlightedText(
                    currentSearchFilter,
                    mappedTreeview.topBranch.label?.label || "",
                    "#FFFF9F",
                    matchingNodesByName
                      .map((n) => n.id)
                      .includes(mappedTreeview.topBranch.id)
                  )
                : mappedTreeview.topBranch.label?.label || ""}
            </div>
          }>
          {!mappedTreeview.topBranch.hasChildren && (
            <TreeItem
              itemId={`${mappedTreeview.topBranch.id}_empty_tree_item`}
              label={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  <Trans>
                    This linkbase tree does not contain any role types
                  </Trans>
                </Typography>
              }
            />
          )}
          {mappedTreeview.topBranch.children.map((branch, index) => {
            return (
              <TaxonomyExplorerTreeItem
                key={`treeview_subbranch_${branch.id}-${index}`}
                matchingNameNodeIds={matchingNodesByName.map((n) => n.id)}
                branch={branch}
                currentSearchFilter={currentSearchFilter}
                highlightColor="#FFFF9F"
                onContextMenu={handleTreeItemContextMenu}
                isPackageTaxonomy={isPackageTaxonomy}
              />
            );
          })}
        </TreeItem>
      </SimpleTreeView>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <MenuItem onClick={handleViewItemDetails}>
          <Trans>View details</Trans>
        </MenuItem>
      </Menu>
    </LoadingOverlay>
  );
};

export default GeneralSidebarTaxonomyExplorerLazyTreeview;
