import { WritableDraft } from "immer/dist/internal";
import {
  ExtractedElementTree,
  ExtractedTaxonomy,
  ExtractedLinkbaseTree,
  LinkbaseTreeMapping,
  ExtractedRoleType,
} from "../api/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { getLinkbaseTreeBranchById } from "./getLinkbaseTreeBranchById";
import { IExtractionState } from "../reducers/extractionReducer";

const updateTrees = (
  trees: ExtractedLinkbaseTree[],
  payload: ExtractedElementTree[] | ExtractedRoleType,
  treeId: string,
  roleTypeId: string | undefined,
  elementId?: string | undefined,
  elementNamespaceUri?: string | undefined
): void => {
  if (roleTypeId) {
    const targetBranch = getLinkbaseTreeBranchById(
      trees,
      treeId,
      roleTypeId,
      elementId
    );
    if (targetBranch) {
      if (elementId) {
        (targetBranch as ExtractedElementTree).children =
          payload as ExtractedElementTree[];
      } else {
        (targetBranch as ExtractedRoleType).elementTrees =
          payload as ExtractedElementTree[];
      }
    }
  }
  if (elementNamespaceUri && elementId) {
    const currentTree = trees.find((tree) => tree.id === treeId);
    if (currentTree) {
      let addRoleType = true;
      currentTree.roleTypes?.map((rt) => {
        if (rt.id === (payload as ExtractedRoleType).id) {
          addRoleType = false;
          rt.elementTrees = (payload as ExtractedRoleType).elementTrees;
        }
        return rt;
      });
      if (addRoleType) {
        (currentTree.roleTypes || []).push(payload as ExtractedRoleType);
      }
    }
  }
};

export const handleUpdateLinkbaseTreesResponse = (
  currentState: WritableDraft<IExtractionState>,
  action: PayloadAction<
    ExtractedElementTree[] | ExtractedRoleType | undefined,
    string,
    {
      arg: {
        treeId: string;
        roleTypeId?: string | undefined;
        elementId?: string | undefined;
        elementNamespaceUri?: string | undefined;
      };
      requestId: string;
      requestStatus: "fulfilled";
    },
    never
  >
): IExtractionState => {
  if (action.payload) {
    const output = {
      ...currentState,
      updatingLinkbaseTree: false,
    };
    const mappings = currentState.linkbaseTreeMappings;
    const reportTrees = currentState.extractedBasicData?.linkbaseTrees;
    const updatingSourceTrees = mappings.some(
      (mapping) =>
        mapping.fromPackage &&
        reportTrees?.some((tree) => tree.id === action.meta.arg.treeId)
    );
    if (updatingSourceTrees) {
      const clonedData: ExtractedTaxonomy = JSON.parse(
        JSON.stringify(currentState.extractedBasicData)
      );
      updateTrees(
        clonedData.linkbaseTrees || [],
        action.payload,
        action.meta.arg.treeId,
        action.meta.arg.roleTypeId,
        action.meta.arg.elementId,
        action.meta.arg.elementNamespaceUri
      );
      output.extractedBasicData = clonedData as ExtractedTaxonomy;
    } else {
      const clonedMappings: LinkbaseTreeMapping[] = JSON.parse(
        JSON.stringify(mappings || [])
      );
      const mapping = clonedMappings.find(
        (mapping) =>
          mapping.trees &&
          mapping.trees.some((tree) => tree.id === action.meta.arg.treeId)
      );
      if (mapping && mapping.trees) {
        updateTrees(
          mapping.trees,
          action.payload,
          action.meta.arg.treeId,
          action.meta.arg.roleTypeId,
          action.meta.arg.elementId,
          action.meta.arg.elementNamespaceUri
        );
      }
      output.linkbaseTreeMappings = clonedMappings;
    }
    return output;
  }
  return currentState;
};
