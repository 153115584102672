import { Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getArtifactForConversion } from "../api/conversions";
import { t } from "@lingui/macro";

export interface IDebugState {
  downloadedReportArtifactUrl: string;
  downloadedReportArtifactName: string;
  downloadingArtifactError: string;
  downloadingArtifact: boolean;
}

const initialState: IDebugState = {
  downloadedReportArtifactName: "",
  downloadedReportArtifactUrl: "",
  downloadingArtifact: false,
  downloadingArtifactError: "",
};

export const downloadConversionArtifact = createAsyncThunk<
  { blob: Blob; name: string },
  {
    conversionId: string;
    artifactType: string;
  }
>(
  "convert/conversion/artifact/download",
  async (args, { rejectWithValue }): Promise<{ blob: Blob; name: string }> => {
    try {
      return getArtifactForConversion(args.conversionId, args.artifactType);
    } catch (ex: any) {
      throw rejectWithValue(ex);
    }
  }
);

const debugSlice: Slice<IDebugState> = createSlice({
  name: "debug",
  initialState: initialState,
  reducers: {
    downloadErrorReset: (state): IDebugState => {
      return {
        ...state,
        downloadingArtifactError: "",
      };
    },
    uploadedReportFromLocalMachine: (state, action): IDebugState => {
      const file = action.payload as File;
      return {
        ...state,
        downloadingArtifactError: "",
        downloadedReportArtifactName: file.name,
        downloadedReportArtifactUrl: URL.createObjectURL(file),
      };
    },
    clearReport: (state): IDebugState => {
      URL.revokeObjectURL(state.downloadedReportArtifactUrl);
      return {
        ...state,
        downloadedReportArtifactName: "",
        downloadedReportArtifactUrl: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadConversionArtifact.pending, (state): IDebugState => {
        if (state.downloadedReportArtifactUrl !== "") {
          URL.revokeObjectURL(state.downloadedReportArtifactUrl);
        }
        return {
          ...state,
          downloadedReportArtifactName: "",
          downloadedReportArtifactUrl: "",
          downloadingArtifact: true,
          downloadingArtifactError: "",
        };
      })
      .addCase(
        downloadConversionArtifact.rejected,
        (state, action): IDebugState => {
          let errorMessage = t`An error occured. Try again later`;
          if (action?.error?.message === "404") {
            errorMessage = t`Could not find conversion with id: ${action.meta.arg.conversionId}. It may have beed flushed from the system`;
          }
          return {
            ...state,
            downloadingArtifact: false,
            downloadingArtifactError: errorMessage,
          };
        }
      )
      .addCase(
        downloadConversionArtifact.fulfilled,
        (state, action): IDebugState => {
          return {
            ...state,
            downloadingArtifact: false,
            downloadedReportArtifactName: action.payload.name,
            downloadedReportArtifactUrl: URL.createObjectURL(
              action.payload.blob
            ),
          };
        }
      );
  },
});

export const {
  downloadErrorReset,
  uploadedReportFromLocalMachine,
  clearReport,
} = debugSlice.actions;

export default debugSlice.reducer;
