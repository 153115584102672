import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
} from "@mui/material";
import {
  DisplayLabel,
  LabeledElement,
  TaxonomyFactElement,
} from "../../api/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { CustomListItem } from "./factViewerAccordion";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  getFactDisplayLabelFromLebeledElements,
  getFactDisplayLabelFromLinkbaseTree,
} from "../../helpers/getFactDisplayLabel";
import { useEffect, useState } from "react";
export interface ElementViewerAccordionProps {
  element: TaxonomyFactElement;
  currentSelectedLanguage: string;
  activeEntryPoint: string;
  labeledElements: LabeledElement[] | undefined;
}

const ElementViewerAccordion = ({
  element,
  currentSelectedLanguage,
  activeEntryPoint,
  labeledElements,
}: ElementViewerAccordionProps) => {
  let documentationLabel = "";
  if (element.documentationLabels && element.documentationLabels.length > 0) {
    documentationLabel =
      element.documentationLabels.find(
        (label) =>
          label.lang.toLowerCase() === currentSelectedLanguage.toLowerCase()
      )?.value || "";
    if (documentationLabel === "") {
      documentationLabel =
        element.documentationLabels.find(
          (label) => label.lang.toLowerCase() === "en"
        )?.value || "";
    }
  }

  const [label, setLabel] = useState<DisplayLabel>();
  const [updatingLabel, setUpdatingLabel] = useState(false);

  const presentationTree = useAppSelector((state) => {
    const mapping = state.extract.linkbaseTreeMappings.find(
      (map) => map.entryPoint?.href === activeEntryPoint
    );
    const relevantTrees = mapping?.fromPackage
      ? state.extract.extractedBasicData?.linkbaseTrees
      : mapping?.trees;
    return relevantTrees?.find((tree) => tree.type.toLowerCase());
  });

  useEffect(() => {
    const getLabel = async (): Promise<void> => {
      setUpdatingLabel(true);
      let output = getFactDisplayLabelFromLinkbaseTree(
        element,
        presentationTree,
        currentSelectedLanguage
      );
      if (!output) {
        output = getFactDisplayLabelFromLebeledElements(
          element,
          labeledElements,
          currentSelectedLanguage
        );
      }
      setLabel(output);
      setUpdatingLabel(false);
    };
    getLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedLanguage, presentationTree?.id, element.elementId]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        id="viewer_sidebar_element_details_header"
        expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <Trans>Element details</Trans>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          <CustomListItem
            header={t`Name`}
            text={element.elementId.replace("_", ":")}
            title={element.elementId.replace("_", ":")}
            shouldShowFullText={true}
          />
          {element.type && (
            <CustomListItem
              header={t`Standard labels`}
              text={label?.label || ""}
              title={label?.label || ""}
              opacity={updatingLabel ? 0.3 : 1}
            />
          )}
          <CustomListItem
            header={t`Documentation`}
            text={documentationLabel}
            title={documentationLabel}
            customSecondaryTypographyProps={{
              fontWeight: 800,
            }}
          />
          <CustomListItem
            header={t`Data type`}
            text={element.type}
            title={element.type}
          />
          <CustomListItem
            header={t`Substitution group`}
            text={element.substitutionGroup}
            title={element.substitutionGroup}
          />
          <CustomListItem
            header={t`Period type`}
            text={element.periodType}
            title={element.periodType}
          />
          <CustomListItem
            header={t`Balance`}
            text={element.balanceType}
            title={element.balanceType}
          />
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default ElementViewerAccordion;
