import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { AppDispatch } from "../../store";
import { userSetTerms } from "../../reducers/userManagementReducer";
import { allImportActionsFinished } from "../../reducers/importReducer";
import { t } from "@lingui/macro";
import ErrorIcon from "@mui/icons-material/Error";
import { errorColor } from "../../helpers/constants";

interface ErrorConversionDialogProps {
  open: boolean;
  onClose: () => void;
  onCloseAndNavigate: () => void;
  conversionError: boolean;
  validationError: boolean;
  extractionError: boolean;
}

export const ErrorConversionDialog = ({
  open,
  onClose,
  onCloseAndNavigate,
  conversionError,
  validationError,
  extractionError,
}: ErrorConversionDialogProps) => {
  const dispatch: AppDispatch = useAppDispatch();

  const [errorCase, setErrorCase] = useState<
    "extractionError" | "validationError" | "none"
  >("none");

  const errorCaseHandler = () => {
    if (!conversionError && !validationError && extractionError) {
      setErrorCase("extractionError");
    } else if (!conversionError && validationError && !extractionError) {
      setErrorCase("validationError");
    } else {
      setErrorCase("none");
    }
  };

  const handleAgreeToTerms = () => {
    onCloseAndNavigate();
    dispatch(userSetTerms(true));
    dispatch(allImportActionsFinished({}));
  };

  useEffect(() => {
    errorCaseHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionError, validationError, extractionError]);

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <DialogTitle>
        <Grid alignItems="center" container spacing={2} sx={{ mt: 0.5 }}>
          <ErrorIcon sx={{ fill: errorColor }} />
          <Typography
            sx={{ pl: 1 }}
            variant="h5">{t`Something went wrong!`}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <Typography variant={"body2"}>
              {errorCase === "extractionError" &&
                t`An error occurred while processing your files, which may affect how your report is displayed.`}
              {errorCase === "validationError" &&
                t`An error occurred while processing your files, which may affect how your report is displayed.`}
            </Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t`Cancel`}</Button>
        <Button variant="outlined" onClick={handleAgreeToTerms} autoFocus>
          {t`Proceed`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
