export const removeFromHtmlString = (
  html: string,
  classes: boolean,
  continuationTags: boolean
): string => {
  if (html === "") return html;
  try {
    const doc = new DOMParser().parseFromString(html, "text/html");

    if (classes) {
      const elementArray = Array.from(doc.querySelectorAll("*"));
      for (const element of elementArray) {
        element.removeAttribute("class");
        element.removeAttribute("id");
        const styles = element.getAttribute("style")?.split(";");
        if (
          styles?.some(
            (style) =>
              style.trim().startsWith("color") ||
              style.trim().startsWith("font")
          )
        ) {
          const newStyles = styles.filter(
            (style) =>
              !style.trim().startsWith("color") &&
              !style.trim().startsWith("font-family")
          );
          element.setAttribute("style", newStyles.join(";"));
        }
      }
    }
    if (continuationTags) {
      const continuationElementArray = Array.from(
        doc.querySelectorAll("continuation")
      );
      continuationElementArray.forEach((e) => e.replaceWith(...e.childNodes));
    }
    return doc.body.innerHTML.replaceAll(/(\r\n|\n|\r)/gm, "");
  } catch {
    return html;
  }
};
