export enum TablePreferences {
  HiddenColumns,
  DontShowWarning,
}

const getLocalStorageKey = (
  tableName: string,
  userId: string,
  settingName: TablePreferences
): string => {
  return `${userId}_${tableName}_${TablePreferences[settingName]}`;
};

const getLocalStorageValue = <T>(key: string): T | null => {
  const stringValue = localStorage.getItem(key);
  if (stringValue !== null) {
    return JSON.parse(stringValue) as T;
  }
  return stringValue;
};

export const getUserTablePreference = <T>(
  tableName: string,
  userId: string,
  settingName: TablePreferences
): T | null => {
  return getLocalStorageValue<T>(
    getLocalStorageKey(tableName, userId, settingName)
  );
};

export const setUserTablePreference = (
  tableName: string,
  userId: string,
  settingName: TablePreferences,
  value: any
): void => {
  const key = getLocalStorageKey(tableName, userId, settingName);
  localStorage.setItem(key, JSON.stringify(value));
};
