import JSZip from "jszip";
import { getCachedPackage } from "./getCachedPackage";

export const getReportPackage = async (
  packageUrl: string,
  packageName: string
): Promise<{ fileNameWithExtension: string; blob: Blob }> => {
  const blob = await getCachedPackage(packageUrl);
  const packageFile = new File([blob], packageName);
  const folder = await JSZip.loadAsync(blob);
  const file = folder.file(/.*.(html|xhtml)/)[0];
  const fileName =
    file.name.split("/").length > 0
      ? file.name.split("/")[file.name.split("/").length - 1]
      : file.name;
  return {
    blob: packageFile,
    fileNameWithExtension: fileName,
  };
};

export const downloadReportFile = (report: Blob, fileName: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  const fileUrl = URL.createObjectURL(report);
  a.href = fileUrl;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(fileUrl);
};

export const downloadPlainFile = (file: string, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFileWithUrl = (fileUrl: string, fileName: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.href = fileUrl;
  a.download = fileName;
  a.click();
};
