import { Trans } from "@lingui/macro";
import { AttachFile } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ListItem,
  Typography,
  Alert,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import AnimatedEllipsis from "../progressDialog/animatedEllipsis";
import { startComparePackages } from "../../reducers/compareReducer";

export interface UploadReportDialogProps {
  open: boolean;
  onClose: () => void;
}

const UploadReportDialog = ({ open, onClose }: UploadReportDialogProps) => {
  const dispatch = useAppDispatch();

  const [comparing, setComparing] = useState(false);
  const [compareError, setCompareError] = useState(false);
  const [pendingFile, setPendingFile] = useState<File>();
  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined);

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    onDrop: (files) => setPendingFile(files[0]),
    noKeyboard: true,
    disabled: comparing,
    accept: {
      "application/zip": [".zip"],
      "application/xhtml+xml": [".xhtml"],
      "text/html": [".html"],
    },
    multiple: false,
    maxFiles: 1,
    noDrag: true,
  });

  const handleCancel = () => {
    if (abortController) {
      abortController.abort();
    }
    setAbortController(undefined);
    setPendingFile(undefined);
    setCompareError(false);
    onClose();
  };

  const handleStartCompare = async () => {
    setCompareError(false);
    setComparing(true);
    const controller = new AbortController();
    setAbortController(controller);
    const response = await dispatch(
      startComparePackages({
        packageFile: pendingFile as File,
        abortSignal: controller.signal,
      })
    );
    setComparing(false);
    if ((response as any).error && response.payload !== "ERR_CANCELED") {
      setCompareError(true);
    } else {
      setPendingFile(undefined);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => null} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Trans>Upload reporting package</Trans>
        </DialogTitle>
        <DialogContent sx={{ height: "155px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            sx={{ width: "100%", mt: 2 }}
            variant="contained"
            color="inherit"
            disabled={comparing}
            startIcon={<AttachFile />}>
            <Trans>Browse Files</Trans>
          </Button>
          {pendingFile && (
            <ListItem
              sx={{ mt: 1 }}
              secondaryAction={
                <IconButton
                  disabled={comparing}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setPendingFile(undefined);
                  }}>
                  <CloseIcon />
                </IconButton>
              }>
              <FolderZipIcon />
              <ListItemText
                primary={pendingFile.name}
                sx={{ ml: 2 }}
                primaryTypographyProps={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
          )}
          {comparing && (
            <>
              <Typography component="span" variant="body2">
                <Trans>Comparing reporting packages</Trans>
              </Typography>
              <AnimatedEllipsis />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            onClick={handleStartCompare}
            disabled={pendingFile === undefined || comparing}>
            <Trans>Go</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={compareError}
        autoHideDuration={6000}
        onClose={() => setCompareError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="error">
          <Trans>Error comparing files. Please try again later</Trans>
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadReportDialog;
