import {
  Alert,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import GeneralSidebarAnchoringTreeview from "./generalSidebarAnchoringTreeview";
import GeneralSidebarFactDetailViewer from "./generalSidebarFactDetailViewer";
import { DrawerIcon } from "./generalSidebarResizableDrawerBodyClosed";
import GeneralSidebarTaxonomyExplorer from "./generalSidebarTaxonomyExplorer";
import GeneralSideBarMandatoryTagsView from "./generalSideBarMandatoryTagsView";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  startGetLinkbaseTreeByEntryPoint,
  startGetUsedElementIdsForLinkbaseTree,
} from "../../reducers/extractionReducer";
import { Trans, t } from "@lingui/macro";
import { ExtractedLinkbaseTree, IApiFact } from "../../api/types";
import LoadingOverlay from "../loadingOverlay";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ClearIcon } from "@mui/x-date-pickers";
import {
  appBarFixedHeight,
  taxonomyTreeSearchMinimumCharCount,
} from "../../helpers/constants";
import SearchIcon from "@mui/icons-material/Search";
import GeneralSidebarTaxonomyExplorerTabs from "./generalSidebarTaxonomyExplorerTabs";
import { applyWeightToRoleTypeElementTrees } from "../../helpers/addWeightToDisplayLabel";

export interface GeneralSidebarDrawerBodyProps {
  activeSectionId: DrawerIcon["id"] | undefined;
  onChangeActiveSection: (id: DrawerIcon["id"]) => void;
  currentSelectedLanguage: string;
  currentWidth: number;
  onToggleOpen: (section?: DrawerIcon["id"]) => void;
  isOpen: boolean;
  icons: DrawerIcon[];
  onToggleSecondarySidebar: () => void;
  currentSidebarId: DrawerIcon["id"] | undefined;
  isSecondarySidebarOpen: boolean;
}

const GeneralSidebarDrawerBody = ({
  activeSectionId,
  onChangeActiveSection,
  currentSelectedLanguage,
  currentWidth,
  onToggleOpen,
  isOpen,
  icons,
  currentSidebarId,
  onToggleSecondarySidebar,
  isSecondarySidebarOpen,
}: GeneralSidebarDrawerBodyProps) => {
  const dispatch = useAppDispatch();
  const [activeTreeviewType, setActiveTreeviewType] =
    useState<ExtractedLinkbaseTree["type"]>("Presentation");

  const facts = useAppSelector((state) =>
    state.extract.factType === "standard"
      ? (state.extract.facts as IApiFact[])
      : []
  );
  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const packageData = useAppSelector(
    (state) => state.extract.extractedBasicData
  );
  const validationEntries = useAppSelector(
    (state) => state.validate.validationResult?.entries
  );

  const linkbaseTreeMappings = useAppSelector(
    (state) => state.extract.linkbaseTreeMappings
  );

  const updatingTrees = useAppSelector(
    (state) => state.extract.getLinkbaseTreeByEntryPointPending
  );

  const usedElementIdsPerTreeMapping = useAppSelector(
    (state) => state.extract.linkbaseTreeUsedElementsMapping
  );

  const [activeEntryPointHref, setActiveEntryPointHref] = useState<string>(
    linkbaseTreeMappings.find((mapping) => mapping.fromPackage)?.entryPoint
      ?.href || ""
  );

  const activeLinkbaseTrees = useMemo(() => {
    if (activeEntryPointHref) {
      const mapping = linkbaseTreeMappings.find(
        (map) => map.entryPoint?.href === activeEntryPointHref
      );
      const newLinkbaseTree = packageData?.linkbaseTrees?.map((tree) => {
        if (tree.type === "Calculation") {
          return {
            ...tree,
            roleTypes: tree.roleTypes?.map(applyWeightToRoleTypeElementTrees),
          };
        }
        return tree;
      });
      return (mapping?.fromPackage ? newLinkbaseTree : mapping?.trees) || [];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEntryPointHref, linkbaseTreeMappings, packageData?.linkbaseTrees]);

  const handleMissingLinkbaseTree = async (
    type: ExtractedLinkbaseTree["type"]
  ): Promise<void> => {
    await dispatch(
      startGetLinkbaseTreeByEntryPoint({
        entryPointHref: activeEntryPointHref,
        type: type,
      })
    );
  };

  const handleMissingUsedElementIdsInTreeMapping = async (
    treeId: string
  ): Promise<void> => {
    const factElementIds = facts.map((fact) => fact.factElement.elementId);
    await dispatch(
      startGetUsedElementIdsForLinkbaseTree({
        treeId: treeId,
        elementIdsUsedInReport: factElementIds,
      })
    );
  };

  const [currentSearchFilter, setCurrentSearchFilter] = useState("");

  const sortedPresentationTrees = useMemo(() => {
    return activeLinkbaseTrees.map((tree) => {
      if (tree.type === "Presentation") {
        let sortedRoleTypes = [...(tree.roleTypes || [])].sort((a, b) => {
          const regex = /\[(\d{6})\]/;
          const aLabel = a.definitionLabels[0]?.label || "";
          const bLabel = b.definitionLabels[0]?.label || "";
          const aMatch = aLabel.match(regex);
          const bMatch = bLabel.match(regex);

          if (!aMatch && !bMatch) {
            return 0;
          }

          const aNumber = aMatch ? parseInt(aMatch[1], 10) : 0;
          const bNumber = bMatch ? parseInt(bMatch[1], 10) : 0;
          return aNumber - bNumber;
        });
        return {
          ...tree,
          roleTypes: sortedRoleTypes,
        };
      }
      return tree;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkbaseTrees]);

  useEffect(() => {
    if (activeLinkbaseTrees) {
      const activeLinkbaseTreeMapping = linkbaseTreeMappings.find(
        (mapp) => mapp.entryPoint?.href === activeEntryPointHref
      );
      if (!activeLinkbaseTreeMapping?.fromPackage) {
        const missingUsedMappings = activeLinkbaseTrees.filter(
          (tree) =>
            !usedElementIdsPerTreeMapping
              .map((map) => map.treeId)
              .includes(tree.id)
        );
        if (missingUsedMappings.length > 0) {
          const missingActive = missingUsedMappings.find(
            (tree) => tree.type === activeTreeviewType
          );
          if (missingActive) {
            handleMissingUsedElementIdsInTreeMapping(missingActive.id);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLinkbaseTrees, activeTreeviewType]);

  useEffect(() => {
    if (activeEntryPointHref) {
      const activeLinkbaseTreeMapping = linkbaseTreeMappings.find(
        (mapp) => mapp.entryPoint?.href === activeEntryPointHref
      );
      if (
        activeLinkbaseTreeMapping &&
        !activeLinkbaseTreeMapping.error &&
        (!activeLinkbaseTreeMapping.trees ||
          activeLinkbaseTreeMapping.trees.length === 0 ||
          !activeLinkbaseTreeMapping.trees.some(
            (tree) => tree.type === activeTreeviewType
          )) &&
        !activeLinkbaseTreeMapping.fromPackage
      ) {
        handleMissingLinkbaseTree(activeTreeviewType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEntryPointHref, activeTreeviewType]);

  const getHeadline = (sectionId: DrawerIcon["id"] | undefined): string => {
    if (!sectionId) return "";
    if (sectionId === "factDetails") return t`Facts & Elements`;
    if (sectionId === "anchorDetails") return t`Anchoring`;
    if (sectionId === "usedTags") return t`Mandatory tag usage`;
    return t`Taxonomy Explorer`;
  };

  const defTree = activeLinkbaseTrees.find(
    (t) => t.type.toLowerCase() === "definition"
  );

  const headerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [currentSidebarId]);

  return (
    <Grid container sx={{}}>
      <Box
        ref={headerRef}
        sx={{
          position: "fixed",
          top: `${appBarFixedHeight}px`,
          zIndex: 1,
          width: "100%",
          maxHeight: 770,
          backgroundColor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}>
        <BottomNavigation
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: currentWidth,
            backgroundColor: "white",
          }}
          value={activeSectionId}
          onChange={(_, val) => {
            if (val === "toggleSecondarySidebar") {
              onToggleSecondarySidebar();
              if (currentSidebarId && currentSidebarId === "factDetails") {
                onToggleOpen("explorer");
              }
              return;
            }
            onToggleOpen(val);
          }}>
          {icons.map((icon) => (
            <BottomNavigationAction
              key={`${icon.id}_${icon.title}`}
              value={icon.id}
              title={icon.title}
              disabled={icon.disabled}
              sx={{
                flex: 1,
              }}
              icon={
                <icon.icon
                  sx={{
                    color:
                      icon.id === activeSectionId
                        ? "#0094C9"
                        : "" || icon.id === "toggleSecondarySidebar"
                          ? isSecondarySidebarOpen
                            ? "#0094C9"
                            : ""
                          : "",
                    opacity: icon.disabled ? 0.5 : 1,
                  }}
                />
              }
            />
          ))}
        </BottomNavigation>
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: currentWidth,
            color: (theme) => theme.palette.text.secondary,
            pr: 1,
            pl: 2,
            pb: 2,
          }}>
          {getHeadline(activeSectionId)}
          <IconButton onClick={() => onToggleOpen()}>
            {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Typography>
        {factsExtractionError && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ whiteSpace: "initial", ml: 2, mr: 2, maxWidth: "100%" }}>
            <Trans>
              An error occurred while processing your files, which may affect
              how your report is displayed.
            </Trans>
          </Alert>
        )}
        {linkbaseTreeMappings.length > 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 2,
              mr: 2,
              mb: 2,
            }}>
            {activeSectionId !== "usedTags" && (
              <FormControl sx={{ maxWidth: currentWidth }}>
                <InputLabel>
                  <Trans>Entry Point</Trans>
                </InputLabel>
                <Select
                  value={activeEntryPointHref}
                  label={t`Entry Point`}
                  onChange={(e) => setActiveEntryPointHref(e.target.value)}>
                  {linkbaseTreeMappings.map((mapping) => (
                    <MenuItem value={mapping.entryPoint.href}>
                      {mapping.entryPoint.names?.find(
                        (name) =>
                          name.language === currentSelectedLanguage ||
                          name.language === "en"
                      )?.text || mapping.entryPoint.href}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}
        {activeSectionId === "explorer" && (
          <FormControl
            variant="outlined"
            sx={{ maxWidth: currentWidth - 50, pl: 2 }}>
            <OutlinedInput
              type="text"
              value={currentSearchFilter}
              placeholder={t`Name, Label, etc. (min 3 letters)`}
              onChange={(e: any) => setCurrentSearchFilter(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {currentSearchFilter.length <
                    taxonomyTreeSearchMinimumCharCount && <SearchIcon />}
                  {currentSearchFilter.length >=
                    taxonomyTreeSearchMinimumCharCount && (
                    <IconButton onClick={() => setCurrentSearchFilter("")}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        )}
        {activeSectionId === "explorer" && (
          <Box sx={{ maxWidth: currentWidth }}>
            <GeneralSidebarTaxonomyExplorerTabs
              currentSelectedTreeType={activeTreeviewType}
              onChangeTab={setActiveTreeviewType}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          mt: `${appBarFixedHeight + headerHeight}px`,
          overflow: "auto",
          maxHeight: `calc(100vh - ${appBarFixedHeight + headerHeight}px)`,
        }}>
        {activeSectionId === "explorer" && (
          <LoadingOverlay isVisible={updatingTrees}>
            <>
              {updatingTrees &&
                !sortedPresentationTrees.find(
                  (tree) => tree.type === activeTreeviewType
                ) && (
                  <Typography sx={{ ml: 2, opacity: 0.6 }}>
                    <Trans>Retreiving Taxonomy data...</Trans>
                  </Typography>
                )}
              <GeneralSidebarTaxonomyExplorer
                linkbaseTrees={sortedPresentationTrees}
                facts={facts}
                activeEntryPointHref={activeEntryPointHref}
                currentSelectedLanguage={currentSelectedLanguage}
                entryPoints={packageData?.entryPoints}
                onViewItemDetails={() => onChangeActiveSection("factDetails")}
                activeTreeviewType={activeTreeviewType}
                currentWidth={currentWidth}
                currentSearchFilter={currentSearchFilter}
                isPackageTaxonomy={
                  linkbaseTreeMappings.find(
                    (map) => map.entryPoint?.href === activeEntryPointHref
                  )?.fromPackage || false
                }
              />
            </>
          </LoadingOverlay>
        )}
        {activeSectionId === "factDetails" && (
          <GeneralSidebarFactDetailViewer
            validationEntries={validationEntries}
            facts={facts}
            activeEntryPoint={activeEntryPointHref}
            labeledElements={packageData?.labeledElements}
            currentSelectedLanguage={currentSelectedLanguage}
            currentWidth={currentWidth}
          />
        )}
        {activeSectionId === "anchorDetails" && (
          <GeneralSidebarAnchoringTreeview
            currentSelectedLanguage={currentSelectedLanguage}
            definitionTree={defTree}
            entryPoints={packageData?.entryPoints}
            facts={facts}
            activeEntryPointHref={activeEntryPointHref}
            onViewItemDetails={() => onChangeActiveSection("factDetails")}
            currentWidth={currentWidth}
          />
        )}
        {activeSectionId === "usedTags" && (
          <GeneralSideBarMandatoryTagsView facts={facts} />
        )}
      </Box>
    </Grid>
  );
};

export default GeneralSidebarDrawerBody;
