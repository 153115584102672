import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import {
  Container,
  Box,
  Alert,
  AlertTitle,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from "@mui/material";
import { useState, useMemo, useEffect, useRef } from "react";
import { IApiEurofilingFact, IApiFact } from "../../api/types";
import {
  defaultHiddenColumnIds,
  defaultTableRowsPerPage,
  ToolbarActions,
  factsExcelExportColumnIds,
} from "../../helpers/constants";
import { getFactsToolbarActions } from "../../helpers/getFactsToolbarActions";
import {
  EuroFilingFactDisplayItem,
  FactDisplayItem,
  factDisplayItemDateKeys,
  mapFactItemsForDisplay,
} from "../../helpers/mapFactItems";
import { DataColumnFilter, filterTable } from "../../helpers/tableFiltering";
import {
  getUserTablePreference,
  TablePreferences,
  setUserTablePreference,
} from "../../helpers/tablePreferencesUtils";
import {
  getComparator,
  Order,
  sortValueColumn,
} from "../../helpers/tableSorting";
import { useAppSelector } from "../../hooks/useAppSelector";
import DataTable from "./dataTable";
import ColumnFilterMenu from "./columnFilterMenu";
import ContentHeaderToolbar from "../contentHeaderToolbar";
import SelectLanguageMenu from "../selectLanguageMenu";
import ExcelExportDialog from "../excelExportDialog";
import {
  factDataTableColumnIdentifiers,
  FactTableAction,
  getFactsTableColumns,
} from "./factsTableColumns";
import FactErrorsDisplayDialog from "./factErrorsDisplayDialog";
import ReportGenerator from "../viewer/reportGenerator";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  factSelectionCallerUpdated,
  selectedFactIdsUpdated,
} from "../../reducers/internalLifeCycleReducer";
import { useNavigate, useLocation } from "react-router-dom";
import { TableVirtuosoHandle } from "react-virtuoso";
import { removeFromHtmlString } from "../../helpers/removeClassesFromHtmlString";

export interface FactsTableContainerProps {
  title: string;
  allFacts: Array<IApiFact | IApiEurofilingFact>;
  isGeneralSidebarOpen: boolean;
  currentGeneralSidebarDrawerWidth: number;
  currentSelectedLanguage: string;
  allLanguages: string[];
  onChangeLang: (lang: string) => void;
  excelExportFileName: string;
  excelExportSheetName: string;
}

const FactsTableContainer = ({
  title,
  allFacts,
  isGeneralSidebarOpen,
  currentGeneralSidebarDrawerWidth,
  currentSelectedLanguage,
  allLanguages,
  onChangeLang,
  excelExportFileName,
  excelExportSheetName,
}: FactsTableContainerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const virtuosoRef = useRef<TableVirtuosoHandle>();
  const user = useAppSelector((state) => state.manageUsers.currentUser);
  const userId = user?.emailAddress?.split("@")[0] || "";
  const extractedFactsType = useAppSelector((state) => state.extract.factType);
  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );
  const factsSelectionCaller = useAppSelector(
    (state) => state.internalLifeCycle.factSelectionCaller
  );

  const [columnFilterMenuAnchorElement, setColumnFilterMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const [currentOpenColumnFilterHeaderId, setCurrentOpenColumnFilterHeaderId] =
    useState("");

  const [currentHiddenColumnIds, setCurrentHiddenColumnIds] = useState<
    string[]
  >(
    getUserTablePreference<string[]>(
      title,
      userId,
      TablePreferences.HiddenColumns
    ) || defaultHiddenColumnIds
  );

  const [
    tableLanguageSelectMenuAnchorElement,
    setTableLanguageSelectMenuAnchorElement,
  ] = useState<null | HTMLElement>(null);

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [currentFilters, setCurrentFilters] = useState<
    DataColumnFilter<FactDisplayItem>[]
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultTableRowsPerPage);
  const [showReOrderColumnsExpDialog, setShowReOrderColumnsExpDialog] =
    useState(false);
  const [
    dontShowReOrderColumnsExpDialogAgain,
    setDontShowReOrderColumnsExpDialogAgain,
  ] = useState(true);

  const [showFactErrorsFactId, setShowFactErrorsFactId] = useState("");

  const [showViewer, setShowViewer] = useState(false);
  const [showExcelExportDialog, setShowExcelExportDialog] = useState(false);
  const [showOnlyFactsWithErrors, setShowOnlyFactsWithErrors] = useState(false);

  const loadFactsError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const linkbaseTrees = useAppSelector(
    (state) => state.extract.extractedBasicData?.linkbaseTrees
  );

  const labeledElements = useAppSelector(
    (state) => state.extract.extractedBasicData?.labeledElements
  );

  const validationResult = useAppSelector(
    (state) => state.validate.validationResult
  );

  const location = useLocation();

  const displayFacts = useMemo(
    () =>
      mapFactItemsForDisplay(
        allFacts,
        extractedFactsType,
        currentSelectedLanguage,
        linkbaseTrees?.find((t) => t.type.toLowerCase() === "presentation"),
        labeledElements,
        validationResult?.entries?.filter(
          (e) => e.elements && e.elements.length > 0
        )
      ),
    [
      allFacts,
      extractedFactsType,
      linkbaseTrees,
      currentSelectedLanguage,
      validationResult,
      labeledElements,
    ]
  );

  const handleToolbarIconClicked = (e: any, action: ToolbarActions) => {
    if (action === ToolbarActions.filterColumns) {
      setColumnFilterMenuAnchorElement(e.currentTarget);
    } else if (action === ToolbarActions.highlightErrors) {
      setShowOnlyFactsWithErrors((prev) => !prev);
      setCurrentToolbarActions(
        currentToolbarActions.map((action) => {
          if (action.id === ToolbarActions.highlightErrors) {
            action.turnedOn = !action.turnedOn;
          }
          return action;
        })
      );
    } else if (action === ToolbarActions.resetToDefaults) {
      setCurrentHiddenColumnIds(defaultHiddenColumnIds);
      setOrder("asc");
      setOrderBy("name");
      setCurrentFilters([]);
      setCurrentOpenColumnFilterHeaderId("");
    } else if (action === ToolbarActions.openInViewer) {
      setShowViewer((prev) => !prev);
      setCurrentToolbarActions(
        currentToolbarActions.map((action) => {
          if (action.id === ToolbarActions.openInViewer) {
            action.turnedOn = !action.turnedOn;
          }
          return action;
        })
      );
    } else if (action === ToolbarActions.selectLanguage) {
      setTableLanguageSelectMenuAnchorElement(e.currentTarget);
    } else if (action === ToolbarActions.download) {
      setShowExcelExportDialog(true);
    }
  };

  const [currentToolbarActions, setCurrentToolbarActions] = useState(
    getFactsToolbarActions(
      handleToolbarIconClicked,
      extractedFactsType,
      allLanguages
    )
  );

  const handleToggleColumnVisability = (
    columnId: string,
    showColumn: boolean
  ) => {
    let hiddenColumnIds: string[] = [];
    if (showColumn) {
      hiddenColumnIds = currentHiddenColumnIds.filter((id) => id !== columnId);
    } else {
      hiddenColumnIds = [...currentHiddenColumnIds, columnId];
    }
    setCurrentHiddenColumnIds(hiddenColumnIds);
    setUserTablePreference(
      title,
      userId,
      TablePreferences.HiddenColumns,
      hiddenColumnIds
    );
  };

  const handleSortTable = (
    event: React.MouseEvent<unknown>,
    property: keyof FactDisplayItem
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeInFilterColumn = (value: string) => {
    let existing = false;
    const filters = currentFilters.filter((filter) => {
      if (filter.key === currentOpenColumnFilterHeaderId) {
        filter.value = value;
        existing = true;
      }
      if (filter.value.length > 0) {
        return filter;
      }
      return false;
    });
    if (!existing) {
      filters.push({
        key: currentOpenColumnFilterHeaderId as keyof FactDisplayItem,
        value: value,
      });
    }
    setCurrentFilters(filters);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleCloseShowReOrderColumnsExpDialog = () => {
    setShowReOrderColumnsExpDialog(false);
    if (dontShowReOrderColumnsExpDialogAgain) {
      setUserTablePreference(
        title,
        userId,
        TablePreferences.DontShowWarning,
        1
      );
    }
  };

  const filterString = JSON.stringify(currentFilters);

  const columns = useMemo(() => {
    const handleRowIconClicked = (rowId: string, action: FactTableAction) => {
      dispatch(selectedFactIdsUpdated(rowId));
      dispatch(factSelectionCallerUpdated("facts"));
      if (action === FactTableAction.openInViewer) {
        navigate(`/viewer`, { state: { keepSelectedIds: true } });
      } else if (action === FactTableAction.ShowErrors) {
        setShowFactErrorsFactId(rowId);
      }
    };
    return getFactsTableColumns(
      extractedFactsType,
      currentOpenColumnFilterHeaderId,
      handleRowIconClicked,
      filterString
    ).filter((col) => !currentHiddenColumnIds.includes(col.key.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString, currentHiddenColumnIds, currentOpenColumnFilterHeaderId]);

  const filteredDisplayFacts = useMemo(() => {
    let filtered = displayFacts;
    if (showOnlyFactsWithErrors && extractedFactsType === "standard") {
      filtered = (displayFacts as FactDisplayItem[]).filter(
        (fact) => fact.relatedErrors && fact.relatedErrors.length > 0
      );
    }
    return filterTable<FactDisplayItem | EuroFilingFactDisplayItem>(
      filtered,
      filterString
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayFacts, filterString, showOnlyFactsWithErrors, extractedFactsType]);

  const orderedAndFilteredDisplayFacts = useMemo(() => {
    if (orderBy === "formattedValue") {
      return sortValueColumn(filteredDisplayFacts.slice(), order);
    } else {
      return filteredDisplayFacts
        .slice()
        .sort(
          getComparator(
            order,
            orderBy,
            factDisplayItemDateKeys.includes(orderBy)
          )
        );
    }
  }, [filteredDisplayFacts, order, orderBy]);

  const orderedFilteredAndPagedDisplayFacts = useMemo(() => {
    return orderedAndFilteredDisplayFacts.length < rowsPerPage ||
      rowsPerPage === -1
      ? orderedAndFilteredDisplayFacts
      : orderedAndFilteredDisplayFacts.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
  }, [orderedAndFilteredDisplayFacts, rowsPerPage, page]);

  useEffect(() => {
    if (
      selectedFactIds.length > 0 &&
      factsSelectionCaller !== "facts" &&
      factsSelectionCaller !== "extensions"
    ) {
      const factId = selectedFactIds[0];
      const index = orderedAndFilteredDisplayFacts
        .map((f) => f.id)
        .indexOf(factId);
      const page = Math.floor(index / rowsPerPage);
      setTimeout(
        () => {
          setPage(page);
          const pageRelativeIndex = index - page * rowsPerPage;
          if (virtuosoRef.current) {
            setTimeout(() => {
              virtuosoRef.current!.scrollToIndex(pageRelativeIndex);
            }, 500);
          }
        },
        factsSelectionCaller === "sidebar" ? 0 : 2000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactIds]);

  useEffect(() => {
    setPage(0);
  }, [filterString]);

  useEffect(() => {
    if (location.search === "?filter=name") {
      setCurrentOpenColumnFilterHeaderId("name");
      setCurrentFilters([
        {
          key: "name",
          value: "ifrs-full:NameOfReportingEntityOrOtherMeansOfIdentification",
        },
      ]);
      navigate("/facts");
    }
  }, [location.search, setCurrentOpenColumnFilterHeaderId, navigate]);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          ml: 0,
          pt: 10,
          overflow: "auto",
          width: (theme) =>
            isGeneralSidebarOpen
              ? `calc(100% - ${currentGeneralSidebarDrawerWidth}px)`
              : `calc(100% - calc(${theme.spacing(7)} + 1px))`,
        }}
        component="div">
        <Box>
          {loadFactsError && (
            <Alert severity="warning">
              <AlertTitle>
                <Trans>No facts available</Trans>
              </AlertTitle>
              <Trans>
                Could not load report facts. Some functionality may not be
                available
              </Trans>
            </Alert>
          )}
          {!loadFactsError && (
            <>
              <ContentHeaderToolbar
                title={title}
                actions={currentToolbarActions}
                showIcons
                showFilterInput={currentOpenColumnFilterHeaderId !== ""}
                onChangeFilterValue={handleChangeInFilterColumn}
                currentFilterValue={
                  columns.find(
                    (c) => c.header.id === currentOpenColumnFilterHeaderId
                  )?.header.currentFilterValue || ""
                }
                currentFilterColumnTitle={
                  columns.find(
                    (c) => c.header.id === currentOpenColumnFilterHeaderId
                  )?.header.label
                }
              />
              <Paper
                sx={{
                  minHeight: showViewer ? 380 : "82vh",
                  maxHeight: showViewer ? 380 : "82vh",
                }}>
                <DataTable<FactDisplayItem | EuroFilingFactDisplayItem>
                  virtuosoRef={virtuosoRef}
                  columns={columns}
                  currentSelectedPage={page}
                  data={orderedFilteredAndPagedDisplayFacts}
                  onToggleShowFilterInput={setCurrentOpenColumnFilterHeaderId}
                  onRequestSort={handleSortTable}
                  onSelectPage={handleChangePage}
                  onSelectRowsPerPage={handleChangeRowsPerPage}
                  onSelectRow={(rowId) => {
                    if (extractedFactsType !== "eurofiling") {
                      dispatch(selectedFactIdsUpdated(rowId));
                      dispatch(factSelectionCallerUpdated("facts"));
                    }
                  }}
                  selectedRowIds={selectedFactIds}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  totalRowCount={filteredDisplayFacts.length}
                  containerHeight={showViewer ? "36vh" : "75vh"}
                />
              </Paper>
            </>
          )}
        </Box>
        {showViewer && (
          <Paper
            sx={{
              overflowY: "scroll",
              mt: 3,
              minHeight: 380,
              maxHeight: 380,
            }}>
            <ReportGenerator
              isMinimal
              highlightAllFacts={false}
              highlightErrors={false}
              showDebugView={false}
            />
          </Paper>
        )}
      </Container>
      <ColumnFilterMenu<FactDisplayItem>
        allColumnIdentifiers={factDataTableColumnIdentifiers(
          extractedFactsType
        ).map((c) => {
          return {
            id: c.headerId,
            label: c.label,
          };
        })}
        anchorElement={columnFilterMenuAnchorElement}
        onCloseMenu={() => setColumnFilterMenuAnchorElement(null)}
        onToggleColumn={handleToggleColumnVisability}
        selectedIds={currentHiddenColumnIds}
      />
      <SelectLanguageMenu
        allLangs={allLanguages}
        anchorElement={tableLanguageSelectMenuAnchorElement}
        onCloseMenu={() => setTableLanguageSelectMenuAnchorElement(null)}
        onSelectLang={onChangeLang}
        selectedLang={currentSelectedLanguage}
      />
      <FactErrorsDisplayDialog
        open={showFactErrorsFactId.length > 0}
        onClose={() => setShowFactErrorsFactId("")}
        errors={
          extractedFactsType === "standard"
            ? (orderedFilteredAndPagedDisplayFacts as FactDisplayItem[]).find(
                (f) => f.id === showFactErrorsFactId
              )?.relatedErrors || []
            : []
        }
      />
      <Dialog
        open={showReOrderColumnsExpDialog}
        onClose={() => setShowReOrderColumnsExpDialog(false)}>
        <DialogTitle>
          <Trans>Re order table columns</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Select a column by clicking on its title, then use the left and
              right arrow keys to move it
            </Trans>
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowReOrderColumnsExpDialogAgain}
                onChange={(e) =>
                  setDontShowReOrderColumnsExpDialogAgain(e.target.checked)
                }
              />
            }
            label={t`Do not show this again`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShowReOrderColumnsExpDialog}>
            <Trans>Ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <ExcelExportDialog
        actionBody={{
          sheets: [
            {
              sheetName: excelExportSheetName,
              serializedCollection: JSON.stringify(
                displayFacts.map((fact) => {
                  const f: Partial<
                    FactDisplayItem | EuroFilingFactDisplayItem
                  > = {};
                  for (const columnId of factsExcelExportColumnIds) {
                    let value = (fact as any)[columnId];
                    if (
                      columnId === "formattedValue" &&
                      /<\/?[a-z][\s\S]*>/i.test(value.toString())
                    ) {
                      value = removeFromHtmlString(
                        value.toString(),
                        false,
                        true
                      );
                    } else if (
                      columnId === "dimensionValues" &&
                      value !== undefined
                    ) {
                      value = Object.entries(value)
                        .map((val) => `${val[0]};${val[1]}`)
                        .join(";");
                    }
                    (f as any)[columnId] = value;
                  }
                  return f;
                })
              ),
              dataType:
                extractedFactsType === "eurofiling" ? "EurofilingFact" : "Fact",
            },
          ],
        }}
        actionUrl={`api/exports/excel?returnFileName=${excelExportFileName}`}
        open={showExcelExportDialog}
        onClose={() => setShowExcelExportDialog(false)}
        title={t`Exporting...`}
      />
    </>
  );
};

export default FactsTableContainer;
