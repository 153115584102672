import { EntryPoint, IApiFact, ExtractedLinkbaseTree } from "../../api/types";

import GeneralSidebarTaxonomyExplorerLazyTreeview from "./generalSidebarTaxonomyExplorerLazyTreeview";

export interface GeneralSidebarTaxonomyExplorerProps {
  linkbaseTrees: ExtractedLinkbaseTree[];
  facts: IApiFact[];
  activeEntryPointHref: string;
  currentSelectedLanguage: string;
  entryPoints: EntryPoint[] | undefined;
  onViewItemDetails: () => void;
  activeTreeviewType: ExtractedLinkbaseTree["type"];
  currentWidth: number;
  currentSearchFilter: string;
  isPackageTaxonomy: boolean;
}

const GeneralSidebarTaxonomyExplorer = ({
  linkbaseTrees,
  facts,
  activeEntryPointHref,
  currentSelectedLanguage,
  entryPoints,
  onViewItemDetails,
  activeTreeviewType,
  currentWidth,
  currentSearchFilter,
  isPackageTaxonomy,
}: GeneralSidebarTaxonomyExplorerProps) => {
  return (
    <GeneralSidebarTaxonomyExplorerLazyTreeview
      currentSearchFilter={currentSearchFilter}
      linkbaseTree={linkbaseTrees.find(
        (tree) => tree.type === activeTreeviewType
      )}
      facts={facts}
      activeEntryPointHref={activeEntryPointHref}
      currentSelectedLanguage={currentSelectedLanguage}
      entryPoints={entryPoints}
      onViewItemDetails={onViewItemDetails}
      currentWidth={currentWidth}
      isPackageTaxonomy={isPackageTaxonomy}
    />
  );
};

export default GeneralSidebarTaxonomyExplorer;
