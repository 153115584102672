import { t } from "@lingui/macro";
import {
  Tooltip,
  Dialog,
  DialogContent,
  Typography,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import CloseIcon from "@mui/icons-material/Close";

export interface LargeTextValueDisplayProps {
  text: string;
}

const LargeTextValueDisplay = ({ text }: LargeTextValueDisplayProps) => {
  const [showContentModal, setShowContentModal] = useState(false);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setShowContentModal((prev) => !prev);
  };

  return (
    <>
      <Tooltip
        title={`${text.substring(
          0,
          50
        )}... (${t`Click to see the entire text`})`}>
        <FormatAlignJustifyIcon onClick={handleClick} />
      </Tooltip>
      <Dialog
        maxWidth="xl"
        open={showContentModal}
        onClose={() => setShowContentModal(false)}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClick}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{text}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LargeTextValueDisplay;
