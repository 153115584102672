import { Typography } from "@mui/material";
import { getChangedLinkbaseItemLabel } from "../../helpers/getChangedLinkbaseItemLabel";
import { LinkbaseTreeBranchWithMatchingComparisonObject } from "../../helpers/getFlatTreeComparisonArray";
import { getChangeTypeCellColor } from "./changedFactsTableColumns";
import LinkbaseTreeChangedPropsTooltip from "./linkbaseTreeChangedPropsTooltip";

interface ChangedLinkbaseTreeBranchListItemProps {
  item: LinkbaseTreeBranchWithMatchingComparisonObject;
  isNew: boolean;
  currentSelectedLanguage: string;
}

const ChangedLinkbaseTreeBranchListItem = ({
  item,
  isNew,
  currentSelectedLanguage,
}: ChangedLinkbaseTreeBranchListItemProps) => {
  const type =
    item.branch && "roleTypeId" in item.branch ? "roleType" : "elementTree";
  const displayLabel = getChangedLinkbaseItemLabel(
    item,
    currentSelectedLanguage,
    isNew
  );
  return (
    <LinkbaseTreeChangedPropsTooltip comparisonObject={item.comparisonObject}>
      <Typography
        component="span"
        sx={{
          width: "100%",
          cursor: "default",
          mt: 1,
          flex: "auto",
          pl: `${item.indentIndex + 2}em`,
          color: (theme) =>
            type === "elementTree"
              ? getChangeTypeCellColor(theme, item.comparisonObject?.state)
              : "",
        }}>
        {displayLabel}
      </Typography>
    </LinkbaseTreeChangedPropsTooltip>
  );
};

export default ChangedLinkbaseTreeBranchListItem;
