import { Switch, TableCell, TableRow, Tooltip } from "@mui/material";
import { IApiUser } from "../../api/types";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { UserEditingAction } from "../../pages/userDetails";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { LoadingButton } from "@mui/lab";

export interface OrganizationUserTableRowProps {
  rowIndex: number;
  user: IApiUser;
  onUserAction: (
    userId: string,
    action: UserEditingAction,
    val?: string | boolean
  ) => void;
  isLoadingState: {
    id: string;
    pending: boolean;
  };
}

const OrganizationUserTableRow = ({
  rowIndex,
  user,
  onUserAction,
  isLoadingState,
}: OrganizationUserTableRowProps) => (
  <TableRow key={`org_users_table_row_${rowIndex}`}>
    <TableCell key={`org_users_table_row_${rowIndex}_cell_0`}>
      <>
        {user.name ?? "N/A"}
        <ModeEditOutlinedIcon
          sx={{ float: "right" }}
          onClick={() => onUserAction(user.id, "openNameEditDialog")}
        />
      </>
    </TableCell>
    <TableCell key={`org_users_table_row_${rowIndex}_cell_1`}>
      {user.emailAddress}
    </TableCell>
    <TableCell key={`org_users_table_row_${rowIndex}_cell_2`}>
      {user.id}
    </TableCell>
    <TableCell
      key={`org_users_table_row_${rowIndex}_cell_4`}
      sx={{ textAlign: "center" }}>
      {user.isAdmin ? (
        <CheckOutlinedIcon
          sx={{ color: (theme) => theme.palette.success.main }}
        />
      ) : (
        <CloseOutlinedIcon
          sx={{ color: (theme) => theme.palette.error.main }}
        />
      )}
    </TableCell>
    <TableCell key={`org_users_table_row_${rowIndex}_cell_3`}>
      <Switch
        disabled={user.editing || user.saving}
        checked={!user.isDeactiviated}
        onChange={() =>
          onUserAction(user.id, "toggleActivate", user.isDeactiviated)
        }
      />
    </TableCell>
    <TableCell>
      <Tooltip title="Resend activation email">
        <LoadingButton
          loading={isLoadingState.pending && isLoadingState.id === user.id}
          variant="outlined"
          sx={{ fontSize: "0.8rem" }}
          onClick={() => onUserAction(user.id, "ResendActivationEmail")}>
          <ForwardToInboxIcon sx={{ mr: 0.5 }} />
          Resend
        </LoadingButton>
      </Tooltip>
    </TableCell>
  </TableRow>
);

export default OrganizationUserTableRow;
