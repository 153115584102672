import {
  ChangedProperty,
  ExtractedElementTree,
  ElementTreeCompareModel,
  IApiFactComparisonResult,
  IApiLinkbaseTreeComparisonResult,
  ExtractedLinkbaseTree,
  ExtractedRoleType,
  RoleTypeCompareModel,
} from "../api/types";
import { getAllChangesFromCompareObject } from "./getAllChangesFromCompareObject";
import { getChangedLinkbaseItemLabel } from "./getChangedLinkbaseItemLabel";

export interface LinkbaseTreeBranchWithMatchingComparisonObject {
  branch: ExtractedRoleType | ExtractedElementTree | undefined;
  indentIndex: number;
  comparisonObject: RoleTypeCompareModel | ElementTreeCompareModel | undefined;
}

export interface ChangedLinkbasetreeExportItem {
  oldValue: string;
  newValue: string;
  indentIndex: number;
  changeType: IApiFactComparisonResult["state"] | null;
  changedProps: ChangedProperty[];
}

const getComparisonObjectByElementId = (
  branchId: string,
  roleTypeComparison: RoleTypeCompareModel | undefined
): ElementTreeCompareModel | undefined => {
  if (!roleTypeComparison) return undefined;
  return roleTypeComparison.elementTreeComparisons.find(
    (c) =>
      c.currentElementTreeId !== undefined &&
      c.currentElementTreeId === branchId
  );
};

const getDeletedChildrenComparisonObjectByElementId = (
  branchId: string,
  roleTypeComparison: RoleTypeCompareModel | undefined
): ElementTreeCompareModel[] => {
  if (!roleTypeComparison) return [];
  return roleTypeComparison.elementTreeComparisons.filter(
    (c) =>
      c.state === "Deleted" &&
      c.parentElementTreeId !== undefined &&
      c.parentElementTreeId === branchId
  );
};

const addTreeElementRecursive = (
  output: LinkbaseTreeBranchWithMatchingComparisonObject[],
  branch: ExtractedElementTree,
  parentComparisonObject: RoleTypeCompareModel | undefined,
  indentIndex: number
) => {
  const relevantComparisonObject = getComparisonObjectByElementId(
    branch.id,
    parentComparisonObject
  );
  const deletedChildComparisonObjects =
    getDeletedChildrenComparisonObjectByElementId(
      branch.id,
      parentComparisonObject
    );

  output.push({
    branch: branch,
    comparisonObject: relevantComparisonObject,
    indentIndex: indentIndex,
  });

  if (deletedChildComparisonObjects.length > 0) {
    for (const obj of deletedChildComparisonObjects) {
      output.push({
        branch: undefined,
        comparisonObject: obj,
        indentIndex: indentIndex + 1,
      });
    }
  }
  if (branch.children) {
    for (const childBranch of branch.children) {
      addTreeElementRecursive(
        output,
        childBranch,
        parentComparisonObject,
        indentIndex + 1
      );
    }
  }
};

export const getFlatTreeComparisonExportArray = (
  tree: ExtractedLinkbaseTree | undefined,
  treeComparison: IApiLinkbaseTreeComparisonResult | undefined,
  currentSelectedLanguage: string
): ChangedLinkbasetreeExportItem[] => {
  if (!treeComparison) return [];
  return getFlatTreeComparisonArray(tree, treeComparison).map((item) => {
    return {
      indentIndex: item.indentIndex,
      changeType: item.comparisonObject?.state || null,
      changedProps: getAllChangesFromCompareObject(item.comparisonObject),
      newValue: getChangedLinkbaseItemLabel(
        item,
        currentSelectedLanguage,
        true
      ),
      oldValue: getChangedLinkbaseItemLabel(
        item,
        currentSelectedLanguage,
        false
      ),
    };
  });
};

export const getFlatTreeComparisonArray = (
  tree: ExtractedLinkbaseTree | undefined,
  treeComparison: IApiLinkbaseTreeComparisonResult
): LinkbaseTreeBranchWithMatchingComparisonObject[] => {
  const output: LinkbaseTreeBranchWithMatchingComparisonObject[] = [];
  if (!tree) return output;
  for (const roleType of tree.roleTypes || []) {
    const initialIndent = 0;
    const comparisonObject = treeComparison.roleTypeComparisons.find(
      (rt) =>
        rt.currentRoleTypeId !== undefined &&
        rt.currentRoleTypeId === roleType.id
    );
    output.push({
      branch: roleType,
      indentIndex: initialIndent,
      comparisonObject: comparisonObject,
    });
    const deletedChildrenCompareObjects =
      comparisonObject?.elementTreeComparisons.filter(
        (comp) =>
          comp.state === "Deleted" &&
          (comp.parentElementTreeId === undefined ||
            comp.parentElementTreeId === null)
      );
    if (deletedChildrenCompareObjects) {
      for (const deletedChildCompareObject of deletedChildrenCompareObjects) {
        output.push({
          branch: undefined,
          indentIndex: initialIndent + 1,
          comparisonObject: deletedChildCompareObject,
        });
      }
    }
    if (roleType.elementTrees) {
      for (const childBranch of roleType.elementTrees) {
        addTreeElementRecursive(
          output,
          childBranch,
          comparisonObject,
          initialIndent + 1
        );
      }
    }
  }

  return output;
};
