import {
  ExtractedElementTree,
  ExtractedLinkbaseTree,
  ExtractedRoleType,
} from "../api/types";
import { anchoringArcrole } from "./constants";

const isElementTreeAnchoringRecursive = (
  elemTree: ExtractedElementTree
): boolean => {
  if (elemTree.arcrole?.toLowerCase() === anchoringArcrole.toLowerCase())
    return true;
  if (!elemTree.children || elemTree.children.length === 0) return false;
  return elemTree.children.some((t) => isElementTreeAnchoringRecursive(t));
};

const isRoleTypeAnchoring = (roleType: ExtractedRoleType): boolean => {
  if (!roleType.elementTrees || roleType.elementTrees.length === 0)
    return false;
  return roleType.elementTrees.some((t) => isElementTreeAnchoringRecursive(t));
};

export const filterAnchoringRoleTypes = (
  tree: ExtractedLinkbaseTree | undefined
): ExtractedLinkbaseTree | undefined => {
  if (!tree) return undefined;
  const relevantRoleTypes = tree.roleTypes?.filter((rt) =>
    isRoleTypeAnchoring(rt)
  );
  return {
    entryPointHref: tree.entryPointHref,
    id: tree.id,
    language: tree.language,
    roleTypes: relevantRoleTypes,
    type: tree.type,
  };
};
