import { signalrInternalTicketAbsoluteUrl } from "../helpers/constants";
import tokenHelper from "../helpers/tokenHelper";

export const getSignalrInternalTicket = async (): Promise<
  string | undefined
> => {
  try {
    const resp = await fetch(
      `${signalrInternalTicketAbsoluteUrl}?cb=${crypto.randomUUID()}`,
      {
        headers: {
          Authorization: `Bearer ${tokenHelper.token}`,
        },
      }
    );
    const body = await resp.json();
    return body.Ticket || body.ticket;
  } catch {
    return undefined;
  }
};
