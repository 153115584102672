export function wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function waitWithPromise(milliseconds: number, promise: Promise<void>) {
  return Promise.allSettled([promise, wait(milliseconds)]).then((results) => {
    if (results[0].status === "fulfilled") {
      return results[0].value;
    } else {
      throw results[0].reason;
    }
  });
}
