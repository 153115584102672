import { Slice, createSlice, current } from "@reduxjs/toolkit";
import { FactErrorMapping } from "../helpers/mapValidationErrorsToFacts";
import { ValidationElement } from "../api/types";
import { getNextSelectedFactId } from "../helpers/getNextSelectedFactId";

export type FactSelectionCaller =
  | "viewer_report"
  | "facts"
  | "extensions"
  | "sidebar"
  | "summary_viewer"
  | "summary_facts"
  | "summary_presentation"
  | "viewer_toolbar"
  | "";

export interface IInternalLifeCycleState {
  selectedFactIds: string[];
  factSelectionCaller: FactSelectionCaller;
  highlightAllFacts: boolean;
  highlightErrors: boolean;
  errorsByFactId: FactErrorMapping[];
  selectedError: ValidationElement | undefined;
  fontsLoadedForReportIds: string[];
}

const initialState: IInternalLifeCycleState = {
  highlightAllFacts: false,
  highlightErrors: false,
  selectedFactIds: [],
  factSelectionCaller: "",
  errorsByFactId: [],
  selectedError: undefined,
  fontsLoadedForReportIds: [],
};

const internalLifeCycleSlice: Slice<IInternalLifeCycleState> = createSlice({
  name: "internalLifeCycle",
  initialState: initialState,
  reducers: {
    clearLifeCycleState: (): IInternalLifeCycleState => initialState,
    XBRLNodeClicked: (state, action): IInternalLifeCycleState => {
      return {
        ...state,
        selectedFactIds: [
          getNextSelectedFactId(action.payload, current(state.selectedFactIds)),
        ],
      };
    },
    selectedFactIdsUpdated: (state, action): IInternalLifeCycleState => {
      const updatedFactIds = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return {
        ...state,
        selectedFactIds: updatedFactIds,
      };
    },
    factSelectionCallerUpdated: (state, action): IInternalLifeCycleState => {
      return {
        ...state,
        factSelectionCaller: action.payload,
      };
    },
    selectedErrorUpdated: (state, action): IInternalLifeCycleState => {
      return {
        ...state,
        selectedError: action.payload,
      };
    },
    errorsByFactIdUpdated: (state, action): IInternalLifeCycleState => {
      return {
        ...state,
        errorsByFactId: action.payload,
      };
    },
    highlightAllFactsUpdated: (state): IInternalLifeCycleState => {
      return {
        ...state,
        highlightAllFacts: !state.highlightAllFacts,
      };
    },
    highlightErrorsUpdated: (state): IInternalLifeCycleState => {
      return {
        ...state,
        highlightErrors: !state.highlightErrors,
      };
    },
    fontsLoadedForReportIdsUpdated: (
      state,
      action
    ): IInternalLifeCycleState => {
      return {
        ...state,
        fontsLoadedForReportIds: [
          ...state.fontsLoadedForReportIds,
          action.payload,
        ],
      };
    },
  },
});

export const {
  clearLifeCycleState,
  XBRLNodeClicked,
  selectedFactIdsUpdated,
  factSelectionCallerUpdated,
  errorsByFactIdUpdated,
  highlightAllFactsUpdated,
  highlightErrorsUpdated,
  selectedErrorUpdated,
  fontsLoadedForReportIdsUpdated,
} = internalLifeCycleSlice.actions;

export default internalLifeCycleSlice.reducer;
