export const getEnumerationValueSets = (
  contextValue: string
): Record<string, string> => {
  if (!contextValue) return {};
  const output: Record<string, string> = {};
  const values = contextValue.split(" ");
  for (const value of values) {
    const splitValue = value.split("#");
    if (splitValue.length === 2) {
      if (!Object.hasOwn(output, splitValue[1])) {
        output[splitValue[1]] = splitValue[0];
      } else {
        console.log(`key ${splitValue[1]} already exists`);
      }
    }
  }
  return output;
};
