export const getFileType = (file: File): string => {
    const fileType = file.type.toLowerCase();
    switch (fileType) {
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'excel';
        case 'application/pdf':
            return 'pdf';
        case 'application/xhtml+xml':
            return 'xhtml';
        case 'application/x-zip-compressed' || 'application/zip':
            return 'zip';
        case 'application/epub+zip':
            return 'epub';
        default:
            return '';
    }
}