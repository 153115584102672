export const modifyHtmlForDebugView = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, "text/html");

  const allPageDivs = doc.querySelectorAll("[data-page-id]");
  const makrerSpan = doc.createElement("span");
  makrerSpan.classList.add("epub-debug-added-indexer-span");

  allPageDivs.forEach((pageDiv) => {
    const pageColumnDivs = pageDiv.querySelectorAll("[data-column-index]");
    if (pageColumnDivs.length > 0) {
      Array.from(pageColumnDivs)
        .flatMap((div) => Array.from(div.querySelectorAll("span")))
        .forEach((span) => {
          (span as HTMLElement).classList.add("epub-debug-marked-text-span");
        });
      Array.from(pageColumnDivs)
        .flatMap((div) => Array.from(div.querySelectorAll("p")))
        .forEach((para, index) => {
          const currentMarker = makrerSpan.cloneNode() as HTMLSpanElement;
          currentMarker.innerText = (index + 1).toString();
          const firstSpan = (para as HTMLElement).querySelector("span");
          firstSpan?.appendChild(currentMarker);
        });
    }
  });

  return new XMLSerializer().serializeToString(doc);
};
