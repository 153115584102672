import { ExtractedElementTree, ExtractedRoleType } from "../api/types";

function addWeightToDisplayLabel(
    elementTree: ExtractedElementTree
): ExtractedElementTree {
    let newDisplayLabels = elementTree.linkbaseTreeElement.displayLabels;
    if (elementTree.weight !== 0) {
        newDisplayLabels = elementTree.linkbaseTreeElement.displayLabels.map(
            (label) => ({
                ...label,
                label: `${elementTree.weight === 1 ? "+" : elementTree.weight === -1 ? "-" : ""} ${label.label}`,
            })
        );
    }

    let newChildren = elementTree.children;
    if (elementTree.children) {
        newChildren = elementTree.children.map(addWeightToDisplayLabel);
    }

    return {
        ...elementTree,
        linkbaseTreeElement: {
            ...elementTree.linkbaseTreeElement,
            displayLabels: newDisplayLabels,
        },
        children: newChildren,
    };
}

export function applyWeightToRoleTypeElementTrees(roleType: ExtractedRoleType): ExtractedRoleType {
    let newElementTrees = roleType.elementTrees;
    if (roleType.elementTrees) {
        newElementTrees = roleType.elementTrees.map(addWeightToDisplayLabel);
    }

    return {
        ...roleType,
        elementTrees: newElementTrees,
    };
}