import { FileError } from "react-dropzone";
import { fileCombinations } from "./constants";
import { getFileType } from "./getFileType";

export const validateAgainstExistingFiles = (
  incomingFile: File,
  acceptedFiles: File[] | undefined
): FileError | FileError[] | null => {

  let allFiles = acceptedFiles ? [incomingFile, ...acceptedFiles] : [incomingFile];
  let result = checkCombination(allFiles);

  return result;
};

function checkCombination(files: File[]): FileError | FileError[] | null {
  const fileTypes = files.map(getFileType);
  for (let combination of fileCombinations) {
    if (fileTypes.every(fileType => combination.files.includes(fileType))) {
      return combination.code;
    }
  }
  return null;
}