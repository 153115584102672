import { ValidationElement } from "../api/types";

export const getHighestSeverityError = (
  entries: ValidationElement[]
): ValidationElement["severity"] => {
  if (entries.length === 0) return "Undefined";
  if (entries.find((e) => e.severity === "FatalError")) return "FatalError";
  if (entries.find((e) => e.severity === "Error")) return "Error";
  if (entries.find((e) => e.severity === "Warning")) return "Warning";
  if (entries.find((e) => e.severity === "Inconsistency"))
    return "Inconsistency";
  if (entries.find((e) => e.severity === "Info")) return "Info";
  return "Undefined";
};
