import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getReportFilesAsHtml } from "../helpers/getReportFileAsHtml";
import { getMappedReport } from "../helpers/getMappedReportHtmlAsString";
import {
  addReportSanitizationHooks,
  getSanitizeReportHtml,
} from "../helpers/getSanitizeReportHtml";
import { factsUpdated } from "./extractionReducer";
import { IApiEurofilingFact, IApiFact } from "../api/types";
import { handleReportFileCss } from "../helpers/handleReportFileCss";
import { handleReportInternalImages } from "../helpers/handleReportInternalImages";

export interface MappedReport {
  id: string;
  name: string;
  reportHtmlUrl: string;
  includesFontResources: boolean;
  showStyleWarning: boolean;
}

export interface IMappingState {
  mappingExtractedData: boolean;
  mappingError: boolean;
  reports: MappedReport[];
}

const initialState: IMappingState = {
  mappingExtractedData: false,
  mappingError: false,
  reports: [],
};

export const startMappingImportedData = createAsyncThunk(
  "mapping/map/start",
  async (
    _,
    { rejectWithValue, getState, dispatch }
  ): Promise<MappedReport[]> => {
    try {
      const state = getState() as RootState;
      const extractedReports = await getReportFilesAsHtml(
        state.import.downloadedPayloadUrl,
        state.import.downloadedPayloadType
      );
      const reports: MappedReport[] = extractedReports.reports.map((report) => {
        return {
          id: crypto.randomUUID(),
          includesFontResources: false,
          reportHtmlUrl: "",
          name: report.name,
          firstTextSpanDataWordIds: [],
          showStyleWarning: false,
        };
      });
      let currentStaringIndex = 0;
      let reOrderedFacts: Array<IApiFact | IApiEurofilingFact> = [];
      let counter = 0;
      for (const report of reports) {
        const extractedReport = extractedReports.reports[counter];
        let currentReportHtml = extractedReport.html;
        if (state.extract.facts.length > 0) {
          const mappedRespone = await getMappedReport(
            currentReportHtml,
            state.extract.facts,
            state.extract.factType,
            report.id,
            currentStaringIndex,
            extractedReports.cssString,
            extractedReports.images
          );
          currentReportHtml = mappedRespone.mappedHtml;
          reOrderedFacts = [...reOrderedFacts, ...mappedRespone.reorderedFacts];
          currentStaringIndex =
            currentStaringIndex + mappedRespone.reorderedFacts.length;
          report.showStyleWarning = mappedRespone.showStyleWarning;
          report.includesFontResources =
            mappedRespone.mappedHtml.includes("@font-face");
        } else {
          let doc = new DOMParser().parseFromString(
            currentReportHtml,
            "application/xhtml+xml"
          );
          const resp = handleReportFileCss(doc, extractedReports.cssString);
          let updatedHtml = resp.html;
          updatedHtml = handleReportInternalImages(
            updatedHtml,
            extractedReports.images
          );
          currentReportHtml = new XMLSerializer().serializeToString(doc);
          const cspTag = window.document.querySelector("meta[property]");
          const noncTag = `<style nonce="${
            cspTag?.getAttribute("content") || ""
          }" `;
          currentReportHtml = currentReportHtml.replaceAll("<style ", noncTag);
          report.showStyleWarning = resp.showStyleWarning;
          report.includesFontResources = resp.html.includes("@font-face");
        }
        addReportSanitizationHooks();
        currentReportHtml = getSanitizeReportHtml(currentReportHtml);
        report.reportHtmlUrl = URL.createObjectURL(
          new Blob([currentReportHtml], { type: "text/html" })
        );
        counter++;
      }
      if (state.extract.facts.length !== reOrderedFacts.length) {
        reOrderedFacts.push(
          ...state.extract.facts.filter(
            (fact) => !reOrderedFacts.map((f) => f.id).includes(fact.id)
          )
        );
      }
      dispatch(factsUpdated(reOrderedFacts));
      return reports;
    } catch (ex) {
      console.log(ex);
      throw rejectWithValue(ex);
    }
  }
);

const mappingSlice: Slice<IMappingState> = createSlice({
  name: "mapping",
  initialState: initialState,
  reducers: {
    mappingExtractedDataCleared: (state): IMappingState => {
      for (const report of state.reports) {
        if (report.reportHtmlUrl) {
          URL.revokeObjectURL(report.reportHtmlUrl);
        }
      }
      return {
        ...state,
        reports: [],
      };
    },
    disableStyleWarning: (state, action): IMappingState => {
      const reports = JSON.parse(JSON.stringify(state.reports));
      for (const report of reports) {
        if (report.id === action.payload) {
          report.showStyleWarning = false;
        }
      }
      return {
        ...state,
        reports: reports,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startMappingImportedData.pending, (state): IMappingState => {
        return {
          ...state,
          mappingExtractedData: true,
          mappingError: false,
        };
      })
      .addCase(
        startMappingImportedData.fulfilled,
        (state, action): IMappingState => {
          return {
            ...state,
            mappingExtractedData: false,
            reports: action.payload,
          };
        }
      )
      .addCase(startMappingImportedData.rejected, (state): IMappingState => {
        console.log("mapping failed!");
        return {
          ...state,
          mappingExtractedData: false,
          mappingError: true,
        };
      });
  },
});

export const { mappingExtractedDataCleared, disableStyleWarning } =
  mappingSlice.actions;

export default mappingSlice.reducer;
