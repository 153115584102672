import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IApiOrganization, IApiUser } from "../api/types";
import {
  createNewUser,
  getCurrentOrganization,
  getCurrentOrganizationUsers,
  getCurrentUser,
  reSendActivationEmail,

  toggleUserActivation,
  updateUser,
} from "../api/accounts";

export interface IUserManagementState {
  currentUser: IApiUser | null;
  currentOragnization: IApiOrganization | null;
  getUserError: boolean;
  getOrgError: boolean;
  getOrgPending: boolean;
  sendEmailError: boolean;
  sendEmailPending: {
    id: string;
    pending: boolean;
  };
  userAgreedToImportErrors: boolean;
}

const initialState: IUserManagementState = {
  currentUser: null,
  currentOragnization: null,
  getUserError: false,
  getOrgError: false,
  getOrgPending: false,
  sendEmailError: false,
  sendEmailPending: {
    id: "",
    pending: false,
  },
  userAgreedToImportErrors: false,
};

export const getCurrentUserDetails = createAsyncThunk<IApiUser>(
  "user/currentuser/get",
  async (_, { rejectWithValue }): Promise<IApiUser> => {
    try {
      const resp = await getCurrentUser();
      return resp.data;
    } catch (ex: any) {
      throw rejectWithValue(ex);
    }
  }
);

export const getCurrentOrgDetails = createAsyncThunk<
  IApiOrganization,
  {
    isAdmin: boolean;
  }
>(
  "user/currentorg/get",
  async (args, { rejectWithValue }): Promise<IApiOrganization> => {
    try {
      const orgResp = await getCurrentOrganization();
      if (args.isAdmin) {
        const usersResp = await getCurrentOrganizationUsers();
        orgResp.data.users = usersResp.data;
      }
      return orgResp.data;
    } catch (ex: any) {
      throw rejectWithValue(ex);
    }
  }
);

export const updateUserStarted = createAsyncThunk(
  "user/users/update",
  async (args: { user: IApiUser }, { rejectWithValue }): Promise<void> => {
    try {
      await updateUser(args.user.id, args.user);
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const createUserStarted = createAsyncThunk(
  "user/users/create",
  async (
    args: { name: string; email: string },
    { rejectWithValue }
  ): Promise<IApiUser> => {
    try {
      const resp = await createNewUser(args.name, args.email);
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const toggleUserActivationStarted = createAsyncThunk(
  "user/users/toggleActivation",
  async (
    args: { id: string; currentlyActivated: boolean },
    { rejectWithValue }
  ): Promise<void> => {
    try {
      await toggleUserActivation(args.id, args.currentlyActivated);
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const reSendActivationEmailStarted = createAsyncThunk(
  "user/users/resendActivationEmail",
  async (args: { id: string }, { rejectWithValue }): Promise<void> => {
    try {
      await reSendActivationEmail(args.id);
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

const userManagementSlice: Slice<IUserManagementState> = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userLoggedOut: (state): IUserManagementState => {
      return {
        ...state,
        currentUser: null,
        currentOragnization: null,
      };
    },
    userSwitchedToEditMode: (
      state,
      action: { payload: { id: string } }
    ): IUserManagementState => {
      const user =
        state.currentUser?.id === action.payload.id
          ? state.currentUser
          : state.currentOragnization?.users.find(
            (u) => u.id === action.payload.id
          );
      if (user) {
        user.editing = true;
      }
      return state;
    },
    userNameUpdated: (
      state,
      action: { payload: { id: string; value: string } }
    ): IUserManagementState => {
      const user =
        state.currentUser?.id === action.payload.id
          ? state.currentUser
          : state.currentOragnization?.users.find(
            (u) => u.id === action.payload.id
          );
      if (user) {
        user.name = action.payload.value;
        user.validationError =
          action.payload.value.length === 0 ? "Value cannot be empty" : "";
      }
      return state;
    },
    userSetTerms: (
      state,
      action: { payload: boolean }
    ): IUserManagementState => {
      return {
        ...state,
        userAgreedToImportErrors: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCurrentUserDetails.fulfilled,
        (state, action): IUserManagementState => {
          return { ...state, currentUser: action.payload };
        }
      )
      .addCase(
        getCurrentUserDetails.rejected,
        (state, action): IUserManagementState => {
          return { ...state, getUserError: true };
        }
      )
      .addCase(getCurrentOrgDetails.pending, (state): IUserManagementState => {
        return {
          ...state,
          currentOragnization: null,
          getOrgPending: true,
        };
      })
      .addCase(
        getCurrentOrgDetails.fulfilled,
        (state, action): IUserManagementState => {
          return {
            ...state,
            currentOragnization: action.payload,
            getOrgPending: false,
          };
        }
      )
      .addCase(getCurrentOrgDetails.rejected, (state): IUserManagementState => {
        return {
          ...state,
          getOrgError: true,
          getOrgPending: false,
        };
      })

      .addCase(
        updateUserStarted.pending,
        (state, action): IUserManagementState => {
          const user =
            action.meta.arg.user.id === state.currentUser?.id
              ? state.currentUser
              : state.currentOragnization?.users.find(
                (u) => u.id === action.meta.arg.user.id
              );
          if (user) {
            user.editing = false;
            user.saving = true;
            user.validationError = "";
          }
          return state;
        }
      )
      .addCase(
        updateUserStarted.fulfilled,
        (state, action): IUserManagementState => {
          const user =
            action.meta.arg.user.id === state.currentUser?.id
              ? state.currentUser
              : state.currentOragnization?.users.find(
                (u) => u.id === action.meta.arg.user.id
              );
          if (user) {
            user.saving = false;
          }
          return state;
        }
      )
      .addCase(
        updateUserStarted.rejected,
        (state, action): IUserManagementState => {
          const user =
            action.meta.arg.user.id === state.currentUser?.id
              ? state.currentUser
              : state.currentOragnization?.users.find(
                (u) => u.id === action.meta.arg.user.id
              );
          if (user) {
            user.saving = false;
            user.validationError = (
              (action.payload as AxiosError).response as any
            )?.data?.Exceptions?.join(";");
          }
          return state;
        }
      )
      .addCase(
        toggleUserActivationStarted.fulfilled,
        (state, action): IUserManagementState => {
          const user = state.currentOragnization?.users.find(
            (u) => u.id === action.meta.arg.id
          );
          if (user) {
            user.isDeactiviated = !action.meta.arg.currentlyActivated;
          }
          return state;
        }
      )
      .addCase(
        createUserStarted.rejected,
        (_, action): IUserManagementState => {
          // eslint-disable-next-line no-throw-literal
          throw action.payload as AxiosError;
        }
      )
      .addCase(
        createUserStarted.fulfilled,
        (state, action): IUserManagementState => {
          state.currentOragnization?.users.unshift(action.payload);
          return state;
        }
      )
      .addCase(
        reSendActivationEmailStarted.fulfilled,
        (state, action): IUserManagementState => {
          return {
            ...state,
            sendEmailPending: {
              id: "",
              pending: false,
            },
          };
        }
      )
      .addCase(
        reSendActivationEmailStarted.pending,
        (state, action): IUserManagementState => {
          return {
            ...state,
            sendEmailError: false,
            sendEmailPending: {
              id: action.meta.arg.id,
              pending: true,
            },
          };
        }
      )
      .addCase(
        reSendActivationEmailStarted.rejected,
        (state, action): IUserManagementState => {
          // eslint-disable-next-line no-throw-literal
          return {
            ...state,
            sendEmailPending: {
              id: "",
              pending: false,
            },
            sendEmailError: true,
          };
        }
      )
  },
});

export const {
  userLoggedOut,
  userSwitchedToEditMode,
  userNameUpdated,
  userAgreedToImportErrors,
  userSetTerms
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
