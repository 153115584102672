import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { t, Trans } from "@lingui/macro";
import AnimatedEllipsis from "./animatedEllipsis";
import {
  numberOfProgressStepsToShow,
  signalRHubUrl,
  signalRMessageSubscriptionMethodName,
} from "../../helpers/constants";
import { IApiConversionStepProgressReport } from "../../api/types";
import { ImportAction } from "../../reducers/importReducer";
import Connector from "../../helpers/initSignalrConnection";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { getSignalRTicket } from "../../reducers/auxDataReducer";

interface ImportProgressDialogProps {
  open: boolean;
  onCancel: () => void;
  onActionCompleted: (actionType: ImportAction["type"]) => void;
}

const emptyStep: IApiConversionStepProgressReport = {
  completedAt: "",
  conversionId: "",
  createdAt: "",
  description: "",
  elapsedTime: -1,
  id: "",
  isDownload: false,
  name: "",
  parentId: "",
  startedAt: "",
  status: "Undefined",
};

const ImportProgressDialog = ({
  open,
  onCancel,
  onActionCompleted,
}: ImportProgressDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const importId = useAppSelector((state) => state.import.importId);
  const importIdRef = useRef(importId);
  const openRef = useRef(open);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showManualStatusUpdates, setShowManualStatusUpdates] = useState(false);
  const [postConverstionActionsFired, setPostConverstionActionsFired] =
    useState(false);
  const [progressReportArray, setProgressReportArray] = useState<
    IApiConversionStepProgressReport[]
  >([]);

  const currentImportActions = useAppSelector(
    (state) => state.import.importActions
  );

  const conversionFailed = useAppSelector(
    (state) => state.convert.conversionFailed
  );

  const conversionStatus = useAppSelector(
    (state) => state.convert.conversionStatus.status
  );

  const downloadConvertedPayloadError = useAppSelector(
    (state) => state.convert.downloadConvertedPayloadError
  );

  const factsExtractionPending = useAppSelector(
    (state) => state.extract.factExtractionPending
  );

  const basicDataExtractionPending = useAppSelector(
    (state) => state.extract.basicDataExtractionPending
  );

  const validationPending = useAppSelector(
    (state) => state.validate.validationPending
  );

  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );
  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const basicDataExtractionError = useAppSelector(
    (state) => state.extract.basicDataExtractionError
  );

  const handleConnectionError = useCallback(async (message: string) => {
    setShowManualStatusUpdates(true);
    dispatch(getSignalRTicket());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signalrTicket = useAppSelector((state) => state.auxData.signalRTicket);

  const signalrConnection = useMemo(() => {
    if (signalrTicket) {
      return Connector(
        `${process.env.REACT_APP_SIGNALR_BASE_URL}${signalRHubUrl}`,
        signalRMessageSubscriptionMethodName,
        signalrTicket,
        handleConnectionError
      );
    }
  }, [signalrTicket, handleConnectionError]);

  useEffect(() => {
    if (signalrConnection?.events) {
      signalrConnection.events(handleStepUpdated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalrConnection]);

  useEffect(() => {
    openRef.current = open;
    importIdRef.current = importId;
  }, [open, importId]);

  useEffect(() => {
    if (
      conversionFailed ||
      validationError ||
      (factsExtractionError && basicDataExtractionError)
    ) {
      setProgressReportArray([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conversionFailed,
    validationError,
    factsExtractionError,
    basicDataExtractionError,
  ]);

  useEffect(() => {
    setProgressReportArray([]);
  }, [open]);

  const handleStepUpdated = (step: IApiConversionStepProgressReport) => {
    if (showManualStatusUpdates) {
      setShowManualStatusUpdates(false);
    }
    if (openRef.current) {
      if (step.conversionId === importIdRef.current) {
        setProgressReportArray((prev) => {
          let newStep = true;
          const steps = prev.map((s) => {
            if (s.id === step.id) {
              newStep = false;
              if (s.status !== "Completed") {
                s = step;
              }
            }
            return s;
          });
          if (newStep) {
            steps.push(step);
          }
          return steps;
        });
      }
    }
  };

  useEffect(() => {
    if (showManualStatusUpdates) {
      const manualProgressArray: IApiConversionStepProgressReport[] = [];
      if (currentImportActions.some((action) => action.type === "conversion")) {
        manualProgressArray.push({
          ...emptyStep,
          id: "converting_manual",
          name: "Converting",
          status: conversionStatus || "Undefined",
          isDownload: conversionStatus === "Completed",
          startedAt: new Date().toLocaleString(),
        });
      }
      if (
        currentImportActions.some((action) => action.type === "validation") &&
        (!currentImportActions.some((action) => action.type === "conversion") ||
          conversionStatus === "Completed") &&
        (validationPending || validationError)
      ) {
        manualProgressArray.push({
          ...emptyStep,
          id: "validating_manual",
          name: "Validating",
          status: validationPending
            ? "Running"
            : validationError
            ? "Failed"
            : "Completed",
          startedAt: new Date().toLocaleString(),
        });
      }
      if (
        currentImportActions.some((action) => action.type === "extraction") &&
        (!currentImportActions.some((action) => action.type === "conversion") ||
          conversionStatus === "Completed") &&
        (factsExtractionPending ||
          factsExtractionError ||
          basicDataExtractionPending ||
          basicDataExtractionError ||
          validationPending)
      ) {
        manualProgressArray.push({
          ...emptyStep,
          id: "extracting_manual",
          name: "Extracting",
          status:
            factsExtractionPending || basicDataExtractionPending
              ? "Running"
              : factsExtractionError || basicDataExtractionError
              ? "Failed"
              : "Completed",
          startedAt: new Date().toLocaleString(),
        });
      }
      setProgressReportArray(manualProgressArray);
    }
  }, [
    showManualStatusUpdates,
    conversionStatus,
    factsExtractionPending,
    basicDataExtractionPending,
    validationPending,
    factsExtractionError,
    basicDataExtractionError,
    validationError,
    currentImportActions,
  ]);

  const handleCancel = () => {
    setProgressReportArray([]);
    setShowCancelDialog(false);
    setPostConverstionActionsFired(false);
    onCancel();
  };

  useEffect(() => {
    if (
      progressReportArray.filter((step) => step.isDownload).length === 1 &&
      !conversionFailed &&
      conversionStatus !== "Cancelled" &&
      conversionStatus !== "Failed"
    ) {
      setPostConverstionActionsFired(true);
    }
  }, [progressReportArray, conversionStatus, conversionFailed]);

  useEffect(() => {
    if (postConverstionActionsFired) {
      onActionCompleted("conversion");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postConverstionActionsFired]);

  useEffect(() => {
    const fakeStep = progressReportArray.find(
      (step) => step.id === "downloading_package_file"
    );
    const fakeStepIndex = progressReportArray
      .map((step) => step.id)
      .indexOf("downloading_package_file");
    if (
      currentImportActions.some((action) => action.type === "conversion") &&
      progressReportArray.length > 0 &&
      postConverstionActionsFired &&
      !fakeStep
    ) {
      setProgressReportArray((prev) => [
        ...prev,
        {
          isDownload: false,
          name: t`Downloading Package File`,
          status: "Running",
          completedAt: "",
          conversionId: "",
          createdAt: "",
          description: "",
          elapsedTime: -1,
          id: "downloading_package_file",
          parentId: "",
          startedAt: new Date().toLocaleString(),
        },
      ]);
    } else if (
      fakeStep &&
      fakeStep.status === "Running" &&
      downloadConvertedPayloadError
    ) {
      setProgressReportArray((prev) =>
        prev.map((step) => {
          if (step.id === "downloading_package_file") {
            step.status = "Failed";
            step.completedAt = new Date().toLocaleString();
          }
          return step;
        })
      );
    } else if (
      fakeStep &&
      fakeStep.status === "Running" &&
      progressReportArray.length > fakeStepIndex + 1
    ) {
      setProgressReportArray((prev) =>
        prev.map((step) => {
          if (step.id === "downloading_package_file") {
            step.status = "Completed";
            step.completedAt = new Date().toLocaleString();
          }
          return step;
        })
      );
    }
  }, [
    progressReportArray,
    postConverstionActionsFired,
    currentImportActions,
    downloadConvertedPayloadError,
  ]);

  let updatedProgArray = progressReportArray.slice().sort((a, b) => {
    const startA = a.startedAt;
    const startB = b.startedAt;
    return new Date(startA).getTime() - new Date(startB).getTime();
  });

  if (
    updatedProgArray.length > numberOfProgressStepsToShow &&
    numberOfProgressStepsToShow !== -1
  ) {
    updatedProgArray = updatedProgArray.slice(-numberOfProgressStepsToShow);
  }
  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown
        maxWidth={"sm"}
        fullWidth
        onClose={() => null}>
        <DialogTitle className="processing-text">
          Processing
          <AnimatedEllipsis />
        </DialogTitle>
        <DialogContent sx={{ minHeight: "400px" }}>
          <DialogContentText id="import-id-display">
            <Trans>Import Id</Trans>: {importId}
          </DialogContentText>
          <List>
            <ListItem key="uploading_list_item">
              <ListItemText primary={t`Uploading and Scanning Files`} />
              <ListItemIcon>
                {progressReportArray.length === 0 && (
                  <CircularProgress size={25} />
                )}
                {progressReportArray.length > 0 && (
                  <CheckIcon
                    sx={{ color: (theme) => theme.palette.success.main }}
                  />
                )}
              </ListItemIcon>
            </ListItem>
            {updatedProgArray.map((step) => {
              return (
                <ListItem key={`${step.name}_${step.status}`}>
                  <ListItemText primary={step.name} />
                  <ListItemIcon>{getIconForStatus(step.status)}</ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setShowCancelDialog(true)}
            disabled={importId === ""}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCancelDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowCancelDialog(false)}>No</Button>
          <Button onClick={handleCancel}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getIconForStatus = (
  status: "Undefined" | "Running" | "Completed" | "Failed" | "Cancelled"
) => {
  if (status === "Completed")
    return <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />;
  if (status === "Failed") {
    return <CloseIcon sx={{ color: (theme) => theme.palette.error.main }} />;
  }
  if (status === "Cancelled") {
    return (
      <DoNotDisturbIcon sx={{ color: (theme) => theme.palette.error.main }} />
    );
  }
  return <CircularProgress size={25} />;
};

export default ImportProgressDialog;
