import { Checkbox, Grid, List, ListItem, ListItemText } from "@mui/material";
import Menu from "@mui/material/Menu";

interface ColumnFilterMenuProps<T> {
  anchorElement: null | HTMLElement;
  allColumnIdentifiers: {
    id: keyof T | string | number;
    label: string;
  }[];
  selectedIds: string[];
  onToggleColumn: (id: string, checked: boolean) => void;
  onCloseMenu: () => void;
}

interface ColumnFilterMenuListItemProps<T> {
  columnIdentifier: {
    id: keyof T | string | number;
    label: string;
  };
  selectedIds: string[];
  onToggleColumn: (id: string, checked: boolean) => void;
}

function ColumnFilterMenuListItem<T>({
  columnIdentifier,
  onToggleColumn,
  selectedIds,
}: ColumnFilterMenuListItemProps<T>) {
  return (
    <ListItem>
      <Checkbox
        edge="start"
        checked={!selectedIds.includes(columnIdentifier.id.toString())}
        onChange={(e) =>
          onToggleColumn(columnIdentifier.id.toString(), e.target.checked)
        }
      />
      <ListItemText primary={columnIdentifier.label} />
    </ListItem>
  );
}

export default function ColumnFilterMenu<T>({
  allColumnIdentifiers,
  anchorElement,
  onToggleColumn,
  onCloseMenu,
  selectedIds,
}: ColumnFilterMenuProps<T>) {
  return (
    <Menu
      anchorEl={anchorElement}
      open={anchorElement !== null}
      onClose={onCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <List>
            {allColumnIdentifiers
              .filter((_, index, arr) => index <= Math.floor(arr.length / 2))
              .map((ci) => {
                return (
                  <ColumnFilterMenuListItem
                    key={`${ci.id.toString()}_${ci.label}`}
                    columnIdentifier={ci}
                    onToggleColumn={onToggleColumn}
                    selectedIds={selectedIds}
                  />
                );
              })}
          </List>
        </Grid>
        <Grid item xs={6}>
          <List>
            {allColumnIdentifiers
              .filter((_, index, arr) => index > Math.floor(arr.length / 2))
              .map((ci) => {
                return (
                  <ColumnFilterMenuListItem
                    key={`${ci.id.toString()}_${ci.label}`}
                    columnIdentifier={ci}
                    onToggleColumn={onToggleColumn}
                    selectedIds={selectedIds}
                  />
                );
              })}
          </List>
        </Grid>
      </Grid>
    </Menu>
  );
}
