import { Trans } from "@lingui/macro";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ValidationElement } from "../../api/types";
import CloseIcon from "@mui/icons-material/Close";
import { reportErrorColor } from "../../helpers/constants";

export interface FactErrorsDisplayDialogProps {
  open: boolean;
  onClose: () => void;
  errors: ValidationElement[];
}

const FactErrorsDisplayDialog = ({
  open,
  onClose,
  errors,
}: FactErrorsDisplayDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {errors.length === 0 && (
          <Alert>
            <Trans>Cannot find related errors. Sorry</Trans>
          </Alert>
        )}
        {errors.length > 0 && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Severity</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Error Id</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Message</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors.map((error, index) => (
                <TableRow key={`fact_error_${index}`}>
                  <TableCell sx={{ color: reportErrorColor(error.severity) }}>
                    {error.severity}
                  </TableCell>
                  <TableCell>{error.code}</TableCell>
                  <TableCell>{error.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FactErrorsDisplayDialog;
