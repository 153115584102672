import { Trans, t } from "@lingui/macro";
import {
  Alert,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IApiFact, IApiMandatoryTag } from "../../api/types";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getAllMandatoryTagsStarted } from "../../reducers/auxDataReducer";
import {
  mandatoryTagsExcelExportFileName,
  orderOfTags,
} from "../../helpers/constants";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelExportDialog from "../excelExportDialog";

interface DisplayMandatoryTag extends IApiMandatoryTag {
  isUsed: boolean;
}

export interface GeneralSideBarMandatoryTagsViewProps {
  facts: IApiFact[];
}

const GeneralSideBarMandatoryTagsView = ({
  facts,
}: GeneralSideBarMandatoryTagsViewProps) => {
  const [showExportDialog, setShowExportDialog] = useState(false);

  const dispatch = useAppDispatch();
  const tagsLoading = useAppSelector(
    (state) => state.auxData.getMandatoryTagsPending
  );
  const loadError = useAppSelector(
    (state) => state.auxData.getMandatoryTagsError
  );
  const mandatoryTags = useAppSelector((state) => state.auxData.mandatoryTags);

  useEffect(() => {
    if (!tagsLoading && !loadError && !mandatoryTags) {
      dispatch(getAllMandatoryTagsStarted());
    }
  }, [mandatoryTags, tagsLoading, loadError, dispatch]);

  if (tagsLoading) {
    return <CircularProgress />;
  }

  if (!tagsLoading && loadError) {
    return (
      <Alert variant="outlined" severity="error">
        <Trans>Could not load mandatory tags list</Trans>
      </Alert>
    );
  }

  if (
    !tagsLoading &&
    !loadError &&
    mandatoryTags &&
    mandatoryTags.length === 0
  ) {
    return (
      <Alert variant="outlined" severity="info" sx={{ ml: 2, mt: 2 }}>
        <Trans>Found no mandatory tags in the database</Trans>
      </Alert>
    );
  }

  const displayTags: DisplayMandatoryTag[] = mandatoryTags
    ? mandatoryTags
        .map((tag: IApiMandatoryTag) => {
          return {
            ...tag,
            isUsed: facts
              .filter((f) => f.contextRef)
              .map((fa) => fa.contextRef.name.toLowerCase())
              .includes(tag.elementName.toLowerCase()),
          };
        })
        .sort((a, b) => Number(b.isUsed) - Number(a.isUsed))
    : [];

  return (
    <>
      <Grid item xs={12}>
        <IconButton
          disabled={showExportDialog}
          onClick={() => setShowExportDialog(true)}
          sx={{
            margin: "auto",
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
          }}
          title={t`Download mandatory tag list`}>
          <DownloadIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: 710, overflow: "auto" }}>
        <List dense>
          {displayTags
            .sort((a, b) => {
              if (a.isUsed !== b.isUsed) {
                return Number(b.isUsed) - Number(a.isUsed);
              }
              if (
                orderOfTags.includes(a.label) &&
                orderOfTags.includes(b.label)
              ) {
                return (
                  orderOfTags.indexOf(a.label) - orderOfTags.indexOf(b.label)
                );
              } else if (orderOfTags.includes(a.label)) {
                return -1;
              } else if (orderOfTags.includes(b.label)) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((tag) => {
              return (
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      noWrap: true,
                      whiteSpace: "initial",
                      fontWeight: tag.isUsed ? "800" : "normal",
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      whiteSpace: "initial",
                    }}
                    sx={{
                      opacity: tag.isUsed ? 1 : 0.4,
                    }}
                    primary={tag.isUsed ? tag.label : ""}
                    secondary={tag.isUsed ? "" : tag.label}
                  />
                </ListItem>
              );
            })}
        </List>
      </Grid>
      <ExcelExportDialog
        actionBody={{
          sheets: [
            {
              dataType: "MandatoryTags",
              serializedCollection: JSON.stringify(displayTags),
              sheetName: t`Mandatory tag list`,
            },
          ],
        }}
        actionUrl={`api/exports/excel?returnFileName=${mandatoryTagsExcelExportFileName}`}
        open={showExportDialog}
        onClose={() => setShowExportDialog(false)}
        title={t`Exporting...`}
      />
    </>
  );
};

export default GeneralSideBarMandatoryTagsView;
