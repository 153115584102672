import { IApiFact, ValidationElement } from "../api/types";

export interface FactErrorMapping {
  factId: string;
  errors: ValidationElement[];
}

export const getValidationErrorsByFact = (
  fact: IApiFact,
  validationEntries: ValidationElement[] | undefined
): ValidationElement[] => {
  if (!validationEntries) return [];
  return [
    ...validationEntries.filter(
      (ve) =>
        ve.elements &&
        ve.elements.length > 0 &&
        ve.elements.some(
          (elem) =>
            elem.name === fact.contextRef?.name &&
            elem.contextRef === fact.contextRef?.contextRef
        )
    ),
  ];
};

export const mapValidationErrorsToFacts = (
  facts: IApiFact[],
  validationEntries: ValidationElement[]
): FactErrorMapping[] => {
  const output: FactErrorMapping[] = [];
  for (const fact of facts) {
    const associatedErrors = getValidationErrorsByFact(fact, validationEntries);
    if (associatedErrors.length > 0) {
      output.push({
        factId: fact.id,
        errors: associatedErrors,
      });
    }
  }
  return output;
};
