import { FactPeriod } from "../api/types";

const formatDate = (rawString: string | undefined): string => {
  if (!rawString) return "";
  if (isNaN(Date.parse(rawString))) return "";
  const date = new Date(rawString);
  const month = date.toLocaleString("default", { month: "short" });
  return `${date.getDate()} ${month} ${date.getFullYear()}`;
};

export const getViewerSidebarDateString = (
  period: FactPeriod | undefined
): string => {
  if (!period) return "";
  if (period.type === "Duration") {
    return `${formatDate(period.startTime)} - ${formatDate(period.endTime)}`;
  } else if (period.type === "Instant") {
    return formatDate(period.value);
  }
  return "";
};
