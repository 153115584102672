import { combineReducers, configureStore } from "@reduxjs/toolkit";
import importReducer from "./reducers/importReducer";
import convertReducer from "./reducers/conversionReducer";
import validationReducer from "./reducers/validationReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import extractionReducer from "./reducers/extractionReducer";
import compareReducer from "./reducers/compareReducer";
import auxDataReducer from "./reducers/auxDataReducer";
import mappingReducer from "./reducers/mappingReducer";
import internalLifeCycleReducer from "./reducers/internalLifeCycleReducer";
import conversionHistoryReducer from "./reducers/conversionHistoryReducer";
import debugReducer from "./reducers/debugReducer";
import generateReducer from "./reducers/generateReducer";

const rootReducer = combineReducers({
  import: importReducer,
  convert: convertReducer,
  validate: validationReducer,
  extract: extractionReducer,
  map: mappingReducer,
  compare: compareReducer,
  manageUsers: userManagementReducer,
  auxData: auxDataReducer,
  internalLifeCycle: internalLifeCycleReducer,
  conversionHistory: conversionHistoryReducer,
  debug: debugReducer,
  generate: generateReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
