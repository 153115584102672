import FactsTableContainer from "../components/facts/factsTableContainer";
import { useAppSelector } from "../hooks/useAppSelector";

export interface FactsProps {
  isGeneralSidebarOpen: boolean;
  currentGeneralSidebarDrawerWidth: number;
  currentSelectedLanguage: string;
  allLanguages: string[];
  onChangeLang: (lang: string) => void;
}

const Facts = ({
  isGeneralSidebarOpen,
  currentGeneralSidebarDrawerWidth,
  currentSelectedLanguage,
  allLanguages,
  onChangeLang,
}: FactsProps) => {
  const allFacts = useAppSelector((state) => state.extract.facts);

  const excelExportFileName = useAppSelector(
    (state) =>
      `${`${state.extract.extractedBasicData?.packageName?.split(".")[0] ?? ""} `}Facts.xlsx`
  );

  return (
    <FactsTableContainer
      allFacts={allFacts}
      isGeneralSidebarOpen={isGeneralSidebarOpen}
      currentGeneralSidebarDrawerWidth={currentGeneralSidebarDrawerWidth}
      title="Facts"
      allLanguages={allLanguages}
      currentSelectedLanguage={currentSelectedLanguage}
      onChangeLang={onChangeLang}
      excelExportFileName={excelExportFileName.trim()}
      excelExportSheetName="facts"
    />
  );
};

export default Facts;
