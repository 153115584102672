import {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import { IApiEurofilingFact, IApiFact } from "../../api/types";
import ReportReplacedXBRLNode from "./reportReplacedXBRLNode";
import { Link } from "@mui/material";

export type NodeType = "fact" | "continuation" | "footnote";

export const getHtmlReactParserOptions = (
  currentNestingLevel: number,
  facts?: Array<IApiFact | IApiEurofilingFact>,
  onReportLinkClick?: ((targetReportName: string) => void) | undefined
): HTMLReactParserOptions => {
  return {
    replace: (domNode: DOMNode) => {
      if (domNode.type === "tag") {
        const props = attributesToProps((domNode as Element).attribs);
        let nodeType: NodeType = "fact";
        if ((domNode as Element).tagName.toLowerCase().includes("footnote")) {
          nodeType = "footnote";
        } else if (
          (domNode as Element).tagName.toLowerCase().includes("continuation")
        ) {
          nodeType = "continuation";
        }
        if (
          nodeType === "footnote" ||
          nodeType === "continuation" ||
          (domNode as Element).attribs.associatedfactid
        ) {
          return (
            <ReportReplacedXBRLNode
              nativeProps={props}
              element={domNode as Element}
              facts={facts || []}
              nodeType={nodeType}
              nestingLevel={currentNestingLevel}
            />
          );
        } else {
          if (
            (domNode as Element).tagName.startsWith("ix:") ||
            (domNode as Element).tagName.startsWith("xbrli")
          ) {
            (domNode as Element).tagName = "span";
          } else if ((domNode as Element).tagName.toLowerCase() === "a") {
            const href = (domNode as Element).attribs.href;
            if (href && onReportLinkClick) {
              return (
                <Link
                  onClick={() => onReportLinkClick(href)}
                  sx={{ cursor: "pointer" }}>
                  {domToReact((domNode as Element).children as DOMNode[])}
                </Link>
              );
            }
          }
          return domNode;
        }
      }
    },
  };
};
