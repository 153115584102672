import { DOMNode, Element, domToReact } from "html-react-parser";
import { Props } from "html-react-parser/lib/attributes-to-props";
import { IApiEurofilingFact, IApiFact } from "../../api/types";
import {
  NodeType,
  getHtmlReactParserOptions,
} from "./getHtmlReactParserOptions";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  XBRLNodeClicked,
  factSelectionCallerUpdated,
} from "../../reducers/internalLifeCycleReducer";
import { memo } from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import {
  internalValidationErrorIds,
  viewerXBRLCell,
} from "../../helpers/constants";
import { useAppSelector } from "../../hooks/useAppSelector";

export interface ReportReplacedXBRLNodeProps {
  nativeProps: Props;
  facts: Array<IApiFact | IApiEurofilingFact>;
  element: Element;
  nodeType: NodeType;
  nestingLevel: number;
}

const arePropsEqual = (
  oldProps: ReportReplacedXBRLNodeProps,
  newProps: ReportReplacedXBRLNodeProps
): boolean => oldProps.facts.length === newProps.facts.length;

const ReportReplacedXBRLNode = memo(
  ({
    nativeProps,
    facts,
    element,
    nodeType,
    nestingLevel,
  }: ReportReplacedXBRLNodeProps) => {
    const dispatch = useAppDispatch();
    const associatedFactId = element.attribs.associatedfactid;

    const extractedFactsType = useAppSelector(
      (state) => state.extract.factType
    );

    const errorsByFactId = useAppSelector(
      (state) =>
        state.internalLifeCycle.errorsByFactId.find(
          (ebf) => ebf.factId === associatedFactId
        )?.errors
    );

    const handleClick = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (associatedFactId && extractedFactsType === "standard") {
        let target = e.currentTarget as HTMLSpanElement;
        if (!target.dataset.associatedFactId) {
          target = e.target as HTMLSpanElement;
        }
        dispatch(XBRLNodeClicked(target));
        dispatch(factSelectionCallerUpdated("viewer_report"));
      }
    };

    let sxProps: SxProps<Theme> = [
      {
        cursor: extractedFactsType === "standard" ? "pointer" : "default",
        fontFamily: "inherit",
        fontSize: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
      },
      {
        "&:hover": {
          outline: (theme) => `2px solid ${theme.palette.warning.light}`,
          outlineOffset: "1px",
        },
      },
    ];

    let title = "";
    if (errorsByFactId) {
      title = errorsByFactId.map((e) => e.message).join("; ");
      const unicodeError = errorsByFactId.find(
        (e) => e.id === internalValidationErrorIds.unicodeChar
      );
      if (unicodeError) {
        title = unicodeError.code;
      }
    }

    const node = (
      <Typography
        {...nativeProps}
        key={`${viewerXBRLCell}_${associatedFactId}_${nestingLevel}`}
        component="span"
        onClick={handleClick}
        sx={sxProps}
        title={title}
        data-internal-viewer-id={`${viewerXBRLCell}_${associatedFactId}_${nodeType}_${element.attribs.id || ""}`}
        data-associated-fact-id={associatedFactId}
        data-node-type={nodeType}
        data-nesting-level={nestingLevel}>
        {domToReact(
          element.children as DOMNode[],
          getHtmlReactParserOptions(
            element.attribs.associatedfactid?.length > 0
              ? nestingLevel + 1
              : nestingLevel,
            facts
          )
        )}
      </Typography>
    );
    return node;
  },
  arePropsEqual
);

export default ReportReplacedXBRLNode;
