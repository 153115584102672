import { ToolbarActions } from "./constants";
import { ContentHeaderToolbarAction } from "./getFactsToolbarActions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import { t } from "@lingui/macro";

export const getCompareToolbarActions = (
  iconClickHandler: (e: any, action: ToolbarActions) => void,
  disableExport: boolean
): ContentHeaderToolbarAction[] => [
  {
    id: ToolbarActions.download,
    clickAction: (e) => iconClickHandler(e, ToolbarActions.download),
    disabled: disableExport,
    turnedOn: false,
    title: t`Export to excel`,
    icon: DownloadIcon,
  },
  {
    id: ToolbarActions.updload,
    clickAction: (e) => iconClickHandler(e, ToolbarActions.updload),
    disabled: false,
    turnedOn: false,
    title: t`Upload comparison reporting package`,
    icon: AddCircleOutlineIcon,
  },
];
