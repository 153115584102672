import { useEffect, useMemo, useState } from "react";
import LoadinDialog from "../components/loadingDialog";
import { useAppSelector } from "../hooks/useAppSelector";
import {
  IApiOrganizationConversionsQuery,
  IApiUserConversionsQuery,
  QueryRequestPaging,
} from "../api/types";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { getConversionHsitoryByQueryStarted } from "../reducers/conversionHistoryReducer";
import { useBeforeunload } from "../hooks/useBeforeUnload";
import { Alert, AlertTitle, Container, Paper } from "@mui/material";
import ContentHeaderToolbar from "../components/contentHeaderToolbar";
import { Trans, t } from "@lingui/macro";
import { ToolbarActions } from "../helpers/constants";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ContentHeaderToolbarAction } from "../helpers/getFactsToolbarActions";
import QueryPopOver from "../components/conversionHistory/queryPopOver";
import ConversionHistoryTableContainer from "../components/conversionHistory/conversionHistoryTableContainer";
import ExcelExportDialog from "../components/excelExportDialog";

const initialPagingValues: QueryRequestPaging = {
  count: 25,
  number: 1,
};

const initialEmptyQueryRequest: IApiOrganizationConversionsQuery = {
  userId: "",
  convertEndAt: "",
  convertStartAt: "",
  paging: initialPagingValues,
};

const ConversionHistory = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.manageUsers.currentUser);
  const fecthingData = useAppSelector(
    (state) => state.conversionHistory.fetchingData
  );
  const fecthingError = useAppSelector(
    (state) => state.conversionHistory.fetchError
  );
  const conversions = useAppSelector(
    (state) => state.conversionHistory.conversions
  );

  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined);

  const [showExcelExportDialog, setShowExcelExportDialog] = useState(false);

  const [areFiltersApplied, setAreFiltersApplied] = useState(false);

  const [filterMenuAnchorElement, setFilterMenuAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const [currentAdminQueryRequest, setCurrentAdminQueryRequest] =
    useState<IApiOrganizationConversionsQuery>({ ...initialEmptyQueryRequest });

  const toolbarActions = useMemo(() => {
    const actions: ContentHeaderToolbarAction[] = [
      {
        id: ToolbarActions.download,
        icon: FileDownloadIcon,
        title: t`Download as Ecxcel sheet`,
        clickAction: () => setShowExcelExportDialog(true),
      },
    ];
    if (currentUser?.isAdmin) {
      actions.push({
        id: ToolbarActions.showFilterMenu,
        icon: FilterAltIcon,
        title: t`Filter Conversion History`,
        turnedOn: areFiltersApplied,
        clickAction: (e: any) => setFilterMenuAnchorElement(e.currentTarget),
      });
    }
    return actions;
  }, [currentUser?.isAdmin, areFiltersApplied]);

  useBeforeunload(() => {
    if (fecthingData && abortController) {
      abortController.abort();
    }
  });

  //initial data fetch
  useEffect(() => {
    if (
      currentUser &&
      !fecthingData &&
      !fecthingError &&
      conversions.length === 0
    ) {
      const controller = new AbortController();
      setAbortController(controller);
      let emptyReq:
        | IApiUserConversionsQuery
        | IApiOrganizationConversionsQuery = {
        paging: initialPagingValues,
      };
      dispatch(
        getConversionHsitoryByQueryStarted({
          isAdmin: currentUser.isAdmin,
          requestBody: emptyReq,
          abortSignal: controller.signal,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.isAdmin]);

  const handleReQuery = (isEmpty?: boolean) => {
    dispatch(
      getConversionHsitoryByQueryStarted({
        isAdmin: currentUser?.isAdmin || false,
        requestBody: isEmpty
          ? { ...initialEmptyQueryRequest }
          : currentAdminQueryRequest,
        abortSignal: abortController?.signal,
      })
    );
  };

  const handleClear = () => {
    setCurrentAdminQueryRequest({ ...initialEmptyQueryRequest });
    setAreFiltersApplied(false);
    setFilterMenuAnchorElement(null);
    handleReQuery(true);
  };

  const handlePagingParamChanged = (
    propName: keyof QueryRequestPaging,
    value: number
  ) => {
    const updated = currentAdminQueryRequest.paging;
    if (propName === "count") {
      updated.count = value;
    } else if (propName === "number") {
      updated.number = value + 1;
    }
    setCurrentAdminQueryRequest((prev) => {
      return {
        ...prev,
        paging: updated,
      };
    });
    handleReQuery();
  };

  const handleQueryPropChanged = (
    propName: keyof Omit<IApiOrganizationConversionsQuery, "paging">,
    value: string
  ) => {
    setCurrentAdminQueryRequest((prev) => {
      const updated = { ...prev };
      updated[propName] = value;
      return updated;
    });
  };

  const handleApplyFilters = () => {
    setCurrentAdminQueryRequest((prev) => {
      return {
        ...prev,
        paging: {
          ...prev.paging,
          number: 1,
        },
      };
    });
    setFilterMenuAnchorElement(null);
    setAreFiltersApplied(true);
    handleReQuery();
  };

  if (fecthingData) {
    return <LoadinDialog title="Fetching data" />;
  }
  return (
    <>
      <Container maxWidth={false} sx={{ mt: 15, mb: 10 }}>
        <Paper
          elevation={0}
          sx={{
            ml: 0,
            overflow: "auto",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "75vh",
          }}>
          <Container maxWidth={false}>
            <ContentHeaderToolbar
              title={t`Conversion History`}
              actions={toolbarActions}
              showIcons
            />
            {fecthingError && (
              <Alert severity="error">
                <AlertTitle>
                  <Trans>Error fetching data</Trans>
                </AlertTitle>
                <Trans>Refresh page to try again</Trans>
              </Alert>
            )}
            {!fecthingError && (
              <ConversionHistoryTableContainer
                allConversions={conversions}
                isAdmin={currentUser?.isAdmin || false}
                paging={currentAdminQueryRequest.paging}
                onChangePagingParam={handlePagingParamChanged}
              />
            )}
          </Container>
        </Paper>
      </Container>
      <QueryPopOver
        anchorElement={filterMenuAnchorElement}
        onApply={handleApplyFilters}
        onChangeProp={handleQueryPropChanged}
        onClose={() => setFilterMenuAnchorElement(null)}
        onClear={handleClear}
        queryRequest={currentAdminQueryRequest}
      />
      <ExcelExportDialog
        actionBody={{
          convertEndAt: currentAdminQueryRequest.convertEndAt || null,
          convertStartAt: currentAdminQueryRequest.convertStartAt || null,
          userId: currentAdminQueryRequest.userId || null,
        }}
        actionUrl={`api/exports/conversions`}
        open={showExcelExportDialog}
        onClose={() => setShowExcelExportDialog(false)}
        title={t`Exporting...`}
      />
    </>
  );
};

export default ConversionHistory;
