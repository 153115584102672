import { t } from "@lingui/macro";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { ParentFile } from "./treeviewComponents/parentFile";

export const ValidationSummaryTreeViewComponent = () => {
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ display: "flex" }}>
            <TableCell
              data-downloadaspdf="zipcontenttablecolumnheader"
              sx={{ border: "none", flex: 7 }}>
              <Typography fontWeight="bold" variant="body1" component="span">
                {t`NAME`}
              </Typography>
            </TableCell>
            <TableCell
              data-downloadaspdf="zipcontenttablecolumnheader"
              sx={{ border: "none", flexGrow: 1 }}>
              <Typography fontWeight="bold" variant="body1" component="span">
                {t`FILEKIND`}
              </Typography>
            </TableCell>
            <TableCell
              data-downloadaspdf="zipcontenttablecolumnheader"
              sx={{ border: "none", flex: 7 }}>
              <Typography fontWeight="bold" variant="body1" component="span">
                {t`HASHCODE`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <ParentFile />
    </TableContainer>
  );
};
