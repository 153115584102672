import { Box, Divider, IconButton, SvgIconProps } from "@mui/material";
import {
  appBarFixedHeight,
  pathsForPagesThatHaveSidebarAvailable,
} from "../../helpers/constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useLocation } from "react-router-dom";
import ResizableDrawerBoundry from "./resizableDrawerBoundry";
import GeneralSidebarDrawerBody from "./generalSidebarDrawerBody";
import { t } from "@lingui/macro";
import { CustomDrawer } from "../sidebar/customDrawer";
import GeneralSidebarResizableDrawerBodyClosed, {
  DrawerIcon,
} from "./generalSidebarResizableDrawerBodyClosed";
import { useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import AnchorOutlinedIcon from "@mui/icons-material/AnchorOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import SecondarySidebarDrawerBody from "./secondarySidebarDrawerBody";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";

type IconType = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
};

const RotatedViewSidebarIcon: IconType = (props: SvgIconProps) => (
  <ViewSidebarIcon style={{ transform: "rotate(180deg)" }} {...props} />
);

RotatedViewSidebarIcon.muiName = ViewSidebarIcon.muiName;

export interface GeneralSidebarResizableDrawerProps {
  isOpen: boolean;
  currentWidth: number;
  onToggleOpen: (section?: DrawerIcon["id"]) => void;
  onToggleSecondarySidebar: () => void;
  currentSidebarId: DrawerIcon["id"] | undefined;
  isSecondarySidebarOpen: boolean;
  onResizeDrawer: (e: any) => void;
  currentSelectedLanguage: string;
  activeSectionId?: DrawerIcon["id"];
  hidden?: boolean;
  secondarySidebarSize: number;
}

const GeneralSidebarResizableDrawer = ({
  isOpen,
  isSecondarySidebarOpen,
  onToggleOpen,
  onToggleSecondarySidebar,
  currentSidebarId,
  currentWidth,
  onResizeDrawer,
  currentSelectedLanguage,
  activeSectionId,
  hidden,
  secondarySidebarSize,
}: GeneralSidebarResizableDrawerProps) => {
  const location = useLocation();
  const showDrawer = useAppSelector(
    (state) =>
      state.extract.facts.length > 0 ||
      state.extract.extractedBasicData !== null
  );

  const icons: DrawerIcon[] = useMemo(
    () => [
      {
        id: "toggleSecondarySidebar",
        title: t`Toggle Secondary Sidebar`,
        icon: RotatedViewSidebarIcon,
        canBeDisabled: false,
        disabled: false,
        hideWhenDrawerClosed: true,
      },
      {
        id: "explorer",
        title: t`Taxonomy Explorer`,
        icon: SearchIcon,
        canBeDisabled: false,
        disabled: false,
      },
      {
        id: "factDetails",
        title: t`Facts & Elements`,
        icon: FactCheckIcon,
        canBeDisabled: false,
        disabled: isSecondarySidebarOpen ? true : false,
      },
      {
        id: "anchorDetails",
        title: t`Anchoring`,
        icon: AnchorOutlinedIcon,
        canBeDisabled: false,
        disabled: false,
      },
      {
        id: "usedTags",
        title: t`Mandatory Tag Usage`,
        icon: LocalOfferOutlinedIcon,
        canBeDisabled: false,
        disabled: false,
      },
    ],
    [isSecondarySidebarOpen]
  );

  if (
    !showDrawer ||
    !pathsForPagesThatHaveSidebarAvailable.some((path) =>
      location.pathname.toLowerCase().includes(path)
    )
  ) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}>
      <CustomDrawer
        hidden={hidden}
        variant="permanent"
        open={isOpen}
        anchor="right"
        currentWidth={currentWidth}>
        <ResizableDrawerBoundry
          onResizeDrawer={onResizeDrawer}
          isDrawerOpen={isOpen}
        />
        <Box sx={{ display: "flex", height: "100%" }}>
          {isOpen && isSecondarySidebarOpen && (
            <>
              {isSecondarySidebarOpen && (
                <SecondarySidebarDrawerBody
                  currentSelectedLanguage={currentSelectedLanguage}
                  currentWidth={secondarySidebarSize}
                />
              )}
            </>
          )}
          {!isOpen && (
            <div
              style={{
                paddingTop: `${appBarFixedHeight}px`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}>
              <IconButton onClick={() => onToggleOpen()}>
                {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
              <Divider sx={{ mb: 1 }} />
              <GeneralSidebarResizableDrawerBodyClosed
                icons={icons}
                onClickIcon={onToggleOpen}
              />
            </div>
          )}
          {isOpen && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
              }}>
              <GeneralSidebarDrawerBody
                onToggleOpen={onToggleOpen}
                currentSidebarId={currentSidebarId}
                isSecondarySidebarOpen={isSecondarySidebarOpen}
                onToggleSecondarySidebar={onToggleSecondarySidebar}
                icons={icons}
                isOpen={isOpen}
                currentWidth={
                  isSecondarySidebarOpen
                    ? currentWidth - secondarySidebarSize
                    : currentWidth
                }
                activeSectionId={activeSectionId}
                currentSelectedLanguage={currentSelectedLanguage}
                onChangeActiveSection={onToggleOpen}
              />
            </Box>
          )}
        </Box>
      </CustomDrawer>
    </Box>
  );
};

export default GeneralSidebarResizableDrawer;
