import { currencySymbols } from "./constants";

export const getCalculationSidebarValueString = (
  value: string | undefined,
  unit: string | undefined,
  decimals: string | undefined
): string => {
  if (!value) return "";
  if (isNaN(parseInt(value))) return value;
  const symbol = currencySymbols.find(
    (sym) => sym.name.toLowerCase() === unit?.toLowerCase()
  )?.symbol;
  if (
    !decimals ||
    decimals.toLowerCase() === "inf" ||
    isNaN(parseInt(decimals))
  )
    return `${value}${symbol ? `${symbol}` : ""}`;
  return `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${symbol ? `${symbol}` : ""
    }`;
};
