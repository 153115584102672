import {
  IExtractionState,
  startExtractBasicData,
  startExtractFacts,
} from "../reducers/extractionReducer";
import { ImportAction, importIdCreated } from "../reducers/importReducer";
import { startValidation } from "../reducers/validationReducer";
import { AppDispatch } from "../store";
import { getCachedPackage } from "./getCachedPackage";

export const handleNonConversionActions = async (
  calculationType: string,
  dispatch: AppDispatch,
  actions: ImportAction[],
  abortController: AbortController,
  importId: string | undefined,
  extractionType: IExtractionState["extractionType"],
  pendingFiles?: File[],
  packageUrl?: string,
  packageName?: string
): Promise<void> => {
  const promises: Promise<any>[] = [];
  let uploadFile;
  if (pendingFiles) {
    uploadFile = pendingFiles[0];
  } else if (packageUrl && packageName) {
    const blob = await getCachedPackage(packageUrl);
    uploadFile = new File([blob], packageName);
    if (blob.type === "application/xhtml+xml") {
      extractionType = "reportFile";
    }
  }
  if (!importId || importId.length === 0) {
    importId = crypto.randomUUID();
    await dispatch(importIdCreated(importId));
  }
  if (actions.some((action) => action.type === "extraction")) {
    promises.push(
      dispatch(
        startExtractFacts({
          packageFile: uploadFile as File,
          type: extractionType,
          abortSignal: abortController.signal,
        })
      )
    );

    promises.push(
      dispatch(
        startExtractBasicData({
          packageFile: uploadFile as Blob | File,
          savePackage:
            !actions.some((action) => action.type === "conversion") &&
            !actions.some((action) => action.type === "generation"),
          extractionType: extractionType,
          abortSignal: abortController.signal,
        })
      )
    );
  }
  if (actions.some((action) => action.type === "validation")) {
    promises.push(
      dispatch(
        startValidation({
          calculationType: calculationType,
          packageFile: uploadFile as Blob | File,
          abortSignal: abortController.signal,
        })
      )
    );
  }
  if (promises.length > 0) {
    await Promise.all(promises);
  }
};
