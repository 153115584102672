import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { userLoggedOut } from "../reducers/userManagementReducer";
import { conversionHistoryCleared } from "../reducers/conversionHistoryReducer";
import { userSetTerms } from "../reducers/userManagementReducer";
import { downloadedPayloadCleared } from "../reducers/importReducer";
import { compareCleared } from "../reducers/compareReducer";
import { validationDataCleared } from "../reducers/validationReducer";
import { extractionCleared } from "../reducers/extractionReducer";
import { mappingExtractedDataCleared } from "../reducers/mappingReducer";
const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(userLoggedOut({}));
    dispatch(conversionHistoryCleared(null));
    dispatch(userSetTerms(false));
    dispatch(compareCleared(null));
    dispatch(validationDataCleared(null));
    dispatch(extractionCleared(null));
    dispatch(mappingExtractedDataCleared(null));
    dispatch(downloadedPayloadCleared(null));
    navigate("/login", { replace: true });
  }, [navigate, dispatch]);

  return <></>;
};

export default Logout;
