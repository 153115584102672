
// Singleton for access to token details in local storage
const tokenStorageHelper: {
  token: string;
  expireAt: string | null;
  refreshToken: string | null;
  refreshTokenExpire: string | null;
  isExpired: boolean;
  isAboutToExpire: boolean;
  isRefreshExpired: boolean;
  isRefreshable: boolean;
  isValid: boolean;
  isInvalid: boolean;
  clear: () => void;
} = {
  get token() {
    return localStorage.getItem("UserToken") || "";
  },

  get expireAt() {
    return localStorage.getItem("ExpireAt");
  },

  get refreshToken() {
    return localStorage.getItem("RefreshToken");
  },

  get refreshTokenExpire() {
    return localStorage.getItem("RefreshTokenExpire");
  },

  get isExpired() {
    if (tokenStorageHelper.expireAt) {
      const expireAt = new Date(tokenStorageHelper.expireAt + 'Z').getTime();
      const localTimeUtc = new Date().getTime();
      return localTimeUtc < expireAt;
    }
    return true;
  },

  get isAboutToExpire() {
    if (tokenStorageHelper.expireAt) {
      const expireAt = new Date(tokenStorageHelper.expireAt + 'Z').getTime();
      const localTimeUtc = new Date().getTime();
      return expireAt > localTimeUtc;
    }
    return false;
  },

  get isRefreshExpired() {
    const localTimeUtc = new Date().getTime();
    return tokenStorageHelper.refreshTokenExpire
      ? new Date(tokenStorageHelper.refreshTokenExpire + 'Z').getTime() < localTimeUtc
      : true;
  },

  get isRefreshable() {
    return (
      (tokenStorageHelper.refreshToken &&
        !tokenStorageHelper.isRefreshExpired) ||
      false
    );
  },

  get isValid() {
    return (tokenStorageHelper.token && !tokenStorageHelper.isExpired) || false;
  },

  get isInvalid() {
    return !tokenStorageHelper.isValid;
  },

  clear() {
    localStorage.clear();
  },
};

export default tokenStorageHelper;
