import { ImportAction } from "../reducers/importReducer";

export const getImportActionDataType = (
  files: File[] | undefined
): ImportAction["dataType"] => {
  if (!files || files.length === 0) return "pdfExcel";
  if (files.length === 1) {
    const file = files[0];
    if (
      file.type.toLowerCase().includes("zip") &&
      !file.type.toLowerCase().includes("epub")
    )
      return "zipPackage";
    if (file.type.toLowerCase().includes("xhtml")) return "xhtmlOnly";
    if (file.type.toLowerCase().includes("pdf")) return "pdfOnly";
    if (file.type.toLowerCase().includes("excel")) return "excelOnly";
    if (file.type.toLowerCase().includes("epub")) return "epubOnly";
    if (
      file.type.toLowerCase().includes("xbrl") ||
      file.name.toLowerCase().includes(".xbrl")
    )
      return "xbrlOnly";
  } else if (files.length === 2) {
    if (files.some((f) => f.type.toLowerCase().includes("epub")))
      return "epubExcel";
  }
  return "pdfExcel";
};
