import { t } from "@lingui/macro";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authenticateWithMicrosoft } from "../api/accounts";
import Loader from "../components/loader";
import getParam from "../helpers/getParam";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { getCurrentUserDetails } from "../reducers/userManagementReducer";

function MicrosoftLoginCallback() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const codeParam = getParam("code") ?? "";
  const stateParam = getParam("state") ?? "";

  const logoutOnError = () => {
    const message = t`It is not possible to login. Please contact your administrator.`;
    alert(message);
    navigate("/logout", { replace: true });
  };

  useEffect(() => {
    // Grab code challenge, and remove since this effect may run twice (in react strict mode)
    const codeChallenge = localStorage.getItem("code_challenge");
    if (codeChallenge === null) {
      return;
    }

    // Verify that state is the same as we expect
    if (stateParam !== localStorage.getItem("state")) {
      console.error("State does not match");
      logoutOnError();
      return;
    }

    // Cleanup local storage
    // Also helps prevent duplicate request since this effect may run twice (in react strict mode)
    localStorage.removeItem("code_challenge");
    localStorage.removeItem("state");

    authenticateWithMicrosoft(codeParam, codeChallenge)
      .then(() => {
        dispatch(getCurrentUserDetails()).then(() => {
          navigate("/", { replace: true });
        });
      })
      .catch(logoutOnError);
  });

  // Show loading indicator while we wait for authentication to complete
  return <Loader />;
}

export default MicrosoftLoginCallback;
