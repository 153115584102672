import axios, { AxiosResponse } from "axios";
import {
  IApiConversionResponse,
  IApiOrganizationConversionsQuery,
  IApiUserConversionsQuery,
} from "./types";
import { getFileNameFromHeader } from "../helpers/getFileNameFromHeader";
import tokenHelper from "../helpers/tokenHelper";

export const convert = async (
  calculationType: string,
  saveArtifacts: boolean,
  body: FormData,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<{ conversionId: string }>> => {
  return axios.post<{ conversionId: string }>(
    "/api/conversions/convert/async",
    body,
    {
      signal: abortSignal,
      params: {
        saveArtifacts: saveArtifacts,
        calculationType: calculationType,
      },
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const getErrors = async (
  conversionId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`/api/conversions/${conversionId}/errors`, {
    headers: {
      Authorization: `Bearer ${tokenHelper.token}`,
    },
  });
};

export const download = async (
  conversionId: string
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}api/conversions/convert/async/download/${conversionId}`,
    {
      headers: {
        Authorization: `Bearer ${tokenHelper.token}`,
      },
    }
  );
  const name = getFileNameFromHeader(
    resp.headers.get("content-disposition") || ""
  );
  if (resp.status !== 200) {
    throw resp.statusText;
  }
  let blob = await resp.blob();
  return {
    blob: blob,
    name: name,
  };
};

export const getStatus = async (
  conversionId: string
): Promise<AxiosResponse<IApiConversionResponse>> => {
  return axios.get(`/api/conversions/${conversionId}`);
};

export const cancel = async (
  conversionId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/conversions/convert/async/cancel/${conversionId}`);
};

export const getPagedByCurrentUser = async (
  request: IApiUserConversionsQuery,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiConversionResponse[]>> => {
  return axios.post("/api/conversions/user/query", request, {
    signal: abortSignal,
  });
};

export const getPagedByOrgQuery = async (
  request: IApiOrganizationConversionsQuery,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiConversionResponse[]>> => {
  return axios.post("/api/conversions/organization/query", request, {
    signal: abortSignal,
  });
};

export const getArtifactForConversion = async (
  conversionId: string,
  artifactType: string
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}api/conversions/${conversionId}/artifacts?type=${artifactType}`,
    {
      headers: {
        Authorization: `Bearer ${tokenHelper.token}`,
      },
    }
  );
  const name = getFileNameFromHeader(
    resp.headers.get("content-disposition") || ""
  );
  if (resp.status !== 200) {
    throw resp.status;
  }
  let blob = await resp.blob();
  return {
    blob: blob,
    name: name,
  };
};
