import { ChangedFactDisplayItem } from "../../helpers/mapFactItems";
import { DataTableColumn } from "../facts/dataTable";
import { Stack, Theme, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import { factValueTextLengthCutoff } from "../../helpers/constants";
import FactHtmlValueDisplay from "../facts/factHtmlValueDisplay";
import LargeTextValueDisplay from "../facts/largeTextValueDisplay";
import ModifiedValueDisplayIcon from "./modifiedValueDisplayIcon";

export const getChangeTypeCellColor = (
  theme: Theme,
  type: ChangedFactDisplayItem["changeType"] | undefined
): string => {
  if (type === "Added") return theme.palette.success.main;
  if (type === "Deleted") return theme.palette.error.main;
  if (type === "Modified") return theme.palette.info.main;

  return "";
};

export const getChangedFactsTableColumns =
  (): DataTableColumn<ChangedFactDisplayItem>[] => {
    return [
      {
        key: "changeType",
        header: {
          id: "changeType",
          label: t`Change Type`,
          sortable: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: false,
          showLabel: true,
        },
        valueFormatter: (row) => (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => getChangeTypeCellColor(theme, row.changeType),
            }}>
            {row.changeType}
          </Typography>
        ),
      },
      {
        key: "name",
        header: {
          showLabel: true,
          minWidth: 350,
          maxWidth: 350,
          filterable: false,
          id: "name",
          label: t`Name`,
          sortable: true,
        },
      },
      {
        key: "label",
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.label}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="label"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
        header: {
          showLabel: true,
          minWidth: 350,
          maxWidth: 350,
          filterable: false,
          id: "label",
          label: t`Label`,
          sortable: true,
        },
      },
      {
        key: "language",
        header: {
          showLabel: true,
          minWidth: 100,
          maxWidth: 100,
          filterable: false,
          id: "language",
          label: t`Language`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.language}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="language"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "unit",
        header: {
          showLabel: true,
          minWidth: 75,
          maxWidth: 75,
          filterable: false,
          id: "unit",
          label: t`Unit`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.unit}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="unit"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "formattedValue",
        header: {
          showLabel: true,
          minWidth: 250,
          maxWidth: 250,
          filterable: false,
          id: "formattedValue",
          label: t`Value`,
          sortable: true,
        },
        valueFormatter: (row) => (
          <>
            {row.isValueHtml && <FactHtmlValueDisplay item={row} />}
            {!row.isValueHtml &&
              row.formattedValue.length > factValueTextLengthCutoff && (
                <LargeTextValueDisplay text={row.formattedValue} />
              )}
            {!row.isValueHtml &&
              row.formattedValue.length <= factValueTextLengthCutoff && (
                <>
                  <ModifiedValueDisplayIcon
                    displayPropName="formattedValue"
                    changedProps={row.changedProps}
                    sxProps={{
                      ml: 2,
                      float: !isNaN(parseFloat(row.formattedValue))
                        ? "right"
                        : "",
                    }}
                    formatCurrency={!isNaN(parseFloat(row.formattedValue))}
                  />
                  <Typography
                    sx={{
                      float: !isNaN(parseFloat(row.formattedValue))
                        ? "right"
                        : "",
                    }}>
                    {row.formattedValue}
                  </Typography>
                </>
              )}
          </>
        ),
      },
      {
        key: "escape",
        header: {
          showLabel: true,
          minWidth: 70,
          maxWidth: 70,
          filterable: false,
          id: "escape",
          label: t`Escape`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.escape}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="escape"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "isHidden",
        header: {
          showLabel: true,
          minWidth: 120,
          maxWidth: 120,
          filterable: false,
          id: "isHidden",
          label: t`Is Hidden`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.isHidden}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="isHidden"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "balanceType",
        header: {
          showLabel: true,
          minWidth: 120,
          maxWidth: 120,
          filterable: false,
          id: "balanceType",
          label: t`Balance`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.balanceType}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="balanceType"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "periodType",
        header: {
          showLabel: true,
          minWidth: 140,
          maxWidth: 140,
          filterable: false,
          id: "periodType",
          label: t`Period Type`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.periodType}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="periodType"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "itemType",
        header: {
          showLabel: true,
          minWidth: 200,
          maxWidth: 200,
          filterable: false,
          id: "itemType",
          label: t`Item Type`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.itemType}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="itemType"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "decimals",
        header: {
          showLabel: true,
          minWidth: 100,
          maxWidth: 100,
          filterable: false,
          id: "decimals",
          label: t`Decimals`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.decimals}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="decimals"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "scale",
        header: {
          showLabel: true,
          minWidth: 90,
          maxWidth: 90,
          filterable: false,
          id: "scale",
          label: t`Scale`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.scale}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="scale"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "periodStart",
        header: {
          showLabel: true,
          minWidth: 200,
          maxWidth: 200,
          filterable: false,
          id: "periodStart",
          label: t`Period Start`,
          sortable: true,
        },
      },
      {
        key: "periodEnd",
        header: {
          showLabel: true,
          minWidth: 190,
          maxWidth: 190,
          filterable: false,
          id: "periodEnd",
          label: t`Period End`,
          sortable: true,
        },
      },
      {
        key: "dimensions",
        header: {
          showLabel: true,
          minWidth: 130,
          maxWidth: 130,
          filterable: false,
          id: "dimensions",
          label: t`Dimensions`,
          sortable: true,
        },
      },
      {
        key: "dimensionMembers",
        header: {
          showLabel: true,
          minWidth: 260,
          maxWidth: 260,
          filterable: false,
          id: "dimensionMembers",
          label: t`Generic Dimension Members`,
          sortable: true,
        },
      },
      {
        key: "wideAnchor",
        header: {
          showLabel: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: false,
          id: "wideAnchor",
          label: t`Wide Anchor`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.wideAnchor}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="wideAnchor"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "narrowerAnchor",
        header: {
          showLabel: true,
          minWidth: 170,
          maxWidth: 170,
          filterable: false,
          id: "narrowerAnchor",
          label: t`Narrower anchor`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.narrowerAnchor}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="narrowerAnchor"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
      {
        key: "footnotes",
        header: {
          showLabel: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: false,
          id: "footnotes",
          label: t`Foot Notes`,
          sortable: true,
        },
        valueFormatter: (row) => {
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{row.footnotes}</Typography>
              <ModifiedValueDisplayIcon
                displayPropName="footnotes"
                changedProps={row.changedProps}
              />
            </Stack>
          );
        },
      },
    ];
  };
