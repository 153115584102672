import { useEffect, useMemo, useState } from "react";
import { IApiConversionResponse, QueryRequestPaging } from "../../api/types";
import { Order, getComparator } from "../../helpers/tableSorting";
import { getConversionHistoryTableColumns } from "./conversionHistoryTableColumns";
import { Box, Paper } from "@mui/material";
import DataTable from "../facts/dataTable";

interface ConversionHistoryTableContainerProps {
  allConversions: IApiConversionResponse[];
  isAdmin: boolean;
  paging: QueryRequestPaging;
  onChangePagingParam: (
    propName: keyof QueryRequestPaging,
    value: number
  ) => void;
}

const ConversionHistoryTableContainer = ({
  allConversions,
  isAdmin,
  paging,
  onChangePagingParam,
}: ConversionHistoryTableContainerProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] =
    useState<keyof IApiConversionResponse>("startedAt");

  const handleSortTable = (
    event: React.MouseEvent<unknown>,
    property: keyof IApiConversionResponse
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const columns = useMemo(
    () => getConversionHistoryTableColumns(isAdmin),
    [isAdmin]
  );

  const orderedData = useMemo(() => {
    return allConversions
      .slice()
      .sort(
        getComparator(
          order,
          orderBy,
          [
            "startedAt",
            "metaData_period_start",
            "metaData_period_end",
          ].includes(orderBy)
        )
      );
  }, [allConversions, order, orderBy]);

  useEffect(() => {
    setOrderBy("startedAt");
    setOrder("desc");
  }, [paging.number]);

  return (
    <Box>
      <Paper
        sx={{
          minHeight: "82vh",
          maxHeight: "82vh",
        }}>
        <DataTable<IApiConversionResponse>
          columns={columns}
          currentSelectedPage={paging.number - 1}
          data={orderedData}
          onRequestSort={handleSortTable}
          onSelectPage={(page) => onChangePagingParam("number", page)}
          onSelectRowsPerPage={(count) => onChangePagingParam("count", count)}
          order={order}
          orderBy={orderBy}
          rowsPerPage={paging.count}
          totalRowCount={
            orderedData.length < paging.count ? orderedData.length : -1
          }
          maximumRowCount={25000}
          containerHeight="75vh"
        />
      </Paper>
    </Box>
  );
};

export default ConversionHistoryTableContainer;
