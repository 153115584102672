import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  cancel,
  convert,
  download,
  getErrors,
  getStatus,
} from "../api/conversions";
import { IApiConversionResponse } from "../api/types";
import {
  filesCleared,
  importIdCreated,
  downloadedPayloadRetrieved,
  uploadedFileNamesUpdated,
} from "./importReducer";
import { clearLifeCycleState } from "./internalLifeCycleReducer";

export interface IConvertorState {
  conversionStatus: Partial<IApiConversionResponse>;
  conversionStatusCheckFailed: boolean;
  conversionErrors: string[];
  conversionFailed: boolean;
  errorCode: number | null;
  downloadConvertedPayloadError: boolean;
  conversionErrorShowcaseDialog: boolean;
  conversionErrorDialogAccepted: boolean;
}

const initialState: IConvertorState = {
  conversionStatus: {
    status: "Undefined",
  },
  conversionErrors: [],
  conversionStatusCheckFailed: false,
  downloadConvertedPayloadError: false,
  conversionFailed: false,
  errorCode: null,
  conversionErrorShowcaseDialog: false,
  conversionErrorDialogAccepted: false,
};

export const startConversion = createAsyncThunk<
  void,
  {
    calculationType: string;
    saveArtifacts: boolean;
    abortSignal: AbortSignal | undefined;
  }
>(
  "convert/conversion/start",
  async (args, { rejectWithValue, getState, dispatch }): Promise<void> => {
    const state = getState() as RootState;
    dispatch(clearLifeCycleState(null));
    dispatch(importIdCreated(""));
    dispatch(
      uploadedFileNamesUpdated(state.import.pendingFiles.map((f) => f.name))
    );
    const formData = new FormData();
    for (const file of state.import.pendingFiles) {
      formData.append("Files", file);
    }
    try {
      const resp = await convert(
        args.calculationType,
        args.saveArtifacts,
        formData,
        args.abortSignal
      );

      if (resp.data.conversionId) {
        dispatch(importIdCreated(resp.data.conversionId));
      }
    } catch (ex: any) {
      throw rejectWithValue(ex);
    }
  }
);

export const getConversionErrors = createAsyncThunk(
  "convert/conversion/geterrors",
  async (_undefined, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.import.importId) return rejectWithValue("");
      const resp = await getErrors(state.import.importId);
      return resp.data;
    } catch (ex: any) {
      return rejectWithValue(ex);
    }
  }
);

export const changeConversionErrorShowcaseDialog = createAsyncThunk(
  "convert/conversion/changeerrorshowcasedialog",
  async (state: boolean) => {
    return state;
  }
);

export const getConversionStatus = createAsyncThunk(
  "convert/conversion/getstatus",
  async (_undefined, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.import.importId) return rejectWithValue("");
      const resp = await getStatus(state.import.importId);
      if (resp.status !== 200) {
        return rejectWithValue(resp.status);
      }
      return resp.data;
    } catch (ex: any) {
      return rejectWithValue(ex);
    }
  }
);

export const downloadConvertedPayload = createAsyncThunk(
  "convert/conversion/download",
  async (_undefined, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(filesCleared(null));
      const state = getState() as RootState;
      const resp = await download(state.import.importId);
      const output = {
        downloadedPayloadUrl: URL.createObjectURL(resp.blob),
        downloadedPayloadName: resp.name,
        downloadedPayloadType:
          resp.name.toLowerCase().split(".")[
            resp.name.toLowerCase().split(".").length - 1
          ] === "zip" ||
          resp.name.toLowerCase().split(".")[
            resp.name.toLowerCase().split(".").length - 1
          ] === "xbri"
            ? "package"
            : "viewerFile",
      };
      await dispatch(downloadedPayloadRetrieved(output));
      return output;
    } catch (ex: any) {
      return rejectWithValue(ex);
    }
  }
);

export const cancelConversion = createAsyncThunk(
  "convert/conversion/cancel",
  async (_undefined, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const resp = await cancel(state.import.importId);
      return resp.data;
    } catch (ex: any) {
      return rejectWithValue(ex);
    }
  }
);

const convertSlice: Slice<IConvertorState> = createSlice({
  name: "convert",
  initialState: initialState,
  reducers: {
    conversionFlagsCleared: (): IConvertorState => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startConversion.pending, (state): IConvertorState => {
        return {
          ...state,
          downloadConvertedPayloadError: false,
          conversionStatus: {
            status: "Running",
          },
        };
      })
      .addCase(startConversion.rejected, (state, action): IConvertorState => {
        const newState: IConvertorState = {
          ...state,
          conversionStatus: {
            status: "Failed",
          },
          errorCode:
            (
              action.payload as {
                response: { status: number };
              }
            )?.response?.status || null,
        };
        newState.conversionFailed = true;
        return newState;
      })
      .addCase(getConversionErrors.pending, (state): IConvertorState => {
        return {
          ...state,
          conversionErrors: [],
        };
      })
      .addCase(
        getConversionErrors.fulfilled,
        (state, action): IConvertorState => {
          const errorTexts = action.payload.map((error: any) => error.text);
          const newState = {
            ...state,
            conversionErrors: errorTexts,
          };
          return newState;
        }
      )
      .addCase(getConversionErrors.rejected, (state): IConvertorState => {
        return {
          ...state,
          conversionErrors: [],
        };
      })
      .addCase(getConversionStatus.pending, (state): IConvertorState => {
        return {
          ...state,
          conversionStatusCheckFailed: false,
        };
      })
      .addCase(
        getConversionStatus.fulfilled,
        (state, action): IConvertorState => {
          const newState = {
            ...state,
            conversionStatus: action.payload,
          };
          if (action.payload.status === "Failed") {
            newState.conversionFailed = true;
          }
          return newState;
        }
      )
      .addCase(getConversionStatus.rejected, (state): IConvertorState => {
        return {
          ...state,
          conversionStatusCheckFailed: true,
        };
      })
      .addCase(cancelConversion.fulfilled, (state): IConvertorState => {
        return {
          ...state,
          conversionStatus: {
            status: "Cancelled",
          },
        };
      })
      .addCase(cancelConversion.rejected, (state): IConvertorState => {
        return {
          ...state,
          conversionStatus: {
            status: "Cancelled",
          },
        };
      })
      .addCase(downloadConvertedPayload.fulfilled, (state): IConvertorState => {
        return {
          ...state,
          conversionStatus: {
            status: "Completed",
          },
        };
      })
      .addCase(downloadConvertedPayload.rejected, (state): IConvertorState => {
        return {
          ...state,
          downloadConvertedPayloadError: true,
          conversionFailed: true,
          conversionStatus: {
            status: "Failed",
          },
        };
      })
      .addCase(changeConversionErrorShowcaseDialog.pending, (state, action) => {
        return {
          ...state,
          conversionErrorDialogAccepted: false,
          conversionErrorShowcaseDialog: false,
        };
      })
      .addCase(
        changeConversionErrorShowcaseDialog.fulfilled,
        (state, action) => {
          return {
            ...state,
            conversionErrorDialogAccepted: action.payload,
            conversionErrorShowcaseDialog: action.payload,
          };
        }
      )
      .addCase(
        changeConversionErrorShowcaseDialog.rejected,
        (state, action) => {
          return {
            ...state,
            conversionErrorDialogAccepted: false,
            conversionErrorShowcaseDialog: false,
          };
        }
      );
  },
});

export const { conversionFlagsCleared } = convertSlice.actions;

export default convertSlice.reducer;
