import { TreeItem, treeItemClasses, TreeItemProps } from "@mui/x-tree-view";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { taxonomyTreeSearchMinimumCharCount } from "../../helpers/constants";
import { getHighlightedText } from "../../helpers/getHighlightedText";
import { getTaxonomyTreeviewItemIcon } from "../../helpers/getTaxonomyTreeviewItemIcon";
import { MappedLinkbaseTreeItem } from "../../helpers/mapLinkbaseTreeToTreeviewElement";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "@lingui/macro";
import { useAppSelector } from "../../hooks/useAppSelector";

interface LinkbaseTreeItemProps extends TreeItemProps {
  labelRole: string;
  labelIcon: any;
  isExtention: boolean;
  isBold: boolean;
}

const StyledTreeItem = styled((props: LinkbaseTreeItemProps) => {
  const { labelRole, labelIcon, isExtention, isBold, ...nativeProps } = props;
  return (
    <TreeItem
      {...nativeProps}
      label={
        <Box
          sx={{ display: "inline-flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box
            component={labelIcon}
            sx={{
              mr: 1,
              color: (theme) =>
                isExtention ? theme.palette.warning.light : "#1C1B1F",
            }}
          />
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: isBold ? 800 : 100,
              textDecoration:
                !labelRole ||
                labelRole
                  .split("/")
                  .map((x) => x.toLowerCase())
                  .find((y) => y === "label")
                  ? "none"
                  : "underline",
            }}>
            {nativeProps.label}
          </Typography>
        </Box>
      }
    />
  );
})(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: theme.palette.grey[500],
    },
  },
}));

export interface TaxonomyExplorerTreeItemProps {
  branch: MappedLinkbaseTreeItem;
  currentSearchFilter: string;
  highlightColor: string;
  matchingNameNodeIds: string[];
  onContextMenu: (event: React.MouseEvent, id: string) => void;
  isPackageTaxonomy: boolean;
}

/*TODO  make try catch on this to ensure crashes */

const TaxonomyExplorerTreeItem = ({
  branch,
  currentSearchFilter,
  highlightColor,
  matchingNameNodeIds,
  onContextMenu,
  isPackageTaxonomy,
}: TaxonomyExplorerTreeItemProps) => {
  const labelText = branch.label?.label || branch.id;
  const isBold = useAppSelector((state) => {
    if (!isPackageTaxonomy) {
      if (branch?.type === "roleType") {
        return state.extract.linkbaseTreeUsedElementsMapping
          .flatMap((tree) => tree.roleTypeUsedElementMappings)
          .map((item) => item.id)
          .includes(branch.unmappedBranchId);
      } else if (branch?.type === "elementTree") {
        return state.extract.linkbaseTreeUsedElementsMapping
          .flatMap((tree) => tree.roleTypeUsedElementMappings)
          .flatMap((item) => item.elementIds)
          .includes(branch.linkbaseTreeElement?.elementId || "");
      }
    }
    return false;
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onContextMenu(event, branch.id);
  };
  return (
    <StyledTreeItem
      onContextMenu={
        branch.type === "elementTree" ? handleContextMenu : undefined
      }
      itemId={branch.id}
      labelRole={branch.label?.role || ""}
      labelIcon={getTaxonomyTreeviewItemIcon(branch)}
      isExtention={branch.isExtension}
      isBold={isBold}
      label={
        currentSearchFilter.length >= taxonomyTreeSearchMinimumCharCount
          ? getHighlightedText(
              currentSearchFilter,
              labelText,
              highlightColor,
              matchingNameNodeIds.includes(branch.id)
            )
          : labelText
      }>
      {(branch.hasChildren ||
        (branch.children && branch.children.length > 0)) && (
        <>
          {branch.children.length === 0 && !branch.errorLoadingChildren && (
            <StyledTreeItem
              isExtention={false}
              labelIcon={HourglassEmptyIcon}
              labelRole=""
              itemId={`${branch.id}_loading_children`}
              label={t`Loading children...`}
              disabled={true}
              isBold={false}
            />
          )}
          {branch.children.length === 0 && branch.errorLoadingChildren && (
            <StyledTreeItem
              isExtention={false}
              labelIcon={CloseIcon}
              labelRole=""
              itemId={`${branch.id}_error_loading_children`}
              label={t`Could not load children`}
              disabled={true}
              isBold={false}
            />
          )}
          {branch.children.map((childBranch, index) => (
            <TaxonomyExplorerTreeItem
              key={`${childBranch.id}-${index}`}
              matchingNameNodeIds={matchingNameNodeIds}
              branch={childBranch}
              currentSearchFilter={currentSearchFilter}
              highlightColor={highlightColor}
              onContextMenu={onContextMenu}
              isPackageTaxonomy={isPackageTaxonomy}
            />
          ))}
        </>
      )}
    </StyledTreeItem>
  );
};

export default TaxonomyExplorerTreeItem;
