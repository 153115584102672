import axios, { AxiosResponse } from "axios";
import { loginWithPasswordRelativeUrl } from "../helpers/constants";
import tokenHelper from "../helpers/tokenHelper";
import {
  IAccessTokenResponse,
  IApiOrganization,
  IApiPasswordOptions,
  IApiUser,
} from "./types";

// Standard email/password login
export const authenticateWithPassword = async (
  email: string,
  password: string
): Promise<void> => {
  const resp = await axios.post<IAccessTokenResponse>(
    loginWithPasswordRelativeUrl,
    {
      AppId: "Platform",
      EmailAddress: email,
      Password: password,
    },
    {
      validateStatus: allowOnly200,
    }
  );

  setTokenFromResponse(resp.data);
  return Promise.resolve();
};

// Microsoft federated authentication
export const authenticateWithMicrosoft = async (
  code: string | boolean,
  codeVerifier: string | null
): Promise<void> => {
  const resp = await axios.post<IAccessTokenResponse>(
    "/api/accounts/login/microsoft",
    {
      AppId: "Platform",
      Code: code,
      CodeVerifier: codeVerifier,
      RedirectUri: process.env.REACT_APP_RediretUrl,
    },
    {
      validateStatus: allowOnly200,
    }
  );
  setTokenFromResponse(resp.data);
};

// Authenticate with a valid refresh token
export const refreshToken = async (): Promise<void> => {
  const resp = await axios.post<IAccessTokenResponse>(
    "/api/accounts/login/refresh",
    {
      RefreshToken: tokenHelper.refreshToken,
    }
  );

  setTokenFromResponse(resp.data);
};

// Trigger a forgotten password email
export const forgotPassword = async (email: string | undefined) => {
  await axios({
    method: "post",
    url: "/api/accounts/password/forgot",
    data: {
      EmailAddress: email,
    },
    validateStatus: allowOnly200,
  });
};

// Reset the password for a user
export async function resetPassword(
  userId: string,
  newPassword: string,
  resetToken: string | boolean
) {
  await axios({
    method: "post",
    url: "/api/accounts/password/reset",
    data: {
      userId: userId,
      Password: newPassword,
      ResetToken: resetToken,
    },
    validateStatus: allowOnly200,
  });
}

export const changePassword = async (
  oldPass: string,
  newPass: string
): Promise<AxiosResponse<void>> => {
  return axios({
    method: "post",
    url: "/api/accounts/password/change",
    data: {
      OldPassword: oldPass,
      NewPassword: newPass,
      ConfirmNewPassword: newPass,
    },
    validateStatus: allowOnly200,
  });
};

export const getCurrentUser = async (): Promise<AxiosResponse<IApiUser>> => {
  return axios.get(`/api/accounts/organization/user?cb=${crypto.randomUUID()}`);
};

export const getCurrentOrganization = async (): Promise<
  AxiosResponse<IApiOrganization>
> => {
  return axios.get(`/api/accounts/organization?cb=${crypto.randomUUID()}`);
};

export const getCurrentOrganizationUsers = async (): Promise<
  AxiosResponse<IApiUser[]>
> => {
  return axios.get(
    `/api/accounts/organization/users?cb=${crypto.randomUUID()}`
  );
};

export const updateUser = async (id: string, user: IApiUser): Promise<void> => {
  return axios.post(`/api/accounts/organization/users/${id}/update`, {
    Name: user.name,
  });
};

export const toggleUserActivation = async (
  id: string,
  currentlyDeActivated: boolean
): Promise<void> => {
  const url = currentlyDeActivated
    ? `/api/accounts/organization/users/${id}/activate`
    : `/api/accounts/organization/users/${id}/deactivate`;
  return axios.post(url);
};

export const createNewUser = async (
  name: string,
  email: string
): Promise<AxiosResponse<IApiUser>> => {
  return axios.post("/api/accounts/organization/users/signup", {
    Name: name,
    EmailAddress: email,
  });
};

export const reSendActivationEmail = async (
  UserId: string
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/accounts/organization/users/signup/resend`, { UserId, });
};

export const getPasswordValidationRequirements = async (): Promise<
  AxiosResponse<IApiPasswordOptions>
> => {
  return axios.get("/api/accounts/password/options");
};

/// Use axios({ validateStatus: allowOnly200 }) to make non-200 response throw an error
const allowOnly200 = (status: number) => status === 200;

// Set the token information from the authentication endpoints
const setTokenFromResponse = (tokenResponse: IAccessTokenResponse) => {
  localStorage.setItem("UserToken", tokenResponse.token);
  const expireAt = new Date(tokenResponse.expireAt);
  localStorage.setItem("ExpireAt", expireAt.toISOString());
  localStorage.setItem("RefreshToken", tokenResponse.refreshToken.token);
  const refreshTokenExpireAt = new Date(tokenResponse.refreshToken.expireAt?.toString() || "");
  localStorage.setItem(
    "RefreshTokenExpire",
    refreshTokenExpireAt ? refreshTokenExpireAt.toISOString() : ""
  );
};

export const freeSignUpUser = async (
  name: string,
  email: string,
  organization: string,
  country: string,
  registrationNumber: string,
  leiCode: string | null,
): Promise<void> => {
  return axios.post("/api/accounts/signup", {
    Name: name,
    EmailAddress: email,
    OrganizationName: organization,
    Country: country,
    RegistrationNumber: registrationNumber,
    LeiCode: leiCode || null,
  });
}