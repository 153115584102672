import { TableCell, Theme } from "@mui/material";
import { DataTableColumn } from "./dataTable";

interface DataTableCellProps<T> {
  row: T;
  column: DataTableColumn<T>;
  isSelected: boolean;
}

const getBackgroundColor = (theme: Theme, isSelected: boolean): string => {
  if (isSelected) {
    return theme.palette.grey[400];
  }
  return "";
};

export default function DataTableCell<T>({
  row,
  column,
  isSelected,
}: DataTableCellProps<T>) {
  return (
    <TableCell
      sx={{
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        backgroundColor: (theme) => getBackgroundColor(theme, isSelected),
        minWidth: column?.header?.minWidth || undefined,
        maxWidth: column?.header?.maxWidth || undefined,
        pl: 2.5,
      }}>
      <>
        {column?.valueFormatter
          ? column?.valueFormatter(row as T)
          : row && column
            ? (row[column.key as keyof T] as string)
            : ""}
      </>
    </TableCell>
  );
}
