import { ListItem, ListItemText } from "@mui/material";
import { IApiFact, ExtractedLinkbaseTree } from "../../api/types";
import { getFactDisplayLabelFromLinkbaseTree } from "../../helpers/getFactDisplayLabel";
import { getCalculationSidebarValueString } from "../../helpers/getCalculationSidebarValueString";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { selectedFactIdsUpdated } from "../../reducers/internalLifeCycleReducer";

export interface CalculationRelationashipDisplayProps {
  fact: IApiFact;
  calculationTree: ExtractedLinkbaseTree | undefined;
  currentSelectedLanguage: string;
}

const CalculationRelationashipDisplay = ({
  fact,
  calculationTree,
  currentSelectedLanguage,
}: CalculationRelationashipDisplayProps) => {
  const dispatch = useAppDispatch();
  const label = getFactDisplayLabelFromLinkbaseTree(
    fact.factElement,
    calculationTree,
    currentSelectedLanguage
  );

  const formattedValue = getCalculationSidebarValueString(
    fact.formattedValue,
    fact.contextRef.unitRef || fact.contextRef.unit?.unitId,
    fact.contextRef.decimals
  );

  return (
    <ListItem
      onClick={() => {
        dispatch(selectedFactIdsUpdated(fact.id));
      }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "0.8rem",
        padding: 0,
        background: (theme) => theme.palette.grey[300],
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[500],
        },
      }}>
      <ListItemText
        secondaryTypographyProps={{
          noWrap: true,
          whiteSpace: "initial",
        }}
        secondary={`${label?.label || ""}`}
        sx={{ flex: 1 }}
      />
      <ListItemText
        secondary={formattedValue}
        sx={{ justifyContent: "flex-end", ml: 5, mr: 1, flexGrow: 0 }}
      />
    </ListItem>
  );
};

export default CalculationRelationashipDisplay;
