import axios, { AxiosResponse } from "axios";
import { getFileNameFromHeader } from "../helpers/getFileNameFromHeader";
import tokenHelper from "../helpers/tokenHelper";
import {
  ExtractedElementTree,
  ExtractedTaxonomy,
  IApiFact,
  IApiFactComparisonResult,
  IApiLinkbaseTreeComparisonResult,
  IApiMandatoryTag,
  ExtractedLinkbaseTree,
  ExtractedRoleType,
  IApiRoleTypeWithElementIds,
  IApiEurofilingFact,
  LabelsForElementByName,
} from "./types";

export const extractFacts = async (
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiFact[] | IApiEurofilingFact[]>> => {
  return axios.post("/api/taxonomies/extract/facts", body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const extractFactsFromReport = async (
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiFact[]>> => {
  return axios.post("/api/taxonomies/report/extract/facts", body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const extract = async (
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<ExtractedTaxonomy>> => {
  return axios.post("/api/taxonomies/extract", body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const extractTaxonomyFromReport = async (
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<ExtractedTaxonomy>> => {
  return axios.post("/api/taxonomies/report/extract", body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const getAllMandatoryTags = async (): Promise<
  AxiosResponse<IApiMandatoryTag[]>
> => {
  return axios.get("/api/taxonomies/mandatorytags/all");
};

//axios seemingly has an issue with blob responses in POST calls, so using fetch instead
export const exportToExcel = async (
  url: string,
  body: any
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}${url}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${tokenHelper.token}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (resp.status !== 200) {
    throw new Error(resp.statusText);
  }
  const blob = await resp.blob();
  return {
    blob: blob,
    name: getFileNameFromHeader(resp.headers.get("content-disposition") || ""),
  };
};

export const compareFacts = async (
  url: string,
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiFactComparisonResult[]>> => {
  return axios.post(url, body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const compareLinkbaseTrees = async (
  url: string,
  body: FormData,
  importId: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiLinkbaseTreeComparisonResult[]>> => {
  return axios.post(url, body, {
    headers: { "Content-Type": "multipart/form-data" },
    params: {
      conversionId: importId,
    },
    signal: abortSignal,
  });
};

export const getLinkbaseTreeRoleTypeChildren = async (
  linkbaseTreeId: string,
  roleTypeId: string
): Promise<AxiosResponse<ExtractedElementTree[]>> => {
  return axios.get(
    `/api/taxonomies/linkbases/trees/${linkbaseTreeId}/roletype/${roleTypeId}/children`
  );
};

export const getLinkbaseTreeElementChildren = async (
  linkbaseTreeId: string,
  roleTypeId: string,
  elementTreeId: string
): Promise<AxiosResponse<ExtractedElementTree[]>> => {
  return axios.get(
    `/api/taxonomies/linkbases/trees/${linkbaseTreeId}/roletype/${roleTypeId}/elementTree/${elementTreeId}/children`
  );
};

export const getLinkbaseRoleTypeWithDescendentsByElement = async (
  linkbaseTreeId: string,
  elementNamespaceUri: string,
  elementId: string
): Promise<AxiosResponse<ExtractedRoleType>> => {
  return axios.get(
    `/api/taxonomies/linkbases/trees/${linkbaseTreeId}/element/hierarchy`,
    {
      params: {
        elementNamespaceUri: elementNamespaceUri,
        elementId: elementId,
      },
    }
  );
};

export const getLinkbaseTreeByEntryPoint = async (
  entryPoint: string,
  type: ExtractedLinkbaseTree["type"]
): Promise<AxiosResponse<ExtractedLinkbaseTree>> => {
  return axios.get(`/api/taxonomies/linkbases/trees/${type}`, {
    params: {
      entryPointHref: entryPoint,
    },
  });
};

export const getReportFileHashCode = async (
  body: FormData,
  abortSignal: AbortSignal | undefined
): Promise<string> => {
  const resp = await axios.post("/api/taxonomies/report/hashCode", body, {
    headers: { "Content-Type": "multipart/form-data" },
    signal: abortSignal,
  });
  if (typeof resp.data === "object") {
    return Object.values(resp.data).join("");
  }
  return resp.data;
};

export const getUsedRoleTypesAndElementIds = async (
  linkbaseTreeId: string,
  body: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiRoleTypeWithElementIds[]>> => {
  return axios.post(
    `/api/taxonomies/linkbases/trees/${linkbaseTreeId}/roletypes/used-elements-from-collection`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      signal: abortSignal,
    }
  );
};

export const getLabelsByNamespaceAndElementName = async (
  valuesToRetrieve: Record<string, string>
): Promise<AxiosResponse<LabelsForElementByName[]>> => {
  return axios.post(
    `/api/taxonomies/elements/labels`,
    JSON.stringify(valuesToRetrieve),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const generateIxbrlFile = async (
  conversionId: string,
  body: FormData,
  abortSignal: AbortSignal | undefined
): Promise<{ blob: Blob; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}api/taxonomies/generate/ixbrl?conversionId=${conversionId}`,
    {
      method: "POST",
      headers: {
        Accept: "application/xhtml+xml",
        Authorization: `Bearer ${tokenHelper.token}`,
      },
      body: body,
      signal: abortSignal,
    }
  );
  if (resp.status !== 200) {
    throw new Error(resp.statusText);
  }
  const blob = await resp.blob();
  return {
    blob: blob,
    name: getFileNameFromHeader(resp.headers.get("content-disposition") || ""),
  };
};
