import { useTheme } from "@mui/material";

interface LoadingOverlayProps extends React.PropsWithChildren {
  isVisible: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  children,
  isVisible,
}) => {
  const theme = useTheme();
  return (
    <div style={{ position: "relative" }}>
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.grey[300],
            zIndex: 999,
            opacity: 0.3,
          }}
        />
      )}
      <div style={{ pointerEvents: isVisible ? "none" : "auto" }}>
        {children}
      </div>
    </div>
  );
};

export default LoadingOverlay;
