import { t } from "@lingui/macro";

export const getValidationError = (val: string, minLength: number, maxLength: number): string => {
    if (val.length >= minLength && val.length <= maxLength) return "";
    return t`Value must be between ${minLength} and ${maxLength} characters`;
};

export const getOrganizationCountryValidationError = (val: string): string => {
    if (val.length === 2) return "";
    return t`Value must be 2 characters long`;
};

export const getOrganizationLeiCodeValidationError = (val: string): string => {
    if (val === "") return "";
    if (val.length !== 20) return t`Value must be exactly 20 characters long`;
    let num = 0;
    for (let x = 0; x < val.length; x++) {
        const char = val.charAt(x);
        const code = val.charCodeAt(x);
        if (!isNaN(parseInt(char))) {
            num = (num * 10 + code - 48) % 97;
            continue;
        }
        if (isNaN(parseInt(char))) {
            num = (num * 100 + code - 55) % 97;
            continue;
        }
    }
    return num === 1 ? "" : t`Invalid LEI Code`;
};

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}