import { GleifDataArray, IApiFact } from "../api/types";

export const isReportingEntityNameMatch = (
  facts: IApiFact[],
  gleifData: GleifDataArray | null
): boolean => {
  if (!gleifData) return false;
  const contextRefName =
    "ifrs-full:NameOfReportingEntityOrOtherMeansOfIdentification";
  const contextRefValue = facts
    .find((fact) => fact.contextRef.name === contextRefName)
    ?.formattedValue?.replace(/\s/g, "");
  const legalNameWithOtherNames = [
    gleifData.data[0].attributes.entity.legalName.name
      ?.toLowerCase()
      .replace(/\s/g, ""),
    ...(gleifData.data[0].attributes.entity.otherNames?.flatMap((name) =>
      name.name.toLowerCase().replace(/\s/g, "")
    ) ?? []),
  ];
  return (
    !!contextRefValue &&
    legalNameWithOtherNames.includes(contextRefValue.toLowerCase())
  );
};
