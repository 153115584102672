import { useState } from "react";
import { useNavigate } from "react-router-dom";
import isValidEmail from "../helpers/isValidEmail";
import ErrorAlert from "../components/errorAlert";
import LoginBackground from "../components/login/loginBackground";
import LoginContainer from "../components/login/loginContainer";
import Navbar from "../components/navbar";
import { forgotPassword } from "../api/accounts";
import { waitWithPromise } from "../helpers/timers";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Trans } from "@lingui/macro";

const invalidEmail = new Error("Invalid Email");

// These errors are handled by the form, so we don't need to show extra alerts
const suppressAlerts = [invalidEmail];

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState<Error | null>(
    null
  );

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget as HTMLFormElement);
    const email = data.get("email");

    if (!isValidEmail(email?.toString())) {
      setForgotPasswordError(invalidEmail);
      return;
    }

    setForgotPasswordError(null);
    setIsLoading(true);

    waitWithPromise(1000, forgotPassword(email?.toString()))
      .then(() => setShowSuccess(true))
      .catch((error: any) => setForgotPasswordError(error))
      .finally(() => setIsLoading(false));
  };

  // Message to render once the form is submitted and the email is sent
  const renderSuccessAlert = () => (
    <Alert severity="success">
      An email has been sent to <strong>{email}</strong> with instructions to
      reset your password.
    </Alert>
  );

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);
    if (!isValidEmail(email)) {
      setForgotPasswordError(invalidEmail);
    } else {
      setForgotPasswordError(null);
    }
  };

  // Default state - form to enter email
  const renderForm = () => (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <ErrorAlert
        formError={
          suppressAlerts.includes(forgotPasswordError as Error)
            ? null
            : forgotPasswordError
        }
      />
      <TextField
        margin="normal"
        fullWidth
        id="email"
        label={<Trans>Email Address</Trans>}
        name="email"
        onChange={handleChangeEmail}
        helperText={
          forgotPasswordError === invalidEmail
            ? "Please enter a valid email address."
            : null
        }
        error={forgotPasswordError === invalidEmail}
        value={email}
        autoComplete="email"
        autoFocus
      />
      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}>
        <Trans>Send email</Trans>
      </LoadingButton>
    </Box>
  );

  return (
    <LoginBackground>
      <Navbar />
      <LoginContainer
        sx={{ pt: 15 }}
        additionalButton={
          <Button fullWidth onClick={() => navigate("/login")}>
            <Trans>Back to login</Trans>
          </Button>
        }>
        <Box sx={{ mt: 1 }}>
          {showSuccess ? renderSuccessAlert() : renderForm()}
        </Box>
      </LoginContainer>
    </LoginBackground>
  );
};

export default ForgotPassword;
