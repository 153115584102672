import {
  ChangedProperty,
  ElementTreeCompareModel,
  RoleTypeCompareModel,
} from "../api/types";

export const getAllChangesFromCompareObject = (
  comparisonObject: RoleTypeCompareModel | ElementTreeCompareModel | undefined
): ChangedProperty[] => {
  const changes: ChangedProperty[] = [];
  if (!comparisonObject || "elementTreeComparisons" in comparisonObject) {
    return changes;
  }
  const compareObj = comparisonObject as ElementTreeCompareModel;
  if (compareObj.changedProperties && compareObj.changedProperties.length > 0) {
    changes.push(...compareObj.changedProperties);
  }
  if (compareObj.linkbaseTreeElementComparison) {
    if (
      compareObj.linkbaseTreeElementComparison &&
      compareObj.linkbaseTreeElementComparison.changedProperties &&
      compareObj.linkbaseTreeElementComparison.changedProperties.length > 0
    ) {
      changes.push(
        ...compareObj.linkbaseTreeElementComparison.changedProperties
      );
    }
    if (
      compareObj.linkbaseTreeElementComparison.displayLabelComparisons &&
      compareObj.linkbaseTreeElementComparison.displayLabelComparisons &&
      compareObj.linkbaseTreeElementComparison.displayLabelComparisons.length >
        0
    ) {
      for (const labelComparison of compareObj.linkbaseTreeElementComparison
        .displayLabelComparisons) {
        changes.push(...(labelComparison.changedProperties || []));
      }
    }
  }
  return changes;
};
