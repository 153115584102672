import { TableRow } from "@mui/material";
import { Order } from "../../helpers/tableSorting";
import React from "react";
import DataTableColumnHeaderContainer, {
  ColumnHeader,
} from "./dataTableColumnHeaderContainer";

interface DataTableColumnHeadersContainerProps<T> {
  columnHeaders: ColumnHeader<T>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onToggleShowFilterInput?: (headerId: string) => void;
  order: Order;
  orderBy: string;
}

export default function DataTableColumnHeadersContainer<T>({
  columnHeaders,
  onRequestSort,
  onToggleShowFilterInput,
  order,
  orderBy,
}: DataTableColumnHeadersContainerProps<T>) {
  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableRow>
      {columnHeaders.map((columnHeader) => (
        <DataTableColumnHeaderContainer<T>
          key={`column_header_${columnHeader.id.toString()}`}
          columnHeader={columnHeader}
          onToggleShowFilterInput={onToggleShowFilterInput}
          onRequestSort={createSortHandler}
          order={order}
          orderBy={orderBy}
        />
      ))}
    </TableRow>
  );
}
