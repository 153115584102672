import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Menu from "@mui/material/Menu";

interface SelectLanguageMenuProps {
  anchorElement: null | HTMLElement;
  allLangs: string[];
  selectedLang: string;
  onSelectLang?: (id: string) => void;
  onCloseMenu: () => void;
}

const SelectLanguageMenu = ({
  anchorElement,
  allLangs,
  selectedLang,
  onSelectLang,
  onCloseMenu,
}: SelectLanguageMenuProps) => {
  return (
    <Menu
      anchorEl={anchorElement}
      open={anchorElement !== null}
      onClose={onCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}>
      <FormControl>
        <RadioGroup
          value={selectedLang}
          onChange={(e) => {
            onSelectLang && onSelectLang(e.target.value);
            onCloseMenu();
          }}>
          {allLangs.map((lang, index) => {
            return (
              <FormControlLabel
                key={`select_report_language_control_${lang}_${index}`}
                value={lang}
                control={<Radio />}
                label={lang}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Menu>
  );
};

export default SelectLanguageMenu;
