import { Trans } from "@lingui/macro";
import { Dialog, CircularProgress, Typography } from "@mui/material";

export interface LoadingDialogProps {
  title: string;
  subTitle?: string;
}

const LoadinDialog = ({ title, subTitle }: LoadingDialogProps) => (
  <Dialog
    maxWidth={"xs"}
    fullWidth
    PaperProps={{ sx: { height: 200, alignItems: "center" } }}
    open>
    <div
      style={{
        position: "absolute",
        top: "30%",
        textAlign: "center",
      }}>
      <CircularProgress />
      <Typography variant="caption" component="div">
        <Trans>{title}</Trans>...
      </Typography>
      {subTitle && (
        <Typography variant="caption" component="div">
          {subTitle}
        </Typography>
      )}
    </div>
  </Dialog>
);

export default LoadinDialog;
