import { keyframes, SxProps, Theme, Typography } from "@mui/material";

const animatedEllipsisKeyframes = keyframes`from {width: 0;} to {width: 40px;}`;

const animatedEllipsisStyles: SxProps<Theme> = {
  overflow: "hidden",
  display: "inline-block",
  verticalAlign: "bottom",
  WebkitAnimation: `${animatedEllipsisKeyframes} steps(4, end) 900ms infinite`,
  animation: `${animatedEllipsisKeyframes} steps(4, end) 900ms infinite`,
};

const AnimatedEllipsis = () => {
  return (
    <Typography component="span" sx={animatedEllipsisStyles}>
      ...
    </Typography>
  );
};

export default AnimatedEllipsis;
