export const getAncestorReplacedXBRLElements = (
  elem: HTMLSpanElement
): HTMLSpanElement[] => {
  const allAncestors: HTMLSpanElement[] = [];
  let parent = elem.parentElement;
  if (!parent) return allAncestors;
  while (parent) {
    allAncestors.unshift(parent);
    parent = parent.parentElement;
  }
  return allAncestors.filter((anc) => anc.dataset.associatedFactId);
};

export const getNextSelectedFactId = (
  elem: HTMLSpanElement,
  currentSelectedFactIds: string[]
): string => {
  const clickedAssociatedFactId = elem.dataset.associatedFactId || "";
  const nestingLevel = parseInt(elem.dataset.nestingLevel || "-1");
  if (nestingLevel < 1) return clickedAssociatedFactId;
  const relevantXBRLElementTree = [
    elem,
    ...getAncestorReplacedXBRLElements(elem),
  ];
  if (relevantXBRLElementTree.length === 1) return clickedAssociatedFactId;
  const currentSelectedElement = relevantXBRLElementTree.find((anc) =>
    currentSelectedFactIds.includes(anc.dataset.associatedFactId || "")
  );
  if (!currentSelectedElement) return clickedAssociatedFactId;
  const currentSelectedNestingLevel = parseInt(
    currentSelectedElement.dataset.nestingLevel || "-1"
  );
  if (currentSelectedNestingLevel < 1) return clickedAssociatedFactId;
  const nextNestingLevel = currentSelectedNestingLevel - 1;
  const nextElement = relevantXBRLElementTree.find(
    (anc) => parseInt(anc.dataset.nestingLevel || "-1") === nextNestingLevel
  );
  return nextElement?.dataset.associatedFactId || clickedAssociatedFactId;
};
