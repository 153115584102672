import { Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IApiConversionResponse,
  IApiOrganizationConversionsQuery,
} from "../api/types";
import { getPagedByCurrentUser, getPagedByOrgQuery } from "../api/conversions";

export interface IConversionHistoryState {
  fetchingData: boolean;
  fetchError: boolean;
  conversions: IApiConversionResponse[];
}

const initialState: IConversionHistoryState = {
  fetchError: false,
  fetchingData: false,
  conversions: [],
};

export const getConversionHsitoryByQueryStarted = createAsyncThunk(
  "conversionStatistics/query/get",
  async (
    args: {
      requestBody: IApiOrganizationConversionsQuery;
      isAdmin: boolean;
      abortSignal?: AbortSignal;
    },
    { rejectWithValue }
  ) => {
    try {
      const body = JSON.parse(
        JSON.stringify(
          Object.fromEntries(
            Object.entries(args.requestBody).filter(([_, v]) => v !== "")
          )
        )
      );
      const action = args.isAdmin ? getPagedByOrgQuery : getPagedByCurrentUser;
      const resp = await action(body, args.abortSignal);
      return resp.data;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  }
);

const conversionHistorySlice: Slice<IConversionHistoryState> = createSlice({
  name: "auxData",
  initialState: initialState,
  reducers: {
    conversionHistoryCleared: (state): IConversionHistoryState => {
      return {
        ...state,
        conversions: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getConversionHsitoryByQueryStarted.pending,
        (state): IConversionHistoryState => {
          return {
            ...state,
            fetchingData: true,
            fetchError: false,
            conversions: [],
          };
        }
      )
      .addCase(
        getConversionHsitoryByQueryStarted.rejected,
        (state): IConversionHistoryState => {
          return {
            ...state,
            fetchingData: false,
            fetchError: true,
          };
        }
      )
      .addCase(
        getConversionHsitoryByQueryStarted.fulfilled,
        (state, action): IConversionHistoryState => {
          return {
            ...state,
            fetchingData: false,
            conversions: action.payload,
          };
        }
      );
  },
});

export const { conversionHistoryCleared } = conversionHistorySlice.actions;

export default conversionHistorySlice.reducer;
