export interface DataColumnFilter<T> {
  key: keyof T;
  value: string;
}
export const filterTable = <T>(
  unfilteredData: T[],
  filterString: string
): T[] => {
  const filters = JSON.parse(filterString) as DataColumnFilter<T>[];
  if (unfilteredData.length === 0 || filters.length === 0)
    return unfilteredData;
  return unfilteredData.filter((row) => {
    for (const filter of filters) {
      if (row[filter.key] === undefined) {
        continue;
      }
      const rowValue = (row[filter.key] as string | number).toString().toLowerCase();
      if (
        !rowValue.includes(filter.value.toLowerCase()) &&
        !rowValue.replace(/[^0-9.-]+/g, "").includes(filter.value.toLowerCase())
      ) {
        return false;
      }
    }
    return true;
  });
};