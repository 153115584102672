import {
  EuroFilingFactDisplayItem,
  FactDisplayItem,
} from "../../helpers/mapFactItems";
import { DataColumnFilter } from "../../helpers/tableFiltering";
import { DataTableColumn } from "./dataTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Stack, Tooltip, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import {
  factValueTextLengthCutoff,
  reportErrorColor,
} from "../../helpers/constants";
import { getHighestSeverityError } from "../../helpers/getHighestSeverityError";
import FactHtmlValueDisplay from "./factHtmlValueDisplay";
import LargeTextValueDisplay from "./largeTextValueDisplay";
import { IExtractionState } from "../../reducers/extractionReducer";

export enum FactTableAction {
  openInViewer,
  ShowErrors,
}

export const factDataTableColumnIdentifiers = (
  type: IExtractionState["factType"]
): {
  headerId: keyof FactDisplayItem | string | number;
  id: keyof FactDisplayItem | string | number;
  label: string;
}[] => {
  return getFactsTableColumns(type).map((column) => {
    return {
      headerId: column.header.id,
      id: column.key,
      label: column.header.label,
    };
  });
};

export const getFactsTableColumns = (
  type: IExtractionState["factType"],
  currentOpenColumnFilterHeaderId?: string,
  iconClickHandler?: (val: string, aciton: FactTableAction) => void,
  filterString?: string
): DataTableColumn<FactDisplayItem | EuroFilingFactDisplayItem>[] => {
  const filters = filterString
    ? (JSON.parse(filterString) as DataColumnFilter<
        FactDisplayItem | EuroFilingFactDisplayItem
      >[])
    : [];
  if (type === "standard") {
    return [
      {
        key: "icon_gotohtmlreport",
        valueFormatter: (row) => (
          <Tooltip title={t`Go to HTML Report`}>
            <VisibilityIcon
              onClick={(e) => {
                if (iconClickHandler) {
                  e.preventDefault();
                  e.stopPropagation();
                  iconClickHandler(row.id, FactTableAction.openInViewer);
                }
              }}
            />
          </Tooltip>
        ),
        header: {
          showLabel: false,
          minWidth: 80,
          maxWidth: 80,
          filterable: false,
          label: t`Go to HTML report icon`,
          id: "icon_gotohtmlreport",
          sortable: false,
          showFilterInput: false,
        },
      },
      {
        key: "label",
        valueFormatter: (row) => {
          const currentRow = row as FactDisplayItem;
          return (
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="body2">{currentRow.label}</Typography>
              {currentRow.relatedErrors.length > 0 && (
                <Tooltip
                  title={
                    currentRow.relatedErrors.length > 1
                      ? t`This fact has ${currentRow.relatedErrors.length} associated errors. Click to see more`
                      : t`This fact has 1 associated error. Click to see more`
                  }>
                  <ReportGmailerrorredIcon
                    onClick={() =>
                      iconClickHandler &&
                      iconClickHandler(row.id, FactTableAction.ShowErrors)
                    }
                    sx={{
                      color: reportErrorColor(
                        getHighestSeverityError(currentRow.relatedErrors)
                      ),
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          );
        },
        header: {
          showLabel: true,
          minWidth: 350,
          maxWidth: 350,
          filterable: true,
          id: "label",
          label: t`Label`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "label"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "label",
        },
      },
      {
        key: "name",
        header: {
          showLabel: true,
          minWidth: 350,
          maxWidth: 350,
          filterable: true,
          id: "name",
          label: t`Name`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "name"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "name",
        },
      },
      {
        key: "language",
        header: {
          showLabel: true,
          minWidth: 230,
          maxWidth: 230,
          filterable: true,
          id: "language",
          label: t`Value Language`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "language"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "language",
        },
      },
      {
        key: "unit",
        header: {
          showLabel: true,
          minWidth: 145,
          maxWidth: 145,
          filterable: true,
          id: "unit",
          label: t`Unit`,
          sortable: true,
          currentFilterValue: filters.find((filter) => filter.key === "unit")
            ?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "unit",
        },
      },
      {
        key: "formattedValue",
        header: {
          showLabel: true,
          minWidth: 250,
          maxWidth: 250,
          filterable: true,
          id: "formattedValue",
          label: t`Value`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) => filter.key === "formattedValue"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "formattedValue",
        },
        valueFormatter: (row) => {
          const currentRow = row as FactDisplayItem;
          return (
            <>
              {currentRow.isValueHtml && (
                <FactHtmlValueDisplay item={currentRow} />
              )}
              {!currentRow.isValueHtml &&
                row.formattedValue?.length > factValueTextLengthCutoff && (
                  <LargeTextValueDisplay text={row.formattedValue} />
                )}
              {!currentRow.isValueHtml &&
                currentRow.formattedValue?.length <=
                  factValueTextLengthCutoff && (
                  <Typography
                    sx={{
                      float: !isNaN(parseFloat(currentRow.formattedValue))
                        ? "right"
                        : "",
                    }}>
                    {currentRow.formattedValue}
                  </Typography>
                )}
            </>
          );
        },
      },
      {
        key: "escape",
        header: {
          showLabel: true,
          minWidth: 160,
          maxWidth: 160,
          filterable: true,
          id: "escape",
          label: t`Escape`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "escape"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "escape",
        },
      },
      {
        key: "isHidden",
        header: {
          showLabel: true,
          minWidth: 160,
          maxWidth: 160,
          filterable: true,
          id: "isHidden",
          label: t`Hidden`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "isHidden"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "isHidden",
        },
      },
      {
        key: "balanceType",
        header: {
          showLabel: true,
          minWidth: 170,
          maxWidth: 170,
          filterable: true,
          id: "balanceType",
          label: t`Balance`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key ===
              "balanceType"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "balanceType",
        },
      },
      {
        key: "periodType",
        header: {
          showLabel: true,
          minWidth: 210,
          maxWidth: 210,
          filterable: true,
          id: "periodType",
          label: t`Period Type`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "periodType"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "periodType",
        },
      },
      {
        key: "itemType",
        header: {
          showLabel: true,
          minWidth: 250,
          maxWidth: 250,
          filterable: true,
          id: "itemType",
          label: t`Item Type`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "itemType"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "itemType",
        },
      },
      {
        key: "decimals",
        header: {
          showLabel: true,
          minWidth: 180,
          maxWidth: 180,
          filterable: true,
          id: "decimals",
          label: t`Decimals`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "decimals"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "decimals",
        },
      },
      {
        key: "scale",
        header: {
          showLabel: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: true,
          id: "scale",
          label: t`Scale`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "scale"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "scale",
        },
      },
      {
        key: "periodStart",
        header: {
          showLabel: true,
          minWidth: 220,
          maxWidth: 220,
          filterable: true,
          id: "periodStart",
          label: t`Period Start`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key ===
              "periodStart"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "periodStart",
        },
      },
      {
        key: "periodEnd",
        header: {
          showLabel: true,
          minWidth: 210,
          maxWidth: 210,
          filterable: true,
          id: "periodEnd",
          label: t`Period End`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "periodEnd"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "periodEnd",
        },
      },
      {
        key: "dimensions",
        header: {
          showLabel: true,
          minWidth: 200,
          maxWidth: 200,
          filterable: true,
          id: "dimensions",
          label: t`Dimensions`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "dimensions"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "dimensions",
        },
      },
      {
        key: "dimensionMembers",
        header: {
          showLabel: true,
          minWidth: 350,
          maxWidth: 350,
          filterable: true,
          id: "dimensionMembers",
          label: t`Generic Dimension Members`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key ===
              "dimensionMembers"
          )?.value,
          showFilterInput:
            currentOpenColumnFilterHeaderId === "dimensionMembers",
        },
      },
      {
        key: "wideAnchor",
        header: {
          showLabel: true,
          minWidth: 220,
          maxWidth: 220,
          filterable: true,
          id: "wideAnchor",
          label: t`Wide Anchor`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "wideAnchor"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "wideAnchor",
        },
      },
      {
        key: "narrowerAnchor",
        header: {
          showLabel: true,
          minWidth: 250,
          maxWidth: 250,
          filterable: true,
          id: "narrowerAnchor",
          label: t`Narrower anchor`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key ===
              "narrowerAnchor"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "narrowerAnchor",
        },
      },
      {
        key: "footnotes",
        header: {
          showLabel: true,
          minWidth: 200,
          maxWidth: 200,
          filterable: true,
          id: "footnotes",
          label: t`Foot Notes`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<FactDisplayItem>).key === "footnotes"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "footnotes",
        },
      },
    ];
  } else if (type === "eurofiling") {
    return [
      {
        key: "icon_gotohtmlreport",
        valueFormatter: (row) => (
          <Tooltip title={t`Go to HTML Report`}>
            <VisibilityIcon
              onClick={(e) => {
                if (iconClickHandler) {
                  e.preventDefault();
                  e.stopPropagation();
                  iconClickHandler(row.id, FactTableAction.openInViewer);
                }
              }}
            />
          </Tooltip>
        ),
        header: {
          showLabel: false,
          minWidth: 80,
          maxWidth: 80,
          filterable: false,
          label: t`Go to HTML report icon`,
          id: "icon_gotohtmlreport",
          sortable: false,
          showFilterInput: false,
        },
      },
      {
        key: "tableName",
        header: {
          showLabel: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: true,
          id: "tableName",
          label: t`Table Name`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<EuroFilingFactDisplayItem>).key ===
              "tableName"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "tableName",
        },
      },
      {
        key: "columnCode",
        header: {
          showLabel: true,
          minWidth: 100,
          maxWidth: 100,
          filterable: true,
          id: "columnCode",
          label: t`Column Code`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<EuroFilingFactDisplayItem>).key ===
              "columnCode"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "columnCode",
        },
      },
      {
        key: "rowCode",
        header: {
          showLabel: true,
          minWidth: 100,
          maxWidth: 100,
          filterable: true,
          id: "rowCode",
          label: t`Row Code`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<EuroFilingFactDisplayItem>).key ===
              "rowCode"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "rowCode",
        },
      },
      {
        key: "unit",
        header: {
          showLabel: true,
          minWidth: 50,
          maxWidth: 50,
          filterable: true,
          id: "unit",
          label: t`Unit`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<EuroFilingFactDisplayItem>).key ===
              "unit"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "unit",
        },
      },
      {
        key: "formattedValue",
        header: {
          showLabel: true,
          minWidth: 150,
          maxWidth: 150,
          filterable: true,
          id: "formattedValue",
          label: t`Value`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) => filter.key === "formattedValue"
          )?.value,
          showFilterInput: currentOpenColumnFilterHeaderId === "formattedValue",
        },
      },
      {
        key: "dimensionValues",
        header: {
          showLabel: true,
          minWidth: 400,
          maxWidth: 400,
          filterable: true,
          id: "dimensionValues",
          label: t`Dimension Values`,
          sortable: true,
          currentFilterValue: filters.find(
            (filter) =>
              (filter as DataColumnFilter<EuroFilingFactDisplayItem>).key ===
              "dimensionValues"
          )?.value,
          showFilterInput:
            currentOpenColumnFilterHeaderId === "dimensionValues",
        },
        valueFormatter: (row) => {
          const currentRow = row as EuroFilingFactDisplayItem;
          if (currentRow.dimensionValues) {
            return (
              <Stack spacing={0} direction="column" justifyContent="center">
                {Object.entries(currentRow.dimensionValues).map((val) => (
                  <Typography variant="body2">
                    {val[0]};{val[1]}
                  </Typography>
                ))}
              </Stack>
            );
          }
          return "";
        },
      },
    ];
  }
  return [];
};
