import axios, { AxiosResponse } from "axios";
import { getFileNameFromHeader } from "../helpers/getFileNameFromHeader";
import tokenStorageHelper from "../helpers/tokenHelper";
import { IApiValidationResult } from "./types";

export const validate = async (
  calculationType: string,
  importId: string,
  body: FormData,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<IApiValidationResult>> => {
  return axios.post("/api/validation/validate", body, {
    params: {
      conversionId: importId,
      calculationType: calculationType,
    },
    headers: { "Content-Type": "multipart/form-data" },
    signal: abortSignal,
  });
};

export const generateStandalone = async (
  endpoint: "reportpackage" | "reportfile",
  importId: string,
  outputFileName: string,
  body: FormData,
  abortSignal: AbortSignal | undefined
): Promise<{ standaloneFileHtmlString: string; name: string }> => {
  const resp = await fetch(
    `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}api/exports/${endpoint}/html-viewer?conversionId=${importId}&outputFileName=${outputFileName}`,
    {
      method: "POST",
      headers: {
        Accept: "text/xhtml",
        Authorization: `Bearer ${tokenStorageHelper.token}`,
      },
      body: body,
      signal: abortSignal,
    }
  );
  if (resp.status !== 200) {
    throw new Error(resp.statusText);
  }
  const reportString = await resp.text();
  return {
    standaloneFileHtmlString: reportString,
    name: getFileNameFromHeader(resp.headers.get("content-disposition") || ""),
  };
};
