import { Alert, Box, Grid, Stack } from "@mui/material";
import { IApiFact, LabeledElement, ValidationElement } from "../../api/types";
import { useMemo } from "react";
import FactViewerAccordion from "./factViewerAccordion";
import ElementViewerAccordion from "./elementViewerAccordion";
import { Trans, t } from "@lingui/macro";
import GeneralSidebarFactValidationErrorDisplayViewer from "./generalSidebarFactValidationErrorDisplayViewer";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useAppSelector } from "../../hooks/useAppSelector";

export interface GeneralSidebarFactDetailViewerProps {
  facts: IApiFact[];
  activeEntryPoint: string;
  labeledElements: LabeledElement[] | undefined;
  currentSelectedLanguage: string;
  validationEntries: ValidationElement[] | undefined;
  capHeight?: boolean;
  currentWidth?: number;
}

const GeneralSidebarFactDetailViewer = ({
  facts,
  activeEntryPoint,
  labeledElements,
  currentSelectedLanguage,
  validationEntries,
  capHeight,
  currentWidth,
}: GeneralSidebarFactDetailViewerProps) => {
  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );
  const displayFact = useMemo(() => {
    if (selectedFactIds.length >= 1) {
      return facts.find((f) => f.id === selectedFactIds[0]);
    } else return undefined;
  }, [facts, selectedFactIds]);

  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const displayElement = useMemo(() => {
    if (displayFact) return displayFact.factElement;
    else if (selectedFactIds.length > 1) {
      return facts.find((f) => f.id === selectedFactIds[0])?.factElement;
    }
  }, [displayFact, selectedFactIds, facts]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        maxWidth: currentWidth ? currentWidth - 20 : undefined,
        minWidth: currentWidth ? currentWidth - 20 : undefined,
        maxHeight: capHeight ? "710" : undefined,
        overflow: "hidden",
      }}>
      {factsExtractionError && (
        <Stack
          direction={"column"}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 22 }}>
          <WarningAmberIcon
            sx={{
              fill: "rgba(253, 118, 14, 0.45)",
              height: "100px",
              width: "100px",
            }}
          />
          {t`Data from package could not be loaded.`}
        </Stack>
      )}
      {selectedFactIds.length > 1 && (
        <Alert
          variant="outlined"
          severity="info"
          sx={{ whiteSpace: "initial", mt: 5 }}>
          <Trans>The presented element is associated with multiple facts</Trans>
        </Alert>
      )}
      <Box sx={{ maxWidth: currentWidth }}>
        {displayFact && (
          <FactViewerAccordion
            allFacts={facts}
            currentSelectedLanguage={currentSelectedLanguage}
            displayFact={displayFact}
            selectedFactIds={selectedFactIds}
            activeEntryPoint={activeEntryPoint}
            labeledElements={labeledElements}
          />
        )}
        {displayElement && (
          <ElementViewerAccordion
            currentSelectedLanguage={currentSelectedLanguage}
            element={displayElement}
            activeEntryPoint={activeEntryPoint}
            labeledElements={labeledElements}
          />
        )}
        {displayFact && (
          <GeneralSidebarFactValidationErrorDisplayViewer
            fact={displayFact}
            validationEntries={validationEntries}
          />
        )}
      </Box>
    </Grid>
  );
};

export default GeneralSidebarFactDetailViewer;
