import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { PropsWithChildren } from "react";

import ParsePortImg from "../../images/parseportblack.svg";

interface LoginContainerProps extends PropsWithChildren {
  maxWidthSize?: "xs" | "sm" | "md" | "lg" | "xl";
  sx?: SxProps;
  additionalButton?: JSX.Element;
}

// Reusable component to wrap login forms (login, forgot password, reset password)
const LoginContainer = ({
  maxWidthSize = "xs",
  additionalButton,
  children,
  ...other
}: LoginContainerProps) => {
  return (
    <Container maxWidth={maxWidthSize} {...other}>
      <Paper variant="outlined" sx={{ p: 4, mb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={ParsePortImg} alt="Parseport" style={{ maxHeight: 100 }} />
        </Box>
        {children}
      </Paper>
      {additionalButton}
    </Container>
  );
};

export default LoginContainer;
