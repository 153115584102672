import { FormControl, FormHelperText, Input } from "@mui/material";
import { IApiUser } from "../../api/types";

export interface EditUserNameFormControlProps {
  user: IApiUser;
  onChangeValue: (val: string) => void;
}

const EditUserNameFormControl = ({
  user,
  onChangeValue,
}: EditUserNameFormControlProps) => (
  <FormControl required error={(user.validationError || "").length > 0}>
    {user.validationError && (
      <FormHelperText id={user.id}>{user.validationError}</FormHelperText>
    )}
    <Input
      readOnly={!user.editing}
      value={user.name || ""}
      id={user.id}
      onChange={(e) => onChangeValue(e.currentTarget.value)}
    />
  </FormControl>
);

export default EditUserNameFormControl;
