import { Tab, Tabs } from "@mui/material";
import { ExtractedLinkbaseTree } from "../../api/types";

export interface GeneralSidebarTaxonomyExplorerTabsProps {
  currentSelectedTreeType: ExtractedLinkbaseTree["type"];
  onChangeTab: (type: ExtractedLinkbaseTree["type"]) => void;
}

const GeneralSidebarTaxonomyExplorerTabs = ({
  currentSelectedTreeType,
  onChangeTab,
}: GeneralSidebarTaxonomyExplorerTabsProps) => {
  return (
    <Tabs
      variant="fullWidth"
      value={currentSelectedTreeType}
      onChange={(_, value) => onChangeTab(value)}>
      <Tab
        key={`taxonomy_explorer_tab_presentation`}
        label={`Presentation linkbase`}
        value="Presentation"
      />
      <Tab
        key={`taxonomy_explorer_tab_calculation`}
        label={`Calculation linkbase`}
        value="Calculation"
      />
      <Tab
        key={`taxonomy_explorer_tab_definition`}
        label={`Definition linkbase`}
        value="Definition"
      />
    </Tabs>
  );
};

export default GeneralSidebarTaxonomyExplorerTabs;
