import { t, Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import isValidEmail from "../../helpers/isValidEmail";
import CloseIcon from "@mui/icons-material/Close";

export interface CreateNewUserDialogProps {
  isSaving: boolean;
  isOpen: boolean;
  onSave: (name: string, email: string) => void;
  onCancel: () => void;
}

const CreateNewUserDialog = ({
  isSaving,
  isOpen,
  onSave,
  onCancel,
}: CreateNewUserDialogProps) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [nameValidationError, setNameValidationError] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");

  const handleChangeInput = (type: "name" | "email", val: string) => {
    if (type === "name") {
      setName(val);
      setNameValidationError(
        val.length > 255
          ? t`User name cannot be longer than 255 characters`
          : ""
      );
    } else {
      setEmail(val);
      setEmailValidationError(
        isValidEmail(val) ? "" : t`Please enter a valid email address`
      );
    }
  };

  const handleClose = () => {
    setName("");
    setEmail("");
    onCancel();
  };

  const handleSave = () => {
    onSave(name, email);
    setName("");
    setEmail("");
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(e: any) => e.preventDefault()}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <Trans>Create New User</Trans>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            error={nameValidationError.length > 0}
            required
            disabled={isSaving}
            label={t`Name`}
            value={name}
            onChange={(e) => handleChangeInput("name", e.currentTarget.value)}
            helperText={nameValidationError}
          />
          <TextField
            error={emailValidationError.length > 0}
            required
            disabled={isSaving}
            label={t`Email`}
            value={email}
            onChange={(e) => handleChangeInput("email", e.currentTarget.value)}
            helperText={emailValidationError}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          <Trans>Cancel</Trans>
        </Button>
        <LoadingButton
          variant="outlined"
          disabled={
            name.length === 0 ||
            email.length === 0 ||
            nameValidationError.length > 0 ||
            emailValidationError.length > 0
          }
          color="primary"
          loading={isSaving}
          onClick={handleSave}>
          <Trans>Create</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewUserDialog;
