import { Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { generateIxbrlFile } from "../api/taxonomies";
import { downloadedPayloadRetrieved, importIdCreated } from "./importReducer";

export interface IGenerateState {
  generatePending: boolean;
  generateError: boolean;
}

const initialState: IGenerateState = {
  generateError: false,
  generatePending: false,
};

export const startGenerateIxbrl = createAsyncThunk<
  void,
  {
    abortSignal: AbortSignal | undefined;
  }
>(
  "generate/ixbrl/start",
  async (args, { rejectWithValue, getState, dispatch }): Promise<any> => {
    const importId = crypto.randomUUID();
    const state = getState() as RootState;
    dispatch(importIdCreated(importId));
    const formData = new FormData();
    for (const file of state.import.pendingFiles) {
      formData.append("file", file);
    }
    try {
      const resp = await generateIxbrlFile(
        importId,
        formData,
        args.abortSignal
      );
      const output = {
        downloadedPayloadUrl: URL.createObjectURL(resp.blob),
        downloadedPayloadName: resp.name,
        downloadedPayloadType: "viewerFile",
      };
      await dispatch(downloadedPayloadRetrieved(output));
      return output;
    } catch (ex: any) {
      throw rejectWithValue(ex);
    }
  }
);

const generateSlice: Slice<IGenerateState> = createSlice({
  name: "generate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startGenerateIxbrl.pending, (state): IGenerateState => {
        return {
          ...state,
          generatePending: true,
          generateError: false,
        };
      })
      .addCase(startGenerateIxbrl.rejected, (state): IGenerateState => {
        return {
          ...state,
          generatePending: false,
          generateError: true,
        };
      })
      .addCase(startGenerateIxbrl.fulfilled, (state): IGenerateState => {
        return {
          ...state,
          generatePending: false,
        };
      });
  },
});

export default generateSlice.reducer;
