import { t, Trans } from "@lingui/macro";
import {
  Box,
  Container,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { IApiFact, IApiFactComparisonResult } from "../api/types";
import ChangedFactsTableContainer from "../components/compare/changedFactsTableContainer";
import LinkbaseTreeComparisonContainer from "../components/compare/linkbaseTreeComparisonContainer";
import { changedPropertyMappings } from "../components/compare/modifiedValueDisplayIcon";
import UploadReportDialog from "../components/compare/uploadReportDialog";
import ContentHeaderToolbar from "../components/contentHeaderToolbar";
import ExcelExportDialog, {
  ExcelSheetConfig,
} from "../components/excelExportDialog";
import {
  changedFactsExcelExportColumnIds,
  compareExcelExportFileName,
  ToolbarActions,
} from "../helpers/constants";
import { getCompareToolbarActions } from "../helpers/getCompareToolbarActions";
import { getFlatTreeComparisonExportArray } from "../helpers/getFlatTreeComparisonArray";
import {
  ChangedFactDisplayItem,
  mapFactChangeItemsForDisplay,
} from "../helpers/mapFactItems";
import { useAppSelector } from "../hooks/useAppSelector";

interface CompareProps {
  currentSelectedLanguage: string;
}

const Compare = ({ currentSelectedLanguage }: CompareProps) => {
  const factComparisons = useAppSelector(
    (state) => state.compare.factCompareResults
  );
  const treeComparisons = useAppSelector(
    (state) => state.compare.treesCompareResults
  );
  const allFacts = useAppSelector((state) =>
    state.extract.factType === "standard" ? state.extract.facts : []
  ) as IApiFact[];
  const linkbaseTrees = useAppSelector(
    (state) => state.extract.extractedBasicData?.linkbaseTrees
  );
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showExcelExportDialog, setShowExcelExportDialog] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [currentChangeDisplayMode, setCurrentChangeDisplayMode] = useState<
    IApiFactComparisonResult["state"] | "All"
  >("All");

  const handleToolbarIconClicked = (e: any, id: ToolbarActions) => {
    if (id === ToolbarActions.updload) {
      setShowUploadDialog(true);
    } else if (id === ToolbarActions.download) {
      setShowExcelExportDialog(true);
    }
  };

  const allDisplayFactItemForExcelExport: ExcelSheetConfig | undefined =
    useMemo(() => {
      if (showExcelExportDialog) {
        return {
          sheetName: t`Changed facts`,
          dataType: "ChangedFact",
          serializedCollection: JSON.stringify(
            mapFactChangeItemsForDisplay(
              factComparisons || [],
              allFacts,
              currentSelectedLanguage,
              linkbaseTrees?.find(
                (tree) => tree.type.toLowerCase() === "presentation"
              )
            ).map((fact) => {
              const f: Partial<ChangedFactDisplayItem> = {};
              for (const columnId of changedFactsExcelExportColumnIds) {
                (f as any)[columnId] = (fact as any)[columnId];
                if (
                  columnId === "changedProps" &&
                  f.changedProps &&
                  f.changedProps.length > 0
                ) {
                  const clone = structuredClone(f.changedProps);
                  for (const changedProp of clone) {
                    const propMapping = changedPropertyMappings.find(
                      (m) =>
                        m.apiPropName.toLowerCase() ===
                        changedProp.propertyName.toLowerCase()
                    );
                    if (propMapping) {
                      changedProp.propertyName =
                        propMapping.displayPropName.replace(
                          /([A-Z]?[a-z]+)/g,
                          (_, word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          }
                        );
                    }
                  }
                  f.changedProps = clone;
                }
              }
              return f;
            })
          ),
        };
      }
    }, [
      showExcelExportDialog,
      factComparisons,
      allFacts,
      currentSelectedLanguage,
      linkbaseTrees,
    ]);

  const allLinkbaseTreeItemsForExcelExport: ExcelSheetConfig[] | undefined =
    useMemo(() => {
      if (showExcelExportDialog) {
        return [
          {
            sheetName: t`Presentation tree`,
            dataType: "ChangedLinkbaseTree",
            serializedCollection: JSON.stringify(
              getFlatTreeComparisonExportArray(
                linkbaseTrees?.find(
                  (tree) => tree.type.toLowerCase() === "presentation"
                ),
                treeComparisons?.find((tc) => tc.type === "Presentation"),
                currentSelectedLanguage
              )
            ),
          },
          {
            sheetName: t`Calculation tree`,
            dataType: "ChangedLinkbaseTree",
            serializedCollection: JSON.stringify(
              getFlatTreeComparisonExportArray(
                linkbaseTrees?.find(
                  (tree) => tree.type.toLowerCase() === "calculation"
                ),
                treeComparisons?.find((tc) => tc.type === "Calculation"),
                currentSelectedLanguage
              )
            ),
          },
          {
            sheetName: t`Definition tree`,
            dataType: "ChangedLinkbaseTree",
            serializedCollection: JSON.stringify(
              getFlatTreeComparisonExportArray(
                linkbaseTrees?.find(
                  (tree) => tree.type.toLowerCase() === "definition"
                ),
                treeComparisons?.find((tc) => tc.type === "Definition"),
                currentSelectedLanguage
              )
            ),
          },
        ];
      }
    }, [
      treeComparisons,
      showExcelExportDialog,
      currentSelectedLanguage,
      linkbaseTrees,
    ]);

  return (
    <>
      <Container maxWidth={false} sx={{ ml: 0, pt: 10 }} component="div">
        <Box>
          <ContentHeaderToolbar
            title={t`Reporting package comparison`}
            actions={getCompareToolbarActions(
              handleToolbarIconClicked,
              !factComparisons && !treeComparisons
            )}
            currentFilterValue={currentChangeDisplayMode}
            onChangeFilterValue={setCurrentChangeDisplayMode}
            selectFilterOptions={[
              {
                value: "All",
                label: t`All`,
              },
              {
                value: "Added",
                label: t`Added`,
              },
              {
                value: "Deleted",
                label: t`Deleted`,
              },
              {
                value: "Modified",
                label: t`Modified`,
              },
            ]}
            showSelectFilter={
              factComparisons &&
              treeComparisons &&
              (factComparisons.length > 0 || treeComparisons.length > 0) &&
              currentTabIndex === 0
            }
            showIcons
          />
          <Paper
            variant="outlined"
            sx={{ minHeight: "83vh", maxHeight: "83vh" }}>
            {(!factComparisons || !treeComparisons) && (
              <Box sx={{ width: 275, margin: "auto", mt: 40 }}>
                <Typography variant="h6">
                  <Trans>No comparison data found. </Trans>
                </Typography>
                <Link
                  sx={{ cursor: "pointer" }}
                  variant="body2"
                  underline="always"
                  onClick={() => setShowUploadDialog(true)}>
                  <Trans>Upload reporting package to begin</Trans>
                </Link>
              </Box>
            )}
            {factComparisons &&
              treeComparisons &&
              treeComparisons.length > 1 && (
                <Tabs
                  variant="fullWidth"
                  value={currentTabIndex}
                  onChange={(_, value) => setCurrentTabIndex(value)}>
                  {factComparisons.length > 0 && (
                    <Tab key="compare_tab_facts" label={t`Facts`} />
                  )}
                  {treeComparisons.map((tc, index) => {
                    return (
                      <Tab
                        key={`compare_tab_tree_${index}`}
                        label={`${tc.type} Linkbase`}
                      />
                    );
                  })}
                </Tabs>
              )}
            {factComparisons && currentTabIndex === 0 && (
              <ChangedFactsTableContainer
                currentChangeDisplayMode={currentChangeDisplayMode}
                factComparisons={factComparisons}
                allFacts={allFacts}
                presentationTree={linkbaseTrees?.find(
                  (tree) => tree.type.toLowerCase() === "presentation"
                )}
                currentSelectedLanguage={currentSelectedLanguage}
              />
            )}
            {treeComparisons &&
              treeComparisons.length > 0 &&
              currentTabIndex !== 0 && (
                <LinkbaseTreeComparisonContainer
                  treeComparison={treeComparisons[currentTabIndex - 1]}
                  currentSelectedLanguage={currentSelectedLanguage}
                />
              )}
          </Paper>
        </Box>
      </Container>
      <UploadReportDialog
        onClose={() => setShowUploadDialog(false)}
        open={showUploadDialog}
      />
      <ExcelExportDialog
        actionBody={{
          sheets: [
            allDisplayFactItemForExcelExport,
            ...(allLinkbaseTreeItemsForExcelExport || []),
          ],
        }}
        actionUrl={`api/exports/excel?returnFileName=${compareExcelExportFileName}`}
        open={showExcelExportDialog}
        onClose={() => setShowExcelExportDialog(false)}
        title={t`Exporting...`}
      />
    </>
  );
};

export default Compare;
