import { Box, Container, Fab, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import UploadReportDialog from "../components/debug/uploadReportDialog";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { Trans, t } from "@lingui/macro";
import DebugReportContainer from "../components/debug/debugReportContainer";
import { useAppSelector } from "../hooks/useAppSelector";
import { downloadFileWithUrl } from "../helpers/downloadReportFile";

const Debug = () => {
  const [showImportDialog, setShowImportDialog] = useState(false);
  const importedReportUrl = useAppSelector(
    (state) => state.debug.downloadedReportArtifactUrl
  );

  const importedReportName = useAppSelector(
    (state) => state.debug.downloadedReportArtifactName
  );

  return (
    <>
      <Container maxWidth={false} sx={{ pt: 10, pb: 10 }}>
        <Box>
          <Toolbar sx={{ flexDirection: "row" }}>
            <Typography variant="h5" component="div">
              <Trans>Debug report view</Trans>
            </Typography>
            <Fab
              color="primary"
              title={t`Download debug file`}
              onClick={() =>
                downloadFileWithUrl(importedReportUrl, importedReportName)
              }
              sx={{ ml: "auto", mb: 2, mr: 3 }}
              disabled={importedReportUrl === ""}>
              <DownloadIcon />
            </Fab>
            <Fab
              color="primary"
              title={t`Select report file to debug`}
              onClick={() => setShowImportDialog(true)}
              sx={{ mb: 2 }}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <DebugReportContainer
            onInitReportRendering={() => setShowImportDialog(false)}
          />
        </Box>
      </Container>
      <UploadReportDialog
        open={showImportDialog}
        onCancel={() => setShowImportDialog(false)}
      />
    </>
  );
};

export default Debug;
