import { Trans } from "@lingui/macro";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { IApiLinkbaseTreeComparisonResult } from "../../api/types";
import { getFlatTreeComparisonArray } from "../../helpers/getFlatTreeComparisonArray";
import { useAppSelector } from "../../hooks/useAppSelector";
import ChangedLinkbaseTreeBranchListItem from "./changedLinkbaseTreeBranchListItem";

interface LinkbaseTreeComparisonContainerProps {
  treeComparison: IApiLinkbaseTreeComparisonResult;
  currentSelectedLanguage: string;
}

const LinkbaseTreeComparisonContainer = ({
  treeComparison,
  currentSelectedLanguage,
}: LinkbaseTreeComparisonContainerProps) => {
  const tree = useAppSelector((state) =>
    state.extract.extractedBasicData?.linkbaseTrees?.find(
      (t) => t.type.toLowerCase() === treeComparison.type.toLowerCase()
    )
  );

  const flatComparisonArray = useMemo(
    () => getFlatTreeComparisonArray(tree, treeComparison),
    [tree, treeComparison]
  );

  if (!tree || flatComparisonArray.length === 0) return null;

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ pl: 2 }}>
            <Trans>Old Tree</Trans>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ pl: 2 }}>
            <Trans>New Tree</Trans>
          </Typography>
        </Grid>
      </Grid>
      <Virtuoso
        style={{ height: "69vh" }}
        totalCount={flatComparisonArray.length}
        itemContent={(index) => (
          <Stack direction="row" spacing={2} justifyContent="space-evenly">
            <ChangedLinkbaseTreeBranchListItem
              key={`compare_taxonomy_tree_original_${index}`}
              item={flatComparisonArray[index]}
              isNew={false}
              currentSelectedLanguage={currentSelectedLanguage}
            />
            <Divider orientation="vertical" flexItem />
            <ChangedLinkbaseTreeBranchListItem
              key={`compare_taxonomy_tree_modified_${index}`}
              item={flatComparisonArray[index]}
              isNew={true}
              currentSelectedLanguage={currentSelectedLanguage}
            />
          </Stack>
        )}
      />
    </>
  );
};

export default LinkbaseTreeComparisonContainer;
