import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { IApiUser } from "../../api/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans } from "@lingui/macro";
import EditUserNameFormControl from "./editUserNameFormControl";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export interface CurrentUserDetailsAccordionProps {
  user: IApiUser;
  onChangeValue: (val: string) => void;
  onToggleSave: () => void;
  onShowChangePasswordDialog: () => void;
}

const CurrentUserDetailsAccordion = ({
  user,
  onChangeValue,
  onToggleSave,
  onShowChangePasswordDialog,
}: CurrentUserDetailsAccordionProps) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary
      sx={{ pl: 3 }}
      expandIcon={<ExpandMoreIcon />}
      id="user_details_accordion">
      <Typography>
        <Trans>User Information</Trans>
      </Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ minHeight: "250px", maxHeight: "250px" }}>
      <Stack
        sx={{ pl: 1, pr: 1 }}
        spacing={1}
        divider={<Divider orientation="horizontal" flexItem />}>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Name</Trans>:
          </Typography>
          {user.editing && (
            <>
              <EditUserNameFormControl
                user={user}
                onChangeValue={onChangeValue}
              />
              <SaveOutlinedIcon
                sx={{
                  ml: "auto",
                  opacity: user.name.length === 0 ? 0.5 : 1,
                  pointerEvents: user.name.length === 0 ? "none" : "all",
                }}
                onClick={onToggleSave}
              />
            </>
          )}
          {!user.editing && (
            <>
              <Typography variant="body2" noWrap title={user.name ?? "N/A"}>
                {user.name ?? "N/A"}
              </Typography>
              <ModeEditOutlinedIcon
                sx={{ ml: "auto" }}
                onClick={onToggleSave}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Email Address</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={user.emailAddress ?? "N/A"}>
            {user.emailAddress ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Identifier</Trans>:
          </Typography>
          <Typography variant="body2" noWrap title={user.id ?? "N/A"}>
            {user.id ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Organization Identifier</Trans>:
          </Typography>
          <Typography
            variant="body2"
            noWrap
            title={user.organizationId ?? "N/A"}>
            {user.organizationId ?? "N/A"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              maxWidth: "300px",
              display: "flex",
              flex: 1,
              flexBasis: "auto",
            }}>
            <Trans>Is Administrator</Trans>:
          </Typography>
          {user.isAdmin ? (
            <CheckOutlinedIcon
              sx={{ color: (theme) => theme.palette.success.main }}
            />
          ) : (
            <CloseOutlinedIcon
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          )}
        </Box>
        <Box>
          <Button variant="outlined" onClick={onShowChangePasswordDialog}>
            <Trans>Change password</Trans>
          </Button>
        </Box>
      </Stack>
    </AccordionDetails>
  </Accordion>
);

export default CurrentUserDetailsAccordion;
