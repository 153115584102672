function redirectForMicrosoftLogin() {
  var state = random();
  var code_challenge = makeId(43);

  // Store state and code challenge so that we can verity it after redirect
  localStorage.setItem("state", state.toString());
  localStorage.setItem("code_challenge", code_challenge);

  const loginUrl = `${process.env.REACT_APP_StartUrl}${process.env.REACT_APP_mainUrl}${process.env.REACT_APP_RediretUrl}${process.env.REACT_APP_secondlast}${state}${process.env.REACT_APP_Last}${code_challenge}`;
  window.location.replace(loginUrl);
}

function makeId(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function random() {
  var maxNumber = 99999;
  var randomNumber = Math.floor(Math.random() * maxNumber + 1);
  return randomNumber;
}

export default redirectForMicrosoftLogin;
