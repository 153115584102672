import { PropsWithChildren } from "react";
import { Navigate } from "react-router";
import { useAppSelector } from "../hooks/useAppSelector";
import { GetImportStatus } from "../helpers/getImportStatus";

const WithData = ({ children }: PropsWithChildren) => {
  const conversionFailed = useAppSelector(
    (state) => state.convert.conversionFailed
  );

  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );

  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const basicDataExtractionError = useAppSelector(
    (state) => state.extract.basicDataExtractionError
  );

  const generateError = useAppSelector((state) => state.generate.generateError);

  const downloadConvertedPayloadError = useAppSelector(
    (state) => state.convert.downloadConvertedPayloadError
  );

  const showNavigationTabs = GetImportStatus(
    conversionFailed,
    validationError,
    factsExtractionError,
    basicDataExtractionError,
    generateError,
    downloadConvertedPayloadError
  );

  const isViewerFileLoaded = useAppSelector(
    (state) =>
      state.import.downloadedPayloadType === "viewerFile" &&
      !state.convert.downloadConvertedPayloadError &&
      state.import.downloadedPayloadUrl !== ""
  );

  const isDataLoaded = useAppSelector(
    (state) =>
      (state.extract.extractedBasicData !== null ||
        state.validate.validationResult !== null) &&
      (!state.extract.factsExtractionError ||
        !state.extract.basicDataExtractionError)
  );

  return (
    <>
      {showNavigationTabs === "fatal" ||
      (!isDataLoaded && !isViewerFileLoaded) ? (
        <>
          <Navigate to="/import" replace={true} />
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default WithData;
