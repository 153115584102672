import axios from "axios";
import {
  authEndpoints,
  loginWithPasswordRelativeUrl,
} from "../helpers/constants";
import { toCamelCase } from "../helpers/toCamelCase";

//import { toCamelCase } from "../helpers/toCamelCase";
import tokenHelper from "../helpers/tokenHelper";
import { refreshToken } from "./accounts";

////////////////////////////////////////////////////////////
/// Interceptors run in the reverse order they are applied
////////////////////////////////////////////////////////////

// Add authorization header to all requests
// FUTURE: For security reasons, the token should be moved to http-only cookie instead of local-storage
axios.interceptors.request.use(async (config) => {
  // requests related to authentication do not need a bearer token or token refresh
  if (authEndpoints.some((e) => config.url?.endsWith(e))) {
    return config;
  }

  if (config.headers) {
    config.headers["Authorization"] = `Bearer ${tokenHelper.token}`;
  }

  if (config.url?.endsWith("refresh")) {
    return config;
  }

  // Refresh the token if needed and possible
  if (tokenHelper.isInvalid && tokenHelper.isRefreshable) {
    try {
      await refreshToken();
    } catch (err) {
      console.warn("Error refreshing token. Will try the request anyway", err);
      window.location.href = "/logout";
    }
  }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ERR_CANCELED") {
      return Promise.reject(error.code);
    }
    if (
      error.response.status === 401 &&
      error.config &&
      error.config.url !== loginWithPasswordRelativeUrl
    ) {
      localStorage.clear();
      console.log("Authorization error. Logging out");
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  if (
    response.headers &&
    response.headers["content-type"] !== "application/zip"
  ) {
    response.data = toCamelCase(response.data);
  }
  return response;
});
