import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, t } from "@lingui/macro";
import { useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  clearReport,
  downloadConversionArtifact,
  downloadErrorReset,
  uploadedReportFromLocalMachine,
} from "../../reducers/debugReducer";
import { getPagedByCurrentUser } from "../../api/conversions";
import { isValidGUID } from "../../helpers/isValidGuid";

export type DebugReportUploadSource = "fromFile" | "fromId" | "mostRecent";
const DEFAULT_DIALOG_TITLE = t`Select a source for your debug report file`;

interface UploadReportDialogProps {
  open: boolean;
  onCancel: () => void;
}

const UploadReportDialog = ({ open, onCancel }: UploadReportDialogProps) => {
  const dispatch = useAppDispatch();
  const [dialogTitle, setDialogTitle] = useState(DEFAULT_DIALOG_TITLE);
  const [fetchingUserConversionHistory, setFetchingUserConversionHistory] =
    useState(false);
  const [pendingProcessError, setPendingProcessError] = useState("");
  const [pendingPastedReportId, setPendingPastedReportId] = useState("");
  const importingReport = useAppSelector(
    (state) => state.debug.downloadingArtifact
  );

  const importError = useAppSelector(
    (state) => state.debug.downloadingArtifactError
  );

  const handleFileSelected = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type === "text/html" || file.type === "application/xhtml+xml") {
        handleSubmit(file);
      } else {
        setPendingProcessError(t`The selected file has an Invalid file type`);
      }
    }
  };

  const handleSubmit = async (payload: string | File | undefined) => {
    if (payload instanceof File) {
      dispatch(clearReport(undefined));
      dispatch(uploadedReportFromLocalMachine(payload));
    } else if (payload !== undefined) {
      setPendingPastedReportId(payload);
      if (!isValidGUID(payload as string)) {
        setPendingProcessError(t`Please paste in a valid GUID value`);
      } else {
        handleGetReportByConversionId(payload);
      }
    } else {
      try {
        setFetchingUserConversionHistory(true);
        const resp = await getPagedByCurrentUser(
          {
            paging: {
              count: 25,
              number: 1,
            },
          },
          undefined
        );
        if (resp.data.length > 0) {
          handleGetReportByConversionId(resp.data[0].id);
        } else {
          setFetchingUserConversionHistory(false);
          setPendingProcessError(
            t`Current user hase no conversions logged in the system`
          );
        }
      } catch (ex) {
        setPendingProcessError(
          t`Could not retrieve current user conversion history`
        );
        setFetchingUserConversionHistory(false);
      }
    }
  };

  const handleGetReportByConversionId = async (id: string) => {
    dispatch(
      downloadConversionArtifact({
        conversionId: id,
        artifactType: "AreaMarkedDebugReport",
      })
    );
    setFetchingUserConversionHistory(false);
  };

  const handleClear = () => {
    setPendingProcessError("");
    setPendingPastedReportId("");
    setDialogTitle(DEFAULT_DIALOG_TITLE);
    onCancel();
  };

  return (
    <>
      <Dialog
        open={open}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        onClose={() => null}>
        <>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent sx={{ minHeight: 210 }}>
            <Stack spacing={2}>
              <>
                <Button
                  disabled={fetchingUserConversionHistory || importingReport}
                  variant="outlined"
                  component="label"
                  onClick={() => handleSubmit(undefined)}>
                  <Trans>Upload your most recent conversion</Trans>
                </Button>
                <Typography sx={{ textAlign: "center" }}>
                  <Trans>OR</Trans>
                </Typography>
                <TextField
                  disabled={fetchingUserConversionHistory || importingReport}
                  sx={{ mt: 1 }}
                  value={pendingPastedReportId}
                  onPaste={(e) => handleSubmit(e.clipboardData.getData("Text"))}
                  onKeyDown={(e) => {
                    if (
                      e.ctrlKey ||
                      (e.ctrlKey && e.key === "v") ||
                      (e.ctrlKey && e.key === "a") ||
                      e.key === "Backspace" ||
                      e.key === "Delete" ||
                      e.key === "Unidentified"
                    ) {
                    } else {
                      e.preventDefault();
                      setPendingProcessError(t`This input is for pasting only`);
                    }
                  }}
                  label={t`Paste in a conversion Id`}
                />
                <Typography sx={{ textAlign: "center" }}>
                  <Trans>OR</Trans>
                </Typography>
                <Button
                  variant="outlined"
                  component="label"
                  disabled={fetchingUserConversionHistory || importingReport}>
                  <Trans>Select a File from your computer</Trans>
                  <input
                    hidden
                    type="file"
                    onChange={(e) => {
                      handleFileSelected(e.target.files);
                    }}
                  />
                </Button>
              </>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={importingReport}
              variant="outlined"
              onClick={handleClear}>
              <Trans>Cancel</Trans>
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <Snackbar
        open={importError !== "" || pendingProcessError !== ""}
        autoHideDuration={6000}
        onClose={() => {
          setPendingProcessError("");
          dispatch(downloadErrorReset(null));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity="error">
          {importError !== "" ? importError : pendingProcessError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadReportDialog;
