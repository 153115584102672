import { Trans } from "@lingui/macro";
import { Alert, AlertTitle } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { delayAsync } from "../../helpers/delayAsync";
import { getHtmlReactParserOptions } from "./getHtmlReactParserOptions";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  MappedReport,
  startMappingImportedData,
} from "../../reducers/mappingReducer";
import LoadinDialog from "../loadingDialog";
import ScrollableReportContainer from "./scrollableReportContainer";
import { IApiFact } from "../../api/types";

interface ReportGeneratorProps {
  isMinimal: boolean;
  highlightAllFacts: boolean;
  highlightErrors: boolean;
  showDebugView: boolean;
  currentZoomScale?: number;
}

const ReportGenerator = ({
  currentZoomScale,
  isMinimal,
  highlightAllFacts,
  highlightErrors,
  showDebugView,
}: ReportGeneratorProps) => {
  const dispatch = useAppDispatch();
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [currentDisplayedReport, setCurrentDisplayedReport] =
    useState<MappedReport>();

  const facts = useAppSelector((state) => state.extract.facts);
  const extractedFactsType = useAppSelector((state) => state.extract.factType);

  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );
  const mappedReports = useAppSelector((state) => state.map.reports);

  const downloadedPayloadUrl = useAppSelector(
    (state) => state.import.downloadedPayloadUrl
  );

  const mappingError = useAppSelector((state) => state.map.mappingError);

  const fontsLoadedForReportIds = useAppSelector(
    (state) => state.internalLifeCycle.fontsLoadedForReportIds
  );

  const handleInitialReportLoading = async (): Promise<void> => {
    try {
      if (downloadedPayloadUrl.length === 0) {
        setLoadingReport(false);
      } else {
        await dispatch(startMappingImportedData());
        await delayAsync(500);
        setLoadingReport(false);
      }
    } catch (ex) {
      setLoadingReport(false);
      setLoadingError(true);
    } finally {
      Promise.resolve();
    }
  };

  const handleReportLinkClick = (href: string) => {
    const report = mappedReports.find(
      (rep) => rep.name.toLowerCase() === href.toLowerCase()
    );
    if (report) {
      setCurrentDisplayedReport(report);
    } else {
      window.open(href, "_blank");
    }
  };

  useEffect(() => {
    if (mappedReports.length === 0) {
      setLoadingReport(true);
      handleInitialReportLoading();
    } else if (mappedReports.length > 0) {
      setCurrentDisplayedReport(mappedReports[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedReports]);

  useEffect(() => {
    if (selectedFactIds.length > 0 && extractedFactsType === "standard") {
      const fact = (facts as IApiFact[]).find(
        (f) => f.id === selectedFactIds[0]
      );
      if (
        fact &&
        fact.reportId &&
        fact.reportId !== currentDisplayedReport?.id
      ) {
        const report = mappedReports.find((r) => r.id === fact.reportId);
        if (report) {
          setCurrentDisplayedReport(report);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactIds, facts, mappedReports, extractedFactsType]);

  useEffect(() => {
    if (mappingError && loadingReport) {
      setLoadingReport(false);
      setLoadingError(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingError]);

  const reactParserOptions = useMemo(() => {
    if (currentDisplayedReport && facts.length > 0) {
      return getHtmlReactParserOptions(0, facts, handleReportLinkClick);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facts.length, currentDisplayedReport?.reportHtmlUrl]);

  if (loadingReport || !currentDisplayedReport) {
    if (loadingError) {
      return (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error loading report</Trans>
          </AlertTitle>
          <Trans>Could not parse the report file</Trans>
        </Alert>
      );
    }
    return <LoadinDialog title="Loading report" />;
  }
  return (
    <ScrollableReportContainer
      reportId={currentDisplayedReport.id}
      isMinimal={isMinimal}
      reactParserOptions={reactParserOptions}
      sourceHtmlUrl={currentDisplayedReport.reportHtmlUrl}
      currentZoomScale={currentZoomScale}
      willLoadFontResources={
        currentDisplayedReport.includesFontResources &&
        !fontsLoadedForReportIds.includes(currentDisplayedReport.id)
      }
      highlightAllFacts={highlightAllFacts}
      highlightErrors={highlightErrors}
      showDebugView={showDebugView}
    />
  );
};

export default ReportGenerator;
