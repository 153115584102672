export function GetImportStatus(
  conversionError: boolean,
  validationError: boolean,
  factsExtractionError: boolean,
  basicDataExtractionError: boolean,
  generateError: boolean,
  downloadConvertedPayloadError: boolean
): "dialog" | "fatal" | "success" | undefined {
  if (conversionError || downloadConvertedPayloadError) {
    return "fatal";
  }
  if (
    !conversionError &&
    !validationError &&
    !factsExtractionError &&
    !basicDataExtractionError &&
    !generateError
  ) {
    return "success";
  } else if (validationError) {
    if (
      !conversionError &&
      validationError &&
      factsExtractionError &&
      !basicDataExtractionError
    ) {
      return "fatal";
    } else if (
      !conversionError &&
      validationError &&
      factsExtractionError &&
      basicDataExtractionError
    ) {
      return "fatal";
    } else if (
      !conversionError &&
      !validationError &&
      !factsExtractionError &&
      basicDataExtractionError
    ) {
      return "dialog";
    } else if (
      !conversionError &&
      validationError &&
      !factsExtractionError &&
      basicDataExtractionError
    ) {
      return "fatal";
    }
  } else if (!validationError) {
    if (
      !conversionError &&
      !validationError &&
      factsExtractionError &&
      !basicDataExtractionError
    ) {
      return "dialog";
    }
  } else {
    return "fatal";
  }
}
