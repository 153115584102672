import JSZip from "jszip";
import { getCachedPackage } from "./getCachedPackage";
import { IImporterState } from "../reducers/importReducer";

export const getReportFilesAsHtmlFromPackage = async (
  zipPackage: Blob | File
): Promise<{
  reports: { name: string; html: string }[];
  cssString: string;
  images: {
    name: string;
    base64Value: string;
  }[];
}> => {
  const promises: Promise<{ name: string; html: string }>[] = [];
  const output: {
    reports: { name: string; html: string }[];
    cssString: string;
    images: {
      name: string;
      base64Value: string;
    }[];
  } = {
    cssString: "",
    reports: [],
    images: [],
  };
  let cssString = "";
  const folder = await JSZip.loadAsync(zipPackage);
  const files = folder.file(/.*.(html|xhtml)/);
  for (const file of files) {
    promises.push(
      file.async("text").then((html) => {
        return {
          name: file.name.split("/")[file.name.split("/").length - 1],
          html: html,
        };
      })
    );
  }
  const extractedFiles = await Promise.all(promises);
  output.reports = extractedFiles;
  const cssFile = folder.file(/.*.(css)/);
  if (cssFile.length > 0) {
    cssString = await cssFile[0].async("text");
    output.cssString = cssString;
  }
  const images = folder.file(/.*.(jpeg|jpg|png|bmp|gif)/);
  for (const imageFile of images) {
    const value = await imageFile.async("base64");
    output.images.push({
      base64Value: value,
      name: imageFile.name,
    });
  }
  return output;
};

export const getReportFilesAsFileFromPackage = async (
  zipPackage: Blob | File
): Promise<File[]> => {
  try {
    let zip: JSZip;
    if (zipPackage.type === "text/xhtml") {
      const zipBlob = await textToZip(await zipPackage.text());
      zip = await JSZip.loadAsync(zipBlob);
    } else {
      zip = await JSZip.loadAsync(zipPackage);
    }
    const promises: Promise<File>[] = [];
    const htmlFiles = zip.file(/.*\.(html|xhtml)$/);
    for (const file of htmlFiles) {
      promises.push(
        file
          .async("blob")
          .then((blob) => new File([blob], file.name, { type: "xhtml/html" }))
      );
    }
    return Promise.all(promises);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const textToZip = async (text: string): Promise<Blob> => {
  try {
    const zip = new JSZip();
    zip.file("file.xhtml", text);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    return zipBlob;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const getReportFilesAsHtml = async (
  packageUrl: string,
  packageType: IImporterState["downloadedPayloadType"]
): Promise<{
  reports: { name: string; html: string }[];
  cssString: string;
  images: {
    name: string;
    base64Value: string;
  }[];
}> => {
  if (!packageUrl)
    return {
      cssString: "",
      reports: [],
      images: [],
    };
  const blob = await getCachedPackage(packageUrl);
  if (packageType === "package") {
    return getReportFilesAsHtmlFromPackage(blob);
  } else {
    const fileHtml = await blob.text();
    return {
      cssString: "",
      reports: [{ name: "", html: fileHtml }],
      images: [],
    };
  }
};
