import { IApiEurofilingFact, IApiFact } from "../api/types";
import { IExtractionState } from "../reducers/extractionReducer";

export const getEdgeDateFromFacts = (
  facts: Array<IApiFact | IApiEurofilingFact>,
  factsType: IExtractionState["factType"],
  isStart: boolean
): string => {
  if (factsType === "eurofiling") return "";
  const usedFacts = facts as IApiFact[];
  const periodValues = usedFacts
    .filter((fact) => fact.contextRef?.context?.period)
    .map((f) => f.contextRef.context.period);
  const dateStrings: string[] = [];
  for (const periodValue of periodValues) {
    if (periodValue.type === "Instant") {
      dateStrings.push(periodValue.value);
    } else if (periodValue.type === "Duration") {
      dateStrings.push(periodValue.startTime);
      dateStrings.push(periodValue.endTime);
    }
  }
  const dateValues = dateStrings
    .filter((str) => str !== "")
    .map((sstr) => new Date(sstr).getTime());
  const maxDate = Math.max(...dateValues);
  if (!isStart) return new Date(maxDate).toLocaleDateString();
  const relevantFacts = usedFacts.filter(
    (fact) =>
      fact.contextRef?.context?.period?.type === "Duration" &&
      new Date(fact.contextRef.context.period.endTime).getTime() === maxDate
  );
  const starts = relevantFacts.map((fac) =>
    new Date(fac.contextRef.context.period.startTime).getTime()
  );
  const firstStart = Math.min(...starts);
  return new Date(firstStart).toLocaleDateString();
};
