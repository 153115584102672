import Box from "@mui/material/Box";
import { PropsWithChildren } from "react";
import BackgroundImg from "../../images/background.png";

// Full page background image for auth pages
const LoginBackground = ({ children }: PropsWithChildren) => {
  return (
    <Box
      height="100vh"
      sx={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}>
      {children}
    </Box>
  );
};

export default LoginBackground;
