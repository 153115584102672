import { Grid, ListItem, ListItemText } from "@mui/material";
import { ExtractedLinkbaseTree, IApiFact } from "../../api/types";
import CalculationRelationashipDisplay from "./calculationRelationashipDisplay";

interface CalculationRelationashipDisplayGroupProps {
  roleUri: string;
  facts: IApiFact[];
  calculationTree: ExtractedLinkbaseTree | undefined;
  currentSelectedLanguage: string;
}

const CalculationRelationashipDisplayGroup = ({
  roleUri,
  facts,
  calculationTree,
  currentSelectedLanguage,
}: CalculationRelationashipDisplayGroupProps) => {
  const roleLabel =
    calculationTree?.roleTypes
      .find((rt) => rt.roleUri === roleUri)
      ?.definitionLabels[0]?.label.replace("- Statement -", "- ") || roleUri;

  return (
    <>
      <ListItem
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
        }}>
        <ListItemText
          secondaryTypographyProps={{
            noWrap: false,
            whiteSpace: "initial",
          }}
          secondary={roleLabel}
          sx={{ flex: 1 }}
        />
      </ListItem>
      {facts.map((fact) => (
        <Grid container spacing={0} sx={{ ml: 3 }}>
          <CalculationRelationashipDisplay
            currentSelectedLanguage={currentSelectedLanguage}
            fact={fact}
            calculationTree={calculationTree}
          />
        </Grid>
      ))}
    </>
  );
};

export default CalculationRelationashipDisplayGroup;
