import { acceptedFileTypes } from "./constants";

export const areFilesForConversion = (files: File[]): boolean => {
  const conversioExtensions = Object.values(
    acceptedFileTypes(false, true)
  ).flat();
  
  const fileExtensions = files.map((f) => `.${f.name.split(".")[f.name.split(".").length - 1]}`);
  return fileExtensions.some((ext) => conversioExtensions.includes(ext));
};
