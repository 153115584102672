import { IApiEurofilingFact, IApiFact } from "../api/types";
import { FactErrorMapping } from "./mapValidationErrorsToFacts";
import { reportReplacedXBRLNodeCustomStylesClassNames } from "../components/viewer/reportReplacedXBRLNodeCustomStyles";
import { internalValidationErrorIds } from "./constants";
import { IExtractionState } from "../reducers/extractionReducer";

const isAncestorSelectedRecursive = (
  selectedFactIds: string[],
  elem: HTMLElement | null,
  currentLevel: number,
  finalLevel: number
): boolean => {
  if (!elem) return false;
  if (currentLevel === finalLevel) return false;
  if (
    elem.dataset.associatedFactId === undefined ||
    elem.dataset.associatedFactId === ""
  )
    return isAncestorSelectedRecursive(
      selectedFactIds,
      elem.parentElement,
      currentLevel,
      finalLevel
    );
  if (selectedFactIds.includes(elem.dataset.associatedFactId || ""))
    return true;
  currentLevel++;
  return isAncestorSelectedRecursive(
    selectedFactIds,
    elem.parentElement,
    currentLevel,
    finalLevel
  );
};

export const handleXBRLNodeHighlightClasses = (
  element: HTMLElement,
  selectedFactIds: string[],
  associatedFactId: string,
  facts: Array<IApiFact | IApiEurofilingFact>,
  extractedFactsType: IExtractionState["factType"],
  relevantTargetNameSpaces: string[],
  highlightErrors: boolean,
  errorsByFactId: FactErrorMapping[],
  highlightAllFacts: boolean,
  nestingLevel: number
) => {
  element.classList.remove(
    ...Object.values(reportReplacedXBRLNodeCustomStylesClassNames)
  );
  if (highlightErrors) {
    if (errorsByFactId.find((ebf) => ebf.factId === associatedFactId)) {
      const errors = errorsByFactId.find(
        (ebf) => ebf.factId === associatedFactId
      )?.errors;
      if (
        errors?.find(
          (error) =>
            error.severity === "Error" || error.severity === "FatalError"
        )
      ) {
        element.classList.add(
          reportReplacedXBRLNodeCustomStylesClassNames.errorHighlighted
        );
        return;
      } else if (errors?.find((error) => error.severity === "Inconsistency")) {
        element.classList.add(
          reportReplacedXBRLNodeCustomStylesClassNames.inconsistencyHighlighted
        );
        return;
      } else {
        if (
          !errors?.some(
            (err) => err.id === internalValidationErrorIds.unicodeChar
          )
        ) {
          element.classList.add(
            reportReplacedXBRLNodeCustomStylesClassNames.warningHighlighted
          );
          return;
        }
      }
    }
  }
  if (selectedFactIds.includes(associatedFactId)) {
    element.classList.add(
      reportReplacedXBRLNodeCustomStylesClassNames.selected
    );
    return;
  }
  if (nestingLevel > 0) {
    if (
      isAncestorSelectedRecursive(
        selectedFactIds,
        element.parentElement,
        0,
        nestingLevel
      )
    )
      return;
  }
  const fact = facts.find((f) => f.id === associatedFactId);
  if (highlightAllFacts) {
    let isNested = false;
    if (nestingLevel > 1) isNested = true;
    else if (nestingLevel === 1) {
      isNested = element.getAttribute("associatedfactid") !== null;
    }
    if (isNested) {
      element.classList.add(
        reportReplacedXBRLNodeCustomStylesClassNames.highlighted,
        reportReplacedXBRLNodeCustomStylesClassNames.nestedHighlighted
      );
      return;
    }
    const isExtension =
      extractedFactsType === "standard"
        ? relevantTargetNameSpaces.includes(
            (fact as IApiFact)?.factElement?.namespace?.uri || ""
          )
        : false;
    if (isExtension) {
      element.classList.add(
        reportReplacedXBRLNodeCustomStylesClassNames.extensionHighlighted
      );
      return;
    }
    element.classList.add(
      reportReplacedXBRLNodeCustomStylesClassNames.highlighted
    );
  }
  if (
    extractedFactsType === "eurofiling" ||
    !(fact as IApiFact)?.factElement?.type
      ?.toLowerCase()
      .includes("textBlockItemType")
  ) {
    const highlightedFacts = facts.filter((fact) =>
      selectedFactIds.includes(fact.id)
    );
    if (highlightedFacts.length > 0 && extractedFactsType === "standard") {
      if (
        (highlightedFacts as IApiFact[]).some(
          (fact) =>
            fact.calculationRelationships &&
            fact.calculationRelationships.length > 0 &&
            fact.calculationRelationships
              .map((calc) => calc.fact?.id)
              .includes(associatedFactId)
        )
      ) {
        element.classList.add(
          reportReplacedXBRLNodeCustomStylesClassNames.borderCalculation
        );
      }
    }
  }
};
