import { SvgIconTypeMap } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { t } from "@lingui/macro";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ToolbarActions } from "./constants";
import LanguageIcon from "@mui/icons-material/Language";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { IExtractionState } from "../reducers/extractionReducer";

export interface ContentHeaderToolbarAction {
  id?: ToolbarActions;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  title?: string;
  clickAction?: (e: any) => void;
  turnedOn?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isDivider?: boolean;
}

export const getFactsToolbarActions = (
  iconClickHandler: (e: any, action: ToolbarActions) => void,
  extractedFactsType: IExtractionState["factType"],
  currentTableLanguages: string[]
): ContentHeaderToolbarAction[] => {
  return [
    {
      id: ToolbarActions.openInViewer,
      title: t`Show in viewer`,
      icon: ViewAgendaIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.openInViewer),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.highlightErrors,
      title: t`Show only facts with errors`,
      icon: ReportGmailerrorredIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.highlightErrors),
      disabled: extractedFactsType !== "standard",
      turnedOn: false,
    },
    {
      id: ToolbarActions.download,
      title: t`Download`,
      icon: DownloadIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.download),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.filterColumns,
      title: t`Show/hide columns`,
      icon: ViewColumnIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.filterColumns),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.resetToDefaults,
      title: t`Reset to defaults`,
      icon: RestartAltIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.resetToDefaults),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.selectLanguage,
      title: t`Select report language`,
      icon: LanguageIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.selectLanguage),
      disabled: !currentTableLanguages || currentTableLanguages.length < 2,
      turnedOn: false,
    },
  ];
};
