import { TableCell, TableSortLabel, IconButton, Box } from "@mui/material";
import React from "react";
import { Order } from "../../helpers/tableSorting";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export interface ColumnHeader<T> {
  id: keyof T | string;
  label: string;
  sortable: boolean;
  filterable: boolean;
  minWidth: number;
  maxWidth: number;
  currentFilterValue?: string;
  showFilterInput?: boolean;
  showLabel: boolean;
}

interface DataTableColumnHeaderContainerProps<T> {
  columnHeader: ColumnHeader<T>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onToggleShowFilterInput?: (headerId: string) => void;
  order: Order;
  orderBy: string;
}

export default function DataTableColumnHeaderContainer<T>({
  columnHeader,
  onRequestSort,
  onToggleShowFilterInput,
  order,
  orderBy,
}: DataTableColumnHeaderContainerProps<T>) {
  return (
    <TableCell
      sx={{
        minWidth: (columnHeader.minWidth * 80) / 100,
        maxWidth: (columnHeader.maxWidth * 80) / 100,
        backgroundColor: "white",
        ml: 1,
        mr: 1,
      }}
      key={columnHeader.id as string}
      sortDirection={
        columnHeader.sortable && (orderBy === columnHeader.id ? order : false)
      }>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {columnHeader.sortable && (
          <TableSortLabel
            sx={{ fontWeight: 600, fontSize: "0.9rem" }}
            active={orderBy === columnHeader.id}
            direction={orderBy === columnHeader.id ? order : "asc"}
            onClick={(e) => onRequestSort(e, columnHeader.id as keyof T)}>
            {columnHeader.showLabel ? columnHeader.label : ""}
          </TableSortLabel>
        )}
        {columnHeader.filterable && (
          <IconButton
            size="small"
            onClick={() =>
              onToggleShowFilterInput &&
              onToggleShowFilterInput(
                columnHeader.showFilterInput ? "" : columnHeader.id.toString()
              )
            }>
            {columnHeader.showFilterInput && (
              <FilterAltIcon
                onClick={() =>
                  onToggleShowFilterInput &&
                  onToggleShowFilterInput(
                    columnHeader.showFilterInput
                      ? ""
                      : columnHeader.id.toString()
                  )
                }
                sx={{
                  color: columnHeader.currentFilterValue ? "#0094C9" : "",
                }}
              />
            )}
            {!columnHeader.showFilterInput && (
              <FilterAltOutlinedIcon
                onClick={() =>
                  onToggleShowFilterInput &&
                  onToggleShowFilterInput(
                    columnHeader.showFilterInput
                      ? ""
                      : columnHeader.id.toString()
                  )
                }
                sx={{
                  color: columnHeader.currentFilterValue ? "#0094C9" : "",
                }}
              />
            )}
          </IconButton>
        )}
      </Box>
    </TableCell >
  );
}
