import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

export const initAppInsights = () => {
  if (
    !process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY ||
    process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY.length === 0
  )
    return;
  const clickPluginInstance = new ClickAnalyticsPlugin();

  const clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      customDataPrefix: "data-insights-",
    },
  };

  const configObj = {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  };

  const appInsights = new ApplicationInsights({ config: configObj });
  appInsights.loadAppInsights();
};
