/* TreeView */
import { alpha, styled } from "@mui/material/styles";
/* Icons TreeView */
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DescriptionIcon from "@mui/icons-material/Description";

import { useAppSelector } from "../../../../hooks/useAppSelector";
import ChildFile from "./childFile";
import {
  EntriesTree,
  RenderTreeStructure,
  ReportingPackageEntry,
} from "../../../../api/types";

import { useMemo, useState } from "react";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
  SimpleTreeView,
} from "@mui/x-tree-view";

export const ParentFile = () => {
  const extractedBasicData = useAppSelector(
    (state) => state.extract.extractedBasicData
  );

  const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      "&.Mui-selected": {
        backgroundColor: "white",
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses}`]: {
      marginLeft: 15,
      borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }));

  const tree = extractedBasicData?.reportingPackageContent?.entries.map(
    (entry: ReportingPackageEntry) => {
      const splitName = entry.name.split("/");
      const name = splitName[splitName.length - 1];
      const path = splitName.slice(0, splitName.length - 1).join("/");
      const fileKind = entry.fileType;
      const hashcode = entry.hashCode;
      return {
        name,
        path,
        fileKind,
        hashcode,
      };
    }
  );

  const treeStructure = tree?.reduce(
    (acc: RenderTreeStructure, entry: EntriesTree) => {
      // Get the path and split it into an array
      const path = entry.path.split("/");

      let current = acc;

      // Loop through each item in array and check if it exists in the current (acc) object
      for (let i = 0; i < path.length; i++) {
        // Get the name of the path
        const name = path[i];

        // If it doesnt exists in the current object, add it to the current (acc) object but with a empty children object
        if (!current[name]) {
          current[name] = {
            children: {},
          };
        }

        // if it exists, then set the current object to the children object
        current = current[name].children;
      }

      current[entry.name] = {
        fileKind: entry.fileKind,
        hashcode: entry.hashcode,
      };
      return acc;
    },
    {}
  );

  const memodTreeStructure = useMemo(() => {
    return treeStructure;
  }, [treeStructure]);

  /* Create array of strings */
  const arrayOfKeyPlusIndex: string[] = [];

  const renderItems = (tree: RenderTreeStructure | undefined) => {
    if (!tree) return null;
    return Object.keys(tree).map((key, index) => {
      const value = tree[key];
      const isFolder = value.children;
      const label = (
        <ChildFile
          fileName={key}
          fileKind={value.fileKind}
          fileHashCode={value.hashcode}
        />
      );
      return (
        /* WHEN NEW STYLEDTREEITEM IS CREATED ALL KEY_INDEX TO ARRAY OF STRINGS */
        arrayOfKeyPlusIndex.push(`${key}_${index}`),
        (
          <StyledTreeItem
            key={`${key}_${index}`}
            itemId={`${key}_${index}`}
            label={label}>
            {isFolder ? renderItems(value.children) : null}
          </StyledTreeItem>
        )
      );
    });
  };

  const [selected, setSelected] = useState<string[]>([]);

  const handleSelect = (event: React.SyntheticEvent, itemIds: string[]) => {
    setSelected(itemIds);
  };

  const TreeMemo = useMemo(() => {
    return renderItems(memodTreeStructure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memodTreeStructure]);

  return (
    <SimpleTreeView
      aria-label="customized"
      defaultExpandedItems={arrayOfKeyPlusIndex}
      multiSelect
      selectedItems={selected}
      onExpandedItemsChange={handleSelect}
      slots={{
        collapseIcon: FolderOpenIcon,
        expandIcon: FolderIcon,
        endIcon: DescriptionIcon,
      }}
      sx={{ maxWidth: "xl", overflowY: "auto" }}>
      {TreeMemo}
    </SimpleTreeView>
    // CustomizedTreeView(TreeMemo)
  );
};
