import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IApiValidationResult } from "../api/types";
import { validate } from "../api/validation";
import { filesCleared, importIdCreated } from "./importReducer";
import { extractionCleared } from "./extractionReducer";

export interface IValidationState {
  validationPending: boolean;
  validationError: boolean;
  validationResult: IApiValidationResult | null;
  validationCancelled: boolean;
}

const initialState: IValidationState = {
  validationError: false,
  validationPending: false,
  validationResult: null,
  validationCancelled: false,
};

export const startValidation = createAsyncThunk(
  "validate/validation/start",
  async (
    args: {
      calculationType: string;
      packageFile: File | Blob;
      abortSignal: AbortSignal | undefined;
    },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    let importId = state.import.importId;
    if (importId.length === 0) {
      importId = crypto.randomUUID();
      await dispatch(importIdCreated(importId));
    }
    const formData = new FormData();
    formData.append("File", args.packageFile);
    try {
      const resp = await validate(
        args.calculationType,
        importId,
        formData,
        args.abortSignal
      );
      return resp.data;
    } catch (ex) {
      if (ex === "ERR_CANCELED") {
        await dispatch(extractionCleared(null));
      }
      return rejectWithValue(ex);
    }
  }
);

export const startValidationBackground = createAsyncThunk(
  "validate/validation/startBackground",
  async (
    args: {
      calculationType: string;
      packageFile: File | Blob;
      abortSignal: AbortSignal | undefined;
    },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    let importId = state.import.importId;
    if (importId.length === 0) {
      importId = crypto.randomUUID();
      await dispatch(importIdCreated(importId));
    }
    const formData = new FormData();
    formData.append("File", args.packageFile);
    try {
      const resp = await validate(
        args.calculationType,
        importId,
        formData,
        args.abortSignal
      );
      dispatch(filesCleared(null));
      return resp.data;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  }
);

const validationSlice: Slice<IValidationState> = createSlice({
  name: "validate",
  initialState: initialState,
  reducers: {
    validationDataCleared: (state): IValidationState => {
      return {
        ...state,
        validationError: false,
        validationResult: null,
        validationCancelled: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startValidation.pending, (state): IValidationState => {
        return {
          ...state,
          validationPending: true,
          validationCancelled: false,
        };
      })
      .addCase(startValidation.fulfilled, (state, action): IValidationState => {
        return {
          ...state,
          validationPending: false,
          validationResult: action.payload,
        };
      })
      .addCase(startValidation.rejected, (state, action): IValidationState => {
        const validationCancelled = action.payload === "ERR_CANCELED";
        const newState = {
          ...state,
          validationPending: false,
          validationError: !validationCancelled,
          validationCancelled: validationCancelled,
        };
        return newState;
      })
      .addCase(startValidationBackground.pending, (state): IValidationState => {
        return {
          ...state,
          validationPending: true,
          validationError: false,
          validationResult: null,
          validationCancelled: false,
        };
      })
      .addCase(
        startValidationBackground.fulfilled,
        (state, action): IValidationState => {
          return {
            ...state,
            validationPending: false,
            validationResult: action.payload,
          };
        }
      )
      .addCase(
        startValidationBackground.rejected,
        (state): IValidationState => {
          return {
            ...state,
            validationPending: false,
            validationError: true,
          };
        }
      );
  },
});

export const { validationDataCleared } = validationSlice.actions;

export default validationSlice.reducer;
