import * as signalR from "@microsoft/signalr";
import { toCamelCase } from "./toCamelCase";

class Connector {
  private connection: signalR.HubConnection;
  private logger: signalR.ILogger;
  private token: string | undefined;
  public events: (onMessageReceived: (payload: any) => void) => void;
  public unsubscribe: () => void;
  public connectionState: signalR.HubConnectionState;
  static instance: Connector;
  constructor(
    hubUrl: string,
    messageRecievedMethodName: string,
    token: string,
    onError: (message: string) => void
  ) {
    this.token = token;
    this.logger = {
      log: (level: signalR.LogLevel, message: string) => {
        if (level > signalR.LogLevel.Debug) {
          console.log(message);
        }
        if (level > signalR.LogLevel.Warning) {
          onError(message);
        }
      },
    };
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        logger: this.logger,
        accessTokenFactory: () => this.token || "",
        withCredentials: false,
      })
      .build();
    this.connection.start();
    this.events = (onMessageReceived) => {
      this.connection.on(messageRecievedMethodName, (payload) => {
        onMessageReceived(toCamelCase(payload));
      });
    };
    this.unsubscribe = () => {
      this.connection.off(messageRecievedMethodName);
    };
    this.connectionState = this.connection.state;
  }
  public static getInstance(
    hubUrl: string,
    messageRecievedMethodName: string,
    token: string,
    onError: (message: string) => void
  ): Connector {
    if (!Connector.instance || token !== Connector.instance.token) {
      Connector.instance = new Connector(
        hubUrl,
        messageRecievedMethodName,
        token,
        onError
      );
    }

    return Connector.instance;
  }
}
export default Connector.getInstance;
