import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import DataTableColumnHeadersContainer from "./dataTableColumnHeadersContainer";
import { Order } from "../../helpers/tableSorting";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { Alert, AlertTitle, Paper, TableRow, styled } from "@mui/material";
import { Trans } from "@lingui/macro";
import { ColumnHeader } from "./dataTableColumnHeaderContainer";
import { DataDisplayObject } from "../../helpers/mapFactItems";
import { TableComponents, TableVirtuoso } from "react-virtuoso";
import DataTableCell from "./dataTableCell";
import { useMemo } from "react";

export interface DataTableColumn<T> {
  header: ColumnHeader<T>;
  key: keyof T | string;
  valueFormatter?: (row: T) => string | JSX.Element;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[300],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface DataTableProps<T extends { [x: string]: string | number }> {
  data: T[];
  columns: DataTableColumn<T>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onToggleShowFilterInput?: (headerId: string) => void;
  onSelectRow?: (rowid: string) => void;
  selectedRowIds?: string[];
  order: Order;
  orderBy: string;
  currentSelectedPage: number;
  onSelectPage: (num: number) => void;
  rowsPerPage: number;
  onSelectRowsPerPage: (count: number) => void;
  totalRowCount: number;
  containerHeight: string;
  maximumRowCount?: number;
  virtuosoRef?: any;
}

export default function DataTable<T extends DataDisplayObject>({
  data,
  columns,
  currentSelectedPage,
  onRequestSort,
  onToggleShowFilterInput,
  onSelectPage,
  onSelectRowsPerPage,
  onSelectRow,
  selectedRowIds,
  order,
  orderBy,
  rowsPerPage,
  totalRowCount,
  containerHeight,
  maximumRowCount,
  virtuosoRef,
}: DataTableProps<T>) {
  const VirtuosoTableComponents: TableComponents<T> = useMemo(() => {
    return {
      Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer
          sx={{ maxHeight: containerHeight, minHeight: containerHeight }}
          component={Paper}
          {...props}
          ref={ref}
        />
      )),
      Table: (props) => (
        <Table {...props} sx={{ borderCollapse: "separate", minWidth: 750 }} />
      ),
      TableRow: ({ item: _item, ...props }) => (
        <StyledTableRow
          {...props}
          onClick={() => onSelectRow && onSelectRow(_item.id.toString())}
          sx={[
            {
              "&:hover": {
                backgroundColor: (theme) => theme.palette.grey[400],
              },
            },
          ]}
        />
      ),
      TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
      )),
      EmptyPlaceholder: () => (
        <tbody>
          <tr>
            <td>
              <Alert
                severity="info"
                sx={{ position: "absolute", width: "100%" }}>
                <AlertTitle>
                  <Trans>No data found</Trans>
                </AlertTitle>
                <Trans>
                  Consider removing some existing filters or adding columns
                </Trans>
              </Alert>
            </td>
          </tr>
        </tbody>
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight]);

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableVirtuoso
        ref={virtuosoRef}
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => (
          <DataTableColumnHeadersContainer<T>
            columnHeaders={columns.map((column) => column.header)}
            onRequestSort={onRequestSort}
            onToggleShowFilterInput={onToggleShowFilterInput}
            order={order}
            orderBy={orderBy}
          />
        )}
        itemContent={(_, row: T) => (
          <>
            {columns.map((column) => (
              <DataTableCell
                key={`${row.id}_${column.key as string}`}
                row={row}
                isSelected={
                  selectedRowIds?.includes(row.id.toString()) || false
                }
                column={column}
              />
            ))}
          </>
        )}
      />

      <TablePagination
        component="div"
        sx={{ float: "right" }}
        rowsPerPageOptions={[
          25,
          50,
          100,
          {
            label: "All",
            value: maximumRowCount || -1,
          },
        ]}
        colSpan={3}
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={currentSelectedPage}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={(_, pageNumber) => onSelectPage(pageNumber)}
        onRowsPerPageChange={(e) =>
          onSelectRowsPerPage(parseInt(e.target.value))
        }
        ActionsComponent={TablePaginationActions}
        showFirstButton
        showLastButton
      />
    </Box>
  );
}
