import { TableCell, Typography } from "@mui/material";
import { ValidationElement } from "../../../api/types";
import { reportErrorColor } from "../../../helpers/constants";

export const TableCellSeverity = (
  title: string[] | string,
  severity: ValidationElement["severity"] | null,
  length: number,
  pdfGeneratorDataKey: string,
  leadingText?: string
) => {
  const color = reportErrorColor(severity);
  return (
    <TableCell sx={{ maxWidth: length }}>
      <Typography
        data-cellcolor={color}
        data-downloadaspdf={pdfGeneratorDataKey}
        sx={{
          fontSize: 12,
          color: color,
        }}>
        {leadingText && (
          <>
            {leadingText} <br />
          </>
        )}
        {Array.isArray(title) ? (
          title.map((text, index) => (
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 12 }}
              key={index}>
              {" "}
              {text}<br/>{" "}
            </Typography>
          ))
        ) : (
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ fontSize: 12 }}>
            {title}
          </Typography>
        )}
      </Typography>
    </TableCell>
  );
};
