import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import GeneralSidebarFactDetailViewer from "./generalSidebarFactDetailViewer";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Container } from "@mui/material";
import { t } from "@lingui/macro";
import { IApiFact } from "../../api/types";

interface SecondarySidebarDrawerBodyProps {
  currentSelectedLanguage: string;
  currentWidth: number;
}

const SecondarySidebarDrawerBody = ({
  currentSelectedLanguage,
  currentWidth,
}: SecondarySidebarDrawerBodyProps) => {
  const facts = useAppSelector((state) =>
    state.extract.factType === "standard"
      ? (state.extract.facts as IApiFact[])
      : []
  );
  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );

  const displayFact = useMemo(() => {
    return facts.find((f) => f.id === selectedFactIds[0]);
  }, [facts, selectedFactIds]);

  const packageData = useAppSelector(
    (state) => state.extract.extractedBasicData
  );
  const validationEntries = useAppSelector(
    (state) => state.validate.validationResult?.entries
  );
  const linkbaseTreeMappings = useAppSelector(
    (state) => state.extract.linkbaseTreeMappings
  );
  const [activeEntryPointHref, setActiveEntryPointHref] = useState<string>(
    linkbaseTreeMappings.find((mapping) => mapping.fromPackage)?.entryPoint
      ?.href || ""
  );

  useEffect(() => {
    if (selectedFactIds.length > 0) {
      const fact = facts.find((f) => f.id === selectedFactIds[0]);
      if (
        fact &&
        fact.entryPointHref &&
        fact.entryPointHref !== activeEntryPointHref
      ) {
        setActiveEntryPointHref(fact.entryPointHref);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactIds]);

  return (
    <Box
      sx={{
        borderRight: "2px solid #E0E0E0",
        p: 0,
        pt: 8,
        pr: 2,
        pl: 1,
        maxWidth: currentWidth,
        minWidth: currentWidth,
        height: "100%",
        overflow: "auto",
      }}>
      {!displayFact && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
          <WarningAmberIcon
            sx={{ color: "warning.main", mr: 1, fontSize: 45 }}
          />
          {t`Please select a fact to display`}
        </Container>
      )}
      {displayFact && (
        <GeneralSidebarFactDetailViewer
          validationEntries={validationEntries}
          facts={facts}
          activeEntryPoint={activeEntryPointHref}
          labeledElements={packageData?.labeledElements}
          currentSelectedLanguage={currentSelectedLanguage}
          capHeight={false}
        />
      )}
    </Box>
  );
};

export default SecondarySidebarDrawerBody;
