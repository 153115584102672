import { escapeRegexExp } from "./escapeRegExp";

export const getHighlightedText = (
  search: string,
  text: string,
  highlightColor: string,
  highlightAll: boolean
) => {
  const parts = text.split(new RegExp(`(${escapeRegexExp(search)})`, "gi"));
  return (
    <span>
      {highlightAll && (
        <span style={{ background: highlightColor }}>{text}</span>
      )}
      {!highlightAll && parts.length > 0 && (
        <>
          {" "}
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === search.toLowerCase()
                  ? { background: highlightColor }
                  : {}
              }>
              {part}
            </span>
          ))}{" "}
        </>
      )}
      {parts.length === 0 && !highlightAll && <span>{text}</span>}
    </span>
  );
};
