import { Trans } from "@lingui/macro";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { ElementTreeCompareModel, RoleTypeCompareModel } from "../../api/types";
import { getAllChangesFromCompareObject } from "../../helpers/getAllChangesFromCompareObject";

const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    border: "1px solid #dadde9",
    maxWidth: "none",
  },
}));

interface LinkbaseTreeChangedPropsTooltipProps extends PropsWithChildren {
  comparisonObject: RoleTypeCompareModel | ElementTreeCompareModel | undefined;
}

const LinkbaseTreeChangedPropsTooltip = ({
  comparisonObject,
  children,
}: LinkbaseTreeChangedPropsTooltipProps) => {
  const allChanges = useMemo(
    () => getAllChangesFromCompareObject(comparisonObject),

    [comparisonObject]
  );

  if (allChanges.length === 0) {
    return <>{children}</>;
  }
  return (
    <TableTooltip
      placement="top-start"
      title={
        <TableContainer component={Box}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Property Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Expected Value</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Actual Value</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allChanges.map((prop, index) => (
                <TableRow
                  key={`changed_props_tooltip_table_row_${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {prop.propertyName}
                  </TableCell>
                  <TableCell>{prop.expectedValue}</TableCell>
                  <TableCell>{prop.currentValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }>
      {children as any}
    </TableTooltip>
  );
};

export default LinkbaseTreeChangedPropsTooltip;
