import { ReactComponent as AbstractIcon } from "../images/icons/abstractitem_black.svg";
import { ReactComponent as HyperCubeIcon } from "../images/icons/hypercube_black.svg";
import { ReactComponent as DimensionIcon } from "../images/icons/dimension_black.svg";
import { ReactComponent as ItemIcon } from "../images/icons/item_black.svg";
import { ReactComponent as GroupIcon } from "../images/icons/group_black.svg";
import { MappedLinkbaseTreeItem } from "./mapLinkbaseTreeToTreeviewElement";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FunctionComponent, SVGProps } from "react";
import DescriptionIcon from "@mui/icons-material/Description";

export const getTaxonomyTreeviewItemIcon = (
  branch: MappedLinkbaseTreeItem
):
  | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    })
  | FunctionComponent<
      SVGProps<SVGSVGElement> & { title?: string | undefined }
    > => {
  if (branch.type === "roleType") return GroupIcon;
  if (
    branch.linkbaseTreeElement?.substitutionGroup?.toLowerCase() ===
    "xbrldt:hypercubeitem"
  )
    return HyperCubeIcon;
  if (
    branch.linkbaseTreeElement?.substitutionGroup?.toLowerCase() ===
    "xbrldt:dimensionitem"
  )
    return DimensionIcon;
  if (
    branch.linkbaseTreeElement?.substitutionGroup?.toLowerCase() ===
      "xbrli:item" &&
    !branch.linkbaseTreeElement?.abstract
  )
    return ItemIcon;
  if (branch.linkbaseTreeElement?.abstract) {
    return AbstractIcon;
  }
  return DescriptionIcon;
};
