import { t, Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import {
  Toolbar,
  Typography,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ContentHeaderToolbarAction } from "../helpers/getFactsToolbarActions";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

interface ContentHeaderToolbarProps {
  title: string;
  actions: ContentHeaderToolbarAction[];
  showIcons: boolean;
  currentFilterColumnTitle?: string;
  currentFilterValue?: any;
  showFilterInput?: boolean;
  showSelectFilter?: boolean;
  selectFilterOptions?: { value: any; label: string }[];
  onChangeFilterValue?: (value: any) => void;
  backgroundColor?: string;
  marginTop?: number;
  warningText?: string;
}

const ContentHeaderToolbar = ({
  title,
  actions,
  showIcons,
  currentFilterColumnTitle,
  currentFilterValue,
  showFilterInput,
  showSelectFilter,
  selectFilterOptions,
  onChangeFilterValue,
  backgroundColor,
  marginTop,
  warningText,
}: ContentHeaderToolbarProps) => {
  return (
    <Toolbar
      sx={{
        mt: marginTop,
        pl: { sm: 2 },
        pr: { xs: 4, sm: 4, md: 4, lg: 4, xl: 1 },
        background: backgroundColor,
      }}>
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
      {warningText && (
        <Alert sx={{ ml: 5 }} severity="warning" variant="outlined">
          {warningText}
        </Alert>
      )}
      {showFilterInput && (
        <TextField
          label={`${t`Filter by`} ${currentFilterColumnTitle}`}
          value={currentFilterValue}
          onChange={(e) =>
            onChangeFilterValue &&
            currentFilterColumnTitle &&
            onChangeFilterValue(e.target.value)
          }
          InputProps={{
            endAdornment: currentFilterValue && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear filter"
                  onClick={() => onChangeFilterValue && onChangeFilterValue("")}
                  edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: 500, ml: 2 }}
        />
      )}
      {showSelectFilter && selectFilterOptions && (
        <FormControl sx={{ flex: "0 1 20%", ml: 10 }}>
          <InputLabel variant="standard">
            <Trans>Show items</Trans>
          </InputLabel>
          <Select
            value={currentFilterValue}
            variant="standard"
            onChange={(e) =>
              onChangeFilterValue && onChangeFilterValue(e.target.value)
            }>
            {selectFilterOptions.map((option, index) => {
              return (
                <MenuItem value={option.value} key={option.value + index}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {showIcons && (
        <Box sx={{ ml: "auto", display: "flex" }}>
          {actions.map((action, index) => (
            <React.Fragment key={index}>
              {action.isDivider && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    background: (theme) => theme.palette.grey[700],
                    width: 2,
                  }}
                  key={`devider_${action.id}_${index}`}
                />
              )}
              {!action.isDivider && (
                <Tooltip title={action.title} key={action.id}>
                  <span>
                    <LoadingButton
                      sx={{
                        color: action.turnedOn
                          ? (theme) => theme.palette.info.main
                          : (theme) => theme.palette.text.secondary,
                      }}
                      disabled={action.disabled}
                      onClick={action.clickAction}
                      loading={action.loading}>
                      {action.icon && <action.icon />}
                    </LoadingButton>
                  </span>
                </Tooltip>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Toolbar>
  );
};

export default ContentHeaderToolbar;
