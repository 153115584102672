import {
  generalEpubConversionIllegalStyleDirectiveText,
  viewerReportContainerClassName,
} from "./constants";

export const handleReportFileCss = (
  document: Document,
  extenalCss: string
): { html: string; showStyleWarning: boolean } => {
  let showStyleWarning = false;
  if (extenalCss) {
    const cspTag = window.document.querySelector("meta[property]");
    const addedStyleTag = document.createElement("style") as HTMLStyleElement;
    addedStyleTag.nonce = cspTag?.getAttribute("content") || "";
    addedStyleTag.innerHTML = extenalCss;
    document.head.appendChild(addedStyleTag);
  }
  const styleElements = document.querySelectorAll("style");
  for (const styleElement of styleElements) {
    if (styleElement.sheet && styleElement.sheet.cssRules) {
      let updatedCSS = "";
      const rules = Array.from(styleElement.sheet.cssRules);
      for (const rule of rules) {
        if (rule instanceof CSSStyleRule) {
          if (
            rule.selectorText &&
            rule.selectorText !== "*" &&
            !rule.selectorText.includes("body") &&
            !rule.selectorText.includes("html")
          ) {
            if (
              !rule.selectorText.includes(".") &&
              !rule.selectorText.includes("#")
            ) {
              updatedCSS += `${viewerReportContainerClassName} ${rule.cssText} `;
            } else {
              updatedCSS += `${rule.cssText} `;
            }
          } else {
            if (
              rule.cssText !== generalEpubConversionIllegalStyleDirectiveText
            ) {
              showStyleWarning = true;
            }
            console.log(
              `the following styling rule could not be processed because it contains universal selectors. this may affect the document's visibility: ${rule.cssText}`
            );
          }
        } else if (rule instanceof CSSMediaRule) {
          let cssText = `${rule.cssText} `;
          if (cssText.includes("body") && !cssText.includes("print")) {
            if (
              rule.cssText !== generalEpubConversionIllegalStyleDirectiveText
            ) {
              showStyleWarning = true;
            }
            console.log(
              `the following media styling rule could not be processed because it contains universal selectors. this may affect the document's visibility: ${rule.cssText}`
            );
          } else {
            if (cssText.includes("#page-container")) {
              cssText = cssText.replace("bottom: 0px;", "");
              console.log(
                "removed 'bottom: 0px' directive from #page-container selector to comply with viewer"
              );
            }
            updatedCSS += cssText;
          }
        } else {
          updatedCSS += `${rule.cssText} `;
        }
      }
      styleElement.innerHTML = updatedCSS;
    }
  }
  return {
    html: new XMLSerializer().serializeToString(document),
    showStyleWarning: showStyleWarning,
  };
};
