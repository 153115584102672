import {
  filesAdded,
  fileRemoved,
  allImportActionsFinished,
  importActionsUpdated,
  importIdCreated,
  filesCleared,
  ImportAction,
  IImporterState,
  downloadedPayloadCleared,
  updateCalculationType,
} from "../reducers/importReducer";
import Dropzone from "../components/importer/dropzone";
import tokenHelper from "../helpers/tokenHelper";
import Container from "@mui/material/Container";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { emailSuffix } from "../helpers/constants";
import ImportProgressDialog from "../components/progressDialog/importProgressDialog";
import { useEffect, useState } from "react";
import { AppDispatch } from "../store";
import { useNavigate } from "react-router-dom";
import { handleNonConversionActions } from "../helpers/handleNonConversionActions";
import { getDecodedToken } from "../helpers/tokenDecoder";
import { startPolling, stopPolling } from "../helpers/pollingHelper";
import OverwriteDataDialog from "../components/importer/overwriteDataDialog";
import { useBeforeunload } from "../hooks/useBeforeUnload";
import {
  cancelConversion,
  downloadConvertedPayload,
  conversionFlagsCleared,
  getConversionStatus,
  startConversion,
  getConversionErrors,
} from "../reducers/conversionReducer";
import { validationDataCleared } from "../reducers/validationReducer";
import {
  extractionCleared,
  extractionErrorsCleared,
  extractionTypeUpdated,
  startGetReportFileHashCode,
} from "../reducers/extractionReducer";
import { Box, Grid } from "@mui/material";
import { ErrorConversionDialog } from "../components/importer/errorConversionDialog";
import { userSetTerms } from "../reducers/userManagementReducer";
import { ErrorAlertCard } from "../components/summaryComponents/errorAlertCard";
import { GetImportStatus } from "../helpers/getImportStatus";
import { compareCleared } from "../reducers/compareReducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { mappingExtractedDataCleared } from "../reducers/mappingReducer";
import { getSignalRTicket } from "../reducers/auxDataReducer";
import { startGenerateIxbrl } from "../reducers/generateReducer";

const Importer = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined);

  const navigate = useNavigate();
  const [intervalId, setIntervalId] = useState(-1);
  const [currentShouldSaveArtifacts, setCurrentShouldSaveArtifacts] =
    useState(false);
  const [showOverwriteDialog, setShowOverwriteDialog] = useState(false);
  const [showConversionErrorDialog, setShowConversionErrorDialog] =
    useState<boolean>(false);

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const conversionStatus = useAppSelector(
    (state) => state.convert.conversionStatus.status
  );

  const files = useAppSelector((state) => state.import.pendingFiles ?? []);

  const conversionFailed = useAppSelector(
    (state) => state.convert.conversionFailed
  );

  const errorCode = useAppSelector((state) => state.convert.errorCode);

  const validationPending = useAppSelector(
    (state) => state.validate.validationPending
  );
  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );

  const downloadConvertedPayloadError = useAppSelector(
    (state) => state.convert.downloadConvertedPayloadError
  );

  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const basicDataExtractionError = useAppSelector(
    (state) => state.extract.basicDataExtractionError
  );

  const generateError = useAppSelector((state) => state.generate.generateError);

  const currentImportActions = useAppSelector(
    (state) => state.import.importActions
  );

  const downloadedPayloadType = useAppSelector(
    (state) => state.import.downloadedPayloadType
  );

  const conversionStatusCheckFailed = useAppSelector(
    (state) => state.convert.conversionStatusCheckFailed
  );

  const importId = useAppSelector((state) => state.import.importId);

  const extractionPending = useAppSelector(
    (state) =>
      state.extract.basicDataExtractionPending ||
      state.extract.factExtractionPending
  );

  const showProgressDialog = useAppSelector(
    (state) =>
      state.convert.conversionStatus.status === "Running" ||
      (state.convert.conversionStatus.status === "Completed" &&
        state.import.importActions.length > 0) ||
      state.generate.generatePending ||
      state.validate.validationPending ||
      state.extract.factExtractionPending ||
      state.extract.basicDataExtractionPending
  );

  const calculationType = useAppSelector(
    (state) => state.import.calculationType
  );

  const extractionType = useAppSelector(
    (state) => state.extract.extractionType
  );

  const userAgreedToImportErrors = useAppSelector(
    (state) => state.manageUsers.userAgreedToImportErrors
  );

  const isDataLoaded = useAppSelector(
    (state) =>
      downloadedPayloadType === "viewerFile" ||
      ((state.extract.extractedBasicData !== null ||
        state.validate.validationResult !== null ||
        state.extract.facts.length > 0) &&
        (!factsExtractionError || !basicDataExtractionError))
  );

  useBeforeunload(() => {
    if (currentImportActions.some((action) => action.type === "conversion")) {
      dispatch(cancelConversion());
    }
    if (abortController) {
      abortController.abort();
    }
  });

  const isParseport =
    (tokenHelper.isValid &&
      getDecodedToken(tokenHelper.token)
        ?.email.toLowerCase()
        .includes(emailSuffix)) ||
    false;

  //used as a fallback in case the logging system fails
  useEffect(() => {
    if (conversionStatus === "Running" && importId) {
      setIntervalId(
        startPolling(() => {
          dispatch(getConversionStatus());
        }, 20)
      );
    } else {
      stopPolling(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionStatus, importId]);

  useEffect(() => {
    if (conversionStatusCheckFailed) {
      setIntervalId(
        startPolling(() => {
          dispatch(downloadConvertedPayload());
        }, 10)
      );
    } else {
      stopPolling(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionStatusCheckFailed]);

  useEffect(() => {
    dispatch(getSignalRTicket());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserImportAction = async (
    actions: ImportAction[],
    shouldSaveArtifacts?: boolean
  ): Promise<void> => {
    dispatch(importActionsUpdated(actions));
    dispatch(conversionFlagsCleared(null));
    dispatch(validationDataCleared(null));
    dispatch(extractionErrorsCleared(null));
    if (isDataLoaded) {
      setCurrentShouldSaveArtifacts(shouldSaveArtifacts || false);
      setShowOverwriteDialog(true);
    } else {
      await fireImportActions(calculationType, actions, shouldSaveArtifacts);
    }
  };

  const importStatus = GetImportStatus(
    conversionFailed,
    validationError,
    factsExtractionError,
    basicDataExtractionError,
    generateError,
    downloadConvertedPayloadError
  );

  useEffect(() => {
    if (userAgreedToImportErrors) {
      return;
    }
    if (importStatus === "success") {
      if (shouldNavigate) {
        navigate("/summary");
        setShouldNavigate(false);
      }
    } else if (importStatus === "dialog") {
      setShowConversionErrorDialog(true);
    } else if (importStatus === "fatal") {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigate, showProgressDialog]);

  const fireImportActions = async (
    calculationType?: string,
    actions?: ImportAction[],
    shouldSaveArtifacts?: boolean
  ): Promise<void> => {
    dispatch(importIdCreated(""));
    dispatch(userSetTerms(false));
    const controller = new AbortController();
    const acts = actions || currentImportActions;
    const artifacts = shouldSaveArtifacts || currentShouldSaveArtifacts;
    setAbortController(controller);
    if (acts.some((action) => action.type === "conversion")) {
      await dispatch(
        startConversion({
          calculationType: calculationType || "Calculation10",
          saveArtifacts: artifacts,
          abortSignal: controller.signal,
        })
      );
      dispatch(filesCleared(null));
    } else if (acts.some((action) => action.type === "generation")) {
      await dispatch(
        startGenerateIxbrl({
          abortSignal: controller.signal,
        })
      );
      await handleNonConversionActions(
        calculationType || "Calculation10",
        dispatch,
        acts,
        controller,
        importId,
        extractionType,
        files
      );
      dispatch(allImportActionsFinished(null));
      stopPolling(intervalId);
      if (importStatus === "success" && !showConversionErrorDialog) {
        setShouldNavigate(true);
      }
    } else {
      await handleNonConversionActions(
        calculationType || "Calculation10",
        dispatch,
        acts,
        controller,
        importId,
        extractionType,
        files
      );
      dispatch(allImportActionsFinished(null));
      stopPolling(intervalId);
      if (importStatus === "success" && !showConversionErrorDialog) {
        setShouldNavigate(true);
      }
    }
  };

  const handleImportActionCompleted = (actionType: ImportAction["type"]) => {
    if (actionType === "conversion") {
      handleConversionCompleted();
    }
  };

  const handleConversionCompleted = async () => {
    const controller = new AbortController();
    setAbortController(controller);
    const resp = (await dispatch(downloadConvertedPayload())) as PayloadAction<{
      downloadedPayloadUrl: string;
      downloadedPayloadName: string;
      downloadedPayloadType: IImporterState["downloadedPayloadType"];
    }>;
    if (resp.payload.downloadedPayloadUrl) {
      await handleNonConversionActions(
        calculationType,
        dispatch,
        currentImportActions,
        controller,
        importId,
        extractionType,
        undefined,
        resp.payload.downloadedPayloadUrl,
        resp.payload.downloadedPayloadName
      );
      await dispatch(getConversionErrors());
      if (resp.payload.downloadedPayloadType === "viewerFile") {
        await dispatch(
          startGetReportFileHashCode({
            reportFileUrl: resp.payload.downloadedPayloadUrl,
            reportFileName: resp.payload.downloadedPayloadName,
            abortSignal: controller?.signal,
          })
        );
      }
    }
    dispatch(allImportActionsFinished(null));
    stopPolling(intervalId);
    if (importStatus === "success" && !showConversionErrorDialog) {
      setShouldNavigate(true);
    }
    dispatch(updateCalculationType("Calculation10"));
  };

  const handleCloseOverwriteDataDialog = (approve: boolean) => {
    setShowOverwriteDialog(false);
    if (approve) {
      dispatch(userSetTerms(false));
      dispatch(importIdCreated(crypto.randomUUID()));
      dispatch(compareCleared(null));
      dispatch(validationDataCleared(null));
      dispatch(extractionCleared(null));
      dispatch(mappingExtractedDataCleared(null));
      dispatch(downloadedPayloadCleared(null));
      fireImportActions();
    }
  };

  const handleCancelActions = () => {
    if (currentImportActions.some((action) => action.type === "conversion")) {
      dispatch(cancelConversion());
    }
    setShowConversionErrorDialog(false);
    abortController?.abort();
    setAbortController(undefined);
    dispatch(allImportActionsFinished(null));
    stopPolling(intervalId);
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ pt: 15 }}>
        <Dropzone
          calculationType={calculationType}
          onCalculationTypeChange={(calculationType: string) => {
            dispatch(updateCalculationType(calculationType));
          }}
          files={files}
          showSaveArtifactsCheckbox={isParseport}
          isLoading={conversionStatus === "Running" || validationPending}
          onFilesAdded={(files: File[]) => {
            dispatch(filesAdded(files));
            dispatch(
              extractionTypeUpdated(
                files.length === 1 &&
                  files.some(
                    (f) =>
                      f.type === "application/xhtml+xml" ||
                      f.name
                        .split(".")
                        [f.name.split(".").length - 1].toLowerCase() === "xbrl"
                  )
                  ? "reportFile"
                  : "package"
              )
            );
          }}
          onFileRemoved={(index: number) => {
            dispatch(fileRemoved(index));
            dispatch(
              extractionTypeUpdated(
                files.length === 0
                  ? undefined
                  : files.length === 1 &&
                      files.some(
                        (f) =>
                          f.type === "application/xhtml+xml" ||
                          f.name
                            .split(".")
                            [f.name.split(".").length - 1].toLowerCase() ===
                            "xbrl"
                      )
                    ? "reportFile"
                    : "package"
              )
            );
            dispatch(conversionFlagsCleared(null));
            dispatch(validationDataCleared(null));
            dispatch(extractionErrorsCleared(null));
          }}
          onStartImportAction={handleUserImportAction}></Dropzone>
      </Container>
      <Box maxWidth={"lg"} sx={{ margin: "auto", mt: 1 }}>
        {!validationPending && !extractionPending && (
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {errorCode === 403 && ErrorAlertCard(true, "Error403", importId)}
            {factsExtractionError &&
              errorCode !== 403 &&
              basicDataExtractionError &&
              ErrorAlertCard(true, "notValid", importId)}
            {conversionFailed === true &&
              errorCode !== 403 &&
              ErrorAlertCard(true, "ProcessFiles", importId)}
            {generateError && ErrorAlertCard(true, "ProcessFiles", importId)}
          </Grid>
        )}
      </Box>
      <ErrorConversionDialog
        open={showConversionErrorDialog}
        onClose={() => {
          setShowConversionErrorDialog(false);
        }}
        onCloseAndNavigate={() => {
          setShowConversionErrorDialog(false);
          dispatch(userSetTerms(true));
          navigate("/summary");
        }}
        conversionError={conversionFailed}
        validationError={validationError}
        extractionError={factsExtractionError}
      />
      <ImportProgressDialog
        open={showProgressDialog}
        onCancel={handleCancelActions}
        onActionCompleted={handleImportActionCompleted}
      />
      <OverwriteDataDialog
        open={showOverwriteDialog}
        onClose={handleCloseOverwriteDataDialog}
      />
    </>
  );
};

export default Importer;
