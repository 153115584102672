export const groupArrayByProperty = <T>(
  arr: T[],
  prop: keyof T
): Map<string, T[]> => {
  const map = new Map();
  arr.forEach((item: T) => {
    const collection = map.get(item[prop]);
    if (!collection) {
      map.set(item[prop], [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};
