
import { gleifApiUrl } from "../helpers/constants";
import tokenStorageHelper from "../helpers/tokenHelper";
import { GleifDataArray } from "./types";

export const getGleifData = async (Id: string): Promise<GleifDataArray> => {
    try {
        const resp = await fetch(`${gleifApiUrl} + ${Id}`, { headers: { 'Accept': 'application/json' }, })
        const data = await resp.json()
        return data;
    } catch {
        throw new Error('Something went wrong')
    }
};

export const getPlainXbrlFile = async (
    importId: string,
    body: FormData,
): Promise<Blob> => {
    const resp = await fetch(
        `${process.env.REACT_APP_PROTOCOL_USE}${process.env.REACT_APP_HOST_PREFIX}${process.env.REACT_APP_HOST_USE}api/file-conversion/ixbrl2xbrl?conversionId=${importId}`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenStorageHelper.token}`,
            },
            body: body,
        }
    );
    if (resp.status !== 200) {
        throw new Error(resp.statusText);
    }
    return resp.blob();
};
