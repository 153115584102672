import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

/* Mui Icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import {
  ValidationElement,
  validationTextHelperProps,
} from "../../../api/types";
import { moreThanOneError } from "../errorShowCase";
import { Trans } from "@lingui/macro";
import {
  errorColor,
  inconsistencyColor,
  successColor,
  warningColor,
} from "../../../helpers/constants";

export const ValidationTextHelper = (props: validationTextHelperProps) => {
  const IconChecker = (status: any) => {
    var severity = "";
    /* get array of status and check each one  */
    try {
      status.forEach((element: ValidationElement) => {
        if (element.severity === "FatalError") {
          severity = "FatalError";
        } else if (element.severity === "Error") {
          severity = "Error";
        } else if (element.severity === "Warning") {
          if (severity !== "Error") {
            severity = "Warning";
          }
        } else if (element.severity === "Inconsistency") {
          if (severity !== "Error" && severity !== "Warning") {
            severity = "Inconsistency";
          }
        } else {
          severity = "Success";
        }
      });
    } catch {
      severity = "Failed";
    }

    return (
      <>
        {severity === "FatalError" ? (
          <ErrorIcon sx={{ color: errorColor, mr: 1 }} />
        ) : severity === "Error" ? (
          <ErrorIcon sx={{ color: errorColor, mr: 1 }} />
        ) : severity === "Warning" ? (
          <WarningIcon sx={{ color: warningColor, mr: 1 }} />
        ) : severity === "Inconsistency" ? (
          <InfoIcon sx={{ color: inconsistencyColor, mr: 1 }} />
        ) : (
          <CheckCircleIcon sx={{ color: successColor, mr: 1 }} />
        )}
      </>
    );
  };

  const ValidationSummaryText = (status: any) => {
    var FatalErrors = 0;
    var errors = 0;
    var warnings = 0;
    var inconsistencies = 0;
    var severity = "";

    /* get array of status and check each one  */
    try {
      status.forEach((element: any) => {
        if (element.severity === "FatalError") {
          FatalErrors++;
          severity = "FatalError";
        } else if (element.severity === "Error") {
          errors++;
          if (severity !== "FatalError") {
            severity = "Error";
          }
        } else if (element.severity === "Warning") {
          warnings++;
          if (severity !== "Error") {
            severity = "Warning";
          }
        } else if (element.severity === "Inconsistency") {
          inconsistencies++;
          if (severity !== "Error" && severity !== "Warning") {
            severity = "Inconsistency";
          }
        }
      });
    } catch {
      severity = "Failed";
    }
    return (
      <>
        {FatalErrors > 0 ||
        errors > 0 ||
        warnings > 0 ||
        inconsistencies > 0 ? (
          <>
            {FatalErrors > 0 && (
              <Typography
                sx={{ color: errorColor, pr: 0.5, pl: 0.5 }}
                display="inline"
                data-downloadaspdf="validationsummaryvalue">
                {" "}
                {moreThanOneError(FatalErrors, "FatalError")}
              </Typography>
            )}
            {FatalErrors > 0 && errors === 0 && warnings !== 0 && (
              <Typography
                data-data-downloadaspdf="validationsummaryvalueAnd"
                sx={{ pl: 0.5, pr: 0.5 }}>
                &
              </Typography>
            )}
            {FatalErrors > 0 && errors !== 0 && (
              <Typography
                data-data-downloadaspdf="validationsummaryvalueAnd"
                sx={{ pl: 0.5, pr: 0.5 }}>
                &
              </Typography>
            )}

            {FatalErrors > 0 &&
              errors === 0 &&
              warnings === 0 &&
              inconsistencies !== 0 && (
                <Typography
                  data-data-downloadaspdf="validationsummaryvalueAnd"
                  sx={{ pl: 0.5, pr: 0.5 }}>
                  &
                </Typography>
              )}

            {errors > 0 ? (
              <Typography
                sx={{ color: errorColor, pl: 0.5, pr: 0.5 }}
                display="inline"
                data-downloadaspdf="validationsummaryvalue">
                {" "}
                {moreThanOneError(errors, "Error")}
              </Typography>
            ) : null}
            {errors > 0 && warnings > 0 && (
              <Typography
                data-downloadaspdf="validationsummaryvalueAnd"
                sx={{ pl: 0.5, pr: 0.5 }}>
                &
              </Typography>
            )}
            {warnings > 0 && (
              <Typography
                sx={{ color: warningColor, pl: 0.5, pr: 0.5 }}
                display="inline"
                data-downloadaspdf="validationsummaryvalueSecond">
                {moreThanOneError(warnings, "Warning")}
              </Typography>
            )}

            {warnings > 0 && inconsistencies !== 0 ? (
              <Typography
                data-data-downloadaspdf="validationsummaryvalueAnd"
                sx={{ pl: 0.5, pr: 0.5 }}>
                &
              </Typography>
            ) : (
              <>
                {errors > 0 && inconsistencies !== 0 ? (
                  <Typography
                    data-data-downloadaspdf="validationsummaryvalueAnd"
                    sx={{ pl: 0.5, pr: 0.5 }}>
                    &
                  </Typography>
                ) : null}
              </>
            )}
            {inconsistencies > 0 && (
              <Typography
                sx={{ color: inconsistencyColor, pl: 0.5, pr: 0.5 }}
                display="inline"
                data-downloadaspdf="validationsummaryvalueSecond">
                {" "}
                {moreThanOneError(inconsistencies, "Inconsistency")}
              </Typography>
            )}
          </>
        ) : null}
        {warnings === 0 && errors === 0 && inconsistencies === 0 && (
          <Typography
            sx={{ color: successColor, pr: 0.5, pl: 0.5 }}
            display="inline"
            data-downloadaspdf="validationsummaryvalue"
            title="Ok">
            <Trans> Ok </Trans>
          </Typography>
        )}
        {warnings !== 0 ||
          errors !== 0 ||
          (inconsistencies !== 0 && (
            <>
              <Typography
                sx={{ color: successColor, pr: 0.5, pl: 0.5 }}
                display="inline"
                data-downloadaspdf="validationsummaryvalue"></Typography>
            </>
          ))}

        {errors > 0 && warnings === 0 && inconsistencies === 0 && (
          <Typography
            data-downloadaspdf="validationsummaryvalueAnd"
            sx={{ pl: 0.5, pr: 0.5 }}></Typography>
        )}

        {errors === 0 && warnings > 0 && (
          <Typography
            data-downloadaspdf="validationsummaryvalueAnd"
            sx={{ pl: 0.5, pr: 0.5 }}></Typography>
        )}

        {errors === 0 && warnings === 0 && inconsistencies > 0 && (
          <Typography
            data-downloadaspdf="validationsummaryvalueAnd"
            sx={{ pl: 0.5, pr: 0.5 }}></Typography>
        )}

        {errors === 0 && warnings > 0 && (
          <Typography
            sx={{ color: successColor, pr: 0.5, pl: 0.5 }}
            display="inline"
            data-downloadaspdf="validationsummaryvalue"></Typography>
        )}

        {warnings === 0 && errors === 0 && (
          <Typography
            sx={{ color: warningColor, pl: 0.5, pr: 0.5 }}
            display="inline"
            data-downloadaspdf="validationsummaryvalueSecond"></Typography>
        )}
      </>
    );
  };
  return (
    <Box
      maxWidth={"xl"}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}>
      {IconChecker(props.IconText)}
      <Typography data-downloadaspdf="validationsummarykey">
        {props.ActionText}
      </Typography>
      {ValidationSummaryText(props.SummaryText)}
    </Box>
  );
};
