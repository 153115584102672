import * as DOMPurify from "dompurify";
import { allowedXBRLTags } from "./constants";

export const addReportSanitizationHooks = (): void => {
  DOMPurify.addHook("uponSanitizeElement", (node, event) => {
    if (
      node.nodeName &&
      allowedXBRLTags.filter((allowedTagName) =>
        node.nodeName.toLowerCase().includes(allowedTagName.toLowerCase())
      ).length > 0
    ) {
      event.allowedTags[event.tagName] = true;
    }
  });
};

const addTableSanitizationHook = (): void => {
  let counter = 1;
  DOMPurify.addHook("uponSanitizeElement", (node) => {
    if (node.nodeName) {
      if (node.nodeName.toLowerCase() === "table") {
        node.setAttribute("class", "inline-fact-html-table");
      } else if (node.nodeName.toLowerCase() === "tr") {
        if (counter % 2 === 0) {
          node.setAttribute("class", "inline-fact-html-table-odd-row");
        }
        counter++;
      } else if (node.nodeName.toLowerCase() === "td") {
        node.setAttribute("class", "inline-fact-html-table-cell");
      }
    }
  });
};

export const getSanitizeReportHtml = (dirtyHtml: string): string => {
  if (!dirtyHtml) return "";
  return DOMPurify.sanitize(dirtyHtml, {
    FORCE_BODY: true,
    ADD_ATTR: [
      "contextref",
      "continuedat",
      "fromrefs",
      "torefs",
      "associatedfactid",
      "nodetype",
      "nestedinteractivenode",
      "xbrlelementtype",
    ],
  });
};

export const getSanitizedFactValue = (dirtyHtml: string): string => {
  DOMPurify.removeAllHooks();
  addTableSanitizationHook();
  const sanitized = DOMPurify.sanitize(dirtyHtml);
  DOMPurify.removeAllHooks();
  return sanitized;
};
