export interface MarkedArea {
  id: string;
  label: string;
}

export const getDebugReportMarkedAreasDistinct = (
  html: string
): MarkedArea[] => {
  const output: MarkedArea[] = [];
  if (html === "") return output;
  const doc = new DOMParser().parseFromString(html, "text/html");
  const areaElems = Array.from(doc.querySelectorAll("[data-debug-area-id]"));
  if (areaElems.length === 0) return output;
  const allIds = areaElems.flatMap(
    (area) => (area as HTMLElement).dataset.debugAreaId?.split(";")
  );
  for (const areaElem of areaElems) {
    const valuesAsString = (areaElem as HTMLElement).dataset.debugAreaId;
    const values = valuesAsString?.split(";") || [];
    if (
      values.every((value) => allIds.filter((id) => id === value).length === 2)
    ) {
      for (const value of values) {
        const valueArr = value.split("#-#");
        if (valueArr.length === 2) {
          output.push({
            id: valueArr[1],
            label: valueArr[0].replaceAll("_", " "),
          });
        }
      }
    }
  }
  return output.filter(
    (ma, i, arr) => arr.map((x) => x.id).indexOf(ma.id) === i
  );
};
