import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../components/errorAlert";
import LoginContainer from "../components/login/loginContainer";
import Navbar from "../components/navbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import LoginBackground from "../components/login/loginBackground";
import isValidEmail from "../helpers/isValidEmail";

/* Translation of Text */
import { Trans, t } from "@lingui/macro";
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@mui/material";
import {
  getOrganizationLeiCodeValidationError,
  getValidationError,
  validateEmail,
} from "../helpers/validateOrganization";
import { freeSignUpUser } from "../api/accounts";
import { countryCodes, termsOfServiceLink } from "../helpers/constants";

const invalidEmail = new Error("Invalid Email");
const missingField = new Error("Missing Field");

// These errors are handled by the form, so we don't need to show extra alerts
const suppressAlerts: Array<Error | null> = [invalidEmail, missingField];

interface FormState {
  name: string;
  email: string;
  organization: string;
  country: string;
  registrationNumber: string;
  leiCode: string | null;
  acceptTerms: boolean;
}

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [signupError, setSignupError] = useState<Error | null>(null);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: "error" | "info" | "success" | "warning" | undefined;
  }>({
    message: "",
    severity: undefined,
  });

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [registrationNumberError, setRegistrationNumberError] = useState("");
  const [leiCodeError, setLeiCodeError] = useState("");

  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    organization: "",
    country: "",
    registrationNumber: "",
    leiCode: null,
    acceptTerms: false,
  });

  const handleFieldValueChange = (
    key: keyof FormState | string | undefined,
    value: string | boolean
  ) => {
    if (key) {
      const updated = { ...formState };
      (updated as any)[key] = value;
      setFormState(updated as FormState);
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    if (
      !formState.name ||
      !formState.email ||
      !formState.organization ||
      !formState.country ||
      !formState.registrationNumber ||
      !formState.acceptTerms ||
      !isValidEmail(formState.email)
    ) {
      setSignupError(missingField);
      return;
    } else {
      setSignupError(null);
      setIsLoading(true);
      try {
        await freeSignUpUser(
          formState.name,
          formState.email,
          formState.organization,
          formState.country,
          formState.registrationNumber,
          formState.leiCode || null
        );
        setAlertMessage({
          message: t`Thank you for signup up to the ParsePort Platform. You should receive an email shortly with instructions to finalize your account.`,
          severity: "success",
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (ex) {
        setSignupError(ex as any);
      }
      setIsLoading(false);
    }
  };

  return (
    <LoginBackground>
      <Navbar />
      <LoginContainer
        maxWidthSize="sm"
        sx={{ pt: 15 }}
        additionalButton={
          <>
            <Button fullWidth onClick={() => navigate("/login")}>
              <Trans>Back To Login</Trans>
            </Button>
          </>
        }>
        <ErrorAlert
          formError={suppressAlerts.includes(signupError) ? null : signupError}
        />
        <TextField
          margin="normal"
          fullWidth
          id="name"
          label={t`Name` + "*"}
          name="name"
          autoComplete="name"
          onChange={(e) => {
            let error = "";
            if (e.target.value) {
              error = getValidationError(e.target.value, 1, 256);
            }
            setNameError(error);
            handleFieldValueChange("name", e.target.value);
          }}
          error={!!nameError || signupError === missingField}
          helperText={nameError}
          autoFocus
        />
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label={t`Email` + "*"}
          name="email"
          autoComplete="email"
          helperText={emailError}
          onChange={(e) => {
            if (!validateEmail(e.target.value)) {
              setEmailError("Please enter a valid email address.");
            } else {
              setEmailError("");
            }
            handleFieldValueChange("email", e.target.value);
          }}
          error={!!emailError || signupError === invalidEmail}
        />
        <TextField
          margin="normal"
          fullWidth
          id="organization"
          label={t`Organization` + "*"}
          name="organization"
          autoComplete="organization"
          helperText={organizationError}
          onChange={(e) => {
            let error = "";
            if (e.target.value) {
              error = getValidationError(e.target.value, 1, 256);
            }
            setOrganizationError(error);
            handleFieldValueChange("organization", e.target.value);
          }}
          error={!!organizationError || signupError === missingField}
        />
        <FormControl fullWidth sx={{ pt: 2, pb: 1 }}>
          <Autocomplete
            id="country-combo-box"
            options={countryCodes.sort((a, b) =>
              a.country.localeCompare(b.country)
            )}
            getOptionLabel={(option) => option.country}
            renderOption={(props, option) => (
              <li {...props}>{option.country}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t`Country` + "*"}
                variant="outlined"
              />
            )}
            onInputChange={(event, newInputValue) => {
              handleFieldValueChange(
                "country",
                countryCodes.find(
                  (country) => country.country === newInputValue
                )?.code || ""
              );
            }}
          />
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}>
          <TextField
            margin="normal"
            fullWidth
            id="registrationNumber"
            label={t`Registration Number` + "*"}
            name="registrationNumber"
            autoComplete="registrationNumber"
            helperText={
              registrationNumberError
                ? registrationNumberError
                : "Company registration number"
            }
            onChange={(e) => {
              let error = "";
              if (e.target.value) {
                error = getValidationError(e.target.value, 1, 32);
              }
              setRegistrationNumberError(error);
              handleFieldValueChange("registrationNumber", e.target.value);
            }}
            error={!!registrationNumberError || signupError === missingField}
          />
          <Box sx={{ m: 1 }} />
          <TextField
            margin="normal"
            fullWidth
            id="leiCode"
            label={t`LEI Code`}
            name="leiCode"
            onChange={(e) => {
              let error = "";
              if (e.target.value) {
                error = getOrganizationLeiCodeValidationError(e.target.value);
              }
              setLeiCodeError(error);
              handleFieldValueChange("leiCode", e.target.value);
            }}
            error={!!leiCodeError || signupError === missingField}
          />
        </Box>
        <FormControlLabel
          sx={{
            mt: 1,
            mb: 2,
          }}
          control={
            <Checkbox id="acceptTerms" name="acceptTerms" color="primary" />
          }
          onChange={(e) => {
            handleFieldValueChange("acceptTerms", !formState.acceptTerms);
          }}
          label={
            <Trans>
              Accept{" "}
              <a
                href={termsOfServiceLink}
                target="_blank"
                rel="noopener noreferrer">
                Terms
              </a>
              *
            </Trans>
          }
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            isLoading ||
            !formState.name ||
            !formState.email ||
            !formState.organization ||
            !formState.country ||
            !formState.registrationNumber ||
            !formState.acceptTerms ||
            !isValidEmail(formState.email)
          }
          onClick={handleSubmit}
          loading={isLoading}
          sx={{ mb: 2, height: 45 }}>
          <Trans>Sign Up</Trans>
        </LoadingButton>
      </LoginContainer>
      <Snackbar
        open={
          alertMessage.message !== "" && alertMessage.severity !== undefined
        }
        autoHideDuration={3000}
        onClose={() => setAlertMessage({ message: "", severity: undefined })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity={alertMessage.severity}>{alertMessage.message}</Alert>
      </Snackbar>
    </LoginBackground>
  );
};

export default Signup;
