import { t } from "@lingui/macro";
import { ContentHeaderToolbarAction } from "./getFactsToolbarActions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import BugReportIcon from "@mui/icons-material/BugReport";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import LanguageIcon from "@mui/icons-material/Language";
import { ToolbarActions } from "./constants";
import { IExtractionState } from "../reducers/extractionReducer";

export const getViewerToolbarActions = (
  iconClickHandler: (e: any, action: ToolbarActions) => void,
  disableHighlightErrors: boolean,
  enableFactRelatedFuncitonlaity: boolean,
  currentTableLanguages: string[],
  zoomScale: number,
  highlightAllFacts: boolean,
  highlightErrors: boolean,
  isParserportAdmin: boolean,
  showDebugView: boolean,
  extractedFactsType: IExtractionState["factType"]
): ContentHeaderToolbarAction[] => {
  const output: ContentHeaderToolbarAction[] = [
    {
      id: ToolbarActions.zoomIn,
      title: t`Zoom in`,
      icon: ZoomInIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.zoomIn),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.zoomOut,
      title: t`Zoom out`,
      icon: ZoomOutIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.zoomOut),
      disabled: zoomScale <= 1.1,
      turnedOn: false,
    },
    {
      id: ToolbarActions.zoomReset,
      title: t`Zoom reset`,
      icon: SearchOffIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.zoomReset),
      disabled: zoomScale === 1,
      turnedOn: false,
    },
    {
      isDivider: true,
    },
    {
      id: ToolbarActions.previousFact,
      title: t`Previous fact`,
      icon: SkipPreviousOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.previousFact),
      disabled: !enableFactRelatedFuncitonlaity,
      turnedOn: false,
    },
    {
      id: ToolbarActions.nextFact,
      title: t`Next fact`,
      icon: SkipNextOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.nextFact),
      disabled: !enableFactRelatedFuncitonlaity,
      turnedOn: false,
    },
    {
      isDivider: true,
    },
    {
      id: ToolbarActions.highlightErrors,
      title: t`Highlight errors`,
      icon: ReportOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.highlightErrors),
      turnedOn: highlightErrors,
      disabled: disableHighlightErrors,
    },
    {
      id: ToolbarActions.highlightFacts,
      title: t`Highlight facts`,
      icon: FactCheckOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.highlightFacts),
      disabled: !enableFactRelatedFuncitonlaity,
      turnedOn: highlightAllFacts,
    },
    {
      isDivider: true,
    },
    {
      id: ToolbarActions.selectLanguage,
      title: t`Select report language`,
      icon: LanguageIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.selectLanguage),
      disabled: !currentTableLanguages || currentTableLanguages.length < 2,
      turnedOn: false,
    },
    {
      id: ToolbarActions.downloadReport,
      title: t`Download report`,
      icon: DownloadOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.downloadReport),
      disabled: false,
      turnedOn: false,
    },
    {
      id: ToolbarActions.showSidebar,
      title: t`Show sidebar`,
      icon: ViewSidebarOutlinedIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.showSidebar),
      disabled:
        !enableFactRelatedFuncitonlaity || extractedFactsType === "eurofiling",
      turnedOn: false,
    },
  ];
  if (isParserportAdmin) {
    output.push({
      id: ToolbarActions.showDebugView,
      title: t`Show debug view`,
      icon: BugReportIcon,
      clickAction: (e) => iconClickHandler(e, ToolbarActions.showDebugView),
      turnedOn: showDebugView,
    });
  }
  return output;
};
