import { GlobalStyles } from "@mui/material";

export const reportReplacedXBRLNodeCustomStylesClassNames = {
  selected: "xbrl-node-selected",
  highlighted: "xbrl-node-highlighted",
  nestedHighlighted: "xbrl-node-highlighted-nested",
  extensionHighlighted: "xbrl-node-highlighted-extension",
  errorHighlighted: "xbrl-node-highlighted-error",
  warningHighlighted: "xbrl-node-highlighted-warning",
  inconsistencyHighlighted: "xbrl-node-highlighted-inconsistency",
  borderCalculation: "xbrl-node-border-calculation",
};

export const reportReplacedXBRLNodeCustomStyles = (
  <GlobalStyles
    styles={(theme) => ({
      ".xbrl-node-selected": {
        backgroundColor: `${theme.palette.info.light} !important`,
      },
      ".xbrl-node-selected *": {
        backgroundColor: `${theme.palette.info.light} !important`,
      },
      ".xbrl-node-highlighted-nested": {
        backgroundColor: "#CBC3E3",
      },
      ".xbrl-node-highlighted-nested *": {
        backgroundColor: "#CBC3E3",
      },
      ".xbrl-node-highlighted": {
        backgroundColor: theme.palette.success.light,
      },
      ".xbrl-node-highlighted *": {
        backgroundColor: theme.palette.success.light,
      },
      ".xbrl-node-highlighted.xbrl-node-highlighted-nested *": {
        backgroundColor: "#CBC3E3",
      },
      ".xbrl-node-highlighted.xbrl-node-highlighted-nested ": {
        backgroundColor: "#CBC3E3",
      },
      ".xbrl-node-highlighted-extension": {
        backgroundColor: theme.palette.secondary.light,
      },
      ".xbrl-node-highlighted-extension *": {
        backgroundColor: theme.palette.secondary.light,
      },
      ".xbrl-node-highlighted-error": {
        backgroundColor: theme.palette.error.light,
      },
      ".xbrl-node-highlighted-error *": {
        backgroundColor: theme.palette.error.light,
      },
      ".xbrl-node-highlighted-warning": {
        backgroundColor: `${theme.palette.warning.light} !important`,
      },
      ".xbrl-node-highlighted-warning *": {
        backgroundColor: theme.palette.warning.light,
      },
      ".xbrl-node-highlighted-inconsistency": {
        backgroundColor: "dodgerblue",
      },
      ".xbrl-node-highlighted-inconsistency *": {
        backgroundColor: "dodgerblue",
      },
      ".xbrl-node-border-calculation": {
        borderColor: "#68cc69",
      },
      ".xbrl-node-border-calculation *": {
        borderColor: "#68cc69",
      },
    })}
  />
);
