export const navigateToTreeviewNode = (partialId: string): void => {
  const elem = document.querySelector(`li[id*="${partialId}"]`);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};

export const navigateToFactByAttribute = (
  dataKey: string,
  value: string,
  block: ScrollLogicalPosition
): void => {
  const elem = document.querySelector(`[${dataKey}="${value}"]`);
  if (elem) {
    elem.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest",
    });
  }
};
