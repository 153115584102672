import {
  DisplayLabel,
  ExtractedElementTree,
  LabeledElement,
  ExtractedLinkbaseTree,
  TaxonomyElementLabel,
  TaxonomyFactElement,
  TaxonomyLabeledSubElement,
  ExtractedRoleType,
  IApiFact,
} from "../api/types";
import { defaultLabelTypeUri } from "./constants";

const findDisplayLabelInTreeRecursive = (
  tree: ExtractedElementTree,
  factElement: TaxonomyFactElement | TaxonomyLabeledSubElement,
  lang: string
): DisplayLabel | undefined => {
  let output;
  if (
    tree.linkbaseTreeElement?.namespaceUri === factElement.namespace?.uri &&
    tree.linkbaseTreeElement?.elementId === factElement.elementId
  ) {
    output = tree.linkbaseTreeElement.displayLabels.find(
      (dl) => dl.language === lang
    );
    if (!output) {
      output = tree.linkbaseTreeElement.displayLabels.find(
        (dl) => dl.language === "en"
      );
    }
    return output;
  } else if (tree.children && tree.children.length > 0) {
    for (const childTree of tree.children) {
      output = findDisplayLabelInTreeRecursive(childTree, factElement, lang);
      if (output) break;
    }
  }
  return output;
};

export const getFactDisplayLabelFromRoleType = (
  factElement: TaxonomyFactElement | TaxonomyLabeledSubElement | undefined,
  roleType: ExtractedRoleType,
  lang: string
): DisplayLabel | undefined => {
  if (!factElement || !roleType) return undefined;
  let output;
  for (const tree of roleType.elementTrees || []) {
    output = findDisplayLabelInTreeRecursive(tree, factElement, lang);
    if (output) break;
  }
  return output;
};

export const getFactDisplayLabelFromLinkbaseTree = (
  factElement: TaxonomyFactElement | TaxonomyLabeledSubElement | undefined,
  presentationTree: ExtractedLinkbaseTree | undefined,
  lang: string
): DisplayLabel | undefined => {
  if (!factElement || !presentationTree) return undefined;
  let output;
  for (const roleType of presentationTree.roleTypes || []) {
    for (const tree of roleType.elementTrees || []) {
      output = findDisplayLabelInTreeRecursive(tree, factElement, lang);
      if (output) break;
    }
    if (output) break;
  }
  return output;
};

export const getFactDisplayLabelFromLebeledElements = (
  factElement: TaxonomyFactElement | TaxonomyLabeledSubElement | undefined,
  labeledElements: LabeledElement[] | undefined,
  lang: string
): DisplayLabel | undefined => {
  if (!factElement || !labeledElements || labeledElements.length === 0)
    return undefined;
  let taxonomyElementLabel: TaxonomyElementLabel | undefined;
  let labeledElement = labeledElements.find(
    (labeledElement) =>
      labeledElement.element.namespace.uri === factElement.namespace.uri &&
      labeledElement.element.elementId === factElement.elementId
  );
  if (labeledElement) {
    taxonomyElementLabel = labeledElement.labels.find(
      (label) => label.lang === lang && label.type === defaultLabelTypeUri
    );
    if (!taxonomyElementLabel) {
      taxonomyElementLabel = labeledElement.labels.find(
        (label) => label.lang === "en" && label.type === defaultLabelTypeUri
      );
    }
    if (!taxonomyElementLabel) {
      taxonomyElementLabel = labeledElement.labels.find(
        (label) => label.lang === lang
      );
    }
    if (!taxonomyElementLabel) {
      taxonomyElementLabel = labeledElement.labels.find(
        (label) => label.lang === "en"
      );
    }
  }
  if (taxonomyElementLabel) {
    return {
      label: taxonomyElementLabel.value,
      language: taxonomyElementLabel.lang,
      role: taxonomyElementLabel.type,
    };
  }
  return undefined;
};

export const getFactDisplayLabelWithoutLabeledElements = (
  displayFact: IApiFact | undefined,
): DisplayLabel | undefined => {
  if (!displayFact) return undefined;
  return {
    label: displayFact?.factElement?.label?.value || "",
    language: displayFact?.factElement?.label?.lang || "",
    role: displayFact?.factElement?.label?.type || "",
  };
}