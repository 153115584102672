import { Button } from "@mui/material";

interface ButtonMailTypeProps {
  mailto: string;
  label: string;
}

export const ButtonMailto = ({ mailto, label }: ButtonMailTypeProps) => {
  return (
    <Button
      sx={{ mt: 1, display: "flex", alignItems: "center", color: "grey-" }}
      variant="outlined"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}>
      {label}
    </Button>
  );
};

export const LinkMailto = ({ mailto, label }: ButtonMailTypeProps) => {
  return <a href={`${mailto}`}>{label}</a>;
};
