import { Trans } from "@lingui/macro";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";

export interface OverwriteDataDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
}

const OverwriteDataDialog = ({ open, onClose }: OverwriteDataDialogProps) => (
  <Dialog open={open} onClose={() => onClose(false)}>
    <DialogTitle>
      <Trans>Are you sure?</Trans>
    </DialogTitle>
    <DialogContent>
      <Trans>
      Starting a new import process will overwrite all existing imported data
      </Trans>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>
        <Trans>No</Trans>
      </Button>
      <Button
        onClick={() => {
          onClose(true);
        }}>
        <Trans>Yes</Trans>
      </Button>
    </DialogActions>
  </Dialog>
);

export default OverwriteDataDialog;
