import { Trans } from "@lingui/macro";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Alert,
  AlertColor,
  Popover,
} from "@mui/material";
import { useMemo, useState } from "react";
import { IApiFact, ValidationElement } from "../../api/types";
import { getValidationErrorsByFact } from "../../helpers/mapValidationErrorsToFacts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getAlertSeverity = (
  level: ValidationElement["severity"]
): AlertColor | undefined => {
  if (level === "Info") return "info";
  if (level === "Warning") return "warning";
  if (!level) return undefined;
  return "error";
};

export interface GeneralSidebarFactValidationErrorDisplayViewerProps {
  validationEntries: ValidationElement[] | undefined;
  fact: IApiFact;
}

const GeneralSidebarFactValidationErrorDisplayViewer = ({
  fact,
  validationEntries,
}: GeneralSidebarFactValidationErrorDisplayViewerProps) => {
  const errors = useMemo(
    () => getValidationErrorsByFact(fact, validationEntries),
    [fact, validationEntries]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentPopoverText, setCurrentPopoverText] = useState("");

  const handleClick = (event: any, text: string) => {
    setAnchorEl(event.currentTarget);
    setCurrentPopoverText(text);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentPopoverText("");
  };

  if (errors.length === 0) return null;
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          id="viewer_sidebar_fact_error_details_header"
          expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <Trans>Errors (click to see details)</Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errors.map((error) => (
            <Alert
              onClick={(e) => handleClick(e, error.message as string)}
              sx={{ mb: 1, cursor: "default" }}
              variant="outlined"
              severity={getAlertSeverity(error.severity)}
              key={`alert_validation_error_${fact.id}_${error.id}`}>
              {error.code}
            </Alert>
          ))}
        </AccordionDetails>
      </Accordion>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Typography sx={{ p: 2, width: "400px" }}>
          {currentPopoverText}
        </Typography>
      </Popover>
    </>
  );
};

export default GeneralSidebarFactValidationErrorDisplayViewer;
