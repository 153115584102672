import {
  ExtractedElementTree,
  ExtractedLinkbaseTree,
  ExtractedRoleType,
} from "../api/types";

const findBranchById = (
  branch: ExtractedRoleType | ExtractedElementTree,
  branchId: string
): ExtractedRoleType | ExtractedElementTree | undefined => {
  if (branch.id === branchId) return branch;
  const children =
    (branch as ExtractedRoleType).elementTrees ||
    (branch as ExtractedElementTree).children;
  if (children && children.length > 0) {
    for (const subBranch of children) {
      const found = findBranchById(subBranch, branchId);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const getLinkbaseTreeBranchById = (
  allTrees: ExtractedLinkbaseTree[] | undefined,
  treeId: string,
  roleTypeId: string,
  branchId?: string
): ExtractedElementTree | ExtractedRoleType | undefined => {
  if (!allTrees || allTrees.length === 0) return undefined;
  const tree = allTrees.find((tree) => tree.id === treeId);
  const roleType = tree?.roleTypes?.find((rt) => rt.id === roleTypeId);
  if (roleType) {
    if (!branchId) {
      return roleType;
    } else {
      return findBranchById(roleType, branchId);
    }
  }
  return undefined;
};
