import { createSlice, Slice } from "@reduxjs/toolkit";

export interface ImportAction {
  type: "conversion" | "validation" | "extraction" | "generation";
  dataType:
    | "zipPackage"
    | "pdfOnly"
    | "pdfExcel"
    | "excelOnly"
    | "xhtmlOnly"
    | "epubExcel"
    | "epubOnly"
    | "xbrlOnly";
}

export interface IImporterState {
  importActions: ImportAction[];
  pendingFiles: File[];
  uploadedFileNames: string[];
  importId: string;
  downloadedPayloadUrl: string;
  downloadedPayloadName: string;
  downloadedPayloadType: "package" | "viewerFile" | "";
  calculationType: string;
}

const initialState: IImporterState = {
  importActions: [],
  pendingFiles: [],
  uploadedFileNames: [],
  importId: "",
  downloadedPayloadUrl: "",
  downloadedPayloadName: "",
  downloadedPayloadType: "",
  calculationType: "Calculation10",
};

const importSlice: Slice<IImporterState> = createSlice({
  name: "import",
  initialState: initialState,
  reducers: {
    filesAdded: (state, action): IImporterState => {
      const filesToAdd = action.payload.filter(
        (f1: File) =>
          !state.pendingFiles.some((f2: File) => f1.name === f2.name)
      );
      const allFiles: File[] = [...state.pendingFiles, ...filesToAdd];
      return {
        ...state,
        pendingFiles: allFiles,
      };
    },
    uploadedFileNamesUpdated: (state, action): IImporterState => {
      return {
        ...state,
        uploadedFileNames: action.payload,
      };
    },
    filesCleared: (state): IImporterState => {
      return {
        ...state,
        pendingFiles: [],
      };
    },
    fileRemoved: (state, action): IImporterState => {
      const cloneFiles = [...state.pendingFiles];
      cloneFiles.splice(action.payload, 1);
      return {
        ...state,
        pendingFiles: cloneFiles,
      };
    },
    importIdCreated: (state, action): IImporterState => {
      return { ...state, importId: action.payload };
    },
    importActionsUpdated: (state, action): IImporterState => {
      return {
        ...state,
        importActions: action.payload,
      };
    },
    downloadedPayloadRetrieved: (
      state,
      action: {
        payload: {
          downloadedPayloadUrl: string;
          downloadedPayloadName: string;
          downloadedPayloadType: IImporterState["downloadedPayloadType"];
        };
      }
    ): IImporterState => {
      return {
        ...state,
        downloadedPayloadUrl: action.payload.downloadedPayloadUrl,
        downloadedPayloadName: action.payload.downloadedPayloadName,
        downloadedPayloadType: action.payload.downloadedPayloadType,
      };
    },
    downloadedPayloadCleared: (state): IImporterState => {
      return {
        ...state,
        downloadedPayloadUrl: "",
        downloadedPayloadName: "",
        downloadedPayloadType: "package",
      };
    },
    allImportActionsFinished: (state): IImporterState => {
      return {
        ...state,
        importActions: [],
      };
    },
    updateCalculationType: (state, action): IImporterState => {
      return {
        ...state,
        calculationType: action.payload,
      };
    },
  },
});

export const {
  filesAdded,
  fileRemoved,
  filesCleared,
  importIdCreated,
  downloadedPayloadRetrieved,
  importActionsUpdated,
  allImportActionsFinished,
  reportFileSanitized,
  navigateFromImport,
  downloadedPayloadCleared,
  uploadedFileNamesUpdated,
  updateCalculationType,
} = importSlice.actions;

export default importSlice.reducer;
