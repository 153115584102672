import {
  DisplayLabel,
  ExtractedElementTree,
  ElementTreeCompareModel,
  ExtractedRoleType,
} from "../api/types";
import { LinkbaseTreeBranchWithMatchingComparisonObject } from "./getFlatTreeComparisonArray";
import { getTreeItemLabel } from "./mapLinkbaseTreeToTreeviewElement";

export const getChangedLinkbaseItemLabel = (
  item: LinkbaseTreeBranchWithMatchingComparisonObject,
  currentSelectedLanguage: string,
  isNew: boolean
): string => {
  let displayLabel = "";
  const type =
    item.branch && "roleTypeId" in item.branch ? "roleType" : "elementTree";
  let labels: DisplayLabel[] | undefined;
  if (item.branch) {
    if (type === "roleType") {
      labels = (item.branch as ExtractedRoleType).definitionLabels;
    } else if (type === "elementTree") {
      labels = (item.branch as ExtractedElementTree)?.linkbaseTreeElement
        .displayLabels;
    }
  } else {
    labels = (item.comparisonObject as ElementTreeCompareModel)
      .expectedElementTree?.expectedElement?.labels;
  }
  const label = getTreeItemLabel(labels, currentSelectedLanguage);
  displayLabel = label?.label || "";
  if (item.comparisonObject) {
    if (
      (item.comparisonObject.state === "Added" && !isNew) ||
      (item.comparisonObject.state === "Deleted" && isNew)
    ) {
      displayLabel = "";
    }
  }
  return displayLabel;
};
