import { findPropertyValueByPath } from "./findPropertyValueByPath";
import { EuroFilingFactDisplayItem, FactDisplayItem } from "./mapFactItems";

const descendingComparator = <T>(
  a: T,
  b: T,
  orderBy: keyof T,
  isDate?: boolean
): number => {
  const aPropVal = findPropertyValueByPath(a, orderBy as string);
  const bPropVal = findPropertyValueByPath(b, orderBy as string);
  if (aPropVal == null || bPropVal == null) {
    if (aPropVal !== bPropVal) {
      return aPropVal == null ? 1 : -1;
    }
    return 0;
  }
  const aVal = isDate ? new Date(aPropVal) : aPropVal;
  const bVal = isDate ? new Date(bPropVal) : bPropVal;
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
};

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  isDate?: boolean
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, isDate)
    : (a, b) => -descendingComparator(a, b, orderBy, isDate);
}

export const sortValueColumn = (
  facts: Array<FactDisplayItem | EuroFilingFactDisplayItem>,
  order: Order
): Array<FactDisplayItem | EuroFilingFactDisplayItem> =>
  facts.sort((a, b) => {
    const numberA = parseFloat(a.formattedValue.replace(/,/g, ""));
    const numberB = parseFloat(b.formattedValue.replace(/,/g, ""));
    if (isNaN(numberA) && isNaN(numberB))
      return order === "asc"
        ? a.formattedValue.localeCompare(b.formattedValue)
        : b.formattedValue.localeCompare(a.formattedValue);
    if (isNaN(numberA)) return order === "asc" ? 1 : -1;
    if (isNaN(numberB)) return order === "asc" ? -1 : 1;
    return order === "asc" ? numberA - numberB : numberB - numberA;
  });
