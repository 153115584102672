import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  SvgIconTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useAppSelector } from "../../hooks/useAppSelector";

export interface DrawerIcon {
  id:
    | "explorer"
    | "factDetails"
    | "anchorDetails"
    | "usedTags"
    | "toggleSecondarySidebar";
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  title: string;
  canBeDisabled: boolean;
  disabled: boolean;
  hideWhenDrawerClosed?: boolean;
}

export interface GeneralSidebarResizableDrawerBodyClosedProps {
  icons: DrawerIcon[];
  onClickIcon: (id: DrawerIcon["id"]) => void;
}

const GeneralSidebarResizableDrawerBodyClosed = ({
  icons,
  onClickIcon,
}: GeneralSidebarResizableDrawerBodyClosedProps) => {
  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );

  return (
    <List>
      {icons.map((icon, index) => {
        return (
          <>
            {icon.hideWhenDrawerClosed ? null : (
              <ListItem
                key={`general_drawer_closed_icon_${index}`}
                disablePadding
                sx={{ display: "block" }}>
                <ListItemButton
                  disabled={
                    (icon.canBeDisabled && selectedFactIds.length === 0) ||
                    icon.disabled
                  }
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                  }}
                  title={icon.title}
                  onClick={() => onClickIcon(icon.id)}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: "auto",
                      justifyContent: "center",
                    }}>
                    <icon.icon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )}
          </>
        );
      })}
    </List>
  );
};

export default GeneralSidebarResizableDrawerBodyClosed;
