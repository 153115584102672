import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AxiosResponse } from "axios";
import {
  IApiFactComparisonResult,
  IApiLinkbaseTreeComparisonResult,
} from "../api/types";
import { compareFacts, compareLinkbaseTrees } from "../api/taxonomies";
import { importIdCreated } from "./importReducer";


export interface ICompareState {
  factCompareResults: IApiFactComparisonResult[] | undefined;
  treesCompareResults: IApiLinkbaseTreeComparisonResult[] | undefined;
}

const initialState: ICompareState = {
  factCompareResults: undefined,
  treesCompareResults: undefined,
};

export const startComparePackages = createAsyncThunk(
  "compare/compare/packages",
  async (
    args: {
      packageFile: File | Blob;
      abortSignal?: AbortSignal;
    },
    { rejectWithValue, getState, dispatch }
  ): Promise<{
    factComparisons: IApiFactComparisonResult[];
    treesComparisons: IApiLinkbaseTreeComparisonResult[];
  }> => {
    const state = getState() as RootState;
    let importId = state.import.importId;
    if (importId.length === 0) {
      importId = crypto.randomUUID();
      await dispatch(importIdCreated(importId));
    }
    const factsRequestFormData = new FormData();
    const fileFormKey = args.packageFile.type.toLowerCase().includes("html") ? "taxonomyReport" : "taxonomyPackage";
    const factsUrl = args.packageFile.type.toLowerCase().includes("html") ? "/api/taxonomies/report/compare/facts" : "/api/taxonomies/compare/facts";
    const treesUrl = args.packageFile.type.toLowerCase().includes("html") ? "/api/taxonomies/report/compare/linkbases/trees" : "/api/taxonomies/compare/linkbases/trees";
    factsRequestFormData.append(fileFormKey, args.packageFile);
    factsRequestFormData.append(
      "extractedFacts",
      JSON.stringify(state.extract.facts)
    );
    const treesRequestFormData = new FormData();
    treesRequestFormData.append(fileFormKey, args.packageFile);
    treesRequestFormData.append(
      "extractedLinkbaseTrees",
      JSON.stringify(state.extract.extractedBasicData?.linkbaseTrees)
    );

    try {
      const promises: Promise<AxiosResponse>[] = [
        compareFacts(factsUrl, factsRequestFormData, importId, args.abortSignal),
        compareLinkbaseTrees(treesUrl, treesRequestFormData, importId, args.abortSignal),
      ];
      const resps = await Promise.all(promises);
      return {
        factComparisons: resps[0].data,
        treesComparisons: resps[1].data,
      };
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

const compareSlice: Slice<ICompareState> = createSlice({
  name: "compare",
  initialState: initialState,
  reducers: {
    compareCleared: (state, _): ICompareState => {
      return {
        ...state,
        factCompareResults: undefined,
        treesCompareResults: undefined,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startComparePackages.pending, (state, _): ICompareState => {
        return {
          ...state,
          factCompareResults: undefined,
          treesCompareResults: undefined,
        };
      })
      .addCase(
        startComparePackages.fulfilled,
        (state, action): ICompareState => {
          return {
            ...state,
            factCompareResults: action.payload.factComparisons,
            treesCompareResults: action.payload.treesComparisons,
          };
        }
      )
  },
});

export const { compareCleared } = compareSlice.actions;

export default compareSlice.reducer;
