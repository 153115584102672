const getImageMIMEType = (extension: string): string => {
  if (!extension) return "";
  return `image/${extension}`;
};

const getFileNameWithExtension = (path: string): string => {
  return path.split("/")[path.split("/").length - 1] || "";
};

export const handleReportInternalImages = (
  reportHtml: string,
  images: {
    name: string;
    base64Value: string;
  }[]
): string => {
  if (images.length === 0) return reportHtml;
  const doc = new DOMParser().parseFromString(reportHtml, "text/html");
  const imageElements = doc.querySelectorAll("img");
  for (const imageElement of imageElements) {
    const elem = imageElement as HTMLImageElement;
    const image = images.find(
      (i) =>
        getFileNameWithExtension(i.name).toLowerCase() ===
        getFileNameWithExtension(elem.src).toLowerCase()
    );
    if (image) {
      elem.src = `data:${getImageMIMEType(
        getFileNameWithExtension(image.name).split(".")[1]
      )}; base64, ${image.base64Value}`;
    }
  }
  return new XMLSerializer().serializeToString(doc);
};
