import { t, Trans } from "@lingui/macro";
import {
  Dialog,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

export interface EditUserNameDialogProps {
  isOpen: boolean;
  userName: string;
  onSave: (val: string) => void;
  onCancel: () => void;
}

const EditUserNameDialog = ({
  isOpen,
  userName,
  onSave,
  onCancel,
}: EditUserNameDialogProps) => {
  const [currentValue, setCurrentValue] = useState("");

  const handleClose = () => {
    setCurrentValue(userName);
    onCancel();
  };

  useEffect(() => {
    setCurrentValue(userName);
  }, [userName]);

  return (
    <Dialog open={isOpen} onClose={(e: any) => e.preventDefault()}>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            required
            label={t`Name`}
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button onClick={() => onSave(currentValue)}>
          <Trans>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserNameDialog;
