import { IApiConversionResponse } from "../api/types";

export const getStatusColor = (
  status: IApiConversionResponse["status"]
):
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | undefined => {
  if (status === "Completed") return "success";
  if (status === "Failed") return "error";
  if (status === "Running") return "warning";
  return "default";
};
