import { getAncestorReplacedXBRLElements } from "./getNextSelectedFactId";
import { getUniqueArray } from "./getUniqueArrayByPoprties";

export const getAssociatedFactIds = (factId: string): string[] => {
  const output: string[] = [];
  const relevantXBRLElements = Array.from(
    document.querySelectorAll(`[data-associated-fact-id="${factId}"]`)
  );
  for (const sourceXBRLElement of relevantXBRLElements) {
    const allXBRLRelatives = [
      ...getAncestorReplacedXBRLElements(sourceXBRLElement as HTMLSpanElement),
      ...Array.from(
        sourceXBRLElement.querySelectorAll("[data-internal-viewer-id]")
      ),
    ];
    output.push(
      ...allXBRLRelatives
        .map((elem) => (elem as HTMLSpanElement).dataset.associatedFactId || "")
        .filter((x) => x !== "")
    );
  }
  return getUniqueArray(output);
};
