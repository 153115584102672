import { IApiValidationResult, ValidationElement } from "../api/types";
import {
  taxonomyChecks,
  xHTMLerrorChecks,
  entryPointsChecks,
  linkBaseChecks,
  xMLerrorChecks,
} from "./constants";

function checkIfStringStartsWith(str: string, substrs: string[]) {
  return substrs.some((substr) =>
    str.toLowerCase().startsWith(substr.toLowerCase())
  );
}

export const allCheckers = (props: IApiValidationResult | null) => {
  var taxonomyImportisValid = false;
  var taxonomyerrors: ValidationElement[] = [];
  var XMLerrors: ValidationElement[] = [];
  var entrypointerrors: ValidationElement[] = [];
  var linkbaseerrors: ValidationElement[] = [];
  var xhtmlerrors: ValidationElement[] = [];
  if (props === undefined || props === null) {
  } else {
    if (props.isValid) {
      taxonomyImportisValid = true;
    }
    try {
      if (props.entries && props.entries.length > 0) {
        props.entries?.forEach((entry: ValidationElement) => {
          if (checkIfStringStartsWith(entry.code, taxonomyChecks)) {
            taxonomyerrors.push(entry);
          } else if (checkIfStringStartsWith(entry.code, xMLerrorChecks)) {
            XMLerrors.push(entry);
          } else if (checkIfStringStartsWith(entry.code, entryPointsChecks)) {
            entrypointerrors.push(entry);
          } else if (checkIfStringStartsWith(entry.code, linkBaseChecks)) {
            linkbaseerrors.push(entry);
          } else if (checkIfStringStartsWith(entry.code, xHTMLerrorChecks)) {
            xhtmlerrors.push(entry);
          }
        });
      }
    } catch (e) { }
  }

  taxonomyerrors.filter((entry: ValidationElement) => {
    return taxonomyChecks.indexOf(entry.code) > -1;
  });

  xhtmlerrors.filter((entry: ValidationElement) => {
    return xHTMLerrorChecks.indexOf(entry.code) > -1;
  });

  entrypointerrors.filter((entry: ValidationElement) => {
    return entryPointsChecks.indexOf(entry.code) > -1;
  });

  linkbaseerrors.filter((entry: ValidationElement) => {
    return linkBaseChecks.indexOf(entry.code) > -1;
  });
  XMLerrors.filter((entry: ValidationElement) => {
    return xMLerrorChecks.indexOf(entry.code) > -1;
  });

  return {
    isValid: taxonomyImportisValid,
    TaxonomyCheck: taxonomyerrors,
    xmlCheck: XMLerrors,
    entryPointsCheck: entrypointerrors,
    linkBaseCheck: linkbaseerrors,
    xHTMLCheck: xhtmlerrors,
  };
};