import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Translation of Text */
import { Trans, t } from "@lingui/macro";

import { BaseTaxonomy, ExtractedTaxonomy } from "../../api/types";

export interface TaxonomyPackageProps {
  basicData: ExtractedTaxonomy;
}

export const TaxonomyPackage = ({ basicData }: TaxonomyPackageProps) => {
  return (
    <>
      <Accordion
        defaultExpanded={true}
        sx={{ overflowX: "hidden", overflowY: "auto" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography data-downloadaspdf="taxonomypackageheader" variant="h6">
            <Trans>Taxonomy Package </Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ minHeight: "550px", maxHeight: "310px" }}>
          <Stack
            sx={{ display: "flex" }}
            spacing={2}
            divider={<Divider orientation="horizontal" flexItem />}>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans> Identifier: </Trans>
              </Typography>
              <Typography
                variant="body2"
                noWrap
                data-downloadaspdf="taxonomypackagevalue"
                title={basicData?.identifier ?? t`No identifier found`}>
                {basicData?.identifier ?? <Trans>No identifier found</Trans>}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans> Name: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2"
                noWrap>
                {basicData?.names && basicData.names.length > 0 ? (
                  basicData?.names[0].text
                ) : (
                  <Trans>No Data Found</Trans>
                )}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                Description:
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2"
                noWrap>
                {basicData?.descriptions &&
                basicData.descriptions.length > 0 ? (
                  basicData.descriptions[0].text
                ) : (
                  <Trans>No Data Found</Trans>
                )}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans>Version: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2"
                noWrap>
                {basicData?.version ?? <Trans>No Data Found</Trans>}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans>Publisher: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2"
                noWrap>
                {basicData?.publisher?.names &&
                basicData?.publisher?.names.length > 0 ? (
                  basicData?.publisher?.names[0].text
                ) : (
                  <Trans>No Data Found</Trans>
                )}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans> PublisherUrl: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2">
                <a
                  target="_blank"
                  rel="external noopener noreferrer"
                  href={"https://" + basicData?.publisher?.url}>
                  {basicData?.publisher?.url ?? (
                    <Trans> No publisher url available </Trans>
                  )}
                </a>
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans>PublisherCountry: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2">
                {basicData?.publisher?.country ?? (
                  <Trans> No publisher country available </Trans>
                )}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans>PublicationDate: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2"
                title={
                  new Date(
                    basicData?.publisher?.publicationDate || ""
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }) ?? <Trans> No entry points available </Trans>
                }>
                {new Date(
                  basicData?.publisher?.publicationDate || ""
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                }) ?? <Trans> No entry points available </Trans>}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                pr: 10,
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans> EntryPoints: </Trans>
              </Typography>
              <Typography
                data-downloadaspdf="taxonomypackagevalue"
                variant="body2">
                {basicData?.entryPoints &&
                basicData?.entryPoints?.length > 0 ? (
                  basicData.entryPoints[0].href
                ) : (
                  <Trans>No entry points available</Trans>
                )}
              </Typography>
            </Box>
            <Box
              maxWidth={"xl"}
              sx={{
                display: "flex",
                overflow: "visible",
              }}>
              <Typography
                data-downloadaspdf="taxonomypackagekey"
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  mr: 10,
                  maxWidth: "300px",
                  display: "flex",
                  flex: 1,
                  flexBasis: "auto",
                }}>
                <Trans> ImportedSchemas: </Trans>
              </Typography>
              <Grid>
                {basicData?.baseTaxonomies?.map(
                  (baseTaxonomy: BaseTaxonomy, index: number) => (
                    <Typography
                      variant="body2"
                      key={index}
                      data-downloadaspdf="taxonomypackagevalue"
                      title={baseTaxonomy.entryPoint}>
                      {baseTaxonomy.entryPoint}
                    </Typography>
                  )
                ) ?? <Trans> No imported schemas available </Trans>}
              </Grid>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
