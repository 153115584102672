import { t } from "@lingui/macro";
import { IApiPasswordOptions } from "../api/types";

/* RegEx function that checks if string has 3 special characters */
const hasSpecialChars = (
  str: string,
  minimumRequiredPasswordSpecialCharCount: number
) => {
  const specialCharRegex = /[^A-Za-z0-9]/g;
  const specialCharCount = str.match(specialCharRegex);
  if (
    specialCharCount &&
    specialCharCount.length >= minimumRequiredPasswordSpecialCharCount
  ) {
    return true;
  }
  return false;
};

export const validatePassword = (
  options: IApiPasswordOptions | undefined,
  password: string,
  repeatedPassword: string,
  oldPassword?: string
): string[] => {
  if (!options)
    return [t`Could not retrieve validation requirements. Please try again later.`];
  const output = [];
  if (options.requireDigit && !/\d/.test(password)) {
    output.push(t`Password must contain a number`);
  }
  if (options.requireLowercase && password.toUpperCase() === password) {
    output.push(t`Password must contain a lowercase letter`);
  }
  if (
    options.requireNonAlphanumeric &&
    options.requiredUniqueCharacters > 0 &&
    !hasSpecialChars(password, options.requiredUniqueCharacters)
  ) {
    output.push(
      t`Password must have at least ${options.requiredUniqueCharacters} special characters`
    );
  }
  if (options.requireUppercase && password.toLowerCase() === password) {
    output.push(t`Password must contain a uppercase letter`);
  }
  if (password.length < options.requiredLength) {
    output.push(
      t`Password must be greater than ${options.requiredLength} characters`
    );
  }
  if (oldPassword && oldPassword.length > 0 && oldPassword === password) {
    output.push(t`Password must be different from existing password`);
  }
  if (
    (password.length > 0 || repeatedPassword.length > 0) &&
    password !== repeatedPassword
  ) {
    output.push(t`Password must match repeated password`);
  }
  return output;
};
