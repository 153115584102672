import { Trans } from "@lingui/macro";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";

export interface DeactivateUserConsentDialogProps {
  name: string;
  open: boolean;
  onClose: (confirmed: boolean) => void;
}

const DeactivateUserConsentDialog = ({
  name,
  open,
  onClose,
}: DeactivateUserConsentDialogProps) => (
  <Dialog open={open} onClose={() => onClose(false)}>
    <DialogTitle>
      <Trans>Are you sure?</Trans>
    </DialogTitle>
    <DialogContent>
      <Trans>You are about to deactivate</Trans>
      {` ${name}`}
      <Trans>. They will no longer have access to the platform.</Trans>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose(false)}>
        <Trans>No</Trans>
      </Button>
      <Button
        onClick={() => {
          onClose(true);
        }}>
        <Trans>Yes</Trans>
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeactivateUserConsentDialog;
