import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MicrosoftImg from "../images/icons/microsoft_icon.svg";
import ErrorAlert from "../components/errorAlert";
import LoginContainer from "../components/login/loginContainer";
import { authenticateWithPassword } from "../api/accounts";
import redirectForMicrosoftLogin from "../helpers/microsoftRedirect";
import Navbar from "../components/navbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import LoginBackground from "../components/login/loginBackground";
import isValidEmail from "../helpers/isValidEmail";

/* Translation of Text */
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import tokenStorageHelper from "../helpers/tokenHelper";
import { getCurrentUserDetails } from "../reducers/userManagementReducer";

const invalidEmail = new Error("Invalid Email");
const missingPassword = new Error("Missing Password");

// These errors are handled by the form, so we don't need to show extra alerts
const suppressAlerts: Array<Error | null> = [invalidEmail, missingPassword];

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState<Error | null>(null);

  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    const data = new FormData(event.currentTarget as HTMLFormElement);
    const email = data.get("email");
    const password = data.get("password");

    if (!isValidEmail(email?.toString())) {
      setLoginError(invalidEmail);
      return;
    } else if (password?.toString().length === 0) {
      setLoginError(missingPassword);
      return;
    }

    setLoginError(null);
    setIsLoading(true);
    try {
      await authenticateWithPassword(
        email?.toString() || "",
        password?.toString() || ""
      );
      await dispatch(getCurrentUserDetails());
      navigate("/import", { replace: true });
    } catch (ex) {
      setLoginError(ex as any);
    } finally {
      setIsLoading(false);
    }
  };

  if (tokenStorageHelper.token && tokenStorageHelper.isValid) {
    navigate("/", { replace: true });
  }

  return (
    <LoginBackground>
      <Navbar />
      <LoginContainer
        sx={{ pt: 15 }}
        additionalButton={
          <Button
            fullWidth
            onClick={() => navigate("/accounts/password/forgot")}>
            <Trans>Forgot Password?</Trans>
          </Button>
        }>
        <Box sx={{ mt: 1 }}>
          <ErrorAlert
            formError={suppressAlerts.includes(loginError) ? null : loginError}
          />
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label={<Trans>Email Address</Trans>}
              name="email"
              autoComplete="email"
              helperText={
                loginError === invalidEmail ? (
                  <Trans>Please enter a valid email address.</Trans>
                ) : null
              }
              error={loginError === invalidEmail}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label={<Trans>Password</Trans>}
              type="password"
              id="password"
              helperText={
                loginError === missingPassword
                  ? "Please enter your password."
                  : null
              }
              error={loginError === missingPassword}
              autoComplete="current-password"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              sx={{ mt: 3, mb: 2, height: 45 }}>
              <Trans>Log In</Trans>
            </LoadingButton>
          </Box>
          <Divider>
            <Trans>OR</Trans>
          </Divider>
          <Button
            color="inherit"
            variant="outlined"
            startIcon={<img src={MicrosoftImg} width="20px" alt="Microsoft" />}
            fullWidth
            onClick={redirectForMicrosoftLogin}
            sx={{ mt: 3, mb: 2, height: 45 }}>
            <Trans>Sign in with Microsoft</Trans>
          </Button>
        </Box>
      </LoginContainer>
    </LoginBackground>
  );
};

export default Login;
