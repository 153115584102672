import { t } from "@lingui/macro";
import { ExcelSheetConfig } from "../components/excelExportDialog";
import { IApiValidationResult } from "../api/types";

const getValidationSummarySheet = (rootElem: HTMLElement): ExcelSheetConfig => {
  const dataKey = "validationsummary";
  const keys = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}key"]`)
  ) as HTMLElement[];
  const values = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}value"]`)
  ) as HTMLElement[];
  const andValue = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}valueAnd"]`)
  ) as HTMLElement[];
  const valueSecond = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}valueSecond"]`)
  ) as HTMLElement[];
  let data: any = {};
  for (let index = 0; index < keys.length; index++) {
    const value = `${values[index]?.innerText} ${andValue[index]?.innerText || ""
      } ${valueSecond[index]?.innerText || ""}`;
    data[keys[index].innerText.replace(":", "")] = value;
  }

  return {
    dataType: "SummaryValidationSummary",
    sheetName: t`Validation Summary`,
    serializedCollection: JSON.stringify(data),
  };
};

const getInfoDictionarySheet = (
  rootElem: HTMLElement,
  dataKey: string,
  name: string,
  type: ExcelSheetConfig["dataType"]
): ExcelSheetConfig => {
  const keys = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}key"]`)
  ) as HTMLElement[];
  const values = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}value"]`)
  ) as HTMLElement[];
  let data: any = {};
  for (let index = 0; index < keys.length; index++) {
    data[keys[index].innerText.replace(":", "")] = `${values[index]?.innerText || ""
      }`;
  }
  return {
    dataType: type,
    sheetName: name,
    serializedCollection: JSON.stringify(data),
  };
};

const getZipContentSheet = (rootElem: HTMLElement): ExcelSheetConfig => {
  const fileNameElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfilename"]`)
  ) as HTMLElement[];
  const fileTypeElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfiletype"]`)
  ) as HTMLElement[];
  const fileHashElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="zipcontentrowfilehash"]`)
  ) as HTMLElement[];

  const tableRows: any[] = [];

  for (let index = 0; index < fileNameElems.length; index++) {
    tableRows.push({
      name: fileNameElems[index].innerText,
      fileKind: fileTypeElems[index]?.innerText || "Folder",
      hashCode: fileHashElems[index].innerText,
    });
  }
  return {
    dataType: "SummaryZipContent",
    sheetName: t`Zip Content`,
    serializedCollection: JSON.stringify(tableRows),
  };
};

const getErrorShowcaseSheet = (
  rootElem: HTMLElement,
  dataKey: string,
  name: string,
  type: ExcelSheetConfig["dataType"]
): ExcelSheetConfig | undefined => {
  const levelElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}level"]`)
  ) as HTMLElement[];
  const errorIdElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}errorId"]`)
  ) as HTMLElement[];
  const messageElems = Array.from(
    rootElem.querySelectorAll(`[data-downloadaspdf="${dataKey}message"]`)
  ) as HTMLElement[];
  if (
    levelElems.length > 0 &&
    errorIdElems.length > 0 &&
    messageElems.length > 0
  ) {
    let data: any[] = [];
    for (let index = 0; index < levelElems.length; index++) {
      data.push({
        severity: levelElems[index].innerText,
        errorId: errorIdElems[index]?.innerText || "",
        message: messageElems[index]?.innerText || "",
      });
    }
    return {
      dataType: type,
      sheetName: name,
      serializedCollection: JSON.stringify(data),
    };
  }
  return undefined;
};

export const getSheetsForSummaryExcelExport = (
  validationResult: IApiValidationResult | null,
  rootId: string
): ExcelSheetConfig[] => {
  const sheets: ExcelSheetConfig[] = [];
  const rootElem = document.getElementById(rootId);
  if (rootElem) {
    if (validationResult) {
      sheets.push(getValidationSummarySheet(rootElem));
    }
    sheets.push(
      getInfoDictionarySheet(
        rootElem,
        "generalinformation",
        t`General Information`,
        "SummaryGeneralInformation"
      )
    );
    sheets.push(
      getInfoDictionarySheet(
        rootElem,
        "taxonomypackage",
        t`Taxonomy Package`,
        "SummaryTaxonomyPackage"
      )
    );
    sheets.push(getZipContentSheet(rootElem));
    const importErrorsSheet = getErrorShowcaseSheet(
      rootElem,
      "importerrors",
      "Import Errors",
      "SummaryErrorLog"
    );
    if (importErrorsSheet) {
      sheets.push(importErrorsSheet);
    }
    if (validationResult) {
      const taxonomypackagecheckSheet = getErrorShowcaseSheet(
        rootElem,
        "taxonomypackagecheck",
        "Taxonomy Check",
        "SummaryErrorLog"
      );
      if (taxonomypackagecheckSheet) {
        sheets.push(taxonomypackagecheckSheet);
      }
      const xmlerrorcheckSheet = getErrorShowcaseSheet(
        rootElem,
        "xmlerrorcheck",
        "XML Error Check",
        "SummaryErrorLog"
      );
      if (xmlerrorcheckSheet) {
        sheets.push(xmlerrorcheckSheet);
      }
      const entrypointscheckSheet = getErrorShowcaseSheet(
        rootElem,
        "entrypointscheck",
        "Entrypoints Check",
        "SummaryErrorLog"
      );
      if (entrypointscheckSheet) {
        sheets.push(entrypointscheckSheet);
      }
      const xhtmlcheckSheet = getErrorShowcaseSheet(
        rootElem,
        "xhtmlcheck",
        "xHTML Check",
        "SummaryErrorLog"
      );
      if (xhtmlcheckSheet) {
        sheets.push(xhtmlcheckSheet);
      }
      const linkbasecheckcheckSheet = getErrorShowcaseSheet(
        rootElem,
        "linkbasecheck",
        "Linkbase Check",
        "SummaryErrorLog"
      );
      if (linkbasecheckcheckSheet) {
        sheets.push(linkbasecheckcheckSheet);
      }
      if (
        validationResult.logEntries &&
        validationResult.logEntries.length > 0
      ) {
        sheets.push({
          dataType: "SummaryValidationLog",
          sheetName: t`Validation Log`,
          serializedCollection: JSON.stringify(validationResult.logEntries),
        });
      }
    }
  }

  return sheets;
};
