import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import LoginBackground from "../components/login/loginBackground";
import LoginContainer from "../components/login/loginContainer";
import Navbar from "../components/navbar";
import {
  getPasswordValidationRequirements,
  resetPassword,
} from "../api/accounts";
import getParam from "../helpers/getParam";
import { LoadingButton } from "@mui/lab";
import { t } from "@lingui/macro";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { IApiPasswordOptions } from "../api/types";
import { validatePassword } from "../helpers/validatePassword";
import tokenHelper from "../helpers/tokenHelper";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tokenParam = getParam("token") ?? "";
  const userId = getParam("userId") ?? "";

  const [loading, setLoading] = useState(false);
  const [responseHandler, setResponseHandler] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRepeatPass, setShowRepeatPass] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [passwordValidationOptions, setPasswordValidationOptions] =
    useState<IApiPasswordOptions>();

  useEffect(() => {
    getPasswordValidationRequirements().then((resp) =>
      setPasswordValidationOptions(resp.data)
    );
  }, []);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setValidationErrors(
      validatePassword(
        passwordValidationOptions,
        event.target.value,
        confirmPassword
      )
    );
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setValidationErrors(
      validatePassword(passwordValidationOptions, password, event.target.value)
    );
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await resetPassword(
        userId?.toString() || "",
        confirmPassword,
        tokenParam
      );
      setLoading(false);
      tokenHelper.clear();
      setResponseHandler("success");
    } catch (error) {
      setLoading(false);
      console.log(error);
      setResponseHandler("error");
    }
  };

  useEffect(() => {
    responseHandler === "success" &&
      setTimeout(() => {
        window.location.href = "/login";
        setResponseHandler("");
      }, 1000);
  }, [responseHandler]);

  return (
    <LoginBackground>
      <Navbar />
      <LoginContainer sx={{ pt: 15 }}>
        <Box sx={{ mt: 1 }}>
          <Typography sx={{ mb: 2 }} variant="h5">
            {location.pathname.toLowerCase().includes("forgot")
              ? t`Reset Password`
              : ""}
          </Typography>
          <form>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="reset_password_dialog_new_password">
                {location.pathname.toLowerCase().includes("forgot")
                  ? t`Password`
                  : t`New Password`}
              </InputLabel>
              <OutlinedInput
                id="reset_password_dialog_new_password"
                type={showNewPass ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPass((prev) => !prev)}
                      onMouseDown={() => setShowNewPass((prev) => !prev)}
                      edge="end">
                      {showNewPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={
                  location.pathname.toLowerCase().includes("forgot")
                    ? t`Password`
                    : t`New Password`
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }} variant="outlined">
              <InputLabel htmlFor="reset_password_dialog_confirm_new_password">
                {t`Confirm Password`}
              </InputLabel>
              <OutlinedInput
                id="reset_password_dialog_confirm_new_password"
                type={showRepeatPass ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    validationErrors.length > 0
                      ? event.preventDefault()
                      : handleSubmit(event);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowRepeatPass((prev) => !prev)}
                      onMouseDown={() => setShowRepeatPass((prev) => !prev)}
                      edge="end">
                      {showRepeatPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t`Confirm Password`}
              />
            </FormControl>
            {validationErrors.map((error) => (
              <Typography
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}>
                {error}
              </Typography>
            ))}
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              sx={{ mt: 5 }}
              disabled={validationErrors.length > 0}
              onClick={(event: any) => handleSubmit(event)}>
              {location.pathname.toLowerCase().includes("forgot") &&
                t`Reset Password`}
              {!location.pathname.toLowerCase().includes("forgot") && t`Save`}
            </LoadingButton>
            {responseHandler === "error" && (
              <Alert
                sx={{ mt: 2 }}
                severity="error"
                onClose={() => setResponseHandler("")}>
                {t`Something went wrong`}
              </Alert>
            )}
            {responseHandler === "success" && (
              <Alert
                sx={{ mt: 2 }}
                severity="success"
                onClose={() => setResponseHandler("")}>
                {t`password successfully updated`}
              </Alert>
            )}
          </form>
        </Box>
      </LoginContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}>
        <Button onClick={() => navigate("/login")}>Back to Login</Button>
      </Box>
    </LoginBackground>
  );
};

export default ResetPassword;
