import { jwtDecode } from "jwt-decode";

export interface DecodedToken {
  email: string;
  hasConverter: string;
  hasInspector: string;
}

export const getDecodedToken = (token: string): DecodedToken | undefined => {
  if (!token) return undefined;
  return jwtDecode<DecodedToken>(token);
};

export const organizationHasInspector = (token: string): boolean => {
  if (!token) return false;
  return jwtDecode<DecodedToken>(token)?.hasInspector
    ? jwtDecode<DecodedToken>(token)?.hasInspector.toLowerCase() === "true"
    : false;
};

export const organizationHasConvertor = (token: string): boolean => {
  if (!token) return false;
  return jwtDecode<DecodedToken>(token)?.hasConverter
    ? jwtDecode<DecodedToken>(token)?.hasConverter.toLocaleLowerCase() ===
    "true"
    : false;
};
