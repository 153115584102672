import { Trans } from "@lingui/macro";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import PasswordValidationForm from "../passwordValidationForm";
import CloseIcon from "@mui/icons-material/Close";

export interface ChangeUserPasswordDialogProps {
  isSaving: boolean;
  isOpen: boolean;
  onSave: (newPass: string, oldPass?: string) => void;
  onCancel: () => void;
}

const ChangeUserPasswordDialog = ({
  isSaving,
  isOpen,
  onSave,
  onCancel,
}: ChangeUserPasswordDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={(e: any) => e.preventDefault()}
      fullWidth
      maxWidth="sm">
      <DialogTitle>
        <Trans>Change Password</Trans>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PasswordValidationForm
          isLoading={isSaving}
          onSave={onSave}
          onCancel={onCancel}
          validateExistingPassword
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeUserPasswordDialog;
