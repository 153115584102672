import Alert from "@mui/material/Alert"
import Typography from "@mui/material/Typography"
import { IApiError } from "../api/types"
import { t, Trans } from "@lingui/macro";

interface ErrorAlertProps {
  formError?: Error | null;
  apiError?: IApiError | null;
  importErrors?: boolean[];
  importId?: string;
}

const ErrorAlert = ({
  formError,
  apiError,
  importErrors,
  importId,
}: ErrorAlertProps) => {
  const actualImportErrors = importErrors?.filter((error) => error) || [];
  if (!formError && !apiError && actualImportErrors.length === 0) {
    return null;
  }

  if (apiError && apiError.isTranslated) {
    return (
      <Alert severity="error">
        {apiError.exceptions.map((message) => (
          <Typography align="left">{message}</Typography>
        ))}
      </Alert>
    );
  }

  if (formError) {
    return (
      <Alert severity="error">
        <Typography align="left">{formError.message}</Typography>
      </Alert>
    );
  }

  if (importErrors && actualImportErrors.length > 0) {
    return (
      <Alert severity="error">
        <Trans>
          One or more unexpected errors occurred while proccesing the file(s).
        </Trans>
        {importId && (
          <>
            <br />
            {t` Import Id: ${importId}`}
          </>
        )}
      </Alert>
    );
  }

  // Unknown error
  return (
    <Alert severity="error">
      <Trans>An unexpected error occurred.</Trans>
    </Alert>
  );
};

export default ErrorAlert;
