import { useMemo, useState } from "react";
import {
  IApiFact,
  IApiFactComparisonResult,
  ExtractedLinkbaseTree,
} from "../../api/types";
import { defaultTableRowsPerPage } from "../../helpers/constants";
import {
  ChangedFactDisplayItem,
  FactDisplayItem,
  factDisplayItemDateKeys,
  mapFactChangeItemsForDisplay,
} from "../../helpers/mapFactItems";
import {
  getComparator,
  Order,
  sortValueColumn,
} from "../../helpers/tableSorting";
import DataTable from "../facts/dataTable";
import { getChangedFactsTableColumns } from "./changedFactsTableColumns";

interface ChangedFactsTableContainerProps {
  factComparisons: IApiFactComparisonResult[];
  allFacts: IApiFact[];
  presentationTree: ExtractedLinkbaseTree | undefined;
  currentChangeDisplayMode: IApiFactComparisonResult["state"] | "All";
  currentSelectedLanguage: string;
}

const ChangedFactsTableContainer = ({
  factComparisons,
  allFacts,
  presentationTree,
  currentSelectedLanguage,
  currentChangeDisplayMode,
}: ChangedFactsTableContainerProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof Omit<ChangedFactDisplayItem, "changedProps">>("changeType");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultTableRowsPerPage);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSortTable = (
    event: React.MouseEvent<unknown>,
    property: keyof ChangedFactDisplayItem
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof Omit<ChangedFactDisplayItem, "changedProps">);
  };

  const columns = useMemo(() => getChangedFactsTableColumns(), []);

  const allDisplayItems = useMemo(
    () =>
      mapFactChangeItemsForDisplay(
        factComparisons,
        allFacts,
        currentSelectedLanguage,
        presentationTree
      ),
    [factComparisons, allFacts, currentSelectedLanguage, presentationTree]
  );

  const filteredDisplayItems = useMemo(() => {
    if (currentChangeDisplayMode === "All") {
      return allDisplayItems;
    } else {
      return allDisplayItems.filter(
        (item) => item.changeType === currentChangeDisplayMode
      );
    }
  }, [allDisplayItems, currentChangeDisplayMode]);

  const sortedAndFilteredDisplayFacts = useMemo(
    () => filteredDisplayItems.slice().sort(getComparator(order, orderBy)),
    [filteredDisplayItems, order, orderBy]
  );

  const filteredSortedPagedDisplayFacts = useMemo(() => {
    return sortedAndFilteredDisplayFacts.length < rowsPerPage ||
      rowsPerPage === -1
      ? sortedAndFilteredDisplayFacts
      : sortedAndFilteredDisplayFacts.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
  }, [sortedAndFilteredDisplayFacts, rowsPerPage, page]);

  const orderedPagedAndFilteredDisplayFacts = useMemo(() => {
    if (orderBy === "formattedValue") {
      return sortValueColumn(
        filteredSortedPagedDisplayFacts.slice() as FactDisplayItem[] | [],
        order
      );
    } else {
      return filteredSortedPagedDisplayFacts
        .slice()
        .sort(
          getComparator(
            order,
            orderBy,
            factDisplayItemDateKeys.includes(orderBy)
          )
        );
    }
  }, [filteredSortedPagedDisplayFacts, order, orderBy]);

  return (
    <>
      <DataTable<ChangedFactDisplayItem>
        columns={columns}
        currentSelectedPage={page}
        data={orderedPagedAndFilteredDisplayFacts as ChangedFactDisplayItem[]}
        onRequestSort={handleSortTable}
        onSelectPage={setPage}
        onSelectRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        totalRowCount={filteredDisplayItems.length}
        containerHeight="72vh"
      />
    </>
  );
};

export default ChangedFactsTableContainer;
