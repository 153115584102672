import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { ValidationSummaryTreeViewComponent } from "./ValidationSummaryTreeViewComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { PackageUrlBlob } from "../../../api/types";

/* Translation of Text */
import { Trans } from "@lingui/macro";

export const ZipContent = (props: PackageUrlBlob) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography data-downloadaspdf="zipcontentheader" variant="h6">
          <Trans> Zip Content </Trans>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          minHeight: "550px",
          maxHeight: "550px",
        }}>
        <ValidationSummaryTreeViewComponent />
      </AccordionDetails>
    </Accordion>
  );
};
