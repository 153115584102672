import { Container } from "@mui/material";

interface ResizableDrawerBoundryProps {
  isDrawerOpen: boolean;
  onResizeDrawer: (e: any) => void;
}

const ResizableDrawerBoundry = ({
  isDrawerOpen,
  onResizeDrawer,
}: ResizableDrawerBoundryProps) => {
  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", onResizeDrawer, true);
  };

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", onResizeDrawer, true);
  };

  return (
    <Container maxWidth={false}>
      <div
        onMouseDown={handleMouseDown}
        style={{
          width: "5px",
          cursor: isDrawerOpen ? "ew-resize" : "default",
          padding: "4px 0 0",
          borderTop: "1px solid #ddd",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          backgroundColor: "#f4f7f9",
        }}
      />
    </Container>
  );
};

export default ResizableDrawerBoundry;
