import { ExtractedLinkbaseTreeElement } from "../api/types";
import {
  MappedLinkbaseTreeItem,
  MappedLinkebaseTree,
} from "./mapLinkbaseTreeToTreeviewElement";

export interface TreeItemIdentifier {
  id: string;
  label: string;
  path: string;
  factIds: string[] | undefined;
  linkbaseTreeElement: ExtractedLinkbaseTreeElement | undefined;
  hasChildren: boolean | undefined;
  childrenLoaded: boolean;
  errorLoadingChildren: boolean | undefined;
  internalId: string;
  parentRoleTypeId: string;
}

const addByBranchesRecursive = (
  currentBranch: MappedLinkbaseTreeItem,
  currentPath: string,
  outputArray: TreeItemIdentifier[]
): void => {
  outputArray.push({
    id: currentBranch.id,
    path: currentPath,
    label: currentBranch.label?.label || "",
    factIds: currentBranch.associatedFacts?.map((f) => f.id),
    linkbaseTreeElement: currentBranch.linkbaseTreeElement,
    hasChildren: currentBranch.hasChildren,
    childrenLoaded:
      (currentBranch.hasChildren || false) && currentBranch.children.length > 0,
    internalId: currentBranch.unmappedBranchId,
    parentRoleTypeId: currentBranch.backendElementId,
    errorLoadingChildren: currentBranch.errorLoadingChildren,
  });
  for (const childBranch of currentBranch.children) {
    const newPath = `${currentPath};${currentBranch.id}`;
    addByBranchesRecursive(childBranch, newPath, outputArray);
  }
};

export const getTaxonomyExplorerNodeFlatArray = (
  paddedTree: MappedLinkebaseTree | undefined
): TreeItemIdentifier[] => {
  const output: TreeItemIdentifier[] = [];
  if (!paddedTree) return output;
  addByBranchesRecursive(paddedTree.topBranch, paddedTree.topBranch.id, output);
  return output;
};
