export const getUniqueArray = (arr: any[], props?: string[]) => {
  if (props) {
    return [
      ...new Map(
        arr.map((entry) => [
          props
            .map((k) =>
              k.includes(".")
                ? entry[k.split(".")[0]][k.split(".")[1]]
                : entry[k]
            )
            .join("|"),
          entry,
        ])
      ).values(),
    ];
  } else {
    return arr.filter((x, i, a) => a.indexOf(x) === i);
  }
};
