import { ValidationTextHelper } from "../validationSummaryComponents/validationTextHelper";

/* Mui Accordion */
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Box, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { allCheckers } from "../../../helpers/validationCheckers";
import { t, Trans } from "@lingui/macro";
import { startValidationBackground } from "../../../reducers/validationReducer";
import { useAppDispatch } from "../../../hooks/useAppDisplatch";
import { getCachedPackage } from "../../../helpers/getCachedPackage";
import { LoadingButton } from "@mui/lab";
import { useMemo } from "react";
import { getUniqueErrorMessages } from "../../../helpers/getUniqueErrorMessages";
import { IApiFact, ValidationElement } from "../../../api/types";
import { emailprefix, emailSuffix } from "../../../helpers/constants";
import { LinkMailto } from "../mailToParsePort";
import { isReportingEntityNameMatch } from "../../../helpers/isReportingEntityNameMatch";

interface ValidationSummaryProps {
  internalTaxonomyValidationEntries: ValidationElement[];
}

export const ValidationSummary = ({
  internalTaxonomyValidationEntries,
}: ValidationSummaryProps) => {
  const pendingFiles = useAppSelector((state) => state.import.pendingFiles);

  const facts = useAppSelector((state) => state.extract.facts);

  const extractedFactsType = useAppSelector((state) => state.extract.factType);

  const validationResult = useAppSelector(
    (state) => state.validate.validationResult
  );

  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );

  const downloadedPayloadName = useAppSelector(
    (state) => state.import.downloadedPayloadName
  );
  const GleifData = useAppSelector((state) => state.auxData.gleifData);

  const dispatch = useAppDispatch();

  const calculationType = useAppSelector(
    (state) => state.import.calculationType
  );

  const downloadedPayloadUrl = useAppSelector(
    (state) => state.import.downloadedPayloadUrl
  );
  const validationPending = useAppSelector(
    (state) => state.validate.validationPending
  );

  const handleClick = async () => {
    let fileToValidate = null;
    if (extractedFactsType === "standard") {
      const blob = await getCachedPackage(downloadedPayloadUrl);
      fileToValidate = new File([blob], downloadedPayloadName);
    } else {
      fileToValidate = pendingFiles[0];
    }

    dispatch(
      startValidationBackground({
        calculationType: calculationType,
        packageFile: fileToValidate,
        abortSignal: undefined,
      })
    );
  };

  const allcheckersSaved = useMemo(
    () => allCheckers(validationResult),
    [validationResult]
  );

  const combinedData: ValidationElement[] = getUniqueErrorMessages(
    internalTaxonomyValidationEntries.map((entry) => ({
      ...entry,
      message: entry.message,
    }))
  );

  const GleifValidNameMemo = useMemo(() => {
    return extractedFactsType === "standard"
      ? isReportingEntityNameMatch(facts as IApiFact[], GleifData)
      : false;
  }, [GleifData, facts, extractedFactsType]);

  return (
    <>
      <Accordion
        defaultExpanded={true}
        sx={{ overflowX: "hidden", overflowY: "auto" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography data-downloadaspdf="validationsummaryheader" variant="h6">
            <Trans> Validation Summary </Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            minHeight: GleifValidNameMemo ? 315 : 370,
            maxHeight: GleifValidNameMemo ? 315 : 370,
          }}>
          <Grid>
            <Grid item xs={12}>
              {validationResult === null ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <LoadingButton
                    sx={{ mt: 10, pl: 5, pr: 5 }}
                    variant="outlined"
                    color="primary"
                    loading={validationPending}
                    onClick={() => {
                      handleClick();
                    }}>
                    <Trans>Validate</Trans>
                  </LoadingButton>
                </Box>
              ) : (
                <>
                  <Grid item xs={12} sm={12}>
                    {validationError && (
                      <Box
                        sx={{
                          pt: 5,
                          margin: "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}>
                        <Typography variant="h6">
                          <WarningAmberIcon
                            sx={{
                              fill: "rgba(253, 118, 14, 0.45)",
                              height: "100px",
                              width: "100px",
                            }}
                          />
                        </Typography>
                        <Typography
                          typography={
                            "h6"
                          }>{t`Can't validate this package`}</Typography>
                        <Box sx={{ position: "absolute", bottom: 2 }}>
                          <LinkMailto
                            label={t`Contact Support`}
                            mailto={"mailto:" + emailprefix + emailSuffix}
                          />
                        </Box>
                      </Box>
                    )}
                    {validationResult !== null && !validationError && (
                      <Stack
                        spacing={1}
                        divider={<Divider orientation="horizontal" flexItem />}>
                        <ValidationTextHelper
                          IconText={combinedData}
                          ActionText={t`Taxonomy Import:`}
                          SummaryText={combinedData}
                        />
                        <ValidationTextHelper
                          IconText={allcheckersSaved.TaxonomyCheck}
                          ActionText={t`Taxonomypackage Check:`}
                          SummaryText={allcheckersSaved.TaxonomyCheck}
                        />
                        <ValidationTextHelper
                          data-downloadaspdf="validationtsummaryvalue"
                          IconText={allcheckersSaved.xmlCheck}
                          ActionText={t`XML Schema Check:`}
                          SummaryText={allcheckersSaved.xmlCheck}
                        />
                        <ValidationTextHelper
                          data-downloadaspdf="validationtsummaryvalue"
                          IconText={allcheckersSaved.linkBaseCheck}
                          ActionText={t`Linkbase Check:`}
                          SummaryText={allcheckersSaved.linkBaseCheck}
                        />
                        <ValidationTextHelper
                          data-downloadaspdf="validationtsummaryvalue"
                          IconText={allcheckersSaved.entryPointsCheck}
                          ActionText={t`Entrypoint Check:`}
                          SummaryText={allcheckersSaved.entryPointsCheck}
                        />
                        <ValidationTextHelper
                          data-downloadaspdf="validationtsummaryvalue"
                          IconText={allcheckersSaved.xHTMLCheck}
                          ActionText={t`xHTML Check:`}
                          SummaryText={allcheckersSaved.xHTMLCheck}
                        />
                      </Stack>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
