import {
  MappedLinkbaseTreeItem,
  MappedLinkebaseTree,
} from "./mapLinkbaseTreeToTreeviewElement";

const CHILD_BRANCH_PAD_CUTOFF = 50;

const getFolderCountPerLevel = (
  totalNodeCount: number,
  folderCountPerLevel: number[]
): number[] => {
  if (folderCountPerLevel.length === 0) {
    let folderCount = totalNodeCount / CHILD_BRANCH_PAD_CUTOFF;
    while (folderCount > CHILD_BRANCH_PAD_CUTOFF) {
      folderCount = folderCount / CHILD_BRANCH_PAD_CUTOFF;
    }
    folderCountPerLevel.push(Math.ceil(folderCount));
    folderCountPerLevel = getFolderCountPerLevel(
      totalNodeCount,
      folderCountPerLevel
    );
  } else {
    const nodesToAcountFor =
      totalNodeCount / folderCountPerLevel.reduce((a, b) => a * b);
    if (nodesToAcountFor / CHILD_BRANCH_PAD_CUTOFF > 1) {
      folderCountPerLevel.push(
        Math.ceil(nodesToAcountFor / CHILD_BRANCH_PAD_CUTOFF)
      );
      folderCountPerLevel = getFolderCountPerLevel(
        totalNodeCount,
        folderCountPerLevel
      );
    }
  }
  return folderCountPerLevel;
};

const calculateTopItemRangeForLabel = (
  label: string,
  itemCount: number
): string => {
  const bottomRangeIndex = parseInt(
    label.split("###;;;###")[0]?.split("[")[
      label.split("###;;;###")[0]?.split("[").length - 1
    ]
  );
  let correctTopRangeIndex = 0;
  if (!isNaN(bottomRangeIndex)) {
    correctTopRangeIndex = bottomRangeIndex - 1 + itemCount;
  }
  return correctTopRangeIndex.toString();
};

const addSingleLevel = (
  branch: MappedLinkbaseTreeItem,
  elementsToAdd: number | MappedLinkbaseTreeItem[]
) => {
  if (Array.isArray(elementsToAdd)) {
    branch.label!.label = branch
      .label!.label.replace("###;;;###", "-")
      .replace(
        "placeholder!!!!!",
        calculateTopItemRangeForLabel(branch.label!.label, elementsToAdd.length)
      );
    branch.children.push(...elementsToAdd);
  } else {
    for (let x = 0; x < (elementsToAdd as number); x++) {
      const itemRangeLabel = `[${
        CHILD_BRANCH_PAD_CUTOFF * x + 1
      }###;;;###placeholder!!!!!]`;
      branch.children.push({
        children: [],
        id: `${branch.id}_${x}`,
        isExtension: false,
        label: {
          label: itemRangeLabel,
          language: branch.label?.language || "en",
          role: branch.label?.role || "",
        },
        type: "elementTree",
        hasChildren: branch.hasChildren,
        errorLoadingChildren: false,
        backendElementId: "",
        unmappedBranchId: "",
      });
    }
  }
};

const addSubLevelsRecursive = (
  branch: MappedLinkbaseTreeItem,
  folderCountPerLevel: number[],
  valueChildren: MappedLinkbaseTreeItem[],
  currentLevel: number,
  currentIndex: number
) => {
  if (currentLevel > folderCountPerLevel.length) return;

  addSingleLevel(
    branch,
    currentLevel === folderCountPerLevel.length
      ? valueChildren.slice(
          CHILD_BRANCH_PAD_CUTOFF * currentIndex,
          CHILD_BRANCH_PAD_CUTOFF * (currentIndex + 1)
        )
      : folderCountPerLevel[currentLevel]
  );
  currentLevel++;
  for (let z = 0; z < branch.children.length; z++) {
    addSubLevelsRecursive(
      branch.children[z],
      folderCountPerLevel,
      valueChildren,
      currentLevel,
      z
    );
  }
};

const padBranchRecursive = (branch: MappedLinkbaseTreeItem): void => {
  if (branch.children.length > CHILD_BRANCH_PAD_CUTOFF) {
    const folderCountPerLevel = getFolderCountPerLevel(
      branch.children.length,
      []
    );
    const allChildren = [...branch.children];
    branch.children = [];
    addSubLevelsRecursive(branch, folderCountPerLevel, allChildren, 0, 0);
  }
  for (const subBranch of branch.children) {
    padBranchRecursive(subBranch);
  }
};

export const padTree = (tree: MappedLinkebaseTree | undefined) => {
  if (!tree) return;
  padBranchRecursive(tree.topBranch);
};
