import { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as enMessages } from "./locales/en/messages";
import "./api/config";
import "./api/interceptors";
import MainNavigation from "./components/mainNavigation";
import { BrowserRouter } from "react-router-dom";
import { initAppInsights } from "./helpers/initAppInsights";
import NotoSansLatin500 from "./style/fonts/noto-sans-v27-latin-500.woff";
import NotoSansLatin500_2 from "./style/fonts/noto-sans-v27-latin-500.woff2";
import NotoSansLatin600 from "./style/fonts/noto-sans-v27-latin-600.woff";
import NotoSansLatin600_2 from "./style/fonts/noto-sans-v27-latin-600.woff2";
import NotoSansLatin700 from "./style/fonts/noto-sans-v27-latin-700.woff";
import NotoSansLatin700_2 from "./style/fonts/noto-sans-v27-latin-700.woff2";

function App() {
  i18n.load("en", enMessages);
  i18n.activate("en");

  useEffect(() => {
    initAppInsights();
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <BrowserRouter>
              <MainNavigation />
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </I18nProvider>
  );
}

// Use emotion cache to set nonce token on inline styles so they don't get blocked by CSP
const cache = createCache({
  key: "key",
  nonce:
    document.querySelector<HTMLMetaElement>('meta[property="csp-nonce"]')
      ?.content || "",
});

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#072a44",
    },
    secondary: {
      main: "#279F24",
      light: "#fff0b2",
    },
    background: {
      default: "#c4c6c6",
    },
    success: {
      main: "#2e7d32",
      light: "#d9f3be",
    },
    info: {
      light: "#73c4ff",
      main: "#0288d1",
    },
  },
  typography: {
    fontFamily: "Noto Sans",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 500;
        src: url(${NotoSansLatin500_2}) format('woff2'), 
             url(${NotoSansLatin500}) format('woff'); 
      }
      
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 600;
        src: url(${NotoSansLatin600_2}) format('woff2'), 
             url(${NotoSansLatin600}) format('woff'); 
      }
      
      @font-face {
        font-display: swap; 
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        src: url(${NotoSansLatin700_2}) format('woff2'), 
             url(${NotoSansLatin700}) format('woff'); 
      }`,
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: process.env.REACT_APP_COLOR ?? "#072a44",
        },
      },
    },
  },
});

export default App;
