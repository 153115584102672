import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { GleifDataArray, IApiMandatoryTag } from "../api/types";
import { getAllMandatoryTags } from "../api/taxonomies";
import { getGleifData } from "../api/summary";
import { getSignalrInternalTicket } from "../api/logging";

export interface IAuxDataState {
  mandatoryTags: IApiMandatoryTag[] | null;
  getMandatoryTagsPending: boolean;
  gleifData: GleifDataArray | null;
  gleifDataIsLoaded: boolean;
  getMandatoryTagsError: boolean;
  signalRTicket: string | undefined;
}

const initialState: IAuxDataState = {
  getMandatoryTagsError: false,
  getMandatoryTagsPending: false,
  mandatoryTags: null,
  gleifData: null,
  gleifDataIsLoaded: false,
  signalRTicket: undefined,
};

export const getAllMandatoryTagsStarted = createAsyncThunk(
  "auxData/mandatoryTags/getAll",
  async (_, { rejectWithValue }): Promise<IApiMandatoryTag[]> => {
    try {
      const resp = await getAllMandatoryTags();
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const getGleif = createAsyncThunk(
  "auxData/gleif/get",
  async (args: { Id: string }): Promise<GleifDataArray | null> => {
    const resp = await getGleifData(args.Id);
    if (resp.data && resp.data && resp.data.length === 0) return null;
    return resp;
  }
);

export const getSignalRTicket = createAsyncThunk(
  "auxData/signalr/ticket/get",
  async (): Promise<string | undefined> => {
    return getSignalrInternalTicket();
  }
);

const auxDataSlice: Slice<IAuxDataState> = createSlice({
  name: "auxData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMandatoryTagsStarted.pending, (state): IAuxDataState => {
        return {
          ...state,
          getMandatoryTagsPending: true,
          getMandatoryTagsError: false,
        };
      })
      .addCase(getAllMandatoryTagsStarted.rejected, (state): IAuxDataState => {
        return {
          ...state,
          getMandatoryTagsPending: false,
          getMandatoryTagsError: true,
        };
      })
      .addCase(
        getAllMandatoryTagsStarted.fulfilled,
        (state, action): IAuxDataState => {
          return {
            ...state,
            getMandatoryTagsPending: false,
            mandatoryTags: action.payload,
          };
        }
      )
      .addCase(getGleif.fulfilled, (state, action): IAuxDataState => {
        return {
          ...state,
          gleifData: action.payload,
          gleifDataIsLoaded: true,
        };
      })
      .addCase(getGleif.rejected, (state): IAuxDataState => {
        return {
          ...state,
          gleifData: null,
          gleifDataIsLoaded: true,
        };
      })
      .addCase(getSignalRTicket.fulfilled, (state, action): IAuxDataState => {
        return {
          ...state,
          signalRTicket: action.payload,
        };
      });
  },
});

export default auxDataSlice.reducer;
